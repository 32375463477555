// SuccessSentCode.js

import React from 'react';
import ImageSection from './ImageSection';
import { useNavigate } from 'react-router-dom';
import Nav01 from './Nav01';
import { MdCheck } from 'react-icons/md';

const SuccessSentCode = () => {
    const navigate =useNavigate();

    const continueButton = () => {
        navigate('/');
  };

  return (
    <>
     <Nav01/>
<div className="z-10  bg-sky-950 h-[90px] w-full"></div>
    <div className="flex w-full overflow-hidden bg-[#d4dde4] items-center justify-center min-h-screen py-7  px-10 max-lg:px-2">
    <div className="flex bg-white rounded-xl h-full max-sm:flex-col-reverse  overflow-hidden max-[765px]:overflow max-[765px]:flex-col w-full">
         <div className="p-8 w-2/5 flex flex-col justify-center min-h-screen max-sm:h-screen max-lg:py-40 max-lg:min-h-full rounded-xl items-center bg-white max-[765px]:w-full">
<div className='w-[100px] h-[100px] text-5xl text-white bg-[#315e7d] rounded-full flex justify-center items-center'>
    <MdCheck/>
</div>
                <h2 className='text-2xl font-bold'>Success!</h2>
      <p className='font-medium text-lg text-center w-9/12 mt-4'>Congratulations! You have been successfully authenticated</p>
      <button onClick={continueButton} className='bg-[#315e7d] w-full rounded-3xl py-3 text-white mt-7'>Continue</button>
    </div>
        <div className=" w-3/5 min-h-full max-sm:h-screen max-[765px]:h-screen max-[765px]:w-full">
          <ImageSection />
        </div>
        </div>
      </div>
   
</>
  );
};

export default SuccessSentCode;
