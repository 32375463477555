import React from "react";
import styles from "../style/door14.module.css";
import { motion } from "framer-motion";
import { Airdrop } from "../../DoorsImages/Doorimg19";

export default function S2Door() {
  return (
    <>
      <motion.div
        className={styles.section1aa}
        initial="hidden"
        animate="visible"
        transition={{ duration: 1 }}
      >
        <motion.div
          initial={{ x: "-15vw", opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{
            duration: 1,
          }}
          viewport={{ once: true }}
          className={styles.walletboxheading19}
        >
          <h2 className="text-white text-3xl text-left font-medium pt-3 ">
            Airdrops can be conducted in two ways:
          </h2>
          <p className="text-white mt-6 text-xl tracking-widest">
            (1) assets are transferred directly into users’ wallets or
            <br />
            {/* <br/> */}
            (2) users can claim their airdrop by visiting the project’s website.
            <br />
          </p>
          <p className="text-slate-200 italic	 mt-6 text-xl tracking-widest">
            When an airdrop is announced, the project usually discloses the
            eligibility criteria. To farm airdrops in DeFi, it is important to
            stay active on chain, stake your cryptocurrency on different chains,
            focus your airdrop farming on a few projects, interact with new
            protocols, and stay up to date.
          </p>
        </motion.div>
      </motion.div>
    </>
  );
}
