import React from "react";
import styles from "../style/door14.module.css";
import Accordion from "./Acordition";
import { motion } from "framer-motion";

import {
  Mask10,
  Mask11,
  Mask12,
  Mask13,
  Mask14,
  Mask15,
  Mask16,
  Mask17,
  Mask9,
} from "../../DoorsImages/Doorimg14";

export default function SDoor() {
  return (
    <div className={styles.section2}>
      <motion.div
        initial={{  opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{
          duration: 2,
        }}
        viewport={{ once: true }}
        className={styles.nftboxheading2}
      >
        <h1 className="text-white text-center font-bold text-3xl tracking-wide">
          Advantages of Interoperability
        </h1>
      </motion.div>
      <div className={styles.imgboxcard}>
        <div className={styles.imgboxcard1}>
          <motion.div
            initial={{ x: "-15vw", opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{
              duration: 1,
            }}
            viewport={{ once: true }}
            className={styles.cards}
          >
            <div className={styles.imuu}>
              <img className={styles.padlock} src={Mask9} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Enhanced Ecosystem"
                content="Interoperability fosters a more interconnected and collaborative DeFi ecosystem, allowing different projects to share data and assets."
              ></Accordion>
            </div>
          </motion.div>
          <motion.div
            initial={{ x: "15vw", opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{
              duration: 1,
            }}
            viewport={{ once: true }}
            className={styles.cards}
          >
            <div className={styles.imuu}>
              <img className={styles.baht} alt="img1" src={Mask10} />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Diversity"
                content=" Users and developers can choose from a variety of blockchain networks based on their specific needs and preferences."
              ></Accordion>
            </div>
          </motion.div>
          </div>
          </div>
          <div className={styles.imgboxcard}>
        <div className={styles.imgboxcard2}>
          <motion.div
            initial={{ x: "-15vw", opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{
              duration: 1,
            }}
            viewport={{ once: true }}
            className={styles.cards}
          >
            <div className={styles.imuu}>
              <img className={styles.mask1} src={Mask11} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Scalability"
                content="Interoperability can contribute to scaling solutions by allowing decentralized applications (DApps) to offload certain tasks to other blockchains."
              ></Accordion>
            </div>
          </motion.div>
</div>
</div>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{
              duration: 1,
            }}
            viewport={{ once: true }}
            className={styles.nftboxheading2}
          >
            <h1 className="text-white text-center font-bold text-3xl tracking-wide">
              Disadvantages of Interoperability
            </h1>
          </motion.div>
          <div className={styles.imgboxcard}>
        <div className={styles.imgboxcard1}>
          <motion.div
            initial={{ x: "-15vw", opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{
              duration: 1,
            }}
            viewport={{ once: true }}
            className={styles.cards}
          >
            <div className={styles.imuu}>
              <img className={styles.mask1} src={Mask14} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Complexity"
                content=" Achieving interoperability can be technically challenging, requiring standardized protocols and consensus mechanisms across different blockchains."
              ></Accordion>
            </div>
          </motion.div>
          <motion.div
            initial={{ x: "15vw", opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{
              duration: 1,
            }}
            viewport={{ once: true }}
            className={styles.cards}
          >
            <div className={styles.imuu}>
              <img className={styles.mask1} src={Mask15} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Security Concerns"
                content="Interconnected systems may introduce additional security risks, as vulnerabilities on one blockchain could potentially impact others in the network."
              ></Accordion>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
}
