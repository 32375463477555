


import React from 'react'
import { Crpto1,  Crpto2 ,  Crpto3 , Crpto4, WalletPicture } from '../assets/Images/index.js'
import {AnimatePresence, motion} from "framer-motion";
const GiftDetails = () => {
  return (
    <div>
    <AnimatePresence>
      <motion.div 
      initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
            className="relative w-full p-4 px-4 mx-auto bg-white sm:px-6 lg:px-8 py-26 lg:mt-20">
        <motion.div initial={{ opacity: 0 }}
        transition={{duration:"0.7"}}
              whileInView={{ opacity: 1 }}
              exit={{ opacity: 0 }}   
                       viewport={{once:true}} className="relative w-full flex flex-col justify-center">
              <h2 className="mt-2 text-4xl  font-bold leading-8 text-gray-900 text-center  sm:text-5xl sm:leading-9">
                What do the recipients get?
              </h2>
              <motion.div className='w-full flex flex-col items-center justify-center'>
              <p className="py-10 leading-10 tracking-widest max-sm:text-xl sm:text-3xl max-w-6xl text-center">
                They’ll experience the magic of blockchain in an entertaining 10-day journey. Our surprise boxes and calendars may include:
              </p>
              <motion.div className='w-full py-5 flex h-fit flex-col items-center justify-center'>

              <ul className="gap-6 md:grid md:grid-cols-2">
       

                <li className="mt-6 lg:mt-0">
                  <div className="flex ">
                    <span className="flex items-center justify-center flex-shrink-0 w-6 h-6 text-green-800 bg-transparent-100 rounded-full  ">
                      <svg className="w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd">
                        </path>
                      </svg>
                    </span>
                    <span className="ml-4 max-sm:text-xl sm:text-3xl  leading-6  ">
                    10 Learnings
                    </span>
                  </div>
                </li>


                <li className="mt-6 lg:mt-0">
                  <div className="flex">
                    <span className="flex items-center justify-center flex-shrink-0 w-6 h-6 text-green-800 bg-transparent-100 rounded-full  ">
                      <svg className="w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd">
                        </path>
                      </svg>
                    </span>
                    <span className="ml-4 max-sm:text-xl sm:text-3xl   leading-6  ">
                    1 Wallet
                    </span>
                  </div>
                </li>


                <li className="mt-6 lg:mt-0">
                  <div className="flex">
                    <span className="flex items-center justify-center flex-shrink-0 w-6 h-6 text-green-800 bg-transparent-100 rounded-full  ">
                      <svg className="w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd">
                        </path>
                      </svg>
                    </span>
                    <span className="ml-4 max-sm:text-xl sm:text-3xl   leading-6  ">
                    Art or AI generated NFT's
                    </span>
                  </div>
                </li>
                <li className="mt-6 lg:mt-0">
                  <div className="flex">
                    <span className="flex items-center justify-center flex-shrink-0 w-6 h-6 text-green-800 bg-transparent-100 rounded-full  ">
                      <svg className="w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd">
                        </path>
                      </svg>
                    </span>
                    <span className="ml-4 max-sm:text-xl sm:text-3xl  leading-6  ">
                     Gaming NFTs
                    </span>
                  </div>
                </li>

                <li className="mt-6 lg:mt-0">
                  <div className="flex">
                    <span className="flex items-center justify-center flex-shrink-0 w-6 h-6 text-green-800 bg-transparent-100 rounded-full  ">
                      <svg className="w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd">
                        </path>
                      </svg>
                    </span>
                    <span className="ml-4 max-sm:text-xl sm:text-3xl   leading-6  ">
                     Perks from Partners
                    </span>
                  </div>
                </li>
        

          
{/* 
                <li className="mt-6 lg:mt-0">
                  <div className="flex">
                    <span className="flex items-center justify-center flex-shrink-0 w-6 h-6 text-green-800 bg-transparent-100 rounded-full  ">
                      <svg className="w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd">
                        </path>
                      </svg>
                    </span>
                    <span className="ml-4 text-xl text-base  leading-6  ">
                    Lifelong Access to an extensive learning section
                    </span>
                  </div>
                </li> */}


                <li className="mt-6 sm:hidden lg:mt-0">
                  <div className="flex">
                    <span className="flex items-center justify-center flex-shrink-0 w-6 h-6 text-green-800 rounded-full  ">
                      <svg className="w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd">
                        </path>
                      </svg>
                    </span>
                    <span className="ml-4 max-sm:text-xl sm:text-3xl   leading-6  ">
                    Access to an extensive learning section (still in construction)
                    </span>
                  </div>
                </li>


              </ul> 
              </motion.div>         
        </motion.div>
        </motion.div>
      </motion.div>
</AnimatePresence>
    </div>
  )
}

export default GiftDetails



// import React from 'react';
// import { MenUsingMobile } from '../assets/Images/WhoItFor';


// const GiftDetails = () => {
//   return (
//     <div className="flex flex-col sm:my-10 md:flex-row justify-center items-center p-6 bg-sky-950 rounded-3xl">
//       Left Section
//       <div className="md:w-1/2 max-w-screen-xl hidden max-[280px]:hidden mx-auto mb-4 md:mb-0">
//         <div className="da relative flex max-sm:rounded-3xl flex-col justify-center overflow-hidden">
//           <div className="absolute flex flex-row inset-0 bg-center dark:bg-black"></div>
//           <div className="group relative m-0 flex h-96 w-96 rounded-xl shadow-xl ring-gray-900/5 sm:mx-auto sm:max-w-lg">
//             <div className="z-10 h-full w-full overflow-hidden max-sm:w-80 mx-auto rounded-3xl max-sm:h-80 border border-gray-200 opacity-80 transition duration-300 ease-in-out group-hover:opacity-100 dark:border-gray-700 dark:opacity-70">
//               <img src={MenUsingMobile} className="animate-fade-in block max-sm:w-80 mx-auto rounded-3xl max-sm:h-80 h-full w-full scale-100 transform object-cover object-center opacity-100 transition duration-300 group-hover:scale-110" alt="" />
//             </div>
//           </div>
//         </div>
//       </div>
//       {/* Right Section */}
//       <div className="md:w-1/2 sm:mx-5">
//         {/* Gift Details */}
//         <div className="text-center max-sm:text-left text-white md:text-left">
//           <h2 className="font-bold text-2xl md:text-4xl max-sm:text-center max-sm:text-2xl mb-2">
//             What do the receivers get?
//           </h2>
//           <p className="text-lg text-center max-sm:my-8 mb-6">
//             They’ll experience the magic of blockchain in an entertaining 25-day journey.
//           </p>
//         </div>

//         {/* Gift Contents */}
//         <div className="mt-6 text-white">
//           <div className="flex flex-wrap text-white justify-between">

//             {/* Perks */}

//             <div className="w-full text-white md:w-1/2 mb-4">
//               <div className="flex items-center">
//                 <span className="text-3xl mr-2">&#9733;</span>
//                 <p className="text-white text-lg font-semibold">2 Perks from Partners</p>
//               </div>
//             </div>

//             {/* Learnings */}
//             <div className="w-full md:w-1/2 mb-4">
//               <div className="flex items-center">
//                 <span className="text-3xl mr-2">&#9733;</span>
//                 <p className="text-lg font-semibold">25 Learnings</p>
//               </div>
//             </div>

//             {/* Art NFTs */}
//             <div className="w-full md:w-1/2 mb-4">
//               <div className="flex items-center">
//                 <span className="text-3xl mr-2">&#9733;</span>
//                 <p className="text-white text-lg font-semibold">5 Art of KI generated NFTs</p>
//               </div>
//             </div>

//             {/* Gaming NFTs */}
//             <div className="w-full md:w-1/2 mb-4">
//               <div className="flex items-center">
//                 <span className="text-3xl mr-2">&#9733;</span>
//                 <p className="text-white text-lg font-semibold">3 Gaming NFTs</p>
//               </div>
//             </div>

//             {/* Wallet */}
//             <div className="w-full md:w-1/2 mb-4">
//               <div className="flex items-center">
//                 <span className="text-3xl mr-2">&#9733;</span>
//                 <p className="text-white text-lg font-semibold">1 Wallet</p>
//               </div>
//             </div>

//             {/* Learning Access */}
//             <div className="w-full md:w-1/2 mb-4">
//               <div className="flex items-center">
//                 <span className="text-3xl mr-2">&#9733;</span>
//                 <p className="text-white text-lg font-semibold">Lifelong Access to an extensive learning section</p>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default GiftDetails;