import React, { useState } from 'react';
import { FcGoogle } from 'react-icons/fc';
import { FaApple, FaRegEye, FaRegEyeSlash } from 'react-icons/fa';
import { useSignIn, useSignUp } from '@clerk/clerk-react';
import { Link } from 'react-router-dom';
import ForgotPassword from './ForgotPassword';
import "./Styles/main.css";

const Authform = ({ loginMethod, setLoginMethod }) => {
  const [isSigninMode, setisSigninMode] = useState(true);
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [emailAddress, setEmailAddress] = useState('');
  const [verifying, setVerifying] = useState(false);
  const [code, setCode] = useState('');
  const [username, setUsername] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const { isLoaded, signUp, setActive } = useSignUp();
  const { signIn } = useSignIn();
  const [forgetModal, setForgetModal] = useState(false);
  const [isCorrectVerificationCode, setIsCorrectVerificationCode] =
  useState(false);

  const CloseforgetModal = ()=>{
    setForgetModal(!forgetModal);
  }

  //console.log("hello",emailAddress)
  //console.log("hello",emailAddress)
  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleResendCode = () => {
    setIsCorrectVerificationCode(false); // Reset the flag on resend
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isLoaded) {
      return;
    }
 //console.log("hello",emailAddress)
    try {
      await signUp.create({
        emailAddress,
        password,
     
        firstName,
        lastName,
        
      });
 
      await signUp.prepareEmailAddressVerification({ strategy: "email_code" });
 
      setVerifying(true);
    } catch (err) {
      console.error(JSON.stringify(err, null, 2));
      //console.log(JSON.stringify(err.errors[0].longMessage));

      setError(err.errors[0].longMessage)
      
    }
  };
    // Handle the submission of the sign-in form
    const handlesigninSubmit = async (e) => {
        e.preventDefault();
        if (!isLoaded) {
          return;
        }
     
        // Start the sign-in process using the email and password provided
        try {
          const completeSignIn = await signIn.create({
            identifier: emailAddress,
            password,
          });
     
          if (completeSignIn.status !== 'complete') {
            // The status can also be `needs_factor_on', 'needs_factor_two', or 'needs_identifier'
            // Please see https://clerk.com/docs/references/react/use-sign-in#result-status for  more information
            //console.log(JSON.stringify(completeSignIn, null, 2));
          }
     
          if (completeSignIn.status === "complete") {
            // If complete, user exists and provided password match -- set session active
            await setActive({ session: completeSignIn.createdSessionId });
            // redirect the user how you see fit.
            window.location.href = "/";
          }

        } catch (err) { 
          // This can return an array of errors.
          // See https://clerk.com/docs/custom-flows/error-handling to learn about error handling
          console.error(JSON.stringify(err, null, 2));
          if(err.errors[0].longMessage === "The verification strategy is not valid for this account"){
            setError("Please log in using the same method you used to create the account.(Google,Apple,Email)")  
          }
          else{
            setError(err.errors[0].longMessage);
          }
         
        }
      };
 
  const handleVerify = async (e) => {
    e.preventDefault();
    if (!isLoaded) {
      return;
    }
 
    try {
      const completeSignUp = await signUp.attemptEmailAddressVerification({
        code,
      });
 
      if (completeSignUp.status !== "complete") {
        //console.log(JSON.stringify(completeSignUp, null, 2));
      }
 
      if (completeSignUp.status === "complete") {
        await setActive({ session: completeSignUp.createdSessionId })
        // Handle your own logic here, like redirecting to a new page if needed.
        window.location.href = "/";
      }
    } catch (err) {
      console.error(JSON.stringify(err, null, 2));
    }
  };
     
    const signInWith = (strategy) => {
      return signIn.authenticateWithRedirect({
        strategy,
        redirectUrl: "/sso-callback",
        redirectUrlComplete: "/",
      });
    }

  return (
    <div className=''>
       <div className="flex mb-4 px-2 bg-[#86a1b3] w-full rounded-full overflow-hidden py-2 flex items-center justify-center text-white mt-7">
        <button
          className={`mr-2   py-2 w-6/12 rounded-full font-medium text-xl  ${
            isSigninMode
              ? "bg-white text-black "
              : "bg-transparent-100"
          }`}
          onClick={() => setisSigninMode(true)}
        >
          Signin
        </button>
        <button
          className={` py-2 w-1/2 rounded-full font-medium text-xl ${
            !isSigninMode
              ? "bg-white text-black"
              : "bg-transparent-200"
          }`}
          onClick={() => setisSigninMode(false)}
        >
          Signup
        </button>
      </div>
      {isSigninMode ? (
        <div>
          <label htmlFor="">Email</label>
          <input
            type="email"
            placeholder="Enter Your Email Address"
            value={emailAddress}
            onChange={(e) => setEmailAddress(e.target.value)}
            className="mb-4 p-2 px-4 w-full rounded-full border border-gray-300"
          />
          <label htmlFor="">Password</label>
          <div className="relative w-full flex items-center">
            <input
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              className="mb-4 p-2 px-4 w-full rounded-full border border-gray-300"
            />
          
            <button
              type="button"
              onClick={handleTogglePassword}
              className="absolute right-4 transform -translate-y-1/2 cursor-pointer"
            >
              {showPassword ? <FaRegEye /> : <FaRegEyeSlash />}
            </button>
          </div>
{/* {forgetModal && ( */}
  <button onClick={CloseforgetModal} >
           Forgot Password? 
          </button>
          { forgetModal && (
            <ForgotPassword CloseforgetModal={CloseforgetModal}/>
)}
          
          {/* <div>
            <input type="checkbox" className="" name="" id="" />
            <label htmlFor="" className="ml-3">
              Remember me
            </label>
          </div> */}
          <div className='text-red-600'> {error}</div>
          <button
        className="mb-4 w-full mt-10 bg-[#86a1b3] hover:bg-sky-900/70 font-semibold rounded-3xl py-3 text-lg text-white"
        onClick={handlesigninSubmit}
      >
        {isSigninMode? "Login " : "Register Now"}
      </button>
        </div>
      ) : (
        <div>
          <div className="relative w-full gap-4 max-lg:gap-0 max-lg:flex-col flex items-center">
            <div  className='w-1/2 max-lg:w-full'>
              <label htmlFor="">First Name</label>
              <input
                type="text"
                placeholder="Miki"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                className="mb-4 p-2 px-4 w-full rounded-full border border-gray-300"
              />
            </div>
            <div  className='w-1/2 max-lg:w-full'>
              <label htmlFor="">Last Name</label>
              <input
                type="text"
                placeholder="Surname "
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                className="mb-4 p-2 px-4 w-full rounded-full border border-gray-300"
              />
            </div>
          </div>
      
          <label htmlFor="">Email Address</label>
          <input
            type="email"
            placeholder="Enter Your Email Address"
            value={emailAddress}
            onChange={(e) => setEmailAddress(e.target.value)}
            className="mb-4 p-2 px-4 w-full rounded-full border border-gray-300"
          />
          <label htmlFor="">Password</label>
          <div className="relative w-full flex items-center">
            <input
              type={showPassword ? 'text' : 'password'}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              value={password}
              className="mb-4 p-2 px-4 w-full rounded-full border border-gray-300"
            />
            <button
              type="button"
              onClick={handleTogglePassword}
              className="absolute right-4 transform -translate-y-1/2 cursor-pointer"
            >
              {showPassword ? <FaRegEye /> : <FaRegEyeSlash />}
            </button>
          </div>
          <div className='text-red-600'><p>{error}</p> </div>
          <button
        className="mb-4 w-full mt-10 bg-[#86a1b3] hover:bg-sky-900/70  font-semibold rounded-3xl py-3 text-lg text-white"
        onClick={handleSubmit}
      >
        {isSigninMode === "true" ? "Login " : "Register Now"}
      </button>
        </div>
      )}
      {verifying && (
          <form onSubmit={handleVerify} className="space-y-6">
            <div className="space-y-1 text-sm">
              <label htmlFor="code" className="block mb-2 font-medium text-gray-900 dark:text-gray-500">
                Verification Code
              </label>
              <input value={code} placeholder="Code..." onChange={(e) => setCode(e.target.value)} id="code" name="code" type="text" required className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"/>
            </div>
            <button type="submit" className="w-full py-2 px-4 bg-[#86a1b3] hover:bg-[#668294] focus:ring-blue-500 focus:outline-none focus:ring focus:ring-opacity-50 transition duration-150 ease-in-out text-white font-semibold text-lg tracking-widest shadow-lg focus:shadow-xl active:shadow-xl disabled:opacity-50 disabled:cursor-not-allowed block rounded-full py-3">
              Verify Email
            </button>
          </form>
        )}
      {isSigninMode === "false" &&
       
        !isCorrectVerificationCode && (
          <a
            href="#"
            className={`w-full flex justify-center text-[#0081CA]
          `}
            onClick={handleResendCode}
          >
            Resend Code
          </a>
        )}
          <p className="text-[#B5B5B5] flex justify-center text-xl py-4 font-semibold">
        or login with{" "}
      </p>
      <div className="flex items-center justify-center">
        
       <section style={{display: 'flex', justifyContent: 'space-between'}}>
    <button onClick={() => signInWith("oauth_google")} className="text-5xl rounded-3xl p-4">
        <FcGoogle/>
    </button>
    <button onClick={() => signInWith("oauth_apple")} className="text-5xl rounded-3xl p-4">
        <FaApple/>
    </button>
</section>
      </div>
      
      {!isSigninMode  ? (
        <p className="mt-4 flex justify-center text-xl "
        >
           If You have an account? {/* {loginMethod === "email" ? ( */}
          <button
          onClick={() => setisSigninMode(true)}
           className="font-semibold ml-2 text-[#5e798a] ">
            Sign in
          </button>
          {/* ) } */}
        </p>
      ) : (
        <p className="mt-4 flex justify-center text-xl max-sm:text-lg ">
    
        You don't have an account? {" "}
        <button className="font-semibold ml-2 max-sm:w-20 text-[#5e798a]  " 
        onClick={() => setisSigninMode(false)}
        >
          Sign up
        </button>
      </p>
    )}
    </div>
  );
};

export default Authform;
