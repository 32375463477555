import React from "react";
import styles from "../style/door4.module.css";
import Accordion from "./Acordition";
import {motion} from "framer-motion";
import { Automatic, Decentralization, SmartContracts, TokenOwner, Transparent } from "../../DoorsImages/Doorimg18";



export default function SDoor() {
  return (
    <div className={styles.section2}>
      <motion.div
      initial={{
              x: "-15vw",opacity:0
            }}
            whileInView={{ x: 0,opacity:1}}
            transition={{
              duration:1
            }}
            viewport={{once:true}}
       className={styles.nftboxheading2}>
        <p className="text-white text-3xl font-extrabold tracking-widest">
        Key features of DAOs include:
        </p>
      </motion.div>
      <div className={styles.imgboxcard}>
        <div className={styles.imgboxcard1}>
        <motion.div
      initial={{
              x: "-15vw",opacity:0
            }}
            whileInView={{ x: 0,opacity:1}}
            transition={{
              duration:1
            }}
            viewport={{once:true}}
           className={styles.cards}>
            <div className={styles.imuu}>
              <img className={styles.padlock} src={SmartContracts} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Smart Contracts: "
                content="DAOs operate through smart contracts, which are self-executing contracts with the terms of the agreement directly written into code. These contracts automatically execute actions when predefined conditions are met."
              ></Accordion>
            </div>
          </motion.div>
          <motion.div
      initial={{
              x: "15vw",opacity:0
            }}
            whileInView={{ x: 0,opacity:1}}
            transition={{
              duration:1
            }}
            viewport={{once:true}}
             className={styles.cards}>
            <div className={styles.imuu}>
              <img className={styles.baht} alt="img1" src={TokenOwner} />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Token-based Governance:"
                content="DAO members usually hold tokens that represent their stake or voting power within the organization. Decisions, such as approving proposals or making changes to the DAO's rules, are often determined by a vote based on the number of tokens held by each member."
              ></Accordion>
            </div>
          </motion.div>
          </div>
          </div>
          <div className={styles.imgboxcard}>
        <div className={styles.imgboxcard1}>
          <motion.div
      initial={{
              x: "-15vw",opacity:0
            }}
            whileInView={{ x: 0,opacity:1}}
            transition={{
              duration:1
            }}
            viewport={{once:true}}
           className={styles.cards}>
            <div className={styles.imuu}>
              <img className={styles.mask1} src={Transparent} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Transparency:"
                content="DAOs are designed to be transparent, with all rules and transactions recorded on a blockchain. This transparency helps ensure accountability and builds trust among members."
              ></Accordion>
            </div>
          </motion.div>
          <motion.div
      initial={{
              x: "15vw",opacity:0
            }}
            whileInView={{ x: 0,opacity:1}}
            transition={{
              duration:1
            }}
            viewport={{once:true}}
             className={styles.cards}>
            <div className={styles.imuu}>
              <img className={styles.mask2} src={Decentralization} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Decentralization: "
                content="DAOs aim to distribute decision-making power and control among their members, reducing the reliance on a central authority. This decentralization is achieved through the use of blockchain technology."
              ></Accordion>
            </div>
          </motion.div>
</div>
</div>
<div className={styles.imgboxcard}>
        <div className={styles.imgboxcard2}>

          <motion.div
      initial={{
              x: "-15vw",opacity:0
            }}
            whileInView={{ x: 0,opacity:1}}
            transition={{
              duration:1
            }}
            viewport={{once:true}}
           className={styles.cards}>
            <div className={styles.imuu}>
              <img className={styles.curency} src={Automatic} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Autonomy"
                content="DAOs are meant to operate autonomously, with decisions and actions being executed automatically based on the rules encoded in the smart contracts. This reduces the need for intermediaries or centralized management."
              ></Accordion>
            </div>
          </motion.div>

        </div>
      </div>

      <motion.div
      initial={{
              x: "-15vw",opacity:0
            }}
            whileInView={{ x: 0,opacity:1}}
            transition={{
              duration:1
            }}
            viewport={{once:true}}
       className={styles.nftboxheading2}>
        <p className="text-white p-5 text-xl tracking-widest">
        DAOs have been used for various purposes, including managing decentralized finance (DeFi) protocols, funding projects through token sales, and making collective decisions within a community. However, it's important to note that DAOs are not without challenges, and issues such as security vulnerabilities in smart contracts or the need for effective governance mechanisms have been subjects of discussion within the blockchain community.
        </p>
      </motion.div>






      {/* <div className={styles.imgboxcard}>
        <div className={styles.imgboxcard1}>
         
          <div className={styles.cards}>
            <div className={styles.imuu}>
              <img className={styles.mask1} src={Safety} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Risk Management"
                content="Off-ramps provide a way for investors to manage their risk exposure. If the value of a particular cryptocurrency is volatile, users may choose to exit the market and convert their digital assets to fiat to avoid potential losses."
              ></Accordion>
            </div>
          </div>
          <div className={styles.cards}>
            <div className={styles.imuu}>
              <img className={styles.mask1} src={Receipt} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Regulatory Compliance"
                content="Off-ramps are often subject to regulatory frameworks, ensuring that transactions involving cryptocurrencies comply with existing laws. This is important for preventing illegal activities such as money laundering and fraud."
              ></Accordion>
            </div>
          </div>
          <div className={styles.cards}>
            <div className={styles.imuu}>
              <img className={styles.mask1} src={Mask3} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Consumer Confidence"
                content="The availability of reliable off-ramps can enhance consumer confidence in the cryptocurrency market. Users are more likely to invest in and use cryptocurrencies if they have a clear and secure means of converting their digital assets back into traditional currency."
              ></Accordion>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}
