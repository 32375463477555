import React, { useState, useEffect } from 'react';
// import ReactDOM from 'react-dom';
import { Picture1, Picture2, Picture3, Picture4 } from "../assets/Images";
import "./Styles/Hero.css";
import { Link } from 'react-router-dom';
import EmailModal from './EmailModal';
import { useApproval } from '../states/ApprovalContext';
import { useUser } from "@clerk/clerk-react";
import { AnimatePresence, motion } from "framer-motion";
import { MdOutlineKeyboardDoubleArrowDown } from "react-icons/md";

const images = [Picture1, Picture2, Picture3, Picture4];

const HeroSection = () => {
  const { user } = useUser();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const { isApproved } = useApproval();
  const [click, setClick] = useState(false);

  const scrollTo = (id) => {

    let element = document.getElementById(id);

    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest'
    })

    setClick(!click);
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000);

    return () => clearInterval(interval);
  }, []);



  return (

    <div className="relative " >
    <AnimatePresence>
      <motion.section initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }} className="text-gray-700  dark:text-white ">
        <img src={images[currentImageIndex]} alt="" className="object-cover duration-150 ease-in w-full h-screen -z-10" />
        {/* <div className="absolute top-0 left-0 w-full h-screen bg-sky-900/60 "> */}
        <div className="absolute top-0 left-0 w-full h-screen bg-gray-900/60 ">

          <div className=" mx-auto flex flex-col hover:backdrop-blur-sm justify-center items-center h-full px-4 py-16 text-center">
            {/* <h1 className="text-3xl sm:text-5xl md:text-6xl  mb-6">
              <strong className=" text-3xl sm:text-5xl md:text-6xl  mb-6 text-gray-100">
                <span className="text-2xl  sm:text-4xl">Welcome to <span className='text-2xl  sm:text-3xl'>MyWeb3Gift</span>.com</span>
              </strong>
            </h1> */}

            <motion.h1
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 1, ease: "easeIn", delay: "0.6" }}
              data-Scroll data-scroll-speed="0.05"
              viewport={{once:true}}
              
              className=" py-1 text-gray-100 font-extrabold text-5xl lg:text-6xl">
             MyWeb3Gift
            </motion.h1>

            <motion.h1
              data-Scroll data-scroll-speed="0.03"
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}

              transition={{ duration: 1, ease: "easeIn",delay:"0.7" }}
              className=" pt-4 w-3/5 text-gray-100 max-[765px]:w-full font-semibold text-2xl lg:text-3xl ">
              {/* Surprise your loved ones with a unique digital gift box to <br className='max-sm:hidden' /> discover the magic of blockchain */}
              We make web3 onboarding easy and joyful for everybody.
            </motion.h1>
            <motion.h1
              data-Scroll data-scroll-speed="0.03"
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 1, ease: "easeIn",delay:"0.7" }}
              className="w-3/5 pb-4 text-gray-100 max-[765px]:w-full font-semibold text-2xl lg:text-3xl ">             
We are the home of Web3 gifts.
            </motion.h1>
            <div className="flex flex-wrap justify-center gap-4">
              <motion.button
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 1, ease: "easeIn", delay: "1" }}
                onClick={() => scrollTo('product')} className='block font-medium sm:w-auto rounded bg-gradient-to-r from-yellow-400 to-yellow-600 px-6 py-2 text-sm  text-white shadow hover:bg-yellow-600 hover:from-yellow-500 hover:to-yellow-600 focus:outline-none focus:ring active:bg-red-500'>
                Start here
              </motion.button>

              {/* <Link to='icon'> click here</Link> */}
            </div>



            <div  onClick={() => scrollTo('product')} className='z-100 absolute bottom-0 max-md:text-5xl  text-white text-7xl  animate-bounce '>  <MdOutlineKeyboardDoubleArrowDown /> </div>
          </div>

        </div>
      </motion.section>
      </AnimatePresence>
    </div>
  );
};

export default HeroSection;
