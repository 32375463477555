import React, { useState, useRef,useEffect } from 'react';
import { Menu } from '@headlessui/react';
import { Link } from 'react-router-dom';
import { FaBahai } from "react-icons/fa6";
import { FaCrown } from "react-icons/fa6";
import { FaCaretRight } from "react-icons/fa";

import {
    collectionGroup,
    query,
    getDocs,
    getFirestore,
    where,
  } from "@firebase/firestore";
  import { SignIn, SignedIn, SignedOut, useUser } from "@clerk/clerk-react";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const MenuItem = ({ to, children }) => (

  <Menu.Item>
    {({ active }) => (
      <Link
        to={to}
        className={classNames(
          active ? 'bg-yellow-100 py-2 text-lg rounded-xl text-gray-900' : 'text-gray-700',
          'block px-4  py-2 text-lg'
        )}
      >
        {children}
      </Link>
    )}
  </Menu.Item>
);

export default function Credit() {


    const { user } = useUser();
    const [gifts, setGifts] = useState([]);
    const db = getFirestore();
    const [credits, setCredits] = React.useState(0);
    const [freecredits, setFreeCredits] = React.useState(0);
    const {isSignedIn} = useUser()
    const calculateCreditsLeft = () => {
      const giftsSentWithLoginFromStorage = parseInt(localStorage.getItem('giftsSentWithLogin')) || 0;
      const creditsLeft = Math.max(0, 5-giftsSentWithLoginFromStorage );
     
      setCredits(creditsLeft);
      return creditsLeft;
     
    };
    const freecalculateCreditsLeft = () => {
      const freegiftsSentWithLoginFromStorage = parseInt(localStorage.getItem('giftsSentWithoutLogin')) || 0;
      const freecreditsLeft = Math.max(0, 2-freegiftsSentWithLoginFromStorage );
   
      setFreeCredits(freecreditsLeft);
      return freecreditsLeft;
     
    };
    useEffect(() => {
      calculateCreditsLeft();
      freecalculateCreditsLeft()
    }, []);  // Empty dependency array ensures it runs once after the initial render
    const fetchGiftsByReceiverEmail = async (receiverEmail) => {
      try {
        // Reference to the "gifts" collection group
        const giftsCollectionGroupRef = collectionGroup(db, "gifts");
  
        // Query to filter gifts based on receiver's email
        const query1 = query(
          giftsCollectionGroupRef,
          where("receiverEmail", "==", receiverEmail)
        );
  
        // Fetch documents that match the query
        const querySnapshot = await getDocs(query1);
  
        const fetchedGifts = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
  
        // console.log("Receiver's gifts: ", fetchedGifts);
        setGifts(fetchedGifts);
      } catch (error) {
        console.error("Error fetching gifts by receiver email:", error);
      }
    };
  

  const [isMenuOpen, setMenuOpen] = useState(false);
  const [delayedClose, setDelayedClose] = useState(null);
  const menuRef = useRef(null);

  const handleMouseEnter = () => {
    setMenuOpen(true);
    clearTimeout(delayedClose);
  };

  const handleMouseLeave = () => {
    setDelayedClose(setTimeout(() => setMenuOpen(false), 50)); // Adjust the delay time as needed
  };

  useEffect(() => {
    if (!user || !user.primaryEmailAddress) {
      // console.log("User or primaryEmailAddress is not defined.");
      return;
    }

    // const receiverEmail = user.primaryEmailAddress.emailAddress;
    const receiverEmail = user.primaryEmailAddress.emailAddress;
    // console.log("Receiver Email:", receiverEmail);

    fetchGiftsByReceiverEmail(receiverEmail);
  
  }, [user]);









  return (
    <Menu
      as="div"
      className="relative inline-block text-left"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div>
        <SignedIn>
        <Menu.Button className="flex w-36 justify-center items-center gap-x-1.5 rounded-full py-2 text-lg font-semibold text-white bg-gradient-to-tr from-yellow-300 shadow-2xl  to-yellow-600">
        <FaCrown className='text-xl -mt-1'/>
         <p className='text-lg'> Credits: 
         {gifts.length >= 0 ? (
         <span>  {credits}</span>
         ): null}
         </p>
        </Menu.Button>
        </SignedIn>

        <SignedOut>
           <Menu.Button className="flex w-40 justify-center items-center gap-x-1.5 rounded-full py-2 text-lg font-semibold text-white bg-gradient-to-tr from-yellow-300 shadow-2xl  to-yellow-900">
        <FaCrown className='text-xl -mt-1'/>
         <p className='text-xl'> Credits: 
         {gifts.length >= 0 ? (
         <span>  {freecredits}</span>
         ): null}
         </p>
        </Menu.Button>
        </SignedOut>
      </div>

      {isMenuOpen && (
        <div className='relative'>
        <FaCaretRight className='text-white absolute flex text-2xl rotate-[-90deg] text-opacity-100 -top-2 right-12'/>
        <div
          ref={menuRef}
          className="absolute  -left-5 flex items-center justify-center z-10 px-3 mt-2 w-56 min-h-[20vh] origin-top-right rounded-md bg-opacity-100 bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className=" ">
          <p className='text-lg text-gray-700'>Credit left.<br/>
          Number of credits equal to number of gifts you can send
          </p>
           
          </div>
        </div>
        </div>
      )}
    </Menu>
  );
}

