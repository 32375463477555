// CalendarGiftForm.js
import React,{useState} from 'react';
// import Nav01 from '../Components/Nav01';
// import { Footer } from '../sections_landing';
import { useApproval } from '../states/ApprovalContext';
// import { Link } from 'react-router-dom';
import { useFormData } from '../states/FormDataContext';
import LoadingSpinner from '../sections_landing/Loader';
import {  formpic } from '../assets/Images';
import { useUser } from '@clerk/clerk-react';
// import { LocomotiveScrollProvider } from "react-locomotive-scroll";
import 'locomotive-scroll/dist/locomotive-scroll.css';
import { useRef } from "react";
// import { AnimatePresence } from 'framer-motion';
import ShowModel from "./ShowModel";

const CalendarGiftForm = () => {
  const ref = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModel = () => setIsModalOpen(false);
  const openModel = () => setIsModalOpen(true);
  const { isApproved } = useApproval();
  const { formData, setFormData } = useFormData();
  const {user} = useUser()
  const useremail = user?.primaryEmailAddress.emailAddress
// //console.log(formData.giftDetails)
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      email: useremail,
      [name]: type === 'checkbox' ? checked : value,
    }));

  };

  if (isApproved === false) {
    return <LoadingSpinner/>;
  }

  return (
    <>
    {/* <div> */}
{/* <Nav01></Nav01>
<div className="z-10 absolute bg-sky-950 h-[90px] w-screen"></div>
</div> */}
 {/* <LocomotiveScrollProvider options={options} containerRef={ref}>
      <AnimatePresence>
          <main data-scroll-container ref={ref}> */}
     
      <div class="flex w-full min-h-screen flex-row content-center items-center justify-items-center overflow-hidden">
        <div class="mx-auto pt-4 flex min-h-[100vh] overflow-hidden min-w-[100vw] flex-col items-center gap-9">
          <div class="py-5 ">
            <img
              class="max-h-80 min-w-[95vw] rounded-3xl overflow-hidden object-cover object-center"
              src={formpic}
              width=""
              alt="Create web3 gift"
            />
          </div>

          <div class="grid grid-cols-2 max-md:grid-cols-1 max-md:justify-items-center  gap-6">
            <div class="flex md:min-w-[500px] flex-col">
              <label class="mx-12 my-2 text-lg font-bold text-gray-500">Enter Your Name</label>
              <input
                class=" mx-11 max-w-xs outline-none rounded-3xl border border-solid border-gray-400 bg-slate-50 px-4 py-2"
                placeholder="Mark Racker"
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
              />

              <label class="mx-12 my-2 text-lg font-bold text-gray-500">Enter Your Email Address</label>
              <input
                class="mx-11 max-w-xs outline-none rounded-3xl border border-solid border-gray-400 bg-slate-50 px-4 py-2"
                placeholder= {useremail}
                type="email"
                name="email"
                value={useremail}
                onChange={handleInputChange}
                required disabled
              />
              {/* <label class="mx-12 my-2 text-lg font-bold text-gray-500">Date of Sharing</label>
              <input
                class="mx-11 max-w-xs rounded-3xl border-2 border-solid border-gray-400 bg-slate-50 px-4 py-2 font-bold text-gray-400"
                type="date"
                name="date"
                value={formData.date}
                onChange={handleInputChange}
              /> */}
            </div>

            <div class="flex md:min-w-[500px] flex-col">
            
              <label class="mx-12 my-2 text-lg font-bold text-gray-500">Enter Gift Receiver Name</label>
              <input
                class="mx-11 max-w-xs outline-none rounded-3xl border border-solid border-gray-400 bg-slate-50 px-4 py-2"
                placeholder="Miki"
                type="text"
                name="receiverName"
                value={formData.receiverName}
                onChange={handleInputChange}
              />

              <label class="mx-12 my-2 text-lg font-bold text-gray-500">Receiver Email Address</label>
              <input
                class="mx-11 max-w-xs outline-none rounded-3xl border border-solid border-gray-400 bg-slate-50 px-4 py-2"
                placeholder="receiver@myweb3gift.com"
                type="email"
                name="receiverEmail"
                value={formData.receiverEmail}
                onChange={handleInputChange}
              />
              {/* <label class="mx-12 my-2 text-lg font-bold text-gray-500">Short Greeting</label>
 
             <textarea
                class="mx-11 min-h-[150px] max-w-xs rounded-3xl border-2 border-solid border-gray-400 bg-slate-50 px-4 py-2 text-gray-400 hover:text-gray-700"
                aria-placeholder=""
                name="greeting"
                value={formData.greeting}
                onChange={handleInputChange}
              >
                Dear Miki, I'm so lucky to get another year around the sun with you, Happy birthday to the love of my life, I'm so excited to celebrate my favorite person today.
              </textarea> */}
            </div>
          </div>

          {/* <div class="items-center mx-4 text-center ">
            <input
              class="font-extrabold focus:ring-2"
              type="checkbox"
              name="agree"
              id="agree"
              checked={formData.agree}
              onChange={handleInputChange}
            />
            <span class=" ">I agree that I know this person, please send this Calendar to this email address</span>
          </div> */}

          {/* <Link to= "/CreateGiftCard"> */}
            <button onClick={openModel} class="rounded-3xl items-center mx-auto bg-yellow-300 px-9  my-11 py-2 text-xl font-bold text-white hover:bg-yellow-400 focus:ring-2">Submit</button>
          {/* </Link> */}
      

        </div>
        {isModalOpen && <ShowModel closeModel={closeModel} />}
      </div>
      
{/* // </main>
// </AnimatePresence>
// </LocomotiveScrollProvider> */}
      {/* <Footer /> */}
    </>
  );
};

export default CalendarGiftForm;
