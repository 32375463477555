// ReadMore.js
import React, { useState } from "react";
// import styles from "../style/door9.module.css";

const Readmore = ({ text, maxLength }) => {
  const [isTruncated, setIsTruncated] = useState(true);

  const toggleTruncate = () => {
    setIsTruncated(!isTruncated);
  };

  const truncatedText = isTruncated ? text.slice(0, maxLength) : text;

  return (
    <div className="">
      <p className="whitespace-pre-line antialiased mt-3 text-xl tracking-widest">
        {truncatedText}
       
        {text.length > maxLength && (
          <button
            className="text-white hover:underline focus:outline-none"
            onClick={toggleTruncate}
          >
            {isTruncated ? "More" : "Less"}
          </button>
        )}
      </p>
    </div>
  );
};

export default Readmore;
