import React from "react";
import styles from "../style/door14.module.css";
import { motion } from "framer-motion";
import { Scam } from "../../DoorsImages/Doorimg14";

export default function FDoor() {
  return (
    <>
      <motion.div
        className={styles.section1}
        initial="hidden"
        animate="visible"
        transition={{ duration: 1 }}
      >
        <div className={styles.walletboxheading}>
          <motion.div
            initial={{
              x: "-15vw",
              opacity: 0,
            }}
            whileInView={{ x: 0, opacity: 1 }}
            // animate={{ opacity: 1, y: 0 }}
            transition={{
              duration: 1,
            }}
            viewport={{ once: true }}
          >
            <h1 className="text-white text-4xl font-bold pt-5 ">Scams</h1>
            <p className="text-white mt-6 text-xl tracking-widest">
              The crypto space has seen its fair share of scams and fraudulent
              activities due to its decentralized and often less regulated
              nature. It's important to stay vigilant and be aware of potential
              scams.
            </p>
          </motion.div>
        </div>
        <motion.div
          className={styles.walletimgbox}
          initial={{ x: "15vw", opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          whileHover={{
            scale: 0.9,
            transition: { type: "spring", duration: 1 },
          }}
          transition={{ duration: 1 }}
          exit={{
            x: "-60vw",
            scale: [1, 0],
            transition: { duration: 1 },
          }}
          viewport={{ once: true }}
        >
          <img src={Scam} alt="img" />
        </motion.div>
      </motion.div>
    </>
  );
}
