import React from "react";
import { motion } from "framer-motion";

import styles from "../style/door3.module.css";
import { Earning } from "../../DoorsImages/Doorimg17";

export default function FDoor() {
  return (
    <>
      <motion.div
        className={styles.section1}
        initial="hidden"
        animate="visible"
        //  variants={fadeInVariants}
        transition={{ duration: 1 }}
      >
      <div  className={styles.tokenheading}>
        <motion.h1
          layout
          // initial={{ opacity: 0, x:20 }}
          initial={{
            x: "-15vw",opacity:0
          }}
          whileInView={{ x: 0,opacity:1}}
          transition={{
           duration:0.6
          }}
          viewport={{once:true}}
         
         className="text-4xl text-white font-bold">Earning on the blockchain</motion.h1>
           <motion.p
      initial={{
              x: "-15vw",opacity:0
            }}
            whileInView={{ x: 0,opacity:1}}
            transition={{
              duration:0.6
            }}
            viewport={{once:true}}
           className="text-white mt-3 text-lg">
          In the context of decentralized finance (DeFi), several terms are commonly used to describe various financial activities. 
            <br />
          </motion.p>
          </div>
        <motion.div
          className={styles.nftimgbox}
          // initial={{ opacity: 0, x:20 }}
          initial={{
            y: "15vw",
          opacity:0
          }}
          whileInView={{ y: 0, opacity:1}}
          transition={{
           duration: 1
          }}
          whileHover={{
            scale: 0.9,
            transition: { type: "spring", duration: 1 },
          }}
          exit={{
            x: "-60vw",
            scale: [1, 0],
            transition: { duration: 1 },
          }}
          viewport={{once:true}}
        >
          <img src={Earning} alt="nftimg" />
        </motion.div>
      </motion.div>
    </>
  );
}
