import React from "react";
import styles from "../style/door14.module.css";
import {motion} from "framer-motion";

export default function Card({ title, text }) {
  return (
    <>
     <motion.div
          initial={{ x: "-15vw", opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{
            duration: 1,
          }}
          viewport={{ once: true }}
       className={styles.door11card1}>
        <h1 className="text-2xl text-white font-medium">{title}</h1>
        <p className="text-lg text-white text-center">{text}</p>
      </motion.div>
    </>
  );
}
