import React from "react";
import styles from "../style/door14.module.css";
import { motion } from "framer-motion";
import { Scam } from "../../DoorsImages/Doorimg14";

export default function FDoor() {
  return (
    <>
      <motion.div
        className={styles.section11}
        initial="hidden"
        animate="visible"
        transition={{ duration: 1 }}
      >
        <motion.div 
        initial={{x: "-15vw",opacity:0}}
        whileInView={{ x: 0,opacity:1}}
        transition={{
          duration:1
        }}
        viewport={{once:true}}
         className={styles.walletboxheading}>
            <h1 className="text-white text-4xl font-bold pt-5 ">
            Deepdive DeFi
            </h1>
            <p className="text-white mt-6 text-xl tracking-widest">
            Yesterday we explained the difference between TradFi, CeFi and DeFi. Today we have a closer look at DeFi.
            <br/>
            <br/>
            DeFi refers to a category of financial services and applications built on blockchain technology. Unlike traditional finance, which relies on centralized institutions such as banks and intermediaries, DeFi operates in a decentralized manner using smart contracts and blockchain protocols.

                        </p>
          </motion.div>
          <motion.div 
        initial={{x: "-15vw",opacity:0}}
        whileInView={{ x: 0,opacity:1}}
        transition={{duration:1}}
        viewport={{once:true}}
        className={styles.walletimgbox}
          
        >
          {/* <img src={Scam} alt="img" />    */}
        </motion.div>
        
      </motion.div>
    </>
  );
}
