import React, { useState, useRef } from 'react';
import { Menu } from '@headlessui/react';
import { Link } from 'react-router-dom';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const MenuItem = ({ to, children }) => (
  <Menu.Item>
    {({ active }) => (
      <Link
        to={to}
        className={classNames(
          active ? 'bg-yellow-100 py-2 text-lg rounded-xl text-gray-900' : 'text-gray-700',
          'block px-4  py-2 text-lg'
        )}
      >
        {children}
      </Link>
    )}
  </Menu.Item>
);

export default function Menu1() {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [delayedClose, setDelayedClose] = useState(null);
  const menuRef = useRef(null);

  const handleMouseEnter = () => {
    setMenuOpen(true);
    clearTimeout(delayedClose);
  };

  const handleMouseLeave = () => {
    setDelayedClose(setTimeout(() => setMenuOpen(false), 30)); // Adjust the delay time as needed
  };

  return (
    <Menu
      as="div"
      className="relative inline-block text-left"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div>
        <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md px-3 py-2 text-xl font-semibold text-white ring-inset ring-gray-300 hover:bg-yellow-500">
          MENU
        </Menu.Button>
      </div>

      {isMenuOpen && (
        <div
          ref={menuRef}
          className="absolute right-0 z-10 mt-2 w-72 min-h-[30vh] origin-top-right rounded-md bg-opacity-80 bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className="py-5 mx-4 capitilize flex flex-col gap-1 ">
            <MenuItem to="/">Home</MenuItem>
            {/* <MenuItem to="/mygifts">My Gifts</MenuItem> */}
            <MenuItem to="/about">About</MenuItem>
            <MenuItem to="/blog"> Further Readings</MenuItem>
            <MenuItem to="/contact">Contact us</MenuItem>
            {/* <MenuItem to="/team">Team</MenuItem> */}
            {/* Add more menu items as needed */}
            <MenuItem to="/faqs">Faq's</MenuItem>
          </div>
        </div>
      )}
    </Menu>
  );
}

