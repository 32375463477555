import React from "react";
import styles from "../style/door3.module.css";
import {
  Blockchain2,
  Certificate,
  Nft2,
  SmartContract,
  Study,
  Wallet2,
} from "../../DoorsImages/Doorimg3";
import Accordion from "./Acordition";
import {motion } from "framer-motion";

export default function SDoor() {
  return (
    <>
      <div className={styles.section2}>
      <motion.div 
        initial={{x: "-15vw",opacity:0}}
        whileInView={{ x: 0,opacity:1}}
        transition={{
          duration:1
        }}
        viewport={{once:true}}
         className={styles.nftboxheading2}>
          <h1 className="text-white text-3xl font-bold">
            Key features of NFT marketplaces include:
          </h1>
          <p className="text-white mt-3 text-lg">
            An NFT marketplace is an online platform that facilitates the
            buying, selling, and trading of NFTs. As explainded yesterday, NFTs
            are unique digital tokens that represent ownership or proof of
            authenticity of a specific digital or physical asset, often
            associated with digital art, music, videos, virtual real estate, and
            other digital or tangible items.
          </p>
        </motion.div>
        <div className={styles.imgboxcard}>
          <div className={styles.imgboxcard1}>
          <motion.div 
        initial={{x: "-15vw",opacity:0}}
        whileInView={{ x: 0,opacity:1}}
        transition={{
          duration:1
        }}
        viewport={{once:true}}
             className={styles.cards}>
              <div className={styles.imuu}>
                <img className={styles.nft2} src={Nft2} alt="img1" />
              </div>
              <div className={styles.box}>
                <Accordion
                  title="Listing and Selling"
                  content="Creators or owners can list their digital assets on these platforms, specifying details such as title, description, and pricing. Interested buyers can then purchase these NFTs using cryptocurrency."
                ></Accordion>
              </div>
            </motion.div>
            <motion.div 
        initial={{x: "-15vw",opacity:0}}
        whileInView={{ x: 0,opacity:1}}
        transition={{
          duration:1
        }}
        viewport={{once:true}}
             className={styles.cards}>
              <div className={styles.imuu}>
                <img className={styles.nft2} src={Study} alt="img1" />
              </div>
              <div className={styles.box}>
                <Accordion
                  title="Discoverability"
                  content="NFT marketplaces often provide search and discovery features to help users find specific types of digital assets, artists, or themes."
                ></Accordion>
              </div>
            </motion.div>
            <motion.div 
        initial={{x: "-15vw",opacity:0}}
        whileInView={{ x: 0,opacity:1}}
        transition={{
          duration:1
        }}
        viewport={{once:true}}
             className={styles.cards}>
              <div className={styles.imuu}>
                <img
                  className={styles.smartContract}
                  src={SmartContract}
                  alt="img1"
                />
              </div>
              <div className={styles.box}>
                <Accordion
                  title="Smart Contracts"
                  content="In the context of NFT marketplaces, smart contracts automate the process of transferring ownership when a purchase is made."
                ></Accordion>
              </div>
            </motion.div>
            <motion.div 
        initial={{x: "-15vw",opacity:0}}
        whileInView={{ x: 0,opacity:1}}
        transition={{
          duration:1
        }}
        viewport={{once:true}}
             className={styles.cards}>
              <div className={styles.imuu}>
                <img
                  className={styles.blockchain2}
                  src={Blockchain2}
                  alt="img1"
                />
              </div>
              <div className={styles.box}>
                <Accordion
                  title="Blockchain Integration"
                  content="The use of blockchain ensures the security, transparency, and immutability of transactions. It also allows users to verify the authenticity and ownership history of each NFT."
                ></Accordion>
              </div>
            </motion.div>
            <motion.div 
        initial={{x: "-15vw",opacity:0}}
        whileInView={{ x: 0,opacity:1}}
        transition={{
          duration:1
        }}
        viewport={{once:true}}
             className={styles.cards}>
              <div className={styles.imuu}>
                <img className={styles.wallet2} src={Wallet2} alt="img1" />
              </div>
              <div className={styles.box}>
                <Accordion
                  title="Wallet Integration"
                  content="Users need a cryptocurrency wallet to participate in NFT transactions. Many marketplaces integrate with popular wallets to facilitate seamless transactions."
                ></Accordion>
              </div>
            </motion.div>
            <motion.div 
        initial={{x: "-15vw",opacity:0}}
        whileInView={{ x: 0,opacity:1}}
        transition={{
          duration:1
        }}
        viewport={{once:true}}
             className={styles.cards}>
              <div className={styles.imuu}>
                <img
                  className={styles.certificate}
                  src={Certificate}
                  alt="img1"
                />
              </div>
              <div className={styles.box}>
                <Accordion
                  title="Royalties"
                  content="NFT marketplaces often have systems in place to automatically distribute royalties to the original creators whenever their NFTs are resold in the secondary market. This provides ongoing compensation to artists and creators."
                ></Accordion>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </>
  );
}
