import React from "react";
import styles from "../style/door11.module.css";
import { motion } from "framer-motion";
import FDoor from "./FDoor";
import SDoor from "./SDoor";
import TDoor from "./TDoor";

export default function Door11comp() {
  const fadeInVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };
  const container1 = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.5,
      },
    },
  };

  return (
    <>
      <motion.div
        initial="hidden"
        animate="visible"
        variants={{ fadeInVariants, container1 }}
        transition={{ duration: 1 }}
        className={styles.container11}
      >
      <FDoor/>
      <SDoor/>
      <TDoor/>
      </motion.div>
    </>
  );
}
