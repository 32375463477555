import ReactGA from "react-ga";
import Main from "./sections_landing/Main.js";

import { getDatabase, ref, set } from "firebase/database";
import { app } from "./firebase.js";

import React, { useState, useEffect } from "react";
import About from "./pages/About.js";
import Contact from "./pages/Contact.js";
// import Layout from "./pages/Layout.js";
import ErrorPage from "./pages/ErrorPage.js";
import FAQS from "./pages/FAQS.js";
import Team from "./pages/Team.js";
import BlogPage from "./pages/BlogPage.js";
import ClaimGift from "./pages/ClaimGift.js";

// import Nav01 from "./Components/Nav01.js";

import BlogPage1 from "./pages/BlogPages/BlogPage1/BlogPage1.js";
import BlogPage2 from "./pages/BlogPages/BlogPage2/BlogPage2.js";
import BlogPage3 from "./pages/BlogPages/BlogPage3/BlogPage3.js";
import ScrollToTop from "./pages/ScrollToTop/ScrollToTop.js";
import Imprint from "./pages/Imprint.js";
import PrivacyPolicy from "./pages/PrivacyPolicy.js";
import TransferedGifts from "./pages/TransferedGifts.js";
import MyGifts from "./pages/MyGifts.js";
import LoginForm from "./pages/LoginForm.js";
import SignUpForm from "./pages/SignUp.js";
import CalendarGiftForm from "./pages/CalendarGiftForm.js";
import { ApprovalProvider } from "./states/ApprovalContext.js"; // Adjust the path
// import CreateGiftCard from './pages/CreateGiftCard.js';
import {
  ClerkProvider,
  SignedIn,
  SignedOut,
  RedirectToSignIn,
  SignIn,
  SignUp,
  UserButton,
  AuthenticateWithRedirectCallback,
} from "@clerk/clerk-react";
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  useNavigate,
} from "react-router-dom";

import { getAuth } from "firebase/auth";
import RegistrationForm from "./sections_landing/Registration.js";
import CreateGiftCard from "./pages/CreateGiftCard.js";
// import ClaimGift from "./pages/ClaimGift.js";
import YourGifts from "./pages/yourGifts.js";

import CryptoAdventCalendar from "./pages/CryptoAdventCalendar.js";
import CryptoChallenge from "./pages/CryptoChallenge.js";
import CryptoSurpriseGiftBox from "./pages/CryptoSurpriseGiftBox.js";
import ReceiverGiftCard from "./pages/ReceiverGiftCard.js";
import { FormDataProvider } from "./states/FormDataContext.js";

// import { Gift } from './assets/Images/index.js';
import Giftbyid from "./pages/Giftbyid.js";
import Gift from "./pages/Gift.js";

// import { ThirdwebProvider } from "@thirdweb-dev/react";

import LockedDoor from "./pages/AdventDoors/LockedDoor.js";
import ADoor1 from "./pages/AdventDoors/ADoor1.js";
import ADoor2 from "./pages/AdventDoors/ADoor2.js";
import ADoor3 from "./pages/AdventDoors/ADoor3.js";
import ADoor4 from "./pages/AdventDoors/ADoor4.js";
import ADoor5 from "./pages/AdventDoors/ADoor5.js";
import ADoor6 from "./pages/AdventDoors/ADoor6.js";
import ADoor7 from "./pages/AdventDoors/ADoor7.js";
import ADoor8 from "./pages/AdventDoors/ADoor8.js";
import ADoor9 from "./pages/AdventDoors/ADoor9.js";
import ADoor10 from "./pages/AdventDoors/ADoor10.js";
import ADoor11 from "./pages/AdventDoors/ADoor11.js";
import ADoor12 from "./pages/AdventDoors/ADoor12.js";
import ADoor13 from "./pages/AdventDoors/ADoor13.js";
import ADoor14 from "./pages/AdventDoors/ADoor14.js";
import ADoor15 from "./pages/AdventDoors/ADoor15.js";
import ADoor16 from "./pages/AdventDoors/ADoor16.js";
import ADoor17 from "./pages/AdventDoors/ADoor17.js";
import ADoor18 from "./pages/AdventDoors/ADoor18.js";
import ADoor19 from "./pages/AdventDoors/ADoor19.js";
import ADoor20 from "./pages/AdventDoors/ADoor20.js";
import { Toaster } from "react-hot-toast";
import ADoor21 from "./pages/AdventDoors/ADoor21.js";
// import Door20 from './pages/AdventDoors/ADoor20.js';
import ADoor22 from "./pages/AdventDoors/ADoor22.js";
import ADoor23 from "./pages/AdventDoors/ADoor23.js";
import { GiftGif } from "./assets/Videos/index.js";
// import Demo from './pages/Demo.js';
import Carddetail from "./pages/Carddetail.js";
import Products from "./pages/Products.js";
import ProductbyId from "./pages/ProductbyId.js";
import GiftSentSuccess from "./pages/GiftSentSuccess.js";
import ReceiverGiftById from "./pages/ReceiverGiftById.js";
import LoginPage from "./Components/LoginPage.js";
import SuccessSentCode from "./Components/SuccessSentCode.js";
import VerificationCodeForm from "./Components/VerificationCodeForm.js";
import Customlogin from "./sections_landing/Customlogin.js";
import SSOCallback from "./sections_landing/SSOCallback.js";
import AuthForm from "./sections_landing/Authform.js";
import SignInForm from "./sections_landing/SignInForm.js";
import UserProfiles from "./Components/UserProfile.js";
import ForgotPassword from "./sections_landing/ForgotPassword.js";
import ADoor24 from "./pages/AdventDoors/ADoor24.js";
import AdminLayout from "./sections_landing/dashboard/layouts/admin";
import AuthLayout from "./sections_landing/dashboard/layouts/auth";
import Feedback from "./pages/Feedback.js";
import NearT from "./pages/NearT.js";
if (!process.env.REACT_APP_CLERK_PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key");
}

const db = getDatabase(app);

const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

// function PublicPage() {
//   return (
//     <>
//       <h1>Public page</h1>
//       <a href="/protected">Go to protected page</a>
//     </>
//   );
// }

function ProtectedPage() {
  useEffect(() => {
    const auth = getAuth();
    // Access Firebase auth features with `auth.currentUser`
    const user = auth.currentUser;
    // console.log("Current user:", user);
  }, []);
  return (
    <>
      <h1>Protected page</h1>
      <UserButton />
      <ClaimGift />
      <MyGifts />
    </>
  );
}

function ClerkProviderWithRoutes() {
  const navigate = useNavigate();

  const TRACKING_ID = "G-G6P69YFVWZ"; // OUR_TRACKING_ID
  ReactGA.initialize(TRACKING_ID);

  // const afterSignInUrl=  " http://localhost:3000/gift"

  // const afterSignUpUrl=  " http://localhost:3000/gift"
  return (
    <ClerkProvider
      publishableKey={clerkPubKey}
      navigate={(to) => navigate(to)}
      // afterSignInUrl={afterSignInUrl}
      // afterSignUpUrl={afterSignUpUrl}
    >
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/about" element={<About />} />
        <Route path="/Carddetail" element={<Carddetail />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/FAQS" element={<FAQS />} />
        {/* <Route path="/Team" element={<Team />} /> */}
        <Route path="/Blog" element={<BlogPage />} />

        <Route path="/Team" element={<Team />} />
        <Route path="/Gift" element={<Gift />} />

        <Route path="/ClaimGift" element={<ClaimGift />} />
        <Route path="/register" element={<RegistrationForm />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/Imprint" element={<Imprint />} />

        <Route path="/Blog1" element={<BlogPage1 />} />
        <Route path="/Blog2" element={<BlogPage2 />} />
        <Route path="/Blog3" element={<BlogPage3 />} />
        {/* <Route path='/giftdashboard' element={<GiftDashboard/>}/> */}
        <Route path="auth/*" element={<AuthLayout />} />
        <Route path="admin/*" element={<AdminLayout />} />
        <Route
          path="/giftdashboard"
          element={<Navigate to="/admin" replace />}
        />

        <Route path="*" element={<ErrorPage />} />

        <Route path="/MyGifts" element={<MyGifts />} />
        <Route path="/History/Received" element={<MyGifts />} />
        <Route path="/History/Sent" element={<TransferedGifts />} />

        <Route path="/LoginForm" element={<LoginForm />} />
        <Route path="/SignUpForm" element={<SignUpForm />} />
        <Route path="/CalendarGiftForm" element={<CalendarGiftForm />} />

        <Route path="/TransferedGifts" element={<TransferedGifts />} />
        <Route path="/CreateGiftCard" element={<CreateGiftCard />} />
        <Route
          path="/Crypto Suprise Gift Box/:id"
          element={<CryptoSurpriseGiftBox />}
        />
        <Route
          path="/10-Day Crypto Challenge/:id"
          element={<CryptoChallenge />}
        />
        <Route
          path="/Crypto Advent Calendar/:id"
          element={<CryptoAdventCalendar />}
        />

        <Route path="/ReceiverGiftCard" element={<ReceiverGiftCard />} />
        <Route path="/giftid/:id" element={<Giftbyid />} />

        <Route path="/Receiver_giftid/:id" element={<ReceiverGiftById />} />

        <Route path="/yourgifts" element={<YourGifts />} />
        <Route path="/products" element={<Products />} />
        <Route path="/product/:id" element={<ProductbyId />} />
        {/* <Route path="/product/:id" element={<ProductbyId/>} /> */}
        {/* <Route path="/Demo" element={<Demo/>} /> */}

        <Route path="/ADoor1" element={<ADoor1 />} />

        <Route path="/LockedDoor" element={<LockedDoor />} />
        <Route path="/ADoor1/:id" element={<ADoor1 />} />
        <Route path="/ADoor1/:id/NearT" element={<NearT />} />
        <Route path="/ADoor2/:id" element={<ADoor2 />} />
        <Route path="/ADoor3/:id" element={<ADoor3 />} />
        <Route path="/ADoor4/:id" element={<ADoor4 />} />
        <Route path="/ADoor5/:id" element={<ADoor5 />} />
        <Route path="/ADoor6/:id" element={<ADoor6 />} />
        <Route path="/ADoor7/:id" element={<ADoor7 />} />
        <Route path="/ADoor8/:id" element={<ADoor8 />} />
        <Route path="/ADoor9/:id" element={<ADoor9 />} />
        <Route path="/ADoor10/:id" element={<ADoor10 />} />
        <Route path="/ADoor11" element={<ADoor11 />} />
        <Route path="/ADoor12" element={<ADoor12 />} />
        <Route path="/ADoor13" element={<ADoor13 />} />
        <Route path="/ADoor14" element={<ADoor14 />} />
        <Route path="/ADoor15" element={<ADoor15 />} />
        <Route path="/ADoor16" element={<ADoor16 />} />
        <Route path="/ADoor17" element={<ADoor17 />} />
        <Route path="/ADoor18" element={<ADoor18 />} />
        <Route path="/ADoor19" element={<ADoor19 />} />
        <Route path="/ADoor20" element={<ADoor20 />} />
        <Route path="/ADoor21" element={<ADoor21 />} />
        <Route path="/ADoor22" element={<ADoor22 />} />
        <Route path="/ADoor23" element={<ADoor23 />} />
        <Route path="/ADoor24" element={<ADoor24 />} />
        <Route path="/GiftSentSuccess" element={<GiftSentSuccess />} />
        <Route path="/customlogin" element={<Customlogin />} />
        <Route path="/customsignin" element={<SignInForm />} />
        <Route path="/sso-callback" element={<SSOCallback />} />
        <Route path="/Auth" element={<AuthForm />} />
        <Route path="/forgot" element={<ForgotPassword />} />
        <Route path="/feedback" element={<Feedback />} />

        <Route path="/userprofile" element={<UserProfiles />} />
        {/* <Route path="/" element={<PublicPage />} /> */}

        {/* <Route
          path="/customsignup"
          element={<SignUp routing="path" path="/sign-up" redirectUrl= "http://localhost:3000/gift"/>}
        /> */}
        <Route
          path="/customsignup"
          element={<SignUp routing="path" path="/sign-up" />}
        />
        <Route
          path="/sign-in/*"
          element={<SignIn routing="path" path="/sign-in" />}
        />
        <Route
          path="/LoginPage"
          element={<LoginPage routing="path" path="/LoginPage" />}
        />

        <Route path="/verification-code" element={<VerificationCodeForm />} />
        <Route path="/success-sent-code" element={<SuccessSentCode />} />
        {/* <Route
          path="/sign-up/gift"
          element={<SignUp routing="path" path="/sign-up" redirectUrl= "http://localhost:3000/gift" />}
        /> */}

        <Route
          path="/sign-up/*"
          element={<SignUp routing="path" path="/sign-up" />}
        />
        <Route
          path="/protected"
          element={
            <>
              <SignedIn>
                <ProtectedPage />
              </SignedIn>
              <SignedOut>
                <RedirectToSignIn />
              </SignedOut>
            </>
          }
        />
      </Routes>
    </ClerkProvider>
  );
}

// function SSOCallback() {
//   // Handle the redirect flow by rendering the
//   // prebuilt AuthenticateWithRedirectCallback component.
//   // This is the final step in the custom OAuth flow
//   return <AuthenticateWithRedirectCallback />;
// }

function App() {
  const putData = () => {
    set(ref(db, "user/piyush"), {
      id: 1,
      name: "Piyush Gupta",
      age: 21,
    });
  };

  const [showPreloader, setShowPreloader] = useState(true);

  useEffect(() => {
    // Show preloader initially

    // Simulate some asynchronous initialization
    const initializationTimeout = setTimeout(() => {
      // Update state to hide the preloader after one second
      setShowPreloader(false);
    }, 1000); // Set the duration to one second (1000 milliseconds)

    // Clean up the timeout to avoid memory leaks
    return () => clearTimeout(initializationTimeout);
  }, []);

  return (
    <div>
      {showPreloader && (
        <div id="preloader">
          <img src={GiftGif} alt="Loading..." />
        </div>
      )}

      {/* The rest of your app */}

      <BrowserRouter>
        <ScrollToTop />
        <FormDataProvider>
          <ApprovalProvider>
            <ClerkProviderWithRoutes />
          </ApprovalProvider>
        </FormDataProvider>

        {/* <button className='bg-blue-400 rounded-r-xl' onClick={putData}>Put data</button> */}
        <Toaster />
      </BrowserRouter>
    </div>
  );
}

export default App;

// function App() {
//   return (

//     <ClerkProvider publishableKey={clerkPubKey}>

//       <Router>

//         {/* <Nav01/> */}
//         <Routes>
//           <Route path="/" element={<Main />} />
//           <Route path="/about" element={<About />} />
//           <Route path="/contact" element={<Contact />} />
//           <Route path="/FAQS" element={<FAQS />} />
//           <Route path="/Team" element={<Team />} />
//           <Route path="/Blog" element={<BlogPage />} />

//           <Route path="/Team" element={<Team />} />
//           <Route path="/Gift" element={<Gift />} />

//           <Route path="*" element={<ErrorPage />} />

//         </Routes>

//       </Router>

//     </ClerkProvider>

//   );
// }

// export default App;
