// VerificationCodeForm.js

import React, { useState } from 'react';
import { useNavigate,Link } from 'react-router-dom';
import { FaApple } from 'react-icons/fa';
import ImageSection from './ImageSection';
// import { IoLogoGoogleplus } from 'react-icons/io';
import Nav01 from './Nav01';
import { FcGoogle } from "react-icons/fc";

const VerificationCodeForm = () => {
  const [loginMethod, setLoginMethod] = useState('phone'); // Default to email login

  const navigate = useNavigate();
  const [verificationCode, setVerificationCode] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const handleVerify = () => {
        navigate('/success-sent-code');
  };

  return (
    <>
    <Nav01/>
<div className="z-10  bg-sky-950 h-[90px] w-full "></div>
         <div style={{fontFamily:"Gilroy"}} className="flex bg-[#d4dde4] py-7 items-center justify-center min-h-screen px-10 max-lg:px-2">
      <div className="flex bg-white rounded-xl h-full max-[765px]:flex-col  overflow-hidden max-[765px]:overflow max-[765px]:flex-col w-full">

         <div className="w-2/5 px-8 py-10 bg-white max-[765px]:w-full ">
         <h2  className="text-xl">Welcome!</h2>
      <h1 className="mt-3 text-3xl font-medium">SignIn into</h1>
      <h3 className="mt-1 text-lg">MyWeb3Gift</h3>
      
         <div className="flex mb-4 px-2
      bg-[#86a1b3] w-full rounded-full overflow-hidden py-2 items-center justify-center text-white mt-7
      ">
        <button
          className={`mr-2  py-2 w-6/12 rounded-full font-medium text-xl  ${
            loginMethod === 'email' ? 'bg-white text-black ' : 'bg-transparent-100'
          }`}
          onClick={() => setLoginMethod('email')}
        >
          Email
        </button>
        <button
          className={` py-2 w-1/2 rounded-full font-medium text-xl ${
            loginMethod === 'phone' ? 'bg-white text-black' : 'bg-transparent-200'
          }`}
          onClick={() => setLoginMethod('phone')}
        >
          Phone Number
        </button>
      </div>
      {loginMethod === 'email' ? (
        <div>
<label for="">Username</label>

          <input
            type="text"
            placeholder="Username"
            className="mb-4 p-2 px-4 w-full rounded-full border border-gray-300"
          />
<label for="">Username</label>

          <input
            type="password"
            placeholder="Password"
            className="mb-4 p-2 px-4 w-full rounded-full border border-gray-300"
          />
          <div>
<input type="checkbox"  name="" id=""/>
<label for="" className='ml-5 '>Remember me</label>

          </div>
        </div>
      ) : (
        <div>
      <label for=""> Verification Code</label>

          <input
        type="text"
        placeholder="563234"
        value={verificationCode}
        onChange={(e) => setVerificationCode(e.target.value)}
        className="mb-4 p-2 px-4 rounded-full w-full border border-gray-300"
      />
        </div>
      )}
     
      <button
        className=" mb-4 w-full mt-10
        bg-[#315e7d] rounded-3xl py-3 text-white
        "
        onClick={handleVerify}
      >
      Login
      </button>
      <p className='text-gray-400 flex justify-center text-xl py-4 font-semibold'>or login with </p>
      <div className='flex justify-center items-center'>
      <button className=" flex items-center gap-4 text-5xl rounded-3xl px-4 py-2 mr-2">
        <FcGoogle/> <span className="bg-black text-white px-2 text-3xl py-2 rounded-full"> <FaApple/></span>
</button>
        {/* <button className="bg-black text-white px-2 text-3xl py-2 rounded-full"><FaApple/></button> */}
      </div>
      <p className="mt-4 flex justify-center text-xl ">
       You don't have an account? <Link to="/signup" className='font-semibold ml-2 text-[#3c6784]'>Sign up</Link>
      </p>
    </div>
    <div className="w-3/5 min-h-full max-sm:h-screen max-[765px]:h-screen max-[765px]:w-full">
          <ImageSection />
        </div>
    </div>
    </div>
    </>
  );
};

export default VerificationCodeForm;
