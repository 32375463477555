import React from 'react';

const SignUpForm = () => {
  return (
    <div className="bg-white font-sans">
      {/* Sidebar */}
      <div className="hidden lg:flex flex-col justify-between bg-[#ffe85c] lg:p-8 xl:p-12 lg:max-w-sm xl:max-w-lg">
        <div className="flex items-center justify-start space-x-3">
          <span className="bg-black rounded-full w-8 h-8"></span>
          <a href="/" className="font-medium text-xl">
            Brand
          </a>
        </div>
        <div className="space-y-5">
          <h1 className="lg:text-3xl xl:text-5xl xl:leading-snug font-extrabold">
            Create an account and start exploring!
          </h1>
          <p className="text-lg">Already have an account?</p>
          <button className="inline-block flex-none px-4 py-3 border-2 rounded-lg font-medium border-black bg-black text-white">
            Login here
          </button>
        </div>
        <p className="font-medium">© 2022 Company</p>
      </div>

      {/* SignUp */}
      <div className="flex flex-1 flex-col items-center justify-center px-10 relative">
        <div className="flex lg:hidden justify-between items-center w-full py-4">
          <div className="flex items-center justify-start space-x-3">
            <span className="bg-black rounded-full w-6 h-6"></span>
            <a href="/" className="font-medium text-lg">
              Brand
            </a>
          </div>
          <div className="flex items-center space-x-2">
            <span>Already a member? </span>
            <a href="/" className="underline font-medium text-[#070eff]">
              Log in now
            </a>
          </div>
        </div>

        {/* SignUp box */}
        <div className="flex flex-1 flex-col justify-center space-y-5 max-w-md">
          <div className="flex flex-col space-y-2 text-center">
            <h2 className="text-3xl md:text-4xl font-bold">Create your account</h2>
            <p className="text-md md:text-xl">Join us and unlock a world of possibilities!</p>
          </div>
          <div className="flex flex-col max-w-md space-y-5">
            <input
              type="text"
              placeholder="Full Name"
              className="flex px-3 py-2 md:px-4 md:py-3 border-2 border-black rounded-lg font-medium placeholder:font-normal"
            />
            <input
              type="text"
              placeholder="Email Address"
              className="flex px-3 py-2 md:px-4 md:py-3 border-2 border-black rounded-lg font-medium placeholder:font-normal"
            />
            <input
              type="password"
              placeholder="Password"
              className="flex px-3 py-2 md:px-4 md:py-3 border-2 border-black rounded-lg font-medium placeholder:font-normal"
            />
            <button className="flex items-center justify-center flex-none px-3 py-2 md:px-4 md:py-3 border-2 rounded-lg font-medium border-black bg-black text-white">
              Create Account
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpForm;
