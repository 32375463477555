import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getFirestore, collection, doc, getDoc } from 'firebase/firestore';
import LoadingSpinner from "../sections_landing/Loader";
import Carddetail from './Carddetail';  // Import Carddetail component

const ProductDetails = () => {
  const [product, setProduct] = useState(null);
  const navigate = useNavigate();
  const params = useParams();

  const fetchProductById = async (productId) => {
    try {
      const db = getFirestore();
      const productsRef = collection(db, 'Products');
      const productDocRef = doc(productsRef, productId);
      const productDocSnapshot = await getDoc(productDocRef);

      if (productDocSnapshot.exists()) {
        const productData = productDocSnapshot.data();
        //console.log('Fetched Product: ', productData);
        setProduct(productData);
      } else {
        //console.log('Product not found');
        navigate('/404');
      }
    } catch (error) {
      console.error('Error fetching product by ID:', error);
    }
  };

  useEffect(() => {
    fetchProductById(params.id);
  }, [params.id, navigate]);

  return (
    <div className="mt-10 ">
      {product ? (
        <Carddetail
          imageSrc={product.imgsrc}
          productName={product.Name}
          description={product.Description}
          price={product.Price}
          Details={product.Details}
          details={product.details}
          unfinished={product.unfinished}
        />
        
      ) : (
        <LoadingSpinner />
      )}
    </div>
  );
};

export default ProductDetails;
