import React from "react";
import styles from "../style/door14.module.css";
import { motion } from "framer-motion";
export default function TDoor() {
  const fadeInVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };
  const container1 = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.5,
      },
    },
  };
  return (
    <motion.div
      className={styles.section31}
      initial="hidden"
      animate="visible"
      variants={{ fadeInVariants, container1 }}
      transition={{ duration: 1 }}
    >
      
     
      <div className={styles.nftboxheading3}>
      <motion.div 
        initial={{x: "-15vw",opacity:0}}
        whileInView={{ x: 0,opacity:1}}
        transition={{
          duration:1
        }}
        viewport={{once:true}}
        className=" w-full"
       >
      <h1 className="text-white pt-5 pb-3 pl-3  text-3xl tracking-widest">
      Different Testnets
      </h1>
      <motion.ul
              initial={{ x: "-15vw", opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{
                duration: 1,
              }}
              viewport={{ once: true }}
              className="text-lg text-white pl-3 list-disc tracking-wider"
            >
              There are multiple testnets available, each serving a specific purpose. Some of the well-known ones include:
              <div className="pl-4">
<li><b>Ropsten (Ethereum):</b> A Proof-of-Work testnet for Ethereum, using the same consensus algorithm as the Ethereum mainnet.
</li>
<li><b>Kovan (Ethereum):</b> A Proof-of-Authority testnet for Ethereum, offering faster block times and facilitating easy access to testnet Ether.
</li>
<li><b>Rinkeby (Ethereum):</b> Another Proof-of-Authority testnet for Ethereum with a faucet for obtaining testnet Ether.
</li>
<li><b>Binance Smart Chain (BSC) Testnet:</b> Binance Smart Chain has its own testnet for developers to test and deploy smart contracts.
</li>
<li><b>Algorand Testnet:</b> Algorand provides a testnet for developers to experiment with its blockchain features.
</li>
</div>
            </motion.ul>


      </motion.div>
        <motion.div
          initial={{ x: "-15vw", opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{
            duration: 1,
          }}
          viewport={{ once: true }}
          className={styles.nfth1}
        >
          <h1 className="text-white font-bold text-center text-3xl tracking-wide">
            Links to Explore more
          </h1>
        </motion.div>
        <div className={styles.nfttextbox}>
          <div className={styles.div1}>
            <motion.p
              initial={{ x: "-15vw", opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{
                duration: 1,
              }}
              viewport={{ once: true }}
              className="text-lg text-white tracking-wider"
            >
              Goerli Faucet
            </motion.p>

            <motion.button
              initial={{ x: "-15vw", opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{
                duration: 1,
              }}
              viewport={{ once: true }}
              className={styles.btn}
            >
            <a target="_blank" href="https://www.goerlifaucet.com">
              Click here
              </a>
            </motion.button>
          </div>
        </div>
      </div>
    </motion.div>
  );
}
