import React from 'react'
import styles from "../style/door12.module.css";
import { motion } from 'framer-motion';
import { Mask1 } from '../../DoorsImages/Doorimg12';
import {RightArrow} from "../Icons/RightArrow";

export default function Card1({navhead,cardimg}) {
  return (
    <motion.div initial={{opacity:0}}
    whileInView={{  opacity:1 }}
    transition={{duration:1}}
     viewport={{once:true}} className={styles.door12card}>
       <nav className={styles.door12nav}>
<span className='font-medium text-xl'>{navhead}</span>
<img src={Mask1} />
       </nav> 
       <div className={styles.cardimgs}>
        <img src={cardimg}/>
       </div>
       <footer className={styles.doo12footer}>
<span><RightArrow/></span>
       </footer>
    </motion.div>
  )
}
