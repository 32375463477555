import React from 'react'
import ADoor3 from './ADoor3';

function Bonus({text}) {
  return (
    <div className='select-none'>
         <button
          className='bg-gradient-to-r from-sky-700 to-sky-500 text-xl font-bold text-white py-3 w-[200px] rounded-3xl  hover:bg-gradient-to-r hover:from-sky-500 hover:to-sky-300 transition duration-300'   
        >
        {text}
        </button>
    </div>
  )
}

export default Bonus