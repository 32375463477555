import React, { useEffect, useState } from "react";
import { Down } from "../Icons/Down";
import { Upper } from "../Icons/Upper";
import styles from "../style/door3.module.css";
import { motion } from "framer-motion";

const Acordion2 = ({ title, content }) => {
  const [collapse, setCollapse] = useState(false);

  return (
    <>
       <motion.div 
        initial={{x: "-100%",opacity:0}}
        whileInView={{ x: 0,opacity:1}}
        transition={{
          duration:0.2,staggerChildren:1.5
        }}
        viewport={{once:true}}
       className={styles.accordion}>
        <div className={styles.title2} onClick={() => setCollapse(!collapse)}>
          <div className={styles.name2}>
            <h2 className="text-2xl">{title}</h2>
          </div>
          {collapse ? (
            <span className={styles.indicator1}>
              <Upper />
            </span>
          ) : (
            <span className={styles.indicator1}>
              <Down />
            </span>
          )}
        </div>
        {collapse && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="pt-1 "
          >
            <div className={styles.reveal}>
              <p className={styles.para}>{content}</p>
            </div>
          </motion.div>
        )}
      </motion.div>
    </>
  );
};

export default Acordion2;
