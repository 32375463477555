import React from "react";
import styles from "../style/door12.module.css";
import { Elipse1, Elipse2, Elipse3 } from "../../DoorsImages/Doorimg12";
import { motion } from "framer-motion";
export default function S1Door() {
  return (
    <div className={styles.section21}>
       <motion.div initial={{ y: "-15vh",opacity:0}}
            whileInView={{ y: 0, opacity:1 }}
            transition={{duration:0.6}}
             viewport={{once:true}} className={styles.oracleboxheading}>
        <h1 className="text-white text-5xl text-center font-medium">
          Types of 
        </h1>
        <h1 className="italic text-white text-5xl mt-2 text-center font-light">
          
          Oracles
        </h1>
      </motion.div>
      <div className={styles.doo11cardds}>
      <div className={styles.door11cards}>
      <motion.div initial={{ x: "-15vw",opacity:0}}
            whileInView={{ x: 0, opacity:1 }}
            transition={{duration:1}}
             viewport={{once:true}} className={styles.door11card1}>
            <div className={styles.cardimg1}>
                <img src={Elipse1}/>
            </div>
            <div className={styles.cardtextbox}>
<h1 className="text-2xl text-white font-medium ">Software Oracles</h1>
<p className="text-white text-center">These are APIs or software-based mechanisms that fetch and deliver data to smart contracts.</p>
            </div>
           
        </motion.div>
        <motion.div initial={{ y: "-15vh",opacity:0}}
            whileInView={{ y: 0, opacity:1 }}
            transition={{duration:0.6}}
             viewport={{once:true}} className={styles.door11card1}>
            <div className={styles.cardimg1}>
                <img src={Elipse2}/>
            </div>
            <div className={styles.cardtextbox}>
<h1 className="text-2xl text-white font-medium ">Hardware Oracles</h1>
<p className=" text-white text-center">
These oracles use hardware devices to provide real-world data. For example, a sensor measuring temperature could provide data to a smart contract.
</p>
            </div>
        </motion.div>

        <motion.div initial={{ x: "15vw",opacity:0}}
            whileInView={{ x: 0, opacity:1 }}
            transition={{duration:1}}
             viewport={{once:true}} className={styles.door11card1}>
            <div className={styles.cardimg1}>
                <img src={Elipse3}/>
            </div>
            <div className={styles.cardtextbox}>
<h1 className="text-white text-2xl font-medium ">Consensus Oracles </h1>
<p className="text-white text-center">
These oracles aggregate data from multiple sources and provide a consensus value to the smart contract. This helps mitigate the risk of inaccurate or manipulated data.
</p>
            </div>
        </motion.div>
       </div>
      </div>
    </div>
  );
}
