import { AuthenticateWithRedirectCallback } from '@clerk/clerk-react'
import React from 'react'

const SSOCallback = () => {
  return (
    <div><AuthenticateWithRedirectCallback />;
    </div>
  )
}

export default SSOCallback