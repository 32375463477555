import React from 'react'
import Nav01 from '../Components/Nav01'
import { Footer } from '../sections_landing'
import CalendarDoors from './CalendarDoors'
import { BlackGifts } from '../assets/Images/AfterLoginPic'

const CryptoChallenge = () => {
  return (
    <div>

<Nav01></Nav01>
<div className="bg-sky-950 h-[90px] w-screen"></div>


<div className="relative mx-auto flex  bg-fixed flex-col items-center gap-9  bg-cover bg-center"   style={{
        backgroundImage: `url(${BlackGifts})`, // Use the imported image
      }} >


{/* <div className='backdrop-blur-md w-full max-sm:pb-64 min-h-screen h-full bg-white/30  '>

<CalendarDoors/>

</div> */}


<div className=' w-full max-sm:pb-64 min-h-screen h-full   '>

<CalendarDoors/>

</div>

</div>

<Footer></Footer>
    </div>
  )
}

export default CryptoChallenge