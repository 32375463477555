const Progress = (props) => {
  const { value, color, width } = props;
  return (
    <div
      className={`h-2 ${
        width ? width : "w-full"
      } rounded-full bg-gray-500`}
    >
      <div
        className={`flex h-full items-center justify-center rounded-full ${
          color === "red"
            
            ? "bg-gray-500 "
            : "bg-yellow-500 "
        }`}
        style={{ width: `${value}%` }}
      />
    </div>
  );
};

export default Progress;
