import React from "react";
import { Link } from "react-router-dom";
import Nav01 from "../../Components/Nav01";
import { Footer } from "../../sections_landing";
import { LocomotiveScrollProvider } from "react-locomotive-scroll";
import "locomotive-scroll/dist/locomotive-scroll.css";

import { useRef } from "react";
import { AnimatePresence } from "framer-motion";

import DoorComplete from "../DoorComplete";
// import styles from "../DoorComponents/style/door3.module.css";
// import Door3comp from "../DoorComponents/Doorcomponent3/Door3comp";
import styles from "../DoorComponents/style/door4.module.css";
import Door4comp from "../DoorComponents/Doorcomponent4/Door4comp";
import Bonus from "./Bonus";
import { useParams } from 'react-router-dom';
import Verify from "./Verify";
const ADoor3 = () => {
  const ref = useRef(null);
  const params = useParams();
  const paramsid = params.id
  const options = {
    smooth: true,
  };

  return (
    <>
     <div className=''><Verify params={paramsid}/></div>
      <div>
        <Nav01></Nav01>
      </div>
      <LocomotiveScrollProvider options={options} containerRef={ref}>
        <AnimatePresence>
          <main data-scroll-container ref={ref} className="select-none">
          <div
              style={{ backgroundColor: "#27272a", overflow: "hidden" }}
              className="pt-20"
            >
              <div>
                <div className={styles.container4}>
                  <Door4comp />
                  
                </div>
                <div className="flex gap-4 flex-row justify-center mb-3">
                
                  <DoorComplete Door={3} id={paramsid} />
                </div>

                <Footer></Footer>
              </div>
            </div>
          </main>
        </AnimatePresence>
      </LocomotiveScrollProvider>
    </>
  );
};

export default ADoor3;
