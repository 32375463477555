import React from "react";
import styles from "../style/door14.module.css";
import Accordion from "./Acordition";
import { motion } from "framer-motion";
import { Centrale, Centralize1, DeCentrale, Intermediaries, Openness, Peer, Regulation, Regulation1, Risks } from "../../DoorsImages/Doorimg15";

export default function SDoor() {
  return (
    <div className={styles.section2}>
      <motion.div
        initial={{ y: "-100%", opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        transition={{
          duration: 1,
        }}
        viewport={{ once: true }}
        className={styles.nftboxheading2}
      >
        <h1 className="text-white text-center font-bold text-3xl tracking-wide">
          TradFi (Traditional Finance):
        </h1>
      </motion.div>
      <div className={styles.imgboxcard}>
        <div className={styles.imgboxcard1}>
          <motion.div
            initial={{ x: "-15vw", opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{
              duration: 1,
            }}
            viewport={{ once: true }}
            className={styles.cards}
          >
            <div className={styles.imuu}>
              <img className={styles.centralize} src={Centralize1} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Centralization"
                content="Traditional finance is characterized by centralized systems where financial activities are governed by centralized authorities such as banks, government institutions, and other financial intermediaries."
              ></Accordion>
            </div>
          </motion.div>
          <motion.div
            initial={{ x: "15vw", opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{
              duration: 1,
            }}
            viewport={{ once: true }}
            className={styles.cards}
          >
            <div className={styles.imuu}>
              <img className={styles.mask1} alt="img1" src={Intermediaries} />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Intermediaries"
                content="Transactions often involve multiple intermediaries, such as banks, brokers, and clearinghouses, which can lead to longer processing times and higher costs."
              ></Accordion>
            </div>
          </motion.div>
          </div>
          </div>
          <div className={styles.imgboxcard}>
        <div className={styles.imgboxcard1}>
          <motion.div
            initial={{ x: "-55vw", opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{
              duration: 1,
            }}
            viewport={{ once: true }}
            className={styles.cards}
          >
            <div className={styles.imuu}>
              <img className={styles.mask1} src={Regulation} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Regulation"
                content="Traditional financial systems are subject to extensive regulations imposed by governmental bodies to ensure stability, security, and compliance with financial laws."
              ></Accordion>
            </div>
          </motion.div>
        </div>
      </div>
      <motion.hr
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{
          duration: 1,
        }}
        viewport={{ once: true }}
        className={styles.hr1}
      ></motion.hr>
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{
          duration: 1,
        }}
        viewport={{ once: true }}
        className={styles.nftboxheading2}
      >
        <h1 className="text-white text-center font-bold text-3xl tracking-wide">
          CeFi (Centralized Finance):
        </h1>
      </motion.div>
      <div className={styles.imgboxcardss}>
        <div className={styles.imgboxcard1}>
          <motion.div
            initial={{ x: "-15vw", opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{
              duration: 1,
            }}
            viewport={{ once: true }}
            className={styles.cards}
          >
            <div className={styles.imuu}>
              <img className={styles.mask2} src={Centrale} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                bold="Example :"
                egtext="Centralized cryptocurrency exchanges (e.g., Coinbase, Binance) and some blockchain-based financial services that are operated by centralized entities fall under the CeFi category.
"
                title="Centralization"
                content="CeFi refers to financial systems or platforms that operate in a centralized manner but may leverage some aspects of blockchain technology. However, key control points, such as user accounts and transaction processing, are typically managed by centralized entities."
              ></Accordion>
            </div>
          </motion.div>
          <motion.div
            initial={{ x: "15vw", opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{
              duration: 1,
            }}
            viewport={{ once: true }}
            className={styles.cards}
          >
            <div className={styles.imuu}>
              <img className={styles.curency} src={Regulation1} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Regulation"
                content="CeFi platforms are often subject to regulatory oversight, similar to traditional financial institutions."
              ></Accordion>
            </div>
          </motion.div>
        </div>
      </div>
      <motion.hr
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{
          duration: 1,
        }}
        viewport={{ once: true }}
        className={styles.hr1}
      ></motion.hr>

      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{
          duration: 1,
        }}
        viewport={{ once: true }}
        className={styles.nftboxheading2}
      >
        <h1 className="text-white text-center font-bold text-3xl tracking-wide">
          DeFi (Decentralized Finance):
        </h1>
      </motion.div>
      <div className={styles.imgboxcard}>
        <div className={styles.imgboxcard1}>
          <motion.div
            initial={{ x: "-15vw", opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{
              duration: 1,
            }}
            viewport={{ once: true }}
            className={styles.cards}
          >
            <div className={styles.imuu}>
              <img className={styles.mask1} src={DeCentrale} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Decentralization "
                content=" DeFi represents a paradigm shift toward decentralized systems built on blockchain technology. It aims to recreate and enhance traditional financial systems using decentralized networks, typically based on smart contracts."
              ></Accordion>
            </div>
          </motion.div>
          <motion.div
            initial={{ x: "15vw", opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{
              duration: 1,
            }}
            viewport={{ once: true }}
            className={styles.cards}
          >
            <div className={styles.imuu}>
              <img className={styles.mask1} src={Peer} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                bold="Example :"
                egtext="Decentralized exchanges (DEXs), lending platforms, and other financial services built on blockchain networks like Ethereum are examples of DeFi."
                title="Peer-to-peer"
                content=" In DeFi, transactions occur directly between participants without the need for traditional intermediaries. Smart contracts automate and enforce the terms of agreements."
              ></Accordion>
            </div>
          </motion.div>
          </div>
          </div>
          <div className={styles.imgboxcard}>
        <div className={styles.imgboxcard1}>
          <motion.div
            initial={{ x: "-15vw", opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{
              duration: 1,
            }}
            viewport={{ once: true }}
            className={styles.cards}
          >
            <div className={styles.imuu}>
              <img className={styles.mask1} src={Openness} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Openness"
                content="DeFi systems are often open-source, transparent, and accessible to anyone with an internet connection. Users have more control over their assets and can engage in financial activities without relying on traditional banking infrastructure."
              ></Accordion>
            </div>
          </motion.div>
          <motion.div
            initial={{ x: "15vw", opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{
              duration: 1,
            }}
            viewport={{ once: true }}
            className={styles.cards}
          >
            <div className={styles.imuu}>
              <img className={styles.mask1} src={Risks} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Risks"
                content="While offering increased transparency and accessibility, DeFi systems also come with their own set of risks, including smart contract vulnerabilities and the potential for hacking."
              ></Accordion>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
}
