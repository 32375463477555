import React, { useEffect,useState } from 'react';
import { VideoUSP, GiftDetails, EmailModal, TrustSymbols, CountDownTimer, FaqSection, Footer, HeroSection, Blog, IconsDetails, WhoForSection, Steps, Testimonial_slider, Testimonials } from './index.js'
import Nav01 from '../Components/Nav01.js'
// import { getAuth, signInWithCustomToken } from "firebase/auth";
import { useAuth, useUser } from '@clerk/clerk-react';
import { useApproval } from '../states/ApprovalContext.js';
import LoadingSpinner from './Loader.js';
import { LocomotiveScrollProvider, useLocomotiveScroll } from "react-locomotive-scroll";
import 'locomotive-scroll/dist/locomotive-scroll.css';
import { useRef } from "react";
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
// import LocomotiveScroll from 'locomotive-scroll';

import { AnimatePresence ,useScroll} from 'framer-motion';
import VimeoVideo from './Vimeo.js';
import "./Styles/main.css";

const Main = () => {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["end end", "start start"]
  });
  // const ref = useRef(null);

  // const options = {
  //   smooth: true,
  // }
  // const containerRef = useRef(null);
  // const scroll = useLocomotiveScroll({
  //   containerRef,
  //   options: { smooth: true },
  // });
  // const { getToken } = useAuth();

  // useEffect(() => {
  //   const signInWithClerk = async () => {
  //     const auth = getAuth();
  //     const token = await getToken({ template: "integration_firebase" });
  //     const userCredentials = await signInWithCustomToken(auth, token);

  //     /**
  //      * The userCredentials.user object will call the methods of
  //      * the Firebase platform as an authenticated user.
  //      */
  //     //console.log("user ::", userCredentials.user);
  //   };

  //   signInWithClerk();
  // }, []);

  const { isLoaded } = useAuth();
  const { isSignedIn, user } = useUser();
  const { isApproved, setApproval } = useApproval();
  //console.log('hello', user)
  // const [Navbar, setNavbar] = useState(false)



  useEffect(() => {
    const fetchUserData = async () => {
      if (isSignedIn) {

        // gsap.fromTo('.section1', {
        //   opacity: 0,
        //   // y: 100,

        // }, {
        //   opacity: 1,
        //   y: 0,
        //   ease: "circ.in",
        //   duration: "1",
        //   scrollTrigger: {
        //     trigger: '.section1',
        //     start: 'top center',
        //     end: '+=300', // Adjust as needed
        //     scrub: true,
        //   },
        // });
        // gsap.fromTo('.section2', {
        //   opacity: 0,
        //   // y: 100,

        // }, {
        //   opacity: 1,
        //   y: 0,
        //   ease: "circ.in",
        //   duration: "1",
        //   scrollTrigger: {
        //     trigger: '.section1',
        //     start: 'top center',
        //     end: '+=300', // Adjust as needed
        //     scrub: true,
        //   },
        // });


        // const scroll = new LocomotiveScroll({
        //   el: document.querySelector('[data-scroll-container]'),
        //   smooth: true,
        // });
    
        // // Update background color based on scroll position
        // scroll.on('scroll', (args) => {
        //   setNavbar(args.scroll.y >= 30);
        // });
    
        // return () => {
        //   scroll.destroy();
        // };







        try {

          const response = await fetch(`https://myweb3gift-bd98e-default-rtdb.firebaseio.com/users/${user.id}.json`);
          //console.log(response)
          // const response = await fetch(`https://myweb3gift-bd98e-default-rtdb.firebaseio.com/submitemails/-NkJIn1tg7RhVEh-7-IF.json`);

          //console.log(response)
          if (response.ok) {
            const data = await response.json();
            //console.log('Fetch URL:', `https://myweb3gift-bd98e-default-rtdb.firebaseio.com/submitemails/${user.id}.json`);
            //console.log('data', data)
            //console.log(user.id)

            if (data && typeof data.approve !== 'undefined') {
              setApproval(data.approve);
            }
            //console.log(isApproved)
          } else {
            console.error('Error fetching data:', response.statusText);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
    };

    fetchUserData();
  }, [isSignedIn, user]);

  //console.log("Outside useEffect:", isApproved);

  if (!isLoaded) {
    return <div><LoadingSpinner /></div>;
  }

  return (

    <>

        <div >
      <Nav01/>
          <AnimatePresence>

            <main data-scroll-section className='unselectable' ref={ref}>



              <section className='section1'>
                <HeroSection />
              </section>




              <section className='section2'>
                <IconsDetails id='icon'/>
              </section>

              <section>
                {/* <VideoUSP /> */}
                <VimeoVideo/>
              </section>


              <section className='section3'>
                <WhoForSection />
              </section>



             


              <section>
                <GiftDetails />
              </section>





              <EmailModal></EmailModal>




              {/* <section>
<BenefitsandFeatures/>
</section> */}

              {/* <section>
<SocialProof/>
</section> */}





              {/* <section>
  <Cards/>
</section> */}


              {/* <section>
<VisualContent/>
</section> */}

              {/* <section>
<Testimonial_slider/>
</section> */}


              <section>
                <Steps />
              </section>







              {/* <section>
<ContactInformation/>
</section> */}

              <section>
                <TrustSymbols />
              </section>


              {/* <section>
  <Testimonials/>
</section> */}


              {/* <section>
  <LogoSlider/>
</section> */}



              {/* <section>
<ValueProposition/>
</section> */}

              {/* <section>
                <CountDownTimer />
              </section> */}

              <section >
                <Blog />
              </section>


              <section>
                <FaqSection />
              </section>

              {/* <section>
<CtaSection/>
</section> */}





              {/* <section>
<ExitIntendPopup/>
</section>



<section>
<LeadCaptureForm/>
</section> */}


              {/* <section>
<EmailForm/>
</section> */}
    <section>
                <Footer />
              </section>




            </main>

          </AnimatePresence>
      
        </div>
    </>
  )
}

export default Main