import React from 'react'
import styles from "../style/door10.module.css";
import {motion} from "framer-motion";

export default function Card2({title,para,img}) {
  return (
    <div className={styles.cardsss}>
          <motion.div initial={{ x: "-15vw",opacity:0}}
            whileInView={{ x: 0, opacity:1 }}
            transition={{duration:0.6}}
             viewport={{once:true}} className={styles.cardimgs}>
<img src={img} alt="img"/>
        </motion.div>
        <motion.div initial={{ x: "15vw",opacity:0}}
            whileInView={{ x: 0, opacity:1 }}
            transition={{duration:2}}
             viewport={{once:true}} className={styles.text}>
<h1 className='text-2xl font-bold mb-5'>{title}</h1>
<p className='text-lg whitespace-pre-line font-normal tracking-wider'>{para} {'\n'}</p>
        </motion.div>
        
    </div>
  )
}
