import MiniCalendar from "../../../components/calendar/MiniCalendar";
import WeeklyRevenue from "./components/WeeklyRevenue";
import TotalSpent from "./components/TotalSpent";
import PieChartCard from "./components/PieChartCard";
import { IoMdHome } from "react-icons/io";
import { IoDocuments } from "react-icons/io5";
import { MdBarChart, MdDashboard } from "react-icons/md";

import { columnsDataCheck, columnsDataComplex } from "./variables/columnsData";

import Widget from "../../../components/widget/Widget";
import CheckTable from "./components/CheckTable";
import ComplexTable from "./components/ComplexTable";
import DailyTraffic from "./components/DailyTraffic";
import TaskCard from "./components/TaskCard";
import tableDataCheck from "./variables/tableDataCheck.json";
import tableDataComplex from "./variables/tableDataComplex.json";
import { useEffect, useState } from "react";

import { collection, collectionGroup, getDocs, getFirestore } from "firebase/firestore";

const Dashboard = () => {
  const [giftsData, setGiftsData] = useState([]);

  useEffect(() => {
  
    const fetchData = async () => {
      try {
        const db = getFirestore();

      const usersref = collectionGroup(db, `gifts`);
      const querySnapshot = await getDocs(usersref);
      console.log(querySnapshot)
        const data = [];
        querySnapshot.forEach((doc) => {
        
          data.push({ id: doc.id, ...doc.data()} );
        });

        setGiftsData(data);
        console.log(giftsData)
      } catch (error) {
        console.error('Error fetching data from Firestore:', error);
      }
    };

    fetchData();
  }, []);
  return (
    <div>
      {/* Card widget */}

      <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6">
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"Loggedin Users"}
          subtitle={giftsData?.length.toString()}
        />
        <Widget
          icon={<IoDocuments className="h-6 w-6" />}
          title={"Sent Gift this month"}
          subtitle={giftsData?.length.toString()}
        />
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"Recieve Gifts"}
          subtitle={giftsData?.length.toString()}
        />
        <Widget
          icon={<MdDashboard className="h-6 w-6" />}
          title={" users "}
          subtitle={"100"}
        />
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"New Tasks"}
          subtitle={"5"}
        />
        <Widget
          icon={<IoMdHome className="h-6 w-6" />}
          title={"Total Gifts"}
          subtitle={giftsData?.length.toString()}
        />
      </div>

      {/* Charts */}

      <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2">
        <TotalSpent />
        <WeeklyRevenue />
      </div>

      {/* Tables & Charts */}

      <div className="mt-5 grid grid-cols-1 gap-5 xl:grid-cols-2">
        {/* Check Table */}
        <div>
          <CheckTable
            columnsData={columnsDataCheck}
            tableData={tableDataCheck}
          />
        </div>

        {/* Traffic chart & Pie Chart */}

        <div className="grid grid-cols-1 gap-5 rounded-[20px] md:grid-cols-2">
       
          <PieChartCard />
          <MiniCalendar />
        </div>


        <ComplexTable
          columnsData={columnsDataComplex}
          tableData={tableDataComplex}
        />

        <div className="grid grid-cols-1 gap-5 rounded-[20px] md:grid-cols-1">
         
            <DailyTraffic />
        
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
