import { useState } from "react";
import { useSignIn, useSignUp } from "@clerk/clerk-react";


export default function SignUpForm() {
  const { isLoaded, signUp, setActive } = useSignUp();
  const [emailAddress, setEmailAddress] = useState("");
  const [password, setPassword] = useState("");
  const [verifying, setVerifying] = useState(false);
  const [code, setCode] = useState("");
  const [username, setUsername] = useState('');
const [firstName, setFirstName] = useState('');
const [lastName, setLastName] = useState('');
const { signIn } = useSignIn();
 
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isLoaded) {
      return;
    }
 
    try {
      await signUp.create({
        emailAddress,
        password,
     
        firstName,
        lastName,
        
      });
 
      await signUp.prepareEmailAddressVerification({ strategy: "email_code" });
 
      setVerifying(true);
    } catch (err) {
      console.error(JSON.stringify(err, null, 2));
    }
  };
 
  const handleVerify = async (e) => {
    e.preventDefault();
    if (!isLoaded) {
      return;
    }
 
    try {
      const completeSignUp = await signUp.attemptEmailAddressVerification({
        code,
      });
 
      if (completeSignUp.status !== "complete") {
        //console.log(JSON.stringify(completeSignUp, null, 2));
      }
 
      if (completeSignUp.status === "complete") {
        await setActive({ session: completeSignUp.createdSessionId })
        // Handle your own logic here, like redirecting to a new page if needed.
      }
    } catch (err) {
      console.error(JSON.stringify(err, null, 2));
    }
  };
     
    const signInWith = (strategy) => {
      return signIn.authenticateWithRedirect({
        strategy,
        redirectUrl: "/sso-callback",
        redirectUrlComplete: "/",
      });
    };
 
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
      <div className="w-full max-w-md p-6 space-y-4 rounded-lg shadow-md sm:p-8 bg-white">
        {!verifying && (
          <form onSubmit={handleSubmit} className="space-y-6">
            {/* <div className="space-y-1 text-sm">
              <label htmlFor="username" className="block mb-2 font-medium text-gray-900 dark:text-gray-500">
                Username
              </label>
              <input onChange={(e) => setUsername(e.target.value)} id="username" name="username" type="text" required className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"/>
            </div> */}
            <div className="space-y-1 text-sm">
              <label htmlFor="firstName" className="block mb-2 font-medium text-gray-900 dark:text-gray-500">
                First Name
              </label>
              <input onChange={(e) => setFirstName(e.target.value)} id="firstName" name="firstName" type="text" required className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"/>
            </div>
            <div className="space-y-1 text-sm">
              <label htmlFor="lastName" className="block mb-2 font-medium text-gray-900 dark:text-gray-500">
                Last Name
              </label>
              <input onChange={(e) => setLastName(e.target.value)} id="lastName" name="lastName" type="text" required className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"/>
            </div>
            <div className="space-y-1 text-sm">
              <label htmlFor="email" className="block mb-2 font-medium text-gray-900 dark:text-gray-500">
                Email address
              </label>
              <input onChange={(e) => setEmailAddress(e.target.value)} id="email" name="email" type="email" required className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"/>
            </div>
            <div className="space-y-1 text-sm">
              <label htmlFor="password" className="block mb-2 font-medium text-gray-900 dark:text-gray-500">
                Password
              </label>
              <input onChange={(e) => setPassword(e.target.value)} id="password" name="password" type="password" required className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"/>
            </div>
            <button type="submit" className="w-full py-2 px-4 bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 focus:outline-none focus:ring focus:ring-opacity-50 transition duration-150 ease-in-out text-white font-semibold text-xs uppercase tracking-widest shadow-lg focus:shadow-xl active:shadow-xl disabled:opacity-50 disabled:cursor-not-allowed block rounded-lg">
              Sign Up
            </button>
          </form>
        )}
        {verifying && (
          <form onSubmit={handleVerify} className="space-y-6">
            <div className="space-y-1 text-sm">
              <label htmlFor="code" className="block mb-2 font-medium text-gray-900 dark:text-gray-500">
                Verification Code
              </label>
              <input value={code} placeholder="Code..." onChange={(e) => setCode(e.target.value)} id="code" name="code" type="text" required className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"/>
            </div>
            <button type="submit" className="w-full py-2 px-4 bg-green-600 hover:bg-green-700 focus:ring-green-500 focus:outline-none focus:ring focus:ring-opacity-50 transition duration-150 ease-in-out text-white font-semibold text-xs uppercase tracking-widest shadow-lg focus:shadow-xl active:shadow-xl disabled:opacity-50 disabled:cursor-not-allowed block rounded-lg">
              Verify Email
            </button>
          </form>
        )}
      </div>
      <div className="mt-6 space-y-2 lg:inline-grid lg:grid-cols-2 lg:gap-5">
        <button onClick={() => signInWith("oauth_google")} className="w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-black bg-white bg-opacity-20 hover:bg-opacity-30 focus:outline-none transition ease-in-out duration-150">
          Sign in with Google
        </button>
        <button onClick={() => signInWith("oauth_apple")} className="w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-black bg-white bg-opacity-20 hover:bg-opacity-30 focus:outline-none transition ease-in-out duration-150">
          Sign in with Apple
        </button>
      </div>
    </div>
  );
}