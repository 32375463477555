// App.js
import React, { useState, useEffect } from "react";
// import { BrowserRouter as Router,Routes, Route } from 'react-router-dom';
// import LoginForm from "./LoginForm";
// import VerificationCodeForm from "./VerificationCodeForm";
// import SuccessSentCode from "./SuccessSentCode";
import ReactDOM from "react-dom";

import Authform from "../sections_landing/Authform";
import { IoMdClose } from "react-icons/io";
import { AnimatePresence,motion } from "framer-motion";

function LoginModel({ CloseLoginModal }) {
  const [loginMethod, setLoginMethod] = useState("email"); // Default to email login

  useEffect(() => {
    document.body.style.overflowY = "hidden";
    return () => {
      document.body.style.overflowY = "scroll";
    };
  }, []);
  return ReactDOM.createPortal(
    <>
    <AnimatePresence>
    <motion.div
       initial={{ opacity: 0, y: "-100%" }}
      whileInView={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: "-100%" }}
      transition={{duration:"0.3"}}
        className="fixed top-0 left-0 z-[110] right-0 bottom-0 flex justify-between backdrop-blur-xl  bg-sky-800/30 "
        onClick={CloseLoginModal}
      >
        <button
          className="fixed top-0 max-[280px]:-right-4 max-[280px]:text-lg right-0 text-2xl text-white px-4 rounded"
          onClick={CloseLoginModal}
        >
         <motion.div
         initial={{ rotate: 0 }}
    animate={{ rotate:  90 }}
    transition={{ duration: 2 }}
        >  
          <IoMdClose className="text-4xl max-[280px]:text-lg  font-bold"/>
          </motion.div>
        </button>
      </motion.div>
      <motion.div className=" fixed top-2/4  shadow-2xl select-none max-[280px]:top-[45%] z-[110] left-2/4 transform overflow-hidden -translate-x-1/2 max-[280px]:top-[60%] -translate-y-1/2 w-full sm:w-11/12 md:w-3/4 max-[280px]:w-[240px] lg:w-1/2 xl:w-1/3 2xl:w-1/4 rounded-xl ">
        {/* <div className="flex min-h-[300px] max-[1280px]:min-h-[200px] max-[280px]:min-h-[600px] w-full max-[765px]:flex-col "> */}
       <div className="flex flex-row justify-center items-center ">
          <div className="w-full px-3 py-4 flex flex-col bg-white rounded-xl">
            <h2 className="text-sm">Welcome!</h2>
            <h1 className=" text-lg font-medium">SignIn into</h1>
            <h3 className="text-sm">MyWeb3Gift</h3>
            <Authform />
          </div>
        </div>
      </motion.div>
      </AnimatePresence>
    </>,
    document.querySelector(".myportaldiv")
  );
}

export default LoginModel;
