import React from 'react'
import "./BlogPage2.css"
import Nav01 from '../../../Components/Nav01'
import { Footer } from '../../../sections_landing'
import Blog2 from "./Blog2"
import { LocomotiveScrollProvider } from "react-locomotive-scroll";
import 'locomotive-scroll/dist/locomotive-scroll.css'

import { useRef } from "react";
import { AnimatePresence } from 'framer-motion';
const BlogPage2 = () => {
  const ref = useRef(null);

  const options = {
    smooth: true,
  } 
  return (
    <>
      <div>
       <Nav01></Nav01> 
       <div data-Scroll className="z-10 absolute bg-sky-950 h-[90px] w-screen"></div>
   </div>
    
    <LocomotiveScrollProvider options={options} containerRef={ref}>
    <AnimatePresence>

        <main data-scroll-container ref={ref}>
    {/* <div> */}

<Blog2/>
<Footer></Footer>


    {/* </div> */}
    </main>
    </AnimatePresence>
    </LocomotiveScrollProvider>
    </>
  )
}

export default BlogPage2