import React, { useEffect, useRef, useState } from 'react';
import { EmailModal, Footer } from '../sections_landing';
import Nav01 from '../Components/Nav01';
import { Video1 } from '../assets/Videos';
import "../sections_landing/Styles/main.css";
import { useNavigate } from 'react-router-dom';

const Carddetail = ({ imageSrc, productName, description, price, Details, details,unfinished }) => {
const navigate =useNavigate();


  const videoRef = useRef(null);
  const progressBarRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    const video = videoRef.current;
    const progressBar = progressBarRef.current;

    const handleTimeUpdate = () => {
      const progress = (video.currentTime / video.duration) * 100;
      progressBar.value = progress;
    };

    video.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      video.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, []);

  if(unfinished){
    navigate('/');
    // return null; 
  }
  const handleProgressBarClick = (e) => {
    const video = videoRef.current;
    const progressBar = progressBarRef.current;
    const clickedX = e.clientX - progressBar.getBoundingClientRect().left;
    const newProgress = (clickedX / progressBar.clientWidth) * 100;
    const newTime = (newProgress / 100) * video.duration;
    video.currentTime = newTime;
  };
  return (
    <>
   
      <div>
      <Nav01></Nav01>
      <div className=" fixed top-0   bg-sky-950 h-[90px] w-screen"></div>

      <div className="max-w-screen-xl unselectable mx-auto pt-20  max-lg:px-3 md:px-6">
        <div className="grid grid-cols-1 items-start lg:grid-cols-2 gap-8">
          <div className="mb-4 lg:mb-0">
            <img
              src={imageSrc || "https://via.placeholder.com/800x800"}
              alt="Product Image"
              className="w-full shadow-md rounded-xl"
            />
          </div>
          <div className="flex flex-col lg:px-16">
            <h1 className="text-4xl text-gray-800 font-bold mb-4">{productName}</h1>
            <p className="text-gray-600 text-xl mb-4">{description}</p>
            <div className="mb-4">
              <span className="text-3xl font-bold">
                <span className="text-sky-700">Free</span>
                <span className="line-through text-black-500 pl-2">${price}</span>
              </span>
            </div>
            <EmailModal
              label="Send"
              className="bg-sky-700 text-white px-6 py-3 rounded-md hover:bg-sky-800 transition duration-300 ease-in-out focus:outline-none focus:ring-4 focus:ring-blue-300"
              props={productName}
            />
          </div>
        </div>
        <div className="mt-8">
          <h2 className="text-4xl font-bold mb-4 text-gray-800">Discover Our Offerings</h2>
          <p className="text-gray-700 font-semibold text-xl">
            {Details}
            {details && details.length > 0 && (
              <ul className='max-sm:pl-4 font-medium text-gray-600'>
                {details.map((item, index) => (
                  <li className='list-inside max-sm:list-outside list-disc' key={index}>{item}</li>
                ))}
              </ul>)}
          </p>
        </div>
</div>

        {/* <div className="mt-8 lg:py-20">
          <h2 className="text-3xl text-gray-500 font-semibold mb-4">Discover Our Offerings</h2>
          <p className="text-gray-700 text-lg">
            {Details}
            {details && details.length > 0 && (
              <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-6">
                {details.map((item, index) => (
                  <div className="bg-yellow-300 p-6 rounded-lg shadow-md text-gray-500 font-semibold text-center" key={index}>
                    {item}
                  </div>
                ))}
              </div>
            )}
          </p>
        </div> */}





        <div className="mt-8">
          {/* <h2 className="text-3xl font-semibold mb-4">Demo Video</h2> */}
          <div className="aspect-w-16 aspect-h-9">


            <div className="rounded-3xl" style={{ position: 'relative', cursor: 'pointer', overflow: 'hidden', maxWidth: '100%', maxHeight: "500px" }}>

              <div
                className='flex relative flex-col w-full  px-6  max-sm:px-10  max-lg:px-16   justify-center items-center'>

                <div className='overflow-x-hidden pt-10 pb-2 bg-white relative'>
                  <video ref={videoRef}
                    className='' src="https://firebasestorage.googleapis.com/v0/b/myweb3gift-bd98e.appspot.com/o/Intro%20(1)%20(1).mp4?alt=media&token=e0cb94f8-93d6-45c8-b083-49a6be67c73e" width="500" autoplay loop controls>

                  </video>


                  <div style={{ marginTop: '12px', backgroundColor: "white" }} onClick={handleProgressBarClick} className='flex min-w-full justify-center items-center text-white px-2 py-3  absolute bottom-0 left-0'>
                    <style>
                      {`
            progress::-webkit-progress-value {
              background-color: #fce130; /* blue color for progress bar */
            }
          `}
                    </style>
                    <progress className='min-w-full h-[10px] rounded-sm  overflow-hidden' ref={progressBarRef} value="0" max="100"></progress>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>
      </div>
    
  

      <div className='p-10'></div>

      <Footer />
    </>
  );
};

export default Carddetail;
