import React from "react";
import styles from "../style/door14.module.css";
import { motion } from "framer-motion";
import Card from "./Card";

export default function Spart2() {
  return (
    <div>
      <motion.div className={styles.sec2part2}>
      <motion.div
          initial={{  opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{
            duration: 3,
          }}
          viewport={{ once: true }}
         className={styles.nfth11}>
          <h1 className="text-white font-medium text-center text-3xl tracking-wide">
            Advantages of Cross-Chain Bridges
          </h1>
        </motion.div>
        <div className={styles.doorcardss}>
          <div className={styles.door11cards}>
            <Card
              title="Asset Portability"
              text="Cross-chain bridges enable the seamless transfer of assets between different blockchains, enhancing liquidity and flexibility for users."
            />

            <Card
              title="Market Access"
              text=" Users can access decentralized applications and assets on multiple blockchains without the need to switch platforms or wallets."
            />
            <Card
              title="Risk Mitigation "
              text=" Cross-chain bridges can provide risk mitigation by allowing users to move assets to more secure or suitable blockchains in response to changing conditions."
            />
          </div>
          {/* <div className={styles.door11cards2}>
     <Card title="Risk Mitigation " text=" Cross-chain bridges can provide risk mitigation by allowing users to move assets to more secure or suitable blockchains in response to changing conditions."  
     />
    </div> */}
        </div>
      </motion.div>
    </div>
  );
}
