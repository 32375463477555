import React, { useState, useRef, useEffect } from "react";
import * as htmlToImage from "html-to-image";
import { IoMdClose } from "react-icons/io";
import { saveAs } from "file-saver";
// import LoginModel from "../Components/LoginModal";
import {
  SignInButton,
  SignedOut,
  SignedIn,
  UserButton,
  SignOutButton,
  SignUpButton,
} from "@clerk/clerk-react";
import "../sections_landing/Styles/main.css";

import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Modal from "react-modal";

import html2canvas from "html2canvas";
import Nav01 from "../Components/Nav01";
import { Footer } from "../sections_landing";
import { QR_CODE } from "../assets/Images";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import { useUser } from "@clerk/clerk-react";
import { useFormData } from "../states/FormDataContext";
import toast from "react-hot-toast";
import { Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import { LogoCP } from "../assets/Images/MyWeb3Gift-red2";
import CalendarGiftForm from "./CalendarGiftForm";
import greetingsData from "../data/greetings.json";
import { AnimatePresence, motion } from "framer-motion";
import { SketchPicker } from "react-color";
import { useApproval } from "../states/ApprovalContext";
// import { Link } from 'react-router-dom';
import ShowModel from "./ShowModel";
import LoadingSpinner from "../sections_landing/Loader";
import { formpic } from "../assets/Images";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";
import "./Styles/ClaimGift.css";
import QRCodeWithLogo from "./QRCode";
import debounce from "lodash/debounce";
import { MdClose } from "react-icons/md";
import { v4 as uuidv4 } from "uuid";
import LoginModel from "../Components/LoginModal";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  TelegramShareButton,
  LinkedinShareButton,
  TelegramIcon,
} from "react-share";
import {
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  LinkedinIcon,
} from "react-share";

const CreateGiftCard = () => {
  const websiteURL = "https://myweb3gift.com/";

  const { formData, setFormData } = useFormData();
  const { isSignedIn, user } = useUser();
  //console.log(formData);

  const navigate = useNavigate();
  const senderUserId = user ? user.id : null;
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [selectedColor, setSelectedColor] = useState("#ffff");
  const [boxColor, setBoxColor] = useState("#0c4a6e");
  const [content, setContent] = useState("");
  const [isPlaceholderHidden, setIsPlaceholderHidden] = useState(false);
  const [generatedGreeting, setGeneratedGreeting] = useState("");
  const colorPickerRef = useRef();
  const [boxShadowColor, setBoxShadowColor] = useState("#0c4a6e");
  // //console.log(boxShadowColor,"boxshadowcolor");

  const [IsLoginModelOpen, setIsLoginModelOpen] = useState(false);

  const CloseLoginModal = () => {
    setIsLoginModelOpen(!IsLoginModelOpen);
  };

  // const { isSignedIn, user } = useUser();

  const UserEmailAddress = user?.primaryEmailAddress.emailAddress;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        colorPickerRef.current &&
        !colorPickerRef.current.contains(event.target)
      ) {
        setShowColorPicker(false);
        setBoxColor(selectedColor);
        setBoxShadowColor(selectedColor);
      }
    };

    window.addEventListener("mousedown", handleClickOutside);

    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, [colorPickerRef, selectedColor]);

  useEffect(() => {
    setIsPlaceholderHidden(!!content || !!generatedGreeting);
  }, [content, generatedGreeting]);

  const clearPlaceholder = () => {
    setIsPlaceholderHidden(true);
  };

  const restorePlaceholder = () => {
    setIsPlaceholderHidden(!!content || !!generatedGreeting);
  };
  const handleContentChange = (e) => {
    // Update the content when it changes
    setGeneratedGreeting(e.target.innerHTML);
    // setContent(e.target.innerHTML);
    setFormData((prevData) => ({
      ...prevData,
      greeting: e.target.innerHTML,
    }));
  };

  const handleColorChange = (color) => {
    setSelectedColor(color.hex);
    //console.log(color.hex);
    setBoxColor(color.hex);
    setBoxShadowColor(color.hex);
  };

  const handleColorButtonClick = () => {
    setShowColorPicker(true);
  };

  //console.log(boxColor);
  const { isApproved } = useApproval();

  // const { formData, setFormData } = useFormData();
  // const { isSignedIn, user } = useUser();

  useEffect(() => {
    if (!user || !user.primaryEmailAddress) {
      //console.log("User or primaryEmailAddress is not defined.");
      return;
    }
    // const receiverEmail = user.primaryEmailAddress.emailAddress;
    const UserEmailAddress = user?.primaryEmailAddress.emailAddress;
    //console.log("User Email Address:", UserEmailAddress);
  }, []);

  //console.log(formData);

  // const navigate = useNavigate();

  // const senderUserId = user ? user.id : null;

  const addGift = async (senderUserId, giftData) => {
    try {
      const db = getFirestore();
      const giftId = uuidv4();
      // Reference to the user's gifts collection
      const userGiftsRef = collection(db, `users/${senderUserId}/gifts`);

      // Add a new document to the gifts collection
      const giftDocRef = await addDoc(userGiftsRef, { ...giftData, giftId });

      //console.log("Gift added with ID:", giftDocRef.id);
    } catch (error) {
      console.error("Error adding gift:", error);
    }
  };
  const addG = async (receiverEmail, giftData) => {
    try {
      // Debounce the function to limit how often it can be called
      const debouncedAddG = debounce(async () => {
        const db = getFirestore();

        // Reference to the user's gifts collection
        const userGiftsRef = collection(db, `users/${receiverEmail}/gifts`);

        // Add a new document to the gifts collection
        const giftDocRef = await addDoc(userGiftsRef, giftData);

        //console.log("Gift added with ID:", giftDocRef.id);
      }, 1000); // Adjust the debounce wait time (in milliseconds) as needed

      // Call the debounced function
      debouncedAddG();
    } catch (error) {
      console.error("Error adding gift:", error);
    }
  };
  // const handleTextareaChange = (e) => {
  //   const newText = e.target.value;
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     greeting: newText,
  //   }));
  //   // setCustomMessage(e.target.value);
  // };

  //   const handleImageChange = (e) => {
  //     const file = e.target.files[0];

  //     if (file) {
  //       const reader = new FileReader();

  //       reader.onload = () => {
  //         setSelectedImage(reader.result);
  //       };

  //       reader.readAsDataURL(file);
  //     }
  //   };

  // Example usage
  // Replace with the actual sender's user ID

  //   const giftData = {
  //     senderName: "Sender's Name",
  //     senderEmail: "ashishpandey46209@gmail.com",
  //     receiverName: "Receiver's Name",
  //     receiverEmail: "ashispandey138c@gmail.com",
  //     greeting: "Short Greeting",
  //     date: "2023-12-01", // Replace with the actual date
  //   };

  // Call the function to add the gift to the database
  //   addGift(senderUserId, giftData);

  const [selectedImage, setSelectedImage] = useState(
    LogoCP // Replace with the URL of your dummy image
  );

  // const handleImageChange = (e) => {
  //   const file = e.target.files[0];

  //   if (file) {
  //     const reader = new FileReader();

  //     reader.onload = () => {
  //       setSelectedImage(reader.result);
  //     };

  //     reader.readAsDataURL(file);
  //   }
  // };

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const cropperRef = useRef(null);

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = () => {
        setSelectedImage(reader.result);
        setModalIsOpen(true); // Open the modal after selecting the image
      };

      reader.readAsDataURL(file);
    }
  };

  const cropImage = () => {
    if (
      !cropperRef.current ||
      typeof cropperRef.current.getCroppedCanvas !== "function"
    ) {
      return;
    }

    const croppedImageData = cropperRef.current.getCroppedCanvas().toDataURL();
    setSelectedImage(croppedImageData);
    setModalIsOpen(false); // Close the modal after cropping
  };

  // const [message, setMessage] = useState(
  //     `Dear Miki,\n\nI'm so lucky to get another year around the sun with you, Happy birthday to the love of my life, I'm so excited to celebrate my favorite person today.\n\nYour Best friend\nMark Racker`
  // );
  const cardRef = useRef(null);

  // const handleShare = () => {
  //     if (!cardRef.current) return;

  //     html2canvas(cardRef.current).then((canvas) => {
  //       const image = canvas.toDataURL('image/png');
  //       // You can use this 'image' variable to share on social media platforms or save it.
  //       //console.log(image);
  //     });
  //   };

  const handleLinkedInShare = () => {
    if (!cardRef.current) return;

    html2canvas(cardRef.current).then((canvas) => {
      const image = canvas.toDataURL("image/png");

      // Create a share link for LinkedIn
      const linkedInShareLink = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
        window.location.href
      )}&title=Birthday%20Card&summary=Check%20out%20this%20birthday%20card&source=My%20Website&source=My%20Website&image=${encodeURIComponent(
        image
      )}`;

      // Open a new window to redirect to LinkedIn for sharing
      window.open(linkedInShareLink, "_blank");
    });
  };

  const handleFacebookShare = () => {
    if (!cardRef.current) return;

    html2canvas(cardRef.current).then((canvas) => {
      const image = canvas.toDataURL("image/png");

      // Create a share link for Facebook
      const facebookShareLink = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        window.location.href
      )}&quote=Check%20out%20this%20birthday%20card&picture=${encodeURIComponent(
        image
      )}`;

      // Open a new window to redirect to Facebook for sharing
      window.open(facebookShareLink, "_blank");
    });
  };

  const handleWhatsAppShare = () => {
    if (!cardRef.current) return;

    html2canvas(cardRef.current).then((canvas) => {
      //   const image = canvas.toDataURL('image/png');

      // Create a share link for WhatsApp
      const whatsAppShareLink = `https://api.whatsapp.com/send?text=Check%20out%20this%20birthday%20card%20${encodeURIComponent(
        window.location.href
      )}`;

      // Open a new window to redirect to WhatsApp for sharing
      window.open(whatsAppShareLink, "_blank");
    });
  };

  const handleTwitterShare = () => {
    if (!cardRef.current) return;

    html2canvas(cardRef.current).then((canvas) => {
      //   const image = canvas.toDataURL('image/png');

      // Create a share link for Twitter
      const twitterShareLink = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
        window.location.href
      )}&text=Check%20out%20this%20birthday%20card&hashtags=BirthdayCard`;

      // Open a new window to redirect to Twitter for sharing
      window.open(twitterShareLink, "_blank");
    });
  };

  const handleInstagramShare = () => {
    if (!cardRef.current) return;

    html2canvas(cardRef.current).then((canvas) => {
      //   const image = canvas.toDataURL('image/png');

      // Create a share link for Instagram
      const instagramShareLink = `https://www.instagram.com/?url=${encodeURIComponent(
        window.location.href
      )}`;

      // Open a new window to redirect to Instagram for sharing
      window.open(instagramShareLink, "_blank");
    });
  };

  //   const handleDownload = () => {
  //     if (!cardRef.current) return;

  //     html2canvas(cardRef.current).then((canvas) => {
  //       const image = canvas.toDataURL('image/png');

  //       // Create an "a" element to act as a download link
  //       const downloadLink = document.createElement('a');
  //       downloadLink.href = image;
  //       downloadLink.download = 'birthday_card.png';

  //       // Append the link to the body and simulate a click to trigger the download
  //       document.body.appendChild(downloadLink);
  //       downloadLink.click();

  //       // Remove the link from the body
  //       document.body.removeChild(downloadLink);
  //     });
  //   };

  const handleDownload = () => {
    if (!cardRef.current) return;

    html2canvas(cardRef.current, {
      ignoreElements: (element) => {
        // Ignore the textarea element
        return element.tagName === "TEXTAREA";
      },

      ignoreElements: (element) => {
        // Ignore the textarea element
        return element.id === "charCount";
      },

      ignoreElements: (element) => {
        // Ignore the textarea element
        return element.id === "textAreaDiv";
      },

      onclone: (doc) => {
        const cardElement = doc.querySelector(".rounded-card"); // Add a class to the card container
        if (cardElement) {
          // Apply rounded border to the cloned card container
          cardElement.style.borderRadius = "0px"; // Adjust the radius as needed
        }
      },
    }).then((canvas) => {
      const image = canvas.toDataURL("image/png");

      // Optionally, you can trigger a download
      const downloadLink = document.createElement("a");
      downloadLink.href = image;
      downloadLink.download = "MyWeb3Gift_Card.png";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    });
  };

  const handleDownloadCard = async () => {
    const node = cardRef.current; // Get a reference to the card element

    const textarea = node.querySelector("textarea");

    // Temporarily hide the scrollbar
    textarea.style.overflow = "hidden";

    try {
      const image = await htmlToImage.toPng(node); // Convert card to PNG image
      saveAs(image, "MyWeb3Gift_Card.png"); // Trigger browser download
    } catch (error) {
      console.error("Error generating image:", error);
    }
  };

  // Modify your textarea onChange event handler to replace newline characters with <br> tags
  const handleTextareaChangeforbr = (e) => {
    const inputValue = e.target.value;
    const replacedValue = inputValue.replace(/\n/g, "<br>");
    setFormData({ ...formData, greeting: replacedValue }); // Assuming you're using useState to manage formData
  };

  const sendmail = async (giftDataforMail) => {
    if (formData.receiverEmail === "") {
      toast.error(
        "Please Fill Complete Form For Sending Gifts To The Right Person 😅"
      );
      navigate("/");
    } else {
      try {
        const response = await axios.post(
          "https://emailservice-azg4.onrender.com/email",
          giftDataforMail,
          { withCredentials: true }
        );

        // Assuming your backend responds with a success message
        toast.success("email sent to the receiver ");
      } catch (error) {
        console.error("Error saving gift:", error);
        toast.error("Failed to send email to receiver");
      }
    }
  };
  const sendsendermail = async (giftDataforMail) => {
    if (formData.email === "") {
      // toast.error(
      //   "Please Fill Complete Form For Sending Gifts To The Right Person 😅"
      return;

      // navigate("/");
    } else {
      try {
        const response = await axios.post(
          "https://emailservice-azg4.onrender.com/senderemail",
          giftDataforMail,
          { withCredentials: true }
        );

        // Assuming your backend responds with a success message
        toast.success("email sent to the sender ");
      } catch (error) {
        console.error("Error saving gift:", error);
        toast.error("Failed to send email to sender");
      }
    }
  };

  const useremail = user?.primaryEmailAddress.emailAddress;
  //console.log(formData.giftDetails);
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      email: UserEmailAddress || "",

      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const [selectedOccasion, setSelectedOccasion] = useState("");
  const [customMessage, setCustomMessage] = useState("");

  const [textareaValue, setTextareaValue] = useState("");
  const handleTextareaChange1 = (event) => {
    setFormData({ ...formData, greeting: event.target.value });
  };
  const handleOccasionChange = (event) => {
    setSelectedOccasion(event.target.value);
    const selectedValue = event.target.value;
    setTextareaValue([selectedValue]);
    const occasionData = greetingsData.find(
      (occasion) => occasion[selectedValue]
    );
    if (occasionData) {
      const randomMessage =
        occasionData[selectedValue][
          Math.floor(Math.random() * occasionData[selectedValue].length)
        ].message;
      ///add code

      // const receiverNameMatch = randomMessage.match(/\[Receiver Name\]/);
      // const updatedReceiverName =
      //   receiverNameMatch && receiverNameMatch.length > 0
      //     ? formData.receiverName
      //     : "Receiver Name"; // Provide a default receiver name if not found

      // // Update the formData with the updated receiver name
      // setFormData((prevData) => ({
      //   ...prevData,
      //   receiverName: updatedReceiverName,
      // }));
      setGeneratedGreeting(`${customMessage} ${randomMessage}`);
      setFormData((prevData) => ({
        ...prevData,
        greeting: `${customMessage} ${randomMessage}`,
      }));
    }
  };
  const generateGreeting = () => {
    const occasionData = greetingsData.find(
      (occasion) => occasion[selectedOccasion]
    );

    if (occasionData) {
      const randomMessage =
        occasionData[selectedOccasion][
          Math.floor(Math.random() * occasionData[selectedOccasion].length)
        ].message;
      setGeneratedGreeting(`${customMessage} ${randomMessage}`);
      setFormData((prevData) => ({
        ...prevData,
        greeting: `${customMessage} ${randomMessage}`,
      }));
    }
  };

  // //console.log(setGeneratedGreeting);

  // //console.log("This is Greeting value", generatedGreeting);

  const greetingvalue = generatedGreeting;
  const handlesave = (formData) => {
    // Access form data from props.location.state
    //   const formData = props.first.location.state;
    const options = {
      day: "numeric",
      month: "short",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: false,
    };
    if (
      // formData.email === "" ||
      formData.giftDetails === "" ||
      formData.receiverEmail === "" ||
      formData.receiverName === "" ||
      formData.name === ""
      // formData.boxColor === ""
    ) {
      toast.error(
        "Please Fill Complete Form For Sending Gifts To Right Person 😅"
      );
      navigate("/creategiftcard");
    } else {
      // Use formData to save to the database
      const giftData = {
        senderName: formData.name,
        senderEmail: formData.email,
        // senderImage: user.imageUrl || 'https://cdn.discordapp.com/attachments/1181482025950126120/1200391239254679552/OS5qcGc.png?ex=65c60275&is=65b38d75&hm=bb6c4b0a5ae94fba6d5090419acc281fcf9209e86c3ac79aa5ab1b3b926e35c8&',
        receiverName: formData.receiverName,
        receiverEmail: formData.receiverEmail,
        greeting: formData.greeting, // Use the parameter directly here
        date: new Date().toLocaleDateString("en-US", options),
        image: selectedImage,
        giftDetails: formData.giftDetails,
        cardColor: boxColor,
        shadowColor: boxShadowColor,
        cardFont: randomFont,
        opened: false,
      };
      //console.log("color giftdata", giftData);

      const giftDataforMail = {
        senderName: formData.name,
        senderEmail: formData.email,
        //  senderImage: user.imageUrl || 'https://cdn.discordapp.com/attachments/1181482025950126120/1200391239254679552/OS5qcGc.png?ex=65c60275&is=65b38d75&hm=bb6c4b0a5ae94fba6d5090419acc281fcf9209e86c3ac79aa5ab1b3b926e35c8&',
        receiverName: formData.receiverName,
        receiverEmail: formData.receiverEmail,
        greeting: formData.greeting, // Use the parameter directly here
        date: formData.date,
        giftDetails: formData.giftDetails,
      };

      if (isSignedIn) {
        // Retrieve the current count of gifts sent with login from local storage
        let giftsSentWithLogin =
          parseInt(localStorage.getItem("giftsSentWithLogin")) || 0;

        // Check if the user has exceeded the limit of gifts with login
        if (giftsSentWithLogin < 5) {
          // Increment the count of gifts sent with login
          giftsSentWithLogin += 1;

          // Store the updated count in local storage
          localStorage.setItem("giftsSentWithLogin", giftsSentWithLogin);

          addGift(senderUserId, giftData);
          toast.success("Your Crypto Gift has been sent. Thank you 🙂🎉");
          sendmail(giftDataforMail);
          sendsendermail(giftDataforMail);
          navigate("/GiftSentSuccess");
        } else {
          console.warn(
            "User has reached the maximum limit of gifts with login."
          );
          toast.error("You have reached your daily limit for logged-in gifts");
        }
      }

      const receiverEmail = formData.receiverEmail;

      // Check if the user is signed in
      if (!isSignedIn) {
        // Retrieve the current count of gifts sent without login from local storage
        let giftsSentWithoutLogin =
          parseInt(localStorage.getItem("giftsSentWithoutLogin")) || 0;

        // Check if the user has exceeded the limit of gifts without login
        if (giftsSentWithoutLogin < 2) {
          // Increment the count of gifts sent without login
          giftsSentWithoutLogin += 1;
          console.warn("adhsgfoagheopwagpao;gjdsg");
          // Store the updated count in local storage
          localStorage.setItem("giftsSentWithoutLogin", giftsSentWithoutLogin);

          // Call the addG function
          console.warn("adhsgfoagheopwagpao;gjdsg");
          addG(receiverEmail, giftData);
          toast.success("Your Crypto Gift has been sent. Thank you 🙂🎉");
          sendmail(giftDataforMail);

          sendsendermail(giftDataforMail);
          navigate("/GiftSentSuccess");
        } else {
          console.warn(
            "User has reached the maximum limit of gifts without login."
          );
          toast.error(
            "you have reached the maximum limit of gifts without login. login to send more "
          );
          setIsLoginModelOpen(!IsLoginModelOpen);
          // You can optionally display a message to the user or take other actions here
        }
      }

      // setFormData({});
    }

    // alert("your data is saved ");
  };

  //   const handleEmail = (formData) => {
  //     sendEmail({
  //         email: formData.receiverEmail,
  //         subject: 'MyWeb3Gift',
  //         message,
  //       });
  //   }
  //     const [status, setStatus] = useState('');

  //   const handleEmail = async (e) => {
  //     e.preventDefault();

  //     try {
  //       const result = await sendEmail({ email: formData.receiverEmail,
  //                 subject: 'MyWeb3Gift',
  //                 message,});
  //       setStatus(result ? 'Email sent successfully!' : 'Failed to send email.');
  //     } catch (error) {
  //       console.error('Error sending email:', error);
  //       setStatus('Failed to send email. Please check the console for details.');
  //     }
  //   };
  const sendEmail = (formData) => {
    // Replace 'recipient@example.com' with the actual email address of the recipient
    const recipientEmail = formData.receiverEmail;

    // Replace 'Hello! This is a custom message.' with your custom message
    const message = formData.greeting;

    // Create the mailto link
    const mailtoLink = `mailto:${recipientEmail}?subject=Subject&body=${encodeURIComponent(
      message
    )}`;

    // Open the default email client with the mailto link
    window.location.href = mailtoLink;
  };

  const [randomFont, setRandomFont] = useState(generateRandomFont());
  function generateRandomFont() {
    const fonts = [
      "Arial, sans-serif",
      "Times New Roman, serif",
      "Courier New, monospace",
      "Georgia, serif",
      "Verdana, sans-serif",
      "Comic Sans MS, cursive",
    ];
    return fonts[Math.floor(Math.random() * fonts.length)];
  }

  const handleGenerateFont = () => {
    setRandomFont(generateRandomFont());
  };

  const hashtags = ["web3gift", "cryptogift", "myweb3gift"];
  const via = "@myweb3gift";

  if (isApproved === false) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <ScrollLink to="sectionsss" smooth={true} duration={1000}></ScrollLink>
      <div
        className="sectionsss unselectable overflow-x-hidden "
        style={{ overflowX: "hidden" }}
      >
        {IsLoginModelOpen && (
          <div className="fixed  w-full h-full z-[100] ">
            <LoginModel CloseLoginModal={CloseLoginModal}></LoginModel>
          </div>
        )}

        <Nav01 />
        <div className="z-10  bg-sky-950 h-[90px] w-full"></div>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 2 }}
          viewport={{ once: true }}
          className="mx-auto"
        >
          <div className="flex mx-auto min-h-screen bg-white max-w-screen-xl flex-row items-center justify-center">
            <div
              // style={{ backgroundColor: "#efefef" }}
              className="relative mx-auto pt-4 flex min-h-full max-w-screen-xl flex-col items-start bg-cover overflow-x-hidden bg-center"
            >
              <div className="pt-0 flex w-full items-start max-lg:flex-col h-full justify-center max-[765px]:justify-start">
                <div className="w-4/12 gap-5 max-lg:w-full min-h-full max-lg:min-h-full flex  items-center flex-col">
                  <div className="flex flex-col items-center gap-2 ">
                    <div className="w-[100px] h-[100px] rounded-[50%] bg-gray-300 flex justify-center items-center">
                      <h1 className="text-5xl text-gray-600 font-light">1</h1>
                    </div>
                    <h1 className="font-bold text-xl text-gray-500">
                      Insert your information
                    </h1>
                  </div>
                  <div class="w-full min-h-full max-lg:py-10  flex flex-col justify-center">
                    <div className="self-center flex flex-col ">
                      <label className=" font-bold text-gray-500 text-xl">
                        Enter Gift Receiver Name
                      </label>
                      <input
                        className="mb-4 p-2 text-gray-500 placeholder-gray-300 px-4 text-xl font-semibold w-[300px] max-[280px]:w-full rounded-full self-center border outline-none border-solid border-gray-500 bg-slate-50 "
                        placeholder="enter here"
                        type="text"
                        name="receiverName"
                        value={formData.receiverName}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="self-center flex flex-col ">
                      <label className=" font-bold text-gray-500 text-xl">
                        Receiver Email Address
                      </label>
                      <input
                        className="mb-4 p-2 placeholder-gray-300 text-gray-500 px-4 w-[300px] max-[280px]:w-[250px] text-xl font-semibold rounded-full self-center border outline-none border-solid border-gray-400 bg-slate-50 "
                        placeholder="enter here"
                        type="email"
                        name="receiverEmail"
                        value={formData.receiverEmail}
                        onChange={handleInputChange}
                      />
                    </div>
                    {/* <div class="w-full flex flex-col justify-center items-start"> */}
                    <div className="self-center flex flex-col ">
                      <label className=" font-bold text-gray-500 text-xl">
                        Enter Your Name
                      </label>
                      <input
                        className="mb-4 p-2 text-gray-500 placeholder-gray-300 px-4 text-xl font-semibold w-[300px] max-[280px]:w-[250px] rounded-full self-center border outline-none border-solid border-gray-400 bg-slate-50 "
                        placeholder="enter here"
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="self-center flex flex-col ">
                      <SignedIn>
                        <label class=" my-2 text-xl font-bold text-gray-500">
                          Your Email Address
                        </label>

                        <input
                          className="text-gray-500 text-xl font-semibold mb-4 p-2 px-4 w-[300px] max-[280px]:w-[250px] rounded-full self-center border outline-none border-solid border-gray-400 bg-slate-50"
                          placeholder={UserEmailAddress}
                          type="email"
                          name="email"
                          value={UserEmailAddress}
                          onChange={handleInputChange}
                          readOnly // Add this attribute
                        />
                      </SignedIn>
                    </div>
                    <div className="self-center flex flex-col justify-center max-lg:max-w-72 ">
                      <SignedOut>
                        <label class=" my-2 max-w-72 mr-3 max-[280px]:max-w-64 max-[280px]:mr-0 flex justify-center  text-xl font-bold text-gray-500">
                          Enter Your Email Address (optional)
                        </label>

                        <input
                          className=" text-xl font-semibold mb-4 p-2 px-4 w-[300px] max-[280px]:w-[250px] rounded-full self-center border outline-none border-solid border-gray-400 bg-slate-50 "
                          placeholder="enter here"
                          type="email"
                          name="email"
                          value={formData.email}
                          onChange={handleInputChange}
                        />
                      </SignedOut>
                    </div>

                    {/*<label class="mx-12 my-2 text-xl font-bold text-gray-500">Date of Sharing</label>
              <input
                class="mx-11 max-w-xs rounded-3xl border-2 border-solid border-gray-400 bg-slate-50 px-4 py-2 font-bold text-gray-400"
                type="date"
                name="date"
                value={formData.date}
                onChange={handleInputChange}
              /> */}
                  </div>
                </div>

                <div className="relative gap-7 z-0 px-6 w-4/12 max-lg:pt-0 max-lg:w-full flex flex-col items-center min-h-full justify-center">
                  <div className="flex flex-col items-center gap-2 ">
                    <div className="w-[100px] h-[100px] rounded-full bg-gray-300 flex justify-center items-center">
                      <h1 className="text-5xl text-gray-600 font-light">2</h1>
                    </div>
                    <h1 className="font-bold text-xl text-gray-500">
                      Personal Note
                    </h1>
                  </div>
                  <div
                    className={`w-full max-h-[80vh]  max-lg:min-h-full gap-4 md:w-[380px] z-10 rounded-card p-4 max-w-[280px]:px-16 flex flex-col items-center relative overflow-hidden rounded-xl `}
                    style={{
                      backgroundColor: boxColor,
                      boxShadow: `0 0 20px ${boxShadowColor}, 0 0 25px ${boxShadowColor}`,
                    }}
                    ref={cardRef}
                  >
                    <div
                      style={{ backgroundImage: `url(${selectedImage})` }}
                      className="   w-[150px] h-[150px] bg-contain bg-no-repeat bg-top flex items-center justify-center z-30"
                    ></div>
                    <div className="relative lg:pb-6 h-fit w-full rounded-[10px] bg-white ">
                      <div
                        className="greetingcards px-2 md:leading-8 text-xl  w-full overflow-x-visible overflow-y-scroll font-medium text-gray-400"
                        style={{
                          fontFamily: randomFont,
                          whiteSpace: "pre-line",
                        }}
                      >
                        <div
                          className="w-full overflow-hidden h-60 text-xl px-3 border-none outline-none py-0"
                          contentEditable={true}
                          style={{
                            borderRadius: "2px",
                          }}
                          onChange={handleTextareaChange1}
                          onFocus={clearPlaceholder}
                          onBlur={restorePlaceholder}
                        >
                          <p className="capitalize mb-1 font-semibold">
                            Dear {formData.receiverName}
                          </p>

                          <p>
                            <div id="textAreaDiv">
                              <textarea
                                placeholder="Type something here..."
                                value={formData.greeting}
                                className="text-xl font-medium outline-none w-full relative"
                                name=""
                                id=""
                                cols="20"
                                rows="7"
                                style={{
                                  resize: "none",
                                  maxHeight: "250px",
                                  overflow: "hidden",
                                  outline: "none",
                                }}
                                onChange={handleTextareaChangeforbr}
                                maxLength={200} // Change 150 to the maximum number of characters you want to allow
                                onInput={(e) => {
                                  const inputValue = e.target.value;
                                  const totalChars = 200;
                                  const usedChars = formData.greeting
                                    ? formData.greeting.length
                                    : 0;
                                  const remainingChars =
                                    totalChars - usedChars - inputValue.length;
                                  document.getElementById(
                                    "charCount"
                                  ).innerText = `${remainingChars}`;
                                }}
                              ></textarea>
                            </div>

                            <div className="hidden" id="charCountDiv">
                              <div
                                className="text-sm  bg-yellow-500 p-1 rounded-full inline text-white"
                                id="charCount"
                              >
                                200
                              </div>
                            </div>

                            <div>{formData.greeting}</div>
                          </p>
                        </div>
                        {/* <div className="py-5">
                          <textarea className="px-8 bg-blue-500 py-4" name="" id="" cols="30" rows="10" value={formData.greeting} onChange={handleTextareaChange1} >


                            <p > {formData.greeting}</p>
                          </textarea>
                        </div> */}
                      </div>

                      <div className="w-full flex items-start relative justify-between">
                        <p
                          style={{
                            fontFamily: randomFont,
                          }}
                          className="pb-4 px-5 pt-3 capitalize text-xl font-semibold text-gray-400"
                        >
                          {formData.name}
                        </p>
                        {/* <div className="px-2 py-5  pt-1">                  
                          <QRCodeWithLogo  value={websiteURL} logo={LogoCP} />
                        </div> */}
                      </div>

                      <div className="w-full flex items-start relative justify-between">
                        <div></div>

                        <div className="px-2   pt-1">
                          <QRCodeWithLogo value={websiteURL} logo={LogoCP} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-4/12 gap-5 max-lg:w-full max-lg:py-5 relative px-10 flex flex-col items-center justify-items-center">
                  <div className="flex flex-col items-center gap-2">
                    {/* #a19d9e */}
                    <div className="w-[100px] h-[100px] rounded-[50%] bg-gray-300 flex justify-center items-center">
                      <h1 className="text-5xl text-gray-600 font-light">3</h1>
                    </div>
                    <h1 className="font-bold text-xl text-gray-500">
                      Individualize your card
                    </h1>
                  </div>

                  <div className=" text-gray-500 w-full flex flex-col justify-center font-semibold text-left">
                    <span className="text-xl text-justify">
                      Customize this card according to your choice for giving
                      personal and feel.
                      {/* </span>{" "}
                                        <span className="text-xl"> */}{" "}
                      Like: Colour, Text, Photo of your receiver
                    </span>
                  </div>

                  <input
                    className="rounded-3xl hover:border-2 hidden my-8 mx-6 bg-gray-50 font-bold text-gray-300"
                    type="file"
                    name=""
                    id=""
                    accept="image/*"
                    capture="camera"
                    onChange={handleImageChange}
                    placeholder="hello"
                  />

                  <label
                    htmlFor="fileInput"
                    className="w-[180px] flex justify-center items-center cursor-pointer"
                  >
                    <span className="hover:bg-yellow-400 text-white text-lg text-black  bg-yellow-300 py-3 flex justify-center items-center  w-full rounded-full font-bold ">
                      Change Picture
                    </span>
                    <input
                      type="file"
                      id="fileInput"
                      className="hidden"
                      onChange={handleImageChange}
                    />
                  </label>

                  {/* <div>
      <input
        type="file"
        accept="image/*"
        capture="camera"
        onChange={handleImageChange}
      />

      <label htmlFor="fileInput">
        <span>Change Picture</span>
      </label>

      <Modal  isOpen={modalIsOpen}>
        <div  >
          <h2 >Crop Image</h2>
          <Cropper
            src={selectedImage}
            style={{ height: '50vh', width: '50%' }}
            initialAspectRatio={1}
            CropBoxWidth={100} // Set minimum crop box width (in pixels)
            CropBoxHeight={100} // Set minimum crop box height (in pixels)
         guides={true}
            ref={cropperRef}
            onInitialized={(instance) => {
              cropperRef.current = instance;
            }}
          />
          <button  className="bg-gray-300 max-w-md px-6 flex justify-center items-center hover:bg-gray-400 py-3 rounded-3xl font-bold" onClick={cropImage}>Crop Image</button>
        </div>
      </Modal>


    </div> */}

                  <label className="w-[180px]  flex justify-center items-center">
                    <span className="hover:bg-yellow-400 px-2 bg-yellow-300 py-3 text-black flex justify-center items-center  w-full rounded-full font-bold ">
                      <select
                        className="bg-transparent text-lg text-white outline-none w-[170px] items-center rounded-3xl font-bold"
                        onChange={handleOccasionChange}
                        value={selectedOccasion}
                      >
                        <option className="">Text Suggestions</option>
                        {greetingsData.map((occasion) => (
                          <option
                            key={Object.keys(occasion)[0]}
                            value={Object.keys(occasion)[0]}
                            className="bg-gray-400"
                          >
                            {Object.keys(occasion)[0]}
                          </option>
                        ))}
                      </select>
                    </span>
                  </label>

                  {showColorPicker && (
                    <div
                      className="absolute top-[10%] max-lg:left-1/4 -left-1/4 z-20"
                      ref={colorPickerRef}
                    >
                      <MdClose
                        className="absolute -top-3  -right-3 bg-gray-200 font-bold rounded-full w-[20px] h-[20px] text-xl cursor-pointer text-black"
                        onClick={() => setShowColorPicker(false)}
                      />
                      <SketchPicker
                        color={selectedColor}
                        onChange={handleColorChange}
                        // onClick={changeBoxColor}
                      />
                    </div>
                  )}
                  {/* <div className="flex w-full flex-col gap-4 py-4 items-center justify-center "> */}
                  <button
                    className="bg-yellow-300 text-white text-lg w-[180px] flex justify-center items-center hover:bg-yellow-400 py-3 rounded-full font-bold"
                    onClick={generateGreeting}
                  >
                    More Text Ideas
                  </button>
                  <button
                    onClick={handleGenerateFont}
                    className=" bg-yellow-300 w-[180px] text-white text-lg flex justify-center items-center font-bold cursor-pointer  focus:ring-2 py-3 rounded-full hover:bg-yellow-400"
                  >
                    Change Font
                  </button>

                  <button
                    className=" rounded-full font-bold text-white text-lg focus:ring-2 py-3 w-[180px]  flex justify-center items-center bg-yellow-300 hover:bg-yellow-400 cursor-pointer"
                    onClick={handleColorButtonClick}
                    onDoubleClick={handleColorButtonClick}
                  >
                    {" "}
                    Change Color
                  </button>
                  {/* </div> */}
                </div>
              </div>
              <div className="flex items-start max-lg:flex-col max-lg:gap-4 w-full h-fit py-5">
                <div className=" max-lg:w-full h-full pl-20 max-lg:px-10 max-lg:text-center flex justify-center items-center text-gray-500 w-4/12 font-semibold">
                  <span className="text-xl">
                    {" "}
                    Suggestions: Why not print this card and handover
                    personally?
                  </span>
                </div>

                <div className="flex gap-10 max-lg:gap-2 max-lg:w-full h-full w-4/12  max-lg:flex-row items-center justify-center">
                  <button
                    onClick={handleDownload}
                    className="flex items-center max-sm:hidden rounded-3xl bg-yellow-300 px-9 py-3 text-xl font-bold text-white hover:bg-yellow-400 focus:ring-2"
                  >
                    Print
                  </button>

                  <button
                    onClick={handleDownloadCard}
                    className="flex sm:hidden items-center rounded-3xl bg-yellow-300 px-9 py-3 text-xl font-bold text-white hover:bg-yellow-400 focus:ring-2"
                  >
                    Print*
                  </button>

                  <button
                    className="flex items-center rounded-3xl bg-yellow-300 px-9 py-3 text-xl font-bold text-white hover:bg-yellow-400 focus:ring-1"
                    onClick={() => handlesave(formData, setGeneratedGreeting)}
                    onChange={handleInputChange}
                  >
                    Send Now
                  </button>

                  {/* <button className="mx-auto my-11 items-center rounded-3xl bg-yellow-300 px-9 py-2 text-xl font-bold text-white hover:bg-yellow-400 focus:ring-2" onClick={() => sendEmail(formData)}>Send Email</button> */}
                </div>
                <div className="h-full flex  pb-6 gap-4 w-4/12 max-lg:w-full flex-col items-center justify-center">
                  <span className="text-gray-500 text-center text-xl font-semibold ">
                    Share this card with your friend
                  </span>

                  <div className="flex py-0 max-lg:flex  flex-row gap-8">
                   
                    <FacebookShareButton
                      url="https://www.myweb3gift.com"
                      quote="I am happy to share this web3gift "
                      hashtag="#myweb3gift"
                      className="max-h-[50px] hover:scale-105 hover:duration-150 hover:ease-in"
                    >
                      <FacebookIcon
                        size={50}
                        round={false}
                        className="rounded-xl"
                      ></FacebookIcon>
                    </FacebookShareButton>
                    <LinkedinShareButton
                      url="https://www.myweb3gift.com"
                      title="MYWEB3GIFT"
                      description="I am happy to share that I received a crypto gift via ${via} \n if you want to check them out:\n"
                      className="max-h-[50px] hover:scale-105 hover:duration-150 hover:ease-in"
                    >
                      <LinkedinIcon
                        size={50}
                        round={false}
                        className="rounded-xl"
                      />
                    </LinkedinShareButton>
                    <WhatsappShareButton
                      url="https://www.myweb3gift.com"
                      title={`I am happy to share that I received a crypto gift if you want to check them out:\n`}
                      separator=""
                      className="max-h-[50px] hover:scale-105 hover:duration-150 hover:ease-in"
                    >
                      <WhatsappIcon
                        size={50}
                        round={false}
                        className="rounded-xl "
                      />
                    </WhatsappShareButton>
                    <TwitterShareButton
                      url="https://www.myweb3gift.com"
                      className="max-h-[50px] hover:scale-105 hover:duration-150 hover:ease-in"
                      title={`I am happy to share that I received a crypto gift via ${via} \n if you want to check them out:\n`}
                      // via="Myweb3gift"
                      hashtags={hashtags}
                    >
                      <TwitterIcon
                        size={50}
                        round={false}
                        className="rounded-xl"
                      />
                    </TwitterShareButton>
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <Footer></Footer> */}
        </motion.div>
      </div>
    </>
  );
};

export default CreateGiftCard;
