import React from "react";
import styles from "../style/door14.module.css";
import { motion } from "framer-motion";
import { Handimg1 } from "../../DoorsImages/Doorimg15";

export default function FDoor() {
  return (
    <>
      <motion.div
        className={styles.section11}
        initial="hidden"
        animate="visible"
        transition={{ duration: 1 }}
      >
        <motion.div
          initial={{ x: "-15vw", opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{
            duration: 1,
          }}
          viewport={{ once: true }}
          className={styles.walletboxheading}
        >
          <h1 className="text-white text-4xl font-bold pt-10 ">
            TradFi vs. CeFi vs. DeFi
          </h1>
          <p className="text-white mt-9 text-xl tracking-widest">
            In summary, TradFi represents traditional, centralized finance, CeFi
            refers to centralized financial systems that may leverage
            blockchain, and DeFi represents a new paradigm focused on
            decentralized, blockchain-based financial services. Each has its
            advantages and challenges, and the landscape is continually
            evolving.
          </p>
        </motion.div>
        <motion.div
          className={styles.walletimgbox}
          initial={{ x: "15vw", opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{
            duration: 1,
          }}
          viewport={{ once: true }}
          whileHover={{
            scale: 0.9,
            transition: { type: "spring", duration: 1 },
          }}
          exit={{
            x: "-60vw",
            scale: [1, 0],
            transition: { duration: 1 },
          }}
        >
          <img src={Handimg1} alt="img" />   
        </motion.div>
      </motion.div>
    </>
  );
}
