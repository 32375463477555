import React from 'react'
import "./BlogPage3.css"
// import { Link } from 'react-router-dom'
import Nav01 from '../../../Components/Nav01'
import { Footer } from '../../../sections_landing'
import Blog3 from './Blog3'
import { LocomotiveScrollProvider } from "react-locomotive-scroll";
import 'locomotive-scroll/dist/locomotive-scroll.css'

import { useRef } from "react";
import { AnimatePresence } from 'framer-motion';
const BlogPage3 = () => {
  const ref = useRef(null);

  const options = {
    smooth: true,
  } 
  return (
    <>
      <div>
       <Nav01></Nav01> 
       <div data-Scroll className="z-10 absolute bg-sky-950 h-[90px] w-screen"></div>
   </div>
    
    <LocomotiveScrollProvider options={options} containerRef={ref}>
    <AnimatePresence>

        <main data-scroll-container ref={ref}>
{/* <div> */}


<Blog3/>
<Footer></Footer>


    {/* </div> */}
    </main>
    </AnimatePresence>
    </LocomotiveScrollProvider>
    </>
  )
}

export default BlogPage3