/* eslint-disable */

import { HiX } from "react-icons/hi";
import Links from "./components/Links";

import SidebarCard from "./componentsrtl/SidebarCard";
import routes from "../../../../routes.js";
import { LogoCP } from "../../../../assets/Images/MyWeb3Gift-red2/index.js";
import { Link } from "react-router-dom";

const Sidebar = ({ open, onClose }) => {
  return (
    <div
      className={`sm:none duration-175  fixed !z-50 flex min-h-full flex-col bg-white pb-10 shadow-2xl shadow-white/5 transition-all  md:!z-50 lg:!z-50 xl:!z-0 ${
        open ? "translate-x-0" : "-translate-x-96"
      }`}
    >
      <span
        className="absolute top-4 right-4 block cursor-pointer xl:hidden"
        onClick={onClose}
      >
        <HiX />
      </span>

      <div className={`mx-[10px] py-4 flex items-center`}>
        <div className="h-2.5 text-[26px] font-bold  ">      
         <div className="flex items-center">
                  <Link to="/">
                    <img
                      src={LogoCP}
                      className=" w-10  rounded-full   hover:ease-in-out hover:duration-700 hover:delay-300  hover:scale-110     "
                      alt="Logo"
                    />

                  </Link>
                  
                  <Link to="/">
                    {" "}
                    <p
                      // style={{ fontFamily: '"Raleway",san-serif' }}
                      className="py-4 px-2"
                    >
                      <span className="text-yellow-400  ">My</span>
                      <span className="text-yellow-400">Web3</span>
                      <span className="text-yellow-400">Gift</span>
                    </p>{" "}
                  </Link>
                  </div>
        </div>
      </div>
      <div class="mt-[58px] mb-7 border-t-2 bg-gray-300 bg-white/30" />
      {/* Nav item */}

      <ul className="mb-auto text-lg pt-1">
        <Links routes={routes} />
      </ul>

      
      <div className="flex justify-center">
        {/* <SidebarCard /> */}
      </div>

      
    </div>
  );
};

export default Sidebar;
