import React, { useState } from "react";
import Nav01 from "../Components/Nav01";
import {
  Screenshot1,
  Screenshot2,
  Screenshot3,
  Screenshot4,
  Screenshot5,
  Screenshot6,
  Screenshot7,
  nearlogo,
} from "./DoorsImages/Doorimg1";
import { Footer } from "../sections_landing";
import { AnimatePresence,motion } from "framer-motion";
import { useNavigate, useParams } from "react-router-dom";
import Verify from "./AdventDoors/Verify";

export default function NearT() {
  const [userProgress, setUserProgress] = useState({
    currentDay: 1,
    completedDoors: [],
  });
  const navigate = useNavigate(); 
  const params = useParams();
  const paramsid = params.id;

  console.log("paramsid",paramsid);
  return (
    <>
    <div><Verify params={paramsid}/></div>
      <div>
        <Nav01 />
        <div className="z-10 absolute bg-sky-950 h-[90px] w-screen"></div>
      </div>
      <AnimatePresence>
      <motion.div
       initial={{opacity:0}}
      whileInView={{opacity:1}}
      transition={{duration:0.3}}
      viewport={{once:true}} 
       className="w-full min-h-screen bg-white pt-20 overflow-hidden">
      {/* <div className="w-full h-screen bg-white "> */}
        <div className="w-full flex min-h-[300px] flex-col items-center gap-4 py-5 bg-[#00ec97] bg-gray-200 justify-center min-h-[100px] ">
          <img src={nearlogo} className="h-16 max-lg:h-10" />
          <p className="text-2xl text-center">We are happy you want to try NEAR.</p>
        </div>
        <div className="w-full flex flex-col min-h-screen py-5 bg-[#0a0a0a] text-white">
          <div className="w-full px-20 py-10 max-lg:px-10">
            <ul className="text-2xl pb-6 ">
             <p className="max-lg:-ml-6">In the following we show you how to </p>
              <li className="mt-3 list-decimal max-lg:list-outside  list-inside  border-solid border-b	py-3  border-gray-800">
                <b> Create a testnet wallet </b>
                <br />
                <ul>
                <li className="ml-6 max-lg:ml-0">
                  (Testnets are a great place to start because no real funds are
                  needed)
                </li>
                </ul>
              </li>
              <li className="mt-3 list-inside max-lg:list-outside  list-decimal border-solid border-b	py-3  border-gray-800 ">
                <b> Get testnet funds into your wallet </b>
                <br />
                <ul>
                <li className="ml-6 max-lg:ml-0">
                  (By using a so called faucet testnet token will be sent to
                  your wallet for free)
                </li>
                </ul>
              </li>
              <li className="mt-3  list-inside max-lg:list-outside  list-decimal border-solid border-b	py-3  border-gray-800 ">
                <b> Mint an NFT </b>
                <br />
                <ul>
                <li className="ml-6 max-lg:ml-0">(A few clicks is all it takes)</li></ul>
              </li>
              <li className="mt-3 list-inside max-lg:list-outside  list-decimal border-solid border-b	py-3  border-gray-800">
                <b> Create a mainnet wallet </b>
                <br />
                <ul>
                <li className="ml-6 max-lg:ml-0">
                  (Now that you have learned to transact on the testnet, you are
                  ready for the mainnet. You will need to fund your mainnet
                  wallet if you want to do real blockchain transactions)
                </li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="w-full  flex flex-col gap-5 pt-5 pb-10 bg-[#00ec97] bg-[#0a0a0a] text-white px-20 max-lg:px-5">
            <ul className="text-xl pb-10">
               <li className="mt-3 text-xl">
                <b className="mt-3 text-3xl"> Create a testnet wallet </b>
                <br />
                (Testnets are a great place to start because no real funds are
                needed)
              </li>
              <li className="mt-3 ml-6 list-disc max-lg:list-outside list-inside text-xl">
                Go to: <a target="_blank" className="text-sky-600 max-lg:text-lg max-sm:text-sm font-bold" href="https://wallet.mintbase.xyz/create-account"
                >
                https://wallet.mintbase.xyz/create-account
                </a>
              </li>
              
              <li className="mt-3 ml-6 list-disc max-lg:list-outside list-inside text-xl">
              Select testnet</li>
              <li className="mt-3 ml-6 list-disc max-lg:list-outside list-inside text-xl">
                Choose your ID and click on continue
              </li>
            </ul>
            <img src={Screenshot1} className="w-[80%] mx-auto" />
            <p className="font-bold text-2xl text-center py-4">
              <span className="text-[#00ec97]">Congratulations,</span> you have
              your NEAR testnet wallet.
            </p>
          </div>
          <div className="w-full  flex flex-col gap-5 py-10 bg-[#0a0a0a] text-white px-20 max-lg:px-5 ">
            <ul className="text-xl pb-10">
              <li className="mt-3 text-xl">
                <b className="mt-3 text-3xl">
                  Get testnet funds into your wallet{" "}
                </b>
                <br />
                (By using a so called faucet testnet token will be sent to your
                wallet for free)
              </li>
              <li className="mt-3 ml-6 list-disc max-lg:list-outside list-inside text-xl">
                Go to: <a
                  className="text-sky-600 font-bold" target="_blank"
                  href="https://near-faucet.io/">
                  https://near-faucet.io/
                </a>
              </li>
              <li className="mt-3 ml-6 list-disc max-lg:list-outside list-inside text-xl">
                Select Near Blockchain and input account username or account
                address. And then request.
              </li>
              <li className="mt-3 ml-6 list-disc max-lg:list-outside list-inside text-xl ">
                Check that you received the testnet token on mintbase wallet
                website.
              </li>
            </ul>
            <div className="flex gap-6 max-lg:flex-col">
              <img src={Screenshot2} className="w-[50%] max-lg:w-[80%]  mx-auto" />
              <img src={Screenshot3} className="w-[50%] max-lg:w-[80%] mx-auto" />
            </div>
            <p className="font-bold text-2xl text-center py-4">
              <span className="text-[#00ec97]">Congratulations,</span> you just
              funded your testnet wallet.{" "}
            </p>
          </div>
          <div className="w-full flex flex-col gap-5 py-10 bg-[#0a0a0a] text-white px-20 max-lg:px-5">
            <ul className="text-xl pb-10">
              <li className="mt-3 text-xl">
                <b className="mt-3 text-3xl">Mint an NFT </b>
                <br />
                (A few clicks is all it takes)
              </li>
              <li className="mt-3 ml-6 list-inside max-lg:list-outside list-disc text-xl">
                Go to: <a className="text-sky-600 font-bold" target="_blank"
                  href="https://testnet.mintbase.xyz/meta/myweb3gift.mintspace2.testnet%3Ae0d46d4f4e8632fe31997846cd9efdc0
"> MyWeb3Gift Test NFT on Mintbase
                </a>
              </li>
              <li className="mt-3 ml-6 list-inside max-lg:list-outside list-disc text-xl">
              Then connect your testnet wallet (connect button in the right hand corner and select testnet)              </li>
              <li className="mt-3 ml-6 list-inside max-lg:list-outside list-disc text-xl ">
              Click on “buy now” (don’t worry this is only testnet with no real funds)
              </li>
              <li className="mt-3 ml-6 list-inside max-lg:list-outside list-disc text-xl ">
              Then confirm transaction in your wallet
                            </li>
                            <li className="mt-3 list-inside max-lg:list-outside ml-6 list-disc text-xl ">
                            Check your purchase in your Mintbase Profile unter “My NFTs”
                            </li>
            </ul>
            <div className="flex gap-6 max-lg:flex-col">
              <img src={Screenshot4} className="w-[50%] max-lg:w-[80%]  mx-auto" />
              <img src={Screenshot6} className="w-[50%] max-lg:w-[80%]  mx-auto" />
              </div>
            <div className="flex gap-6 justify-start max-lg:flex-col">
              <img src={Screenshot5} className="h-[50%] w-[45%] max-lg:w-[80%]  mx-auto" />
              <img src={Screenshot7} className="h-[50%] max-lg:w-[40%] mx-auto" />
            </div>

            <p className="font-bold text-2xl text-center py-4">
              <span className="text-[#00ec97]">Congratulations, </span> 
              you just bought a NFT on the testnet.  
            </p>
            <button
                    className="self-center font-bold w-1/5 widt rounded-full bg-gradient-to-r from-yellow-400 to-yellow-600 px-6 py-2 text-lg  text-white shadow hover:bg-yellow-600 hover:from-yellow-500 hover:to-yellow-600 focus:outline-none  active:bg-red-500"
                    onClick={() => {
                      // setShowThankYouMessage(false);
                      // toggleModal(); // Close the modal
                      navigate(`/ADoor${userProgress.currentDay}/${paramsid}`); // Navigate back to the calendar door page
                    }}
                  >
                    Go Back 
                  </button>
 

          </div>
        {/* </div> */}
        </div>
        <Footer />
      </motion.div>
      </AnimatePresence>
    </>
  );
}
