import React, { useEffect, useState } from "react";
import { RightArrow } from "../Icons/RightArrow";
import { DownArrow } from "../Icons/DownArrow";
import styles from "../style/door3.module.css";
import { motion } from "framer-motion";

const Acordion = ({ title, content }) => {
  const [collapse, setCollapse] = useState(false);

  return (
    <>
      <div className={styles.accordion}>
        <div className={styles.title} onClick={() => setCollapse(!collapse)}>
          <div className={styles.name}>
            <h2 className="text-2xl">{title}</h2>
          </div>
          {collapse ? (
            <span className={styles.indicator}>
              <DownArrow />
            </span>
          ) : (
            <span className={styles.indicator}>
              <RightArrow />
            </span>
          )}
        </div>
        {collapse && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="pt-4 pl-1"
          >
            <div className={styles.reveal}>{content}</div>
          </motion.div>
        )}
      </div>
    </>
  );
};

export default Acordion;
