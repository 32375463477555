import { Link, Routes, Route, Navigate } from "react-router-dom";
import routes from "../../../../routes.js";
export default function Auth() {
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      } else {
        return null;
      }
    });
  };
  document.documentElement.dir = "ltr";
  return (
    <div>
      <div className="relative h-full min-h-screen w-full bg-white ">

        <div className="relative flex flex-col">

          <Routes>
            {getRoutes(routes)}
            <Route
              path="/LoginPage"
              element={<Navigate to="/LoginPage" replace />}
            />
          </Routes>
        </div>

      </div>
    </div>
  );
}
