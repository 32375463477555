import React from "react";
import styles from "../style/door12.module.css";
import Card2 from "../Doorcomponent12/Card2";
import {motion} from "framer-motion"
export default function TDoor1() {
  return (
    <div className={styles.section221}>
      <div className="">
      <motion.div
          initial={{ y: "-100%", opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{
            duration: 1,
          }}
          viewport={{ once: true }}
         className={styles.oracleboxheading}>
          <h1 className="text-white text-4xl text-center font-medium">
            Advantage
          </h1>
          <h1 className="italic text-white text-3xl mt-2 text-center font-light">
            of Multi Chain
          </h1>
        </motion.div>
        <motion.div
          initial={{ x: "-15vw", opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{
            duration: 1,
          }}
          viewport={{ once: true }}
         className={styles.cardboxsss}>
          <div className="flex justify-between items-center ">
            <Card2
              cheading="Scalability"
              text1=" Multi-chain systems can distribute the processing load across multiple blockchains, improving overall scalability."
            />
            <Card2
              cheading="Specialization "
              text1=" Different blockchains may specialize in specific functionalities, allowing projects to choose the blockchain that best suits their requirements.
"
            />
          </div>
          <Card2
            cheading="Flexibility"
            text1=" Developers and users have the flexibility to choose blockchains based on factors such as transaction speed, cost, and security."
          />
        </motion.div>
      </div>
      <hr />
      <div>
      <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{
            duration: 1,
          }}
          viewport={{ once: true }}
         className={styles.oracleboxheading}>
          <h1 className="text-white text-4xl text-center font-medium">
            Disadvantage
          </h1>
          <h1 className="italic text-white text-3xl mt-2 text-center font-light">
            of Multi Chain
          </h1>
        </motion.div>
        <motion.div
          initial={{ x: "-15vw", opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{
            duration: 1,
          }}
          viewport={{ once: true }}
         className={styles.cardboxsss1}>
      
          <Card2
            cheading=" Fragmentation "
            text1=" The use of multiple blockchains might lead to a fragmented ecosystem, making it challenging to achieve consensus and coordinate updates."
          />
          
          <div className="flex justify-between items-center ">
            <Card2
              cheading="Complexity"
              text1=" Managing and coordinating activities across multiple blockchains can be complex and may require additional development effort.
"
            />
            <Card2
              cheading=" Fragmentation "
              text1=" The use of multiple blockchains might lead to a fragmented ecosystem, making it challenging to achieve consensus and coordinate updates."
            />
          </div>
        </motion.div>
      </div>
    </div>
  );
}
