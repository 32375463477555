import React from "react";
import styles from "../style/door14.module.css";
import Accordion from "./Acordition";
import {motion} from "framer-motion";

import { Mask10, Mask11, Mask12, Mask13, Mask14, Mask15, Mask16, Mask17, Mask9 } from "../../DoorsImages/Doorimg14";
import { Choosetest, Enviro, Testing } from "../../DoorsImages/Doorimg23";
import { Transparent } from "../../DoorsImages/Doorimg18";

export default function SDoor() {
  return (
    <div className={styles.section2}>
       <motion.div 
        initial={{opacity:0}}
        whileInView={{ opacity:1}}
        transition={{
          duration:3
        }}
        viewport={{once:true}}
       className={styles.nftboxheading2}>
      <h1 className="text-white text-center font-bold text-3xl tracking-wide">
      Here are some key points about testnets:
        </h1>
      </motion.div>
      <div className={styles.imgboxcard}>
        <div className={styles.imgboxcard1}>
        <motion.div 
        initial={{x: "-15vw",opacity:0}}
        whileInView={{ x: 0,opacity:1}}
        transition={{
          duration:1
        }}
        viewport={{once:true}}
           className={styles.cards}>
            <div className={styles.imuu}>
              <img className={styles.padlock} src={Testing} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Development and Testing" 
                content1=" Testnets provide a sandbox environment where developers can deploy and test their blockchain applications, smart contracts, and other features without affecting the mainnet. This is crucial for identifying and fixing bugs, improving performance, and ensuring the security of the system.
"
              ></Accordion>
            </div>
          </motion.div>
          <motion.div 
        initial={{x: "15vw",opacity:0}}
        whileInView={{ x: 0,opacity:1}}
        transition={{
          duration:1
        }}
        viewport={{once:true}}
           className={styles.cards}>
            <div className={styles.imuu}>
              <img className={styles.baht} alt="img1" src={Enviro} />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Simulated Environment"
                content1="Testnets mimic the conditions of the mainnet but use testnet tokens (which have no real-world value) to simulate transactions. This allows developers to test various functionalities without the risk of losing actual assets."
 ></Accordion>
            </div>
          </motion.div>
          </div>
          </div>
          <div className={styles.imgboxcard}>
        <div className={styles.imgboxcard1}>
          <motion.div 
        initial={{x: "-15vw",opacity:0}}
        whileInView={{ x: 0,opacity:1}}
        transition={{
          duration:1
        }}
        viewport={{once:true}}
           className={styles.cards}>
            <div className={styles.imuu}>
              <img className={styles.mask1} src={Choosetest} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Choosing the Right Testnet" 
                content1=" The best testnet depends on the specific blockchain platform you are working with. Different testnets may have varying features, consensus algorithms, and tools available. Choose a testnet that closely resembles the characteristics of the mainnet you intend to deploy on.
"
>

</Accordion>
            </div>
          </motion.div>
          <motion.div 
        initial={{x: "15vw",opacity:0}}
        whileInView={{ x: 0,opacity:1}}
        transition={{
          duration:1
        }}
        viewport={{once:true}}
           className={styles.cards}>
            <div className={styles.imuu}>
              <img className={styles.mask2} src={Mask12} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Faucets"
                content1="Testnets often have faucets, which are tools that distribute testnet tokens to users for free. These tokens are used to interact with the testnet blockchain. Faucets help developers and testers obtain the necessary resources to experiment without having to mine or purchase tokens.
"
                ></Accordion>
            </div>
          </motion.div>
          </div>
          </div>
          <div className={styles.imgboxcard}>
        <div className={styles.imgboxcard1}>
          <motion.div 
        initial={{x: "-15vw",opacity:0}}
        whileInView={{ x: 0,opacity:1}}
        transition={{
          duration:1
        }}
        viewport={{once:true}}
           className={styles.cards}>
            <div className={styles.imuu}>
              <img className={styles.curency} src={Transparent} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Community Support" 
content1="Some testnets have a strong community presence, with forums and documentation that can assist developers in troubleshooting issues and getting the most out of the testnet environment.
"
></Accordion>
            </div>
          </motion.div>
          
          </div>
          </div>
          
      <motion.div 
        initial={{x: "-15vw",opacity:0}}
        whileInView={{ x: 0,opacity:1}}
        transition={{
          duration:1
        }}
        viewport={{once:true}}
       className={styles.nftboxheading2}>
      <p className="text-white pt-5 pl-3 whitespace-pre-line text-lg tracking-widest">
      In summary, testnets play a crucial role in the development and testing of blockchain applications. They provide a safe and controlled environment for developers to experiment with blockchain features before deploying them on the mainnet. The choice of the best testnet depends on the specific requirements of the project and the blockchain platform being used.
      </p>
      </motion.div>
    </div>
  );
}
