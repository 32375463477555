import React from "react";
import { motion } from "framer-motion";
import styles from "../style/door10.module.css";
import { Wallet3 } from "../../DoorsImages/Doorimg10";

export default function FDoor() {
  return (
    <>
      <motion.div
        className={styles.section1}
        initial="hidden"
        animate="visible"
        //  variants={fadeInVariants}
        transition={{ duration: 1 }}
      >
        <motion.div
          layout
          // initial={{ opacity: 0, x:20 }}
          initial={{
            x: "-15vw",
            transition: { type: "spring", duration: 2.5 },
          }}
          animate={{ x: 0, transition: { type: "spring", duration: 2.5 } }}
          // animate={{ opacity: 1, y: 0 }}
          transition={{
            opacity: { ease: "linear" },
            type: "spring",
            layout: { duration: 1 },
          }}
          className={styles.tokenheading}
        >
          <h1 className="text-5xl font-bold">Token Types</h1>
          <p className="text-center mt-6 tracking-wider text-lg whitespace-pre-line">
          In the context of blockchain technology, "tokens" refer to digital assets that are created and managed on a blockchain. Tokens can represent various assets, including cryptocurrencies, digital assets, or even real-world assets like real estate or commodities. There are different token types, each serving specific purposes within the blockchain ecosystem.
          </p>

        </motion.div>
        <motion.div
          className={styles.bitcoinimgbox}
          initial={{
            y: "15vw",
            transition: { type: "spring", duration: 2.5 },
          }}
          animate={{ y: 0, transition: { type: "spring", duration: 2.5 } }}
          transition={{
            opacity: { ease: "linear" },
            type: "spring",
            layout: { duration: 1 },
          }}
        >
          <img src={Wallet3} alt="nftimg" />
        </motion.div>
      </motion.div>
    </>
  );
}
