import React from "react";
import styles from "../style/door14.module.css";
import { motion } from "framer-motion";
// import { Airdrop } from "../../DoorsImages/Doorimg19";

export default function S2Door() {
  return (
    <>
      <motion.div
        className={styles.section1aa}
        initial="hidden"
        animate="visible"
        transition={{ duration: 1 }}
      >
        <motion.div
          initial={{ x: "-15vw", opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{
            duration: 1,
          }}
          viewport={{ once: true }}
          className={styles.walletboxheading19}
        >
          <h2 className="text-white text-3xl text-left font-medium pt-3 ">
            Cross-Chain Bridges:
          </h2>
          <p className="text-white mt-6 text-xl tracking-widest">
            Cross-chain bridges are mechanisms that facilitate the transfer of
            assets or data between different blockchain networks. These bridges
            act as connectors, enabling interoperability by allowing tokens or
            information to move from one blockchain to another.
            <br />
            <br />
            <b>Importance:</b>
            Cross-chain bridges are crucial for achieving interoperability and
            creating a unified DeFi ecosystem. They enable users to interact
            with decentralized applications (DApps) and assets across multiple
            blockchains seamlessly."
          </p>
        </motion.div>
      </motion.div>
    </>
  );
}
