import React from 'react'
import styles from "../style/door10.module.css";
import { Token2 } from '../../DoorsImages/Doorimg10';
import { motion } from 'framer-motion';
export default function FourSec() {
  return (
    <div className={styles.section4}>
<motion.div initial={{ x: "-15vw",opacity:0}}
            whileInView={{ x: 0, opacity:1 }}
            transition={{duration:0.6}}
             viewport={{once:true}} className={styles.tokenbox}>
      <h1 className='text-xl font-bold tracking-wide'>
      Some important links to learn more about token types 
      </h1>
      <div className={styles.btnbox}>
<button className={styles.btn} >
<a  href='https://legalnodes.com/article/token-types-legal-status'target="_blank" rel="noopener noreferrer">Click Now</a>
</button>
<button className={styles.btn}>
<a  href='https://capital.com/what-is-a-wrapped-token-guide-into-wrapped-crypto'target="_blank" rel="noopener noreferrer">Find more</a>

</button>
</div>
      <div className={styles.btnbox}>
<button className={styles.btn}> 
<a  href='https://wirexapp.com/blog/post/the-8-different-types-of-crypto-assets-0471' target="_blank" rel="noopener noreferrer">Learn more</a>
</button>
<button className={styles.btn}>
<a  href='https://medium.com/untitled-inc/the-token-classification-framework-290b518eaab6' target="_blank" rel="noopener noreferrer">Click here</a>
</button>
      </div>
</motion.div>
<motion.div initial={{ x: "15vw",opacity:0}}
            whileInView={{ x: 0, opacity:1 }}
            transition={{duration:0.6}}
             viewport={{once:true}} className={styles.bgimgbox}>
<img src={Token2} alt="img"/>
</motion.div>
    </div>
  )
}
