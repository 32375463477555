import React from "react";
import styles from "../style/door11.module.css";
import {motion } from "framer-motion"
export default function Card({ title, text }) {
  return (
    <>
      <motion.div initial={{ opacity:0}}
            whileInView={{  opacity:1 }}
            transition={{duration:2}}
             viewport={{once:true}} className={styles.door11card1}>
        <h1 className="text-2xl font-medium">{title}</h1>
        <p className="text-lg text-center">{text}</p>
      </motion.div>
    </>
  );
}
