import React from 'react'
import styles from "../style/door11.module.css";
import { motion} from "framer-motion";
import Card from './Card';

export default function Spart2(){
  return (
    <div>
    <motion.div className={styles.sec2part}>
    <motion.div initial={{opacity:0}}
            whileInView={{  opacity:1 }}
            transition={{duration:2}}
             viewport={{once:true}} className={styles.nfth1}>
       <h1 className="font-medium text-center text-3xl tracking-wide">
       Advantages of Stablecoins
      </h1>
     </motion.div>
     <div className={styles.doorcardss}>
    <div className={styles.door11cards}>
     <Card title="Reduced Volatility" text="Stablecoins provide a more stable store of value compared to traditional cryptocurrencies like Bitcoin, making them more suitable for transactions and everyday use."/>
     <Card title="Facilitates Trading" text="Traders can use stablecoins to quickly move funds between different cryptocurrency exchanges without being exposed to the price volatility of other cryptocurrencies."/>
    </div>
    <div className={styles.door11cards2}>
     <Card title="Smart Contracts and Decentralized Finance (DeFi) " text="Stablecoins are widely used in decentralized finance applications, allowing for programmable and automated financial transactions without the risk of significant value fluctuations."/>
     <Card title=" Remittances and Cross-Border Payments" text="Stablecoins can facilitate faster and more cost-effective cross-border transactions compared to traditional banking systems."/>
    </div>
   </div>
   </motion.div>
 
 </div>
  )
}
