import React from 'react'
import Nav01 from "../../Components/Nav01";
import { Footer } from "../../sections_landing";
import DoorComplete from "../DoorComplete";
// import Door13comp from '../DoorComponents/Doorcomponent13/Door13comp';
import styles from "../DoorComponents/style/door3.module.css";
import { LocomotiveScrollProvider } from "react-locomotive-scroll";
import 'locomotive-scroll/dist/locomotive-scroll.css'

import { useRef } from "react";
import { AnimatePresence } from 'framer-motion'
import Door3comp from '../DoorComponents/Doorcomponent3/Door3comp';
import { useParams } from 'react-router-dom';
import Verify from './Verify';

const ADoor13 = () => {
  const ref = useRef(null);
  const params = useParams();
  const paramsid = params.id
  const options = {
    smooth: true,
  } 
  return (
    <>
     <div className=''><Verify params={paramsid}/></div>

       <div>
<Nav01></Nav01>
{/* <div className="z-10 absolute bg-sky-950 h-[90px] w-screen"></div> */}
</div>
    
    <LocomotiveScrollProvider options={options} containerRef={ref}>
    <AnimatePresence>

        <main data-scroll-container ref={ref} className='select-none'>
    {/* <div style={{backgroundColor:"#0a0c14",overflow:"hidden"}} className='pt-10'>
   
    <div>
    <div className={styles.container13}>
        <Door13comp/>
        </div>
        <div className="flex flex-row justify-center mb-3">
          <DoorComplete Door={13}/>
        </div>
        <Footer></Footer>
      </div>
    </div> */}
    <div style={{ backgroundColor: "#8b56fc", overflow: "hidden" }} className="pt-20">
            <div>
                <div className={styles.container3}>
                    <Door3comp />
                   
                    <div className="flex flex-row justify-center mb-3">
                        <DoorComplete Door={13} id={paramsid}/>
                    </div>
                </div>
                    <Footer></Footer>
            </div>
        </div>
    </main>
    </AnimatePresence>
    </LocomotiveScrollProvider>
    </>
  )
}

export default ADoor13