import React from 'react'
import styles from "../style/door11.module.css";
import { motion} from "framer-motion";
// import gsap from "gsap";
import Accordion from "./Acordition2";
import { door11img5, door11img6, door11img7, door11img8 } from '../../DoorsImages/Doorimg11';

export default function Spart1() {
  return (
    <div>
       <motion.div className={styles.nftboxheading3}>
       <motion.div initial={{ y: "-15vh",opacity:0}}
            whileInView={{ y: 0, opacity:1 }}
            transition={{duration:1}}
             viewport={{once:true}} className={styles.nfth1}>
          <h1 className="font-medium text-center text-3xl tracking-wide">
          There are generally four types of stablecoins
          </h1>
        </motion.div>
        <div className={styles.accordionsection2}>
        <motion.div initial={{ x: "-100%",opacity:0}}
            whileInView={{ x: 0, opacity:1 }}
            transition={{duration:1.2}}
             viewport={{once:true}} className={styles.accordion2}>
            <Accordion door11img58={door11img7}
              title="Fiat-Collateralized Stablecoins" 
              content="How they work: These stablecoins are backed by a reserve of fiat currency, typically held in a bank account. Each stablecoin issued is pegged to a specific amount of fiat currency, like the US Dollar.
" egtext="Examples: Tether (USDT), USD Coin (USDC), TrueUSD (TUSD)."
            ></Accordion>
          </motion.div>
          <motion.div initial={{ x: "-100%",opacity:0}}
            whileInView={{ x: 0, opacity:1 }}
            transition={{duration:1.5}}
             viewport={{once:true}} className={styles.accordion2}>
            <Accordion door11img58={door11img6}
              title="Commodity collateralized stablecoins"
              content="How they work: These are a type of stablecoin whose value is pegged to the value of a commodity, such as precious metals like gold or silver. The idea is to use a tangible asset as collateral to provide stability and mitigate the volatility often associated with other forms of cryptocurrencies."
            ></Accordion>
          </motion.div>
          <motion.div initial={{ x: "-100%",opacity:0}}
            whileInView={{ x: 0, opacity:1 }}
            transition={{duration:1.7}}
             viewport={{once:true}} className={styles.accordion2}>
            <Accordion door11img58={door11img5}
              title="Crypto-Collateralized Stablecoins " egtext=" Examples: DAI (part of the MakerDAO system)."
              content="How they work: These stablecoins are backed by a reserve of other cryptocurrencies. Smart contracts and algorithms maintain the stable value by adjusting the supply of the stablecoin based on the value of the collateral."
            ></Accordion>
          </motion.div>
          <motion.div initial={{ x: "-100%",opacity:0}}
            whileInView={{ x: 0, opacity:1 }}
            transition={{duration:1.9}}
             viewport={{once:true}} className={styles.accordion2}>
            <Accordion door11img58={door11img8}
              title=" Algorithmic or Non-Collateralized Stablecoins"
              content="How they work: These stablecoins use algorithms and smart contracts to adjust the supply of the coin without relying on a collateral reserve. They aim to maintain stability through algorithmic mechanisms. "
              egtext="Examples: Ampleforth (AMPL), Terra (LUNA)."
            ></Accordion>
          </motion.div>
        </div>
      </motion.div>
    
    </div>
  )
}
