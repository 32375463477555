import React from 'react';
import { Surprise, Gift, Garrow } from '../assets/Images';
import {AnimatePresence, motion} from "framer-motion"
const Steps = () => {
  return (
    <div className=" py-10">
     <AnimatePresence>
      <motion.section  initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }} 
              exit={{ opacity: 0}}
      transition={{duration:"1"}}      
      viewport={{once:true}}  className="container w-full mx-auto text-center">
        <h1 className="font-bold text-4xl pb-3 sm:text-5xl">How it works?
        </h1>
        <div className="text-center hidden mb-5 max-sm:hidden">
                    <span className="inline-block w-1 h-1 rounded-full bg-sky-950 ml-1"></span>
                    <span className="inline-block w-3 h-1 rounded-full bg-sky-950 ml-1"></span>
                    <span className="inline-block w-80 h-1 rounded-full bg-sky-950"></span>
                    <span className="inline-block w-3 h-1 rounded-full bg-sky-950 ml-1"></span>
                    <span className="inline-block w-1 h-1 rounded-full bg-sky-950 ml-1"></span>
                </div>
        {/* </div> */}
        
        <motion.div
         className="flex max-lg:flex-col justify-center">
          {/* Gifter Side */}
          <div 
        className=" flex flex-1 max-lg:flex-col  max-lg:px-4 gap-5 items-center py-3 justify-center flex-row  min-h-[50vh]">
          <div className="text-center h-full w-5/12 max-lg:w-full
          flex flex-col items-center justify-center
          bg-white rounded-lg shadow-md max-lg:pb-[1.5rem] md:px-4 transform transition-transform duration-600 ease-in-out hover:-translate-y-1 hover:shadow-2xl
          ">
            <h2 className="text-4xl text-center max-lg:text-2xl my-6 font-bold">
              Gifter
              <img src={Gift} className="w-8 inline-block ml-2" alt="Gift Icon" loading="lazy"/>
            </h2>
            <ol className="text-left text-2xl max-lg:text-xl ml-10 max-lg:ml-0 max-sm:ml-10 leading-10 list-decimal">
              <li>Select a gift box</li>
              <li>Add an optional personal message (optional)</li>
              <li>Handover gift via email and printed card (optional)</li>
              {/* <li>Choose how to hand it over:</li>
              // &emsp;&emsp; &emsp; 
              <ul className="pl-6">
                <li>a. via email with a link/barcode</li>
                <li>b. in person with a printed card containing the link/barcode</li>
              </ul> */}
            </ol>
          </div>
          <div className='h-full w-[10%] max-lg:w-2/12 flex items-center justify-center'>
            <img className='w-full max-lg:rotate-90' src={Garrow} alt="rightarrow" />
          </div>
          {/* Recipient Side */}
           <div className="h-full w-5/12 max-lg:w-full max-lg:h-6/12
          bg-white rounded-lg shadow-md transform max-lg:pb-[1.5rem] md:px-4 transition-transform hover:-translate-y-1 hover:shadow-2xl
         flex flex-col items-center justify-center
           ">
            <h2 className="text-4xl max-lg:text-2xl my-6 font-bold">
              Recipient 📲
              {/* <img src={Surprise} className="w-8 inline-block ml-2" alt="Surprise Icon" loading="lazy" /> */}
            </h2>
            <ol className="text-left text-2xl ml-10 max-lg:ml-0 max-sm:ml-10 max-lg:text-xl leading-10 list-decimal">
            <li>Access our redeem page via received email/card.</li>
            <li>Open gift box:</li>
            <ul className="ml-8">
                <li>a. Discover personal message</li>
                <li>b. Open daily gifts and content for 10 days</li>
              </ul> 
              {/* <li>Use the link/barcode to access our redeem page</li>
              <li>Discover the personal message from the gifter</li>
              <li>Open one gift content per day for 24 days</li> */}
            </ol>
          </div>
          </div>
        </motion.div>
      </motion.section>
      </AnimatePresence>
    </div>
  );
}

export default Steps;
