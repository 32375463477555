import React from "react";
import styles from "../style/door3.module.css";
import { motion, useAnimation } from "framer-motion";

export default function LDoor() {
  const fadeInVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };
  const container1 = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.5,
      },
    },
  };

  return (
    <>
      <motion.div
        className={styles.section3}
        initial="hidden"
        animate="visible"
        variants={{ container1 }}
        transition={{ duration: 1 }}
      >
        <div className={styles.nftboxheading4}>
        <motion.p 
        initial={{x: "-15vw",opacity:0}}
        whileInView={{ x: 0,opacity:1}}
        transition={{
          duration:0.6
        }}
        viewport={{once:true}}
           className="text-white mt-3 text-lg tracking-widest">
            OpenSea is one of the largest and most well-known NFT marketplaces.
            It supports a wide variety of digital assets, including art,
            collectibles, and virtual real estate.
          </motion.p>
          <motion.button
        initial={{x: "-15vw",opacity:0}}
        whileInView={{ x: 0,opacity:1}}
        transition={{
          duration:0.6
        }}
        viewport={{once:true}}
        className={styles.btn}>
            <a className="font-bold underline text-xl" target="_blank" href="https://opensea.io">
              Click to open
            </a>
          </motion.button>
          <motion.p 
        initial={{x: "-15vw",opacity:0}}
        whileInView={{ x: 0,opacity:1}}
        transition={{
          duration:0.6
        }}
        viewport={{once:true}}
           className="text-white mt-3 text-lg tracking-widest">
            Rarible is a decentralized NFT marketplace that allows users to
            create, buy, and sell NFTs. It operates on the Ethereum blockchain.
          </motion.p>
          <motion.button
        initial={{x: "-15vw",opacity:0}}
        whileInView={{ x: 0,opacity:1}}
        transition={{
          duration:0.6
        }}
        viewport={{once:true}}
         className={styles.btn}>
            <a className="font-bold underline text-xl" target="_blank" href="https://rarible.com">
              Explore
            </a>
          </motion.button>
          <motion.p 
        initial={{x: "-15vw",opacity:0}}
        whileInView={{ x: 0,opacity:1}}
        transition={{
          duration:0.6
        }}
        viewport={{once:true}}
           className="text-white mt-3 text-lg tracking-widest">
            SuperRare is a marketplace specifically for digital art NFTs. It
            focuses on curated, limited-edition pieces created by artists.
          </motion.p>

          <motion.button
        initial={{x: "-15vw",opacity:0}}
        whileInView={{ x: 0,opacity:1}}
        transition={{
          duration:0.6
        }}
        viewport={{once:true}}
         className={styles.btn}>
            <a className="font-bold underline text-xl" target="_blank" href="https://superrare.com">
              Open Now
            </a>
          </motion.button>
          <motion.p 
        initial={{x: "-15vw",opacity:0}}
        whileInView={{ x: 0,opacity:1}}
        transition={{
          duration:0.6
        }}
        viewport={{once:true}} className="text-white mt-3 text-lg tracking-widest">
            Nifty Gateway is an NFT marketplace known for collaborating with
            well-known artists and musicians to release exclusive digital
            collectibles.
          </motion.p>
          <motion.button
        initial={{x: "-15vw",opacity:0}}
        whileInView={{ x: 0,opacity:1}}
        transition={{
          duration:0.6
        }}
        viewport={{once:true}}
           className={styles.btn}>
            <a className="font-bold underline text-xl" target="_blank" href="https://www.niftygateway.com">
              See Now
            </a>
          </motion.button>
        </div>
      </motion.div>
    </>
  );
}
