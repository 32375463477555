import React from 'react'
import Nav01 from '../Components/Nav01'
import { Blog, Footer } from '../sections_landing'
import { LocomotiveScrollProvider } from "react-locomotive-scroll";
import 'locomotive-scroll/dist/locomotive-scroll.css';
import { useRef } from "react";
import { AnimatePresence } from 'framer-motion';

const BlogPage = () => {
  const ref = useRef(null);

  const options = {
    smooth: true,
  } 
  return (
<>

<div>
<Nav01/>
<div className="absolute z-10 bg-sky-950 h-[90px] w-full"></div>
</div>
<LocomotiveScrollProvider options={options} containerRef={ref}>
      <AnimatePresence>
          <main data-scroll-container ref={ref} className='select-none'>
<div>
<Blog/>

<Footer/>
</div>
</main>
</AnimatePresence>
</LocomotiveScrollProvider>
   </>
  )
}

export default BlogPage