// ReadMore.js
import React, { useState } from "react";
import styles from "../style/card.module.css";

const Readmore = ({ text,text2, maxLength ,maxLength2}) => {
  const [isTruncated, setIsTruncated] = useState(true);
  const toggleTruncate = () => {
    setIsTruncated(!isTruncated);
  };

  const truncatedText = isTruncated ? text.slice(0, maxLength) : text;
  const truncatedText2 = isTruncated ? text2.slice(0, maxLength2) : text2;

  return (
    <div className={styles.walletboxhead}>
      <p className="text-white mt-3 text-lg">{truncatedText}
      <br/>
      {truncatedText2}
      </p>
      {text.length > maxLength && (
        <>
        
        {!isTruncated &&(
          
        <div className="flex w-full max-lg:flex-col max-lg:gap-5 pt-4 items-start">
       
<p className="text-lg w-9/12 max-lg:w-full text-white">Optional: If you are interested in the tech watch this deep dive into private and public key cryptography.</p>
<button className={styles.btn}>
  <a className="font-bold underline" target="_blank" href="https://andersbrownworth.com/blockchain/public-private-keys/"  rel="noopener noreferrer">
    Click to watch
  </a>
</button>

</div>
      )}
      
        <button
          className="text-blue-500 hover:underline focus:outline-none"
          onClick={toggleTruncate}
        >
          {isTruncated ? "Read More" : "Show Less"}
        </button>
        
        </>
      )}
    </div>
  );
};

export default Readmore;
