import React from "react";
import styles from "../style/door4.module.css";
import Accordion from "./Acordition";
import {
  Mask1,
  Mask2,
  Mask3,
  Receipt,
  Baht,
  Safety,
  Web32,
  Tmoney,
  Curency,
  Padlock,
} from "../../DoorsImages/Doorimg4";
import { motion } from "framer-motion";

export default function SDoor() {
  return (
    <div className={styles.section2}>
      <motion.div
        initial={{
          x: "-15vw",
          opacity: 0
        }}
        whileInView={{ x: 0, opacity: 1 }}
        transition={{
          duration: 1,
        }}
        viewport={{ once: true }}
        className={styles.nftboxheading2}
      >
        <p className="text-white pt-4 text-2xl tracking-widest"> On-Ramp refers
          to the process of acquiring or entering the cryptocurrency ecosystem.
          It involves converting traditional fiat currency (like US dollars,
          euros, etc.) into cryptocurrency.
        </p>
      </motion.div>
      <div className={styles.imgboxcard}>
        <div className={styles.imgboxcard1}>
        <motion.div
            initial={{
              x: "-15vw",opacity:0,
            }}
            whileInView={{ x: 0, opacity:1 }}
            transition={{
             duration:0.6
            }}
             viewport={{once:true}}
           className={styles.cards}>
            <div className={styles.imuu}>
              <img className={styles.padlock} src={Padlock} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Accessibility"
                content="On-ramps provide an entry point for people to acquire cryptocurrencies. This is crucial for widespread adoption, as it allows individuals to participate in the crypto space even if they don't initially possess any digital assets."
              ></Accordion>
            </div>
          </motion.div>
          <motion.div
            
            initial={{
              x: "15vw",opacity:0,
            }}
            whileInView={{ x: 0, opacity:1 }}
            transition={{
             duration:0.6
            }}
             viewport={{once:true}}
           className={styles.cards}>
            <div className={styles.imuu}>
              <img className={styles.baht} alt="img1" src={Baht} />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Integration with Traditional Finance"
                content="On-ramps facilitate the integration of cryptocurrencies into the traditional financial system. When individuals can easily convert fiat currency (traditional currency like USD, EUR, etc.) into cryptocurrencies, it opens up opportunities for a broader range of people to use and invest in digital assets."
              ></Accordion>
            </div>
          </motion.div>
          </div>
          </div>
          <div className={styles.imgboxcard}>
        <div className={styles.imgboxcard1}>
          <motion.div
            
            initial={{
              x: "-15vw",opacity:0,
            }}
            whileInView={{ x: 0, opacity:1 }}
            transition={{
             duration:0.6
            }}
             viewport={{once:true}}
           className={styles.cards}>
            <div className={styles.imuu}>
              <img className={styles.mask1} src={Mask1} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Increased Liquidity"
                content="On-ramps contribute to the liquidity of cryptocurrencies by enabling a constant influx of new capital into the market. This liquidity is essential for the functioning of crypto exchanges and the stability of digital asset prices."
              ></Accordion>
            </div>
          </motion.div>
          <motion.div
            
            initial={{
              x: "15vw",opacity:0,
            }}
            whileInView={{ x: 0, opacity:1 }}
            transition={{
             duration:0.6
            }}
             viewport={{once:true}}
           className={styles.cards}>
            <div className={styles.imuu}>
              <img className={styles.mask2} src={Mask2} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Market Growth"
                content="By providing accessible on-ramps, the cryptocurrency market can attract a larger user base, fostering growth and innovation within the space. This, in turn, can lead to increased legitimacy and acceptance of cryptocurrencies."
              ></Accordion>
            </div>
          </motion.div>
        </div>
      </div>

      <motion.div
            initial={{
              x: "-15vw",opacity:0,
            }}
            whileInView={{ x: 0, opacity:1 }}
            transition={{
             duration:0.6
            }}
            viewport={{once:true}} className={styles.nftboxheading2}>
        <p className="text-white pt-10 text-2xl whitespace-pre-line tracking-widest">
          Off-Ramp refers to the process of leaving the cryptocurrency ecosystem
          and converting cryptocurrency back into traditional fiat currency.
        </p>
      </motion.div>
      <div className={styles.imgboxcard}>
        <div className={styles.imgboxcard1}>
        <motion.div
            
            initial={{
              x: "-15vw",opacity:0,
            }}
            whileInView={{ x: 0, opacity:1 }}
            transition={{
             duration:0.6
            }}
             viewport={{once:true}}
           className={styles.cards}>
            <div className={styles.imuu}>
              <img className={styles.curency} src={Curency} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Conversion to Fiat"
                content="Off-ramps allow individuals to convert their cryptocurrencies back into traditional fiat currency. This is important for users who want to realize profits, make purchases in the traditional economy, or mitigate risks by holding assets in a more stable form."
              ></Accordion>
            </div>
          </motion.div>
          <motion.div
            
            initial={{
              x: "15vw",opacity:0,
            }}
            whileInView={{ x: 0, opacity:1 }}
            transition={{
             duration:0.6
            }}
             viewport={{once:true}}
          className={styles.cards}>
            <div className={styles.imuu}>
              <img className={styles.mask1} src={Safety} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Risk Management"
                content="Off-ramps provide a way for investors to manage their risk exposure. If the value of a particular cryptocurrency is volatile, users may choose to exit the market and convert their digital assets to fiat to avoid potential losses."
              ></Accordion>
            </div>
          </motion.div>
          </div>
          </div>
          <div className={styles.imgboxcard}>
        <div className={styles.imgboxcard1}>
          <motion.div
            
            initial={{
              x: "-15vw",opacity:0,
            }}
            whileInView={{ x: 0, opacity:1 }}
            transition={{
             duration:0.6
            }}
             viewport={{once:true}}
           className={styles.cards}>
            <div className={styles.imuu}>
              <img className={styles.mask1} src={Receipt} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Regulatory Compliance"
                content="Off-ramps are often subject to regulatory frameworks, ensuring that transactions involving cryptocurrencies comply with existing laws. This is important for preventing illegal activities such as money laundering and fraud."
              ></Accordion>
            </div>
          </motion.div>
          <motion.div  
            initial={{
              x: "15vw",opacity:0,
            }}
            whileInView={{ x: 0, opacity:1 }}
            transition={{
             duration:0.6
            }}
             viewport={{once:true}}
          className={styles.cards}>
            <div className={styles.imuu}>
              <img className={styles.mask1} src={Mask3} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Consumer Confidence"
                content="The availability of reliable off-ramps can enhance consumer confidence in the cryptocurrency market. Users are more likely to invest in and use cryptocurrencies if they have a clear and secure means of converting their digital assets back into traditional currency."
              ></Accordion>
            </div>
          </motion.div>
        </div>
      </div>
      <motion.div
            
            initial={{
              x: "-15vw",opacity:0,
            }}
            whileInView={{ x: 0, opacity:1 }}
            transition={{
             duration:0.6
            }}
             viewport={{once:true}}
       className={styles.nftboxheading2}>
        <p className="text-white pt-10 pl-3 whitespace-pre-line text-lg tracking-widest">
          It's important to note that regulatory frameworks and the availability
          of these services can vary by country, influencing how easily
          individuals can access or exit the cryptocurrency space. Additionally,
          advancements in technology and changes in the regulatory environment
          can affect the overall landscape of on-ramp and off-ramp options for
          cryptocurrency users.
        </p>
      </motion.div>
    </div>
  );
}
