import React from "react";
import { motion } from "framer-motion";
import { Shop } from "../../DoorsImages/Doorimg3";
import styles from "../style/door3.module.css";

export default function FDoor() {
  return (
    <>
      <motion.div
        className={styles.section1}
        initial="hidden"
        animate="visible"
        //  variants={fadeInVariants}
        transition={{ duration: 1 }}
      >
         <motion.div 
        initial={{x: "-15vw",opacity:0}}
        whileInView={{ x: 0,opacity:1}}
        transition={{
          duration:1
        }}
        viewport={{once:true}}
          className={styles.tokenheading}
        >
          <h1 className="text-4xl text-white font-bold">NFT-market places</h1>
          <p className="text-white mt-3 text-lg">
            An NFT marketplace is an online platform that facilitates the
            buying, selling, and trading of NFTs. As explained yesterday, NFTs
            are unique digital tokens that represent ownership or proof of
            authenticity of a specific digital or physical asset, often
            associated with digital art, music, videos, virtual real estate, and
            other digital or tangible items.
            <br />
          </p>
        </motion.div>
        <motion.div
          className={styles.nftimgbox}
          initial={{
            y: "15vw",opacity:0
          }}
          whileInView={{ y: 0,opacity:1}}
          // animate={{ opacity: 1, y: 0 }}
          transition={{
           duration:1
          }}
          whileHover={{
            scale: 0.9,
            transition: { type: "spring", duration: 1 },
          }}
          exit={{
            x: "-60vw",
            scale: [1, 0],
            transition: { duration: 1 },
          }}
          viewport={{once:true}}
        >
          <img src={Shop} alt="nftimg" />
        </motion.div>
      </motion.div>
    </>
  );
}
