import React from "react";
import styles from "../style/door14.module.css";
import Accordion from "./Acordition";
import { motion } from "framer-motion";

import {
  Mask10,
  Mask11,
  Mask12,
  Mask13,
  Mask14,
  Mask15,
  Mask16,
  Mask17,
  Mask9,
} from "../../DoorsImages/Doorimg14";

export default function SDoor() {
  return (
    <div className={styles.section2}>
      <motion.div
        initial={{ y: "-100%", opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        transition={{
          duration: 1,
        }}
        viewport={{ once: true }}
        className={styles.nftboxheading2}
      >
        <h1 className="text-white text-center font-bold text-3xl tracking-wide">
          Here are some common scams in the crypto space:
        </h1>
      </motion.div>
      <div className={styles.imgboxcard}>
        <div className={styles.imgboxcard1}>
          <motion.div
            initial={{ x: "-15vw", opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{
              duration: 1.5,
            }}
            viewport={{ once: true }}
            className={styles.cards}
          >
            <div className={styles.imuu}>
              <img className={styles.padlock} src={Mask9} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Phishing Scams"
                content="How it works: Scammers create fake websites or emails that mimic legitimate crypto platforms to trick users into providing their private keys or login credentials.
Prevention: Always double-check URLs, use two-factor authentication, and be cautious of unsolicited emails."
              ></Accordion>
            </div>
          </motion.div>
          <motion.div
            initial={{ x: "15vw", opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{
              duration: 1.5,
            }}
            viewport={{ once: true }}
            className={styles.cards}
          >
            <div className={styles.imuu}>
              <img className={styles.baht} alt="img1" src={Mask10} />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Ponzi Schemes"
                content="How it works: Fraudsters promise high returns with little or no risk, using funds from new investors to pay previous investors. These schemes eventually collapse when there are not enough new investors to sustain it.
Prevention: Be skeptical of investment opportunities that promise guaranteed high returns with little or no risk."
              ></Accordion>
            </div>
          </motion.div>
          </div>
          </div>
          <div className={styles.imgboxcard}>
        <div className={styles.imgboxcard1}>
          <motion.div
            initial={{ x: "-15vw", opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{
              duration: 1,
            }}
            viewport={{ once: true }}
            className={styles.cards}
          >
            <div className={styles.imuu}>
              <img className={styles.mask1} src={Mask11} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Rug Pull "
                content='A "rug pull" is a deceptive tactic in cryptocurrency where the creators of a project suddenly withdraw funds, leaving investors with worthless assets. It is a fraudulent maneuver often associated with decentralized projects, particularly in decentralized finance (DeFi). To avoid rug pulls, investors should conduct thorough research and be cautious about projects lacking transparency.'
              ></Accordion>
            </div>
          </motion.div>
          <motion.div
            initial={{ x: "15vw", opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{
              duration: 1,
            }}
            viewport={{ once: true }}
            className={styles.cards}
          >
            <div className={styles.imuu}>
              <img className={styles.mask2} src={Mask12} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="ICO (Initial Coin Offering)
Scams"
                content="How it works: Fraudulent projects raise funds through an ICO, collect money from investors, and disappear without delivering any product or service.
Prevention: Thoroughly research ICO projects, their teams, and the underlying technology before investing."
              ></Accordion>
            </div>
          </motion.div>
          </div>
          </div>
          <div className={styles.imgboxcard}>
        <div className={styles.imgboxcard1}>
          <motion.div
            initial={{ x: "-15vw", opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{
              duration: 1,
            }}
            viewport={{ once: true }}
            className={styles.cards}
          >
            <div className={styles.imuu}>
              <img className={styles.curency} src={Mask13} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Fake Exchanges"
                content="How it works: Scammers create fake cryptocurrency exchanges that appear legitimate but are designed to steal users' funds. 
Prevention: Stick to well-known and reputable exchanges. Double-check the website URL and look for user reviews."
              ></Accordion>
            </div>
          </motion.div>
          <motion.div
            initial={{ x: "15vw", opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{
              duration: 1,
            }}
            viewport={{ once: true }}
            className={styles.cards}
          >
            <div className={styles.imuu}>
              <img className={styles.mask1} src={Mask14} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Fake Wallets "
                content="How it works: Fraudulent wallet apps or software that claim to securely store your cryptocurrency but actually steal your private keys.
Prevention: Only use wallets recommended by reputable sources, and verify the authenticity of the wallet provider."
              ></Accordion>
            </div>
          </motion.div>
          </div>
          </div>
          <div className={styles.imgboxcard}>
        <div className={styles.imgboxcard1}>
          <motion.div
            initial={{ x: "-15vw", opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{
              duration: 1,
            }}
            viewport={{ once: true }}
            className={styles.cards}
          >
            <div className={styles.imuu}>
              <img className={styles.mask1} src={Mask15} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Pump and Dump Schemes"
                content="How it works: Organized groups artificially inflate the price of a low-cap cryptocurrency (pump) and then quickly sell off their holdings (dump), leaving other investors with losses. 
Prevention: Be cautious of sudden price spikes, especially in lesser-known cryptocurrencies."
              ></Accordion>
            </div>
          </motion.div>
          <motion.div
            initial={{ x: "15vw", opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{
              duration: 1,
            }}
            viewport={{ once: true }}
            className={styles.cards}
          >
            <div className={styles.imuu}>
              <img className={styles.mask1} src={Mask16} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Impersonation Scams"
                content="How it works: Scammers impersonate influential figures in the crypto space on social media, forums, or email to trick users into sending them cryptocurrency. 
Prevention: Verify the identity of individuals through official channels and be skeptical of unsolicited messages."
              ></Accordion>
            </div>
          </motion.div>
          </div>
          </div>
          <div className={styles.imgboxcard}>
        <div className={styles.imgboxcard1}>
          <motion.div
            initial={{ x: "-15vw", opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{
              duration: 1,
            }}
            viewport={{ once: true }}
            className={styles.cards}
          >
            <div className={styles.imuu}>
              <img className={styles.mask1} src={Mask17} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Malware and Ransomware Attacks"
                content="How it works: Malicious software is used to steal cryptocurrency from users' wallets or encrypt their files, demanding a ransom in cryptocurrency.
Prevention: Use reputable antivirus and anti-malware software. Keep your operating system and software up to date."
              ></Accordion>
            </div>
          </motion.div>
        </div>
      </div>
      <motion.div
        initial={{ x: "-15vw", opacity: 0 }}
        whileInView={{ x: 0, opacity: 1 }}
        transition={{
          duration: 1,
        }}
        viewport={{ once: true }}
        className={styles.nftboxheading2}
      >
        <p className="text-white pt-5 pl-3 whitespace-pre-line text-lg tracking-widest">
          Always exercise caution, do thorough research, and stay informed about
          the latest scams and security measures in the crypto space. If
          something seems too good to be true, it probably is.{" "}
        </p>
      </motion.div>
    </div>
  );
}
