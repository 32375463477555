import React,{useState} from 'react'
import styles from "../style/door7.module.css";
import Readmore from './Readmore';
import {motion} from "framer-motion";

export default function Card3({title,para,img}) {

  return (
    <>
    
    <div className={styles.cardsss}>
    <motion.div initial={{ x: "-15vw",opacity:0}}
            whileInView={{ x: 0, opacity:1 }}
            transition={{duration:0.6}}
             viewport={{once:true}} className={styles.cardimgss}>
<img src={img} alt="img"/>
        </motion.div>
        <motion.div initial={{ x: "15vw",opacity:0}}
            whileInView={{ x: 0, opacity:1 }}
            transition={{duration:0.6}}
             viewport={{once:true}} className={styles.textac}>
{/* <h1 className='text-3xl font-bold mb-5'>{title}</h1> */}        
          <div className="pt-4 pl-1">
  <p className='antialiased text-lg whitespace-pre-line font-normal tracking-wider'>
    <Readmore heading={title} text={para} maxLength={210}/>
    </p>
    </div>
        </motion.div>
        
    </div>
    </>
  )
}
