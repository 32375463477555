import React from "react";
import styles from "../style/door14.module.css";
import { motion } from "framer-motion";
import { Raffle } from "../../DoorsImages/Doorimg20";

export default function FDoor() {
  return (
    <>
      <motion.div
        className={styles.section11}
        initial="hidden"
        animate="visible"
        transition={{ duration: 1 }}
      >
        <motion.div
          initial={{ x: "-15vw", opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{
            duration: 1,
          }}
          viewport={{ once: true }}
          className={styles.walletboxheading}
        >
          <h1 className="text-white text-4xl font-bold pt-5 ">
            Onchain Raffle
          </h1>
          <p className="text-white mt-6 text-lg tracking-wider">
            In a traditional raffle, participants buy tickets for a chance to
            win a prize, with the winners typically selected randomly. In the
            context of blockchain and decentralized technologies, an "on-chain"
            raffle implies that the entire process, from ticket sales to prize
            distribution, is executed on the blockchain. It is a smart
            contract-based raffle running on Ethereum and powered by Chainlink.
            The winners are for example automatically drawn using Chainlink VRF
            to make it a truly random drawing. To participate, you simply need
            to buy the NFT Raffle Ticket, spread the word of this new fun way to
            play a Raffle, and donate to charity.
          </p>
        </motion.div>
        <motion.div
          initial={{ x: "15vw", opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
          className={styles.walletimgbox}
        >
          <img src={Raffle} alt="img" />
        </motion.div>
      </motion.div>
    </>
  );
}
