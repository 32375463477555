import React from 'react';
import QRCode from 'qrcode.react';
import { useNavigate } from 'react-router-dom';

const QRCodeWithLogo = ({ value, logo }) => {
  const history = useNavigate();

  const handleQRCodeClick = () => {
    // Redirect to the website when the QR code is clicked
    window.location.href = value;
  };

  return (
    <div style={{ textAlign: 'center', position: 'relative',border:"0.01px solid #000",padding:"0.2rem",backgroundColor:"#fff" }} >
      <QRCode value={value} size={70} onClick={handleQRCodeClick} />
      <img src={logo} alt="Logo" style={logoStyle} onClick={handleQRCodeClick} />

    </div>
  );
};

const logoStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '40px', // Adjust the width and height as needed
  height: '40px',
  borderRadius: '50%',
  cursor: 'pointer',
};

export default QRCodeWithLogo;
