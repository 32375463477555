import React from "react";
// import { Link } from 'react-router-dom';
import Nav01 from "../../Components/Nav01";
import { Footer } from "../../sections_landing";
import DoorComplete from "../DoorComplete";
import styles from "../DoorComponents/style/door7.module.css";
import Door7comp from "../DoorComponents/Doorcomponent7/Door7comp";
import { LocomotiveScrollProvider } from "react-locomotive-scroll";
import "locomotive-scroll/dist/locomotive-scroll.css";

import { useRef } from "react";
import { AnimatePresence } from "framer-motion";
import { Link } from "react-router-dom";
import Bonus from "./Bonus";
import { useParams } from 'react-router-dom';
import Verify from "./Verify";
const ADoor5 = () => {
  const ref = useRef(null);
  
  const options = {
    smooth: true,
  };
  const params = useParams();
  const paramsid = params.id
  return (
    <>
     <div className=''><Verify params={paramsid}/></div>
      <div>
        <Nav01></Nav01>
        {/* <div className="z-10 absolute bg-sky-950 h-[90px] w-screen"></div> */}
      </div>

      <LocomotiveScrollProvider options={options} containerRef={ref}>
        <AnimatePresence>
          <main data-scroll-container ref={ref} className="select-none">
            {/* <div
              style={{ backgroundColor: "#27272a", overflow: "hidden" }}
              className="pt-20"
            >
              <div>
                <div className={styles.container4}>
                  <Door4comp />
                </div>
                <div className="flex flex-row justify-center mb-3">
                  <DoorComplete Door={5} />
                </div>

                <Footer></Footer>
              </div>
            </div> */}
            <div style={{backgroundColor:"#FACC15",overflow:"hidden"}} className='pt-20'>
    <div>
    <div className={styles.container7}>
          <Door7comp/>
        </div>
        <div className="flex gap-4 flex-row justify-center mb-3">
        {/* <Link to="/ADoor22"><Bonus/></Link> */}
                {/* <Link to="/ADoor16">
                <button
          className='bg-gradient-to-r from-sky-700 to-sky-500 text-3xl font-bold text-white py-4 px-7 rounded-3xl my-7 hover:bg-gradient-to-r hover:from-sky-500 hover:to-sky-300 transition duration-300'   
        >
         Get Bonus II
        </button>
                </Link> */}
          <DoorComplete Door={5} id={paramsid}/>
        </div>

        <Footer></Footer>
      </div>
    </div>
          </main>
        </AnimatePresence>
      </LocomotiveScrollProvider>
    </>
  );
};

export default ADoor5;
