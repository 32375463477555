import React from "react";
import styles from "../style/door14.module.css";
import { motion } from "framer-motion";
import { Airdrop } from "../../DoorsImages/Doorimg19";

export default function FDoor() {
  return (
    <>
      <motion.div
        className={styles.section11}
        initial="hidden"
        animate="visible"
        transition={{ duration: 1 }}
      >
        <motion.div 
        initial={{x: "-15vw",opacity:0}}
        whileInView={{ x: 0,opacity:1}}
        transition={{
          duration:1
        }}
        viewport={{once:true}}
         className={styles.walletboxheading}>
            <h1 className="text-white text-4xl text-left font-bold pt-5 ">
            Airdrop farming
            </h1>
            <p className="text-white mt-6 text-xl tracking-widest">
            Airdrop farming is a marketing strategy used by cryptocurrency startups to raise awareness for their project and increase token ownership. It involves distributing cryptocurrency assets to a specific group of users within the project’s community. 
            <br/>
            <br/>
            These assets are usually native to the project and can have varying values, ranging from a few cents to thousands of dollars, depending on the level of hype and publicity surrounding the project. 

                        </p>
          </motion.div>
          <motion.div 
        initial={{x: "15vw",opacity:0}}
        whileInView={{ x: 0,opacity:1}}
        transition={{duration:1}}
        viewport={{once:true}}
        className={styles.walletimgbox1}
          
        >
          <img src={Airdrop} alt="img" />   
        </motion.div>
        
      </motion.div>
    </>
  );
}
