import React from 'react'
import { motion } from "framer-motion";
import styles from "../style/door9.module.css";
import { Growth } from '../../DoorsImages/Doorimg9';
import Readmore from './Readmore';


export default function FDoor() {

  const longtext =`Most well-known blockchains are public open networks. Therefore, the participants in this network need to be incentivized to offer their services. Many participants (nodes) believe that the network creates value by being there, so they participate without receiving monetary incentives. However, the network requires participants (miners/validators) who bundle transactions and create new blocks. They are incentivized by receiving (new) network tokens (in the largest networks, these are bitcoins and ether). While the Bitcoin network rewards new bitcoins (plus transaction fees) for finding new blocks, the Ethereum network requires users to pay for computation power. How it works will be explained in the following parts.
Gas fees are associated with blockchain networks that utilize smart contracts and decentralized applications (DApps). They are a crucial component of blockchain systems like Ethereum.`

  return (
    <>
    <motion.div
        className={styles.section1}
        initial="hidden"
        animate="visible"
        //  variants={fadeInVariants}
        transition={{ duration: 1 }}
      >
          <motion.div
            layout
            initial={{
              x: "-15vw",
             opacity:0
            }}
            animate={{ x: 0, opacity:1}}
            transition={{
              duration:1
            }}
            viewport={{once:true}}
            className={styles.walletboxheading}
          >
            <h1 className="text-4xl font-bold ">
            Gas Fees (on Ethereum)
            </h1>
            <p className=" mt-6 text-xl tracking-widest">
            We explained in the privous day what web3 is, what the blockchain is and what can seen in the explorers. Today we have look why anyone would participate in a public network and how the network is operated.
<br></br>
<Readmore text={longtext} maxLength={287}/>
            </p>
          </motion.div>
          <motion.div initial={{ x: "15vw",opacity:0}}
            whileInView={{ x: 0, opacity:1 }}
            transition={{duration:0.6}}
            whileHover={{scale:0.9 , transition:{type:"spring",duration:1}}}
            exit={{scale:[1,0],x:"-60vw",transition:{duration:1}}}
             viewport={{once:true}} className={styles.walletimg}>
          <img src={Growth} alt="img1" />

        </motion.div>
        </motion.div>
        
    
    </>
  )
}
