import React from "react";
import styles from "../style/door14.module.css";
import { motion } from "framer-motion";
// import gsap from "gsap";
import Accordion from "../Doorcomponent19/Acordition2";

export default function S3Door() {
  return (
    <div className={styles.section1aa}>
      <motion.div className={styles.nftboxheading3}>
        <motion.div
          initial={{ y: "-100%", opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
          className={styles.nfth11}
        >
          <h1 className="text-white font-medium text-center text-3xl tracking-wide">
            How Cross-Chain Bridges Work:
          </h1>
        </motion.div>
        <div className={styles.accordionsection2}>
          <motion.div
            initial={{ x: "-105%", opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.2 }}
            viewport={{ once: true }}
            className={styles.accordion2}
          >
            <Accordion
              title="Locking and Unlocking"
              content='In a typical cross-chain bridge, assets are locked on one blockchain and then "minted" or created on another. This process involves a trustless mechanism where assets are locked in a smart contract on one chain, and an equivalent representation (a token) is created on the target chain. When users want to move back, the process is reversed.'
            ></Accordion>
          </motion.div>
          <motion.div
            initial={{ x: "-105%", opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.2 }}
            viewport={{ once: true }}
            className={styles.accordion2}
          >
            <Accordion
              title="Oracles and Validators"
              content="Cross-chain bridges often use oracles or validators to ensure the accuracy of information being transferred between chains. Validators verify transactions and ensure that the representation of assets on one chain matches the locked assets on another chain."
              eg="Examples:"
              bold="Wrapped Tokens: "
              content1="Many cross-chain bridges use wrapped tokens (e.g., wrapped Bitcoin on Ethereum) as representations of assets from one chain on another."
              bold2="Projects: "
              content2="Examples of cross-chain projects include Chainlink, Cosmos, Polkadot, and others. These projects focus on creating frameworks and technologies to enable seamless communication between different blockchains."
            ></Accordion>
          </motion.div>
        </div>
      </motion.div>
    </div>
  );
}
