import { useUser } from '@clerk/clerk-react'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'


const Verify = (paramsid) => {
const {user}= useUser()
const Navigate= useNavigate()


    const verfying = () =>{
        let token1 =  localStorage.getItem('token1')
        if(!token1){
         alert("please buy Gift")
        Navigate('/')
       }
        if(user && token1 === user.id + paramsid){
            return
        }
        
    }
    useEffect( () =>{
        verfying()
    },[user]

    )
 
  return (
    <div></div>
  )
}

export default Verify