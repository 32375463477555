import React from "react";
import styles from "../style/door14.module.css";
import { motion } from "framer-motion";
import { Introperability } from "../../DoorsImages/Doorimg22";

export default function FDoor() {
  return (
    <>
      <motion.div
        className={styles.section11}
        initial="hidden"
        animate="visible"
        transition={{ duration: 1 }}
      >
        <motion.div
          initial={{ x: "-15vw", opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{
            duration: 1,
          }}
          viewport={{ once: true }}
          className={styles.walletboxheading}
        >
          <h1 className="text-white text-4xl font-bold pt-5 ">
            Interoperability
          </h1>
          <p className="text-white mt-6 text-xl tracking-widest">
            Interoperability in the context of blockchain and DeFi refers to the
            ability of different blockchain networks and platforms to
            communicate and work together effectively.
            <br />
            <br />
            <b>Importance:</b> DeFi projects often operate on separate
            blockchain networks, such as Ethereum, Binance Smart Chain, Solana,
            etc. Interoperability allows these projects to share data, assets,
            or functionality, fostering a more interconnected and efficient
            ecosystem."
          </p>
        </motion.div>
        <motion.div
          initial={{ x: "15vw", opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
          className={styles.walletimgbox}
        >
          <img src={Introperability} alt="img" />
        </motion.div>
      </motion.div>
    </>
  );
}
