import React, { useState } from "react";
import { AnimatePresence, motion, stagger } from "framer-motion";
import "./Styles/main.css";
const Faqs = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = (index) => {
    // setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    if(activeIndex===index){
      return setActiveIndex(null);
    }
    setActiveIndex(index);
  };

  return (
    <div>
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
          className="w-full unselectable min-h-screen py-10 mx-auto px-5 bg-gray-50  min-h-sceen"
        >
          <motion.div
            className="flex flex-col items-center"
            initial={{ opacity: 0, y: "-100%" }}
            whileInView={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: "-100%" }}
            transition={{ duration: "1" }}
            viewport={{ once: "true" }}
          >
            <h2 className="font-bold text-5xl mt-5 tracking-tight">FAQs</h2>
            <p className="text-neutral-500 text-xl mt-3">
              Frequently asked questions
            </p>
          </motion.div>
          <motion.div className="grid divide-y overflow-hidden divide-neutral-200 max-w-7xl mx-auto mt-8">
            <AnimatePresence>
              {data.map((item, index) => (
                <motion.div
                  key={index}
                  className="py-7 px-2 hover:bg-gray-100 dark:hover:text-black  hover:ease accordion-item"
                >
                  <motion.details
                    initial={{ opacity: 0, x: "-100%" }}
                    whileInView={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: "-100%" }}
                    transition={{ duration: "0.7" }}
                    viewport={{ once: "true" }}
                    className={`accordion-header ${
                      activeIndex === index ? "active" : ""
                    }`}
                    onClick={() => handleToggle(index)}
                  >
                    <summary className="flex justify-between items-center font-medium cursor-pointer list-none">
                      <span className="text-2xl tracking-wide">
                        {item.question}
                      </span>
                      <span
                        className={`transition ${
                          activeIndex === index ? "rotate-180" : ""
                        }`}
                      >
                        <svg
                          fill="none"
                          height="24"
                          shapeRendering="geometricPrecision"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                          viewBox="0 0 24 24"
                          width="24"
                        >
                          <path d="M6 9l6 6 6-6"></path>
                        </svg>
                      </span>
                    </summary>
                    </motion.details>
                    {activeIndex === index && (
                      <motion.div
                        key={`content-${index}`}
                        initial={{ opacity: 0, x: "-100%" }}
                        whileInView={{
                          opacity: 1,
                          x: 0,
                          delay: stagger(1, { startDelay: 1 }),
                        }}
                        // exit={{ opacity: 0, x: "-100%" }}
                        transition={{ duration: "0.7" }}
                        viewport={{ once: "true" }}
                        className="accordion-content text-gray-600 tracking-wider text-lg"
                      >
                        {item.answer}
                      </motion.div>
                    )}
                 
                </motion.div>
              ))}
            </AnimatePresence>
          </motion.div>
        </motion.div>
      </AnimatePresence>
    </div>
  );
};
const FaqSection = () => {
  const accordionData = [
    {
      question: "How can my recipient transact if his wallet is unfunded?",
      answer: "MyWeb3Gift will pay for the gas fees of your recipient.",
    },

    {
      question: "What’s the difference between our products?",
      answer:
        "All products have 10 doors and the same content. Only the arrangements and layouts differ. The doors of the Advent Calendar are meant to be opened in the 10 days leading up to Christmas. The Surprise Gift Boxes are meant to opened on or after the day of a special occasion. The recipient is then asked to open the doors subsequently. The 10-day Challenge Calendar is ideal for anyone who would like to experience our interactive and educational journey without a special occasion.",
    },

    {
      question: "Can I personalize my gift?",
      answer:
        "Yes, you can personalize your gift by adding a heartfelt message and even a personal picture. We offer options to tailor the gift to match the recipients interests and the occasion.",
    },

    {
      question: "What happens if I choose the email delivery option?",
      answer:
        "If you choose email delivery, the gift code will be sent directly to the recipients inbox, making it easy to surprise them with a unique crypto gift.",
    },

    {
      question: "Is MyWeb3Gift suitable for beginners?",
      answer:
        "Absolutely! MyWeb3Gift is designed for individuals of all knowledge levels. Our platform is an excellent starting point for beginners looking to learn about crypto and blockchain.",
    },

    {
      question: "Are there opportunities for partnerships and collaborations?",
      answer:
        "Yes, we welcome partnerships and collaborations with crypto-related organizations, educators, NFT artists, and more. Reach out to us to explore how your content can be featured in our gifts.",
    },

    {
      question:
        "What happens after my recipient has completed the Crypto Christmas Calendar?",
      answer:
        "Users have acquired the necessary knowledge and tools can continue to explore and deepen their understanding of cryptocurrencies and blockchain technology. They can always come back and walk through the doors again, ask us questions and/or participate in our communities or the communities of our cooperation partners to dive deeper.",
    },

    {
      question: "How can I contact MyWeb3Gift for further assistance?",
      answer:
        "You can reach out to us through our contact page or email us at [info@myweb3gift.com]. Our team is here to assist you with any questions or concerns.",
    },
    {
      question: "What blockchain are your gift boxes built on?",
      answer:
        "Our current gift boxes are built on Ethereum Layer 2 solutions, as they combine the security of Ethereum with transaction speed and cheap transactions costs. However, we might into further chains in the future.",
    },
    {
      question: "Will MyWeb3Gift create an own token?",
      answer:
        "We might at one point, but there are no plans yet. If we do, early adopters will play a special role.",
    },

    // Add more faqs as needed
  ];

  return (
    <div>
      {/* <h1>Frequently Asked Questions</h1> */}
      <Faqs data={accordionData} />
    </div>
  );
};

export default FaqSection;
