import React from 'react'
import Nav01 from "../../Components/Nav01";
import { Footer } from "../../sections_landing";
import DoorComplete from "../DoorComplete";
import Door12comp from '../DoorComponents/Doorcomponent12/Door12comp';
import styles from "../DoorComponents/style/door12.module.css";
import { LocomotiveScrollProvider } from "react-locomotive-scroll";
import 'locomotive-scroll/dist/locomotive-scroll.css'

import { useRef } from "react";
import { AnimatePresence } from 'framer-motion'
import Bonus from './Bonus';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import Verify from './Verify';
const ADoor6 = () => {
  const ref = useRef(null);
  const params = useParams();
  const paramsid = params.id
  const options = {
    smooth: true,
  } 
  return (
    <>
     <div className=''><Verify params={paramsid}/></div>
       <div>
<Nav01></Nav01>
{/* <div className="z-10 absolute bg-sky-950 h-[90px] w-screen"></div> */}
</div>
    <LocomotiveScrollProvider options={options} containerRef={ref} >
      <AnimatePresence>

          <main data-scroll-container ref={ref} className='select-none'>

    {/* <div style={{backgroundColor:"#3f6212",overflow:"hidden"}} className='pt-20'>
    <div>
    <div className={styles.container4}>
          <Door6comp/>
        </div>
        <div className="flex flex-row justify-center">
          <DoorComplete Door={6}/>
        </div>

        <Footer></Footer>
      </div>
    </div> */}
    <div style={{backgroundColor:"#00352c",overflow:"hidden"}} className='pt-20'>
   
   <div>
   <div className={styles.container11}>
       <Door12comp/>
       </div>
       <div className="flex gap-4 flex-row justify-center mb-3">
       {/* <Link to="/ADoor18"><Bonus/></Link>
       <Link to="/ADoor12">
                <button
          className='bg-gradient-to-r from-sky-700 to-sky-500 text-3xl font-bold text-white py-4 px-7 rounded-3xl my-7 hover:bg-gradient-to-r hover:from-sky-500 hover:to-sky-300 transition duration-300'   
        >
         Get Bonus II
        </button>
        </Link> */}
         <DoorComplete Door={6} id={paramsid}/>
       </div>
       <Footer></Footer>
     </div>
   </div>
    </main>
    </AnimatePresence>
    </LocomotiveScrollProvider>
    </>
  )
}

export default ADoor6