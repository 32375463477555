import React from "react";
import styles from "../style/door14.module.css";
import Accordion from "./Acordition";
import { motion } from "framer-motion";

import {
  Mask10,
  Mask11,
  Mask12,
  Mask13,
  Mask14,
  Mask15,
  Mask16,
  Mask17,
  Mask9,
} from "../../DoorsImages/Doorimg14";

export default function SDoor() {
  return (
    <div className={styles.section2}>
      <motion.div
        initial={{ y: "-15vh", opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        transition={{
          duration: 1,
        }}
        viewport={{ once: true }}
        className={styles.nftboxheading2}
      >
        <h1 className="text-white text-center font-bold text-3xl tracking-wide">
          Advantages are :
        </h1>
      </motion.div>
      <div className={styles.imgboxcard}>
        <div className={styles.imgboxcard1}>
          <motion.div
            initial={{ x: "-15vw", opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{
              duration: 1,
            }}
            viewport={{ once: true }}
            className={styles.cards}
          >
            <div className={styles.imuu}>
              <img className={styles.padlock} src={Mask9} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Transparency"
                content="The entire process is visible and auditable on the blockchain, reducing the risk of fraud or manipulation."
              ></Accordion>
            </div>
          </motion.div>
          <motion.div
            initial={{ x: "15vw", opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{
              duration: 1,
            }}
            viewport={{ once: true }}
            className={styles.cards}
          >
            <div className={styles.imuu}>
              <img className={styles.baht} alt="img1" src={Mask10} />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Immutable Records"
                content="Once recorded on the blockchain, the data is tamper-proof and cannot be altered, ensuring the integrity of the raffle results."
              ></Accordion>
            </div>
          </motion.div>
        </div>
      </div>
      <div className={styles.imgboxcard}>
        <div className={styles.imgboxcard2}>
          <motion.div
            initial={{ x: "-15vw", opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{
              duration: 1,
            }}
            viewport={{ once: true }}
            className={styles.cards}
          >
            <div className={styles.imuu}>
              <img className={styles.mask1} src={Mask11} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Trustless Execution"
                content=" Participants do not need to trust a centralized entity to conduct the raffle fairly. The rules are enforced by the smart contract."
              ></Accordion>
            </div>
          </motion.div>
        </div>
      </div>
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{
          duration: 1,
        }}
        viewport={{ once: true }}
        className={styles.nftboxheading2}
      >
        <h1 className="text-white text-center font-bold text-3xl tracking-wide">
          Disadvantages are:
        </h1>
      </motion.div>
      <div className={styles.imgboxcard}>
        <div className={styles.imgboxcard1}>
          <motion.div
            initial={{ x: "-15vw", opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{
              duration: 1,
            }}
            viewport={{ once: true }}
            className={styles.cards}
          >
            <div className={styles.imuu}>
              <img className={styles.mask2} src={Mask12} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Gas Fees"
                bold1="High Transaction Costs:"
                content="On many blockchain networks, especially during periods of high demand, transaction fees (gas fees) can be significant. Participants may find that the cost of purchasing raffle tickets and participating in on-chain transactions outweighs the potential rewards.
"
              ></Accordion>
            </div>
          </motion.div>
          <motion.div
            initial={{ x: "15vw", opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{
              duration: 1,
            }}
            viewport={{ once: true }}
            className={styles.cards}
          >
            <div className={styles.imuu}>
              <img className={styles.curency} src={Mask13} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Scalability"
                bold1="Network Congestion:"
                content=" During periods of high demand or congestion on the blockchain network, the processing of transactions can be delayed. This can lead to a less-than-optimal user experience, especially if participants have to wait for an extended period to see the results.
"
              ></Accordion>
            </div>
          </motion.div>
        </div>
      </div>
      <div className={styles.imgboxcard}>
        <div className={styles.imgboxcard1}>
          <motion.div
            initial={{ x: "-15vw", opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{
              duration: 1,
            }}
            viewport={{ once: true }}
            className={styles.cards}
          >
            <div className={styles.imuu}>
              <img className={styles.mask1} src={Mask14} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="User Experience "
                content="Interacting with blockchain technology, especially for those who are not familiar with it, can be complex. Setting up a wallet, managing private keys, and executing transactions on the blockchain may be intimidating or confusing for some users.
"
              ></Accordion>
            </div>
          </motion.div>
          <motion.div
            initial={{ x: "15vw", opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{
              duration: 1,
            }}
            viewport={{ once: true }}
            className={styles.cards}
          >
            <div className={styles.imuu}>
              <img className={styles.mask1} src={Mask15} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Smart Contract Risks"
                content="Smart contracts are subject to vulnerabilities, and if there are bugs or flaws in the code, it could lead to security issues or unexpected behavior. This is particularly relevant in the context of financial transactions and prize distribution."
              ></Accordion>
            </div>
          </motion.div>
        </div>
      </div>
      <div className={styles.imgboxcard}>
        <div className={styles.imgboxcard1}>
          <motion.div
            initial={{ x: "-15vw", opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{
              duration: 1,
            }}
            viewport={{ once: true }}
            className={styles.cards}
          >
            <div className={styles.imuu}>
              <img className={styles.mask1} src={Mask15} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Regulatory Compliance"
                bold1="Legal Challenges: "
                content="Depending on the jurisdiction, on-chain raffles may face legal and regulatory challenges. Compliance with local laws and regulations regarding gambling, lotteries, and financial transactions is essential but may vary globally.
"
              ></Accordion>
            </div>
          </motion.div>
          <motion.div
            initial={{ x: "15vw", opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{
              duration: 1,
            }}
            viewport={{ once: true }}
            className={styles.cards}
          >
            <div className={styles.imuu}>
              <img className={styles.mask1} src={Mask15} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Lack of Centralized Oversight"
                content="While decentralization is a key feature of blockchain technology, it also means that there is no central authority to oversee or intervene in the event of disputes or fraudulent activities. Participants must rely on the integrity of the smart contract code.
"
              ></Accordion>
            </div>
          </motion.div>
        </div>
      </div>
      <div className={styles.imgboxcard}>
        <div className={styles.imgboxcard1}>
          <motion.div
            initial={{ x: "-15vw", opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{
              duration: 1,
            }}
            viewport={{ once: true }}
            className={styles.cards}
          >
            <div className={styles.imuu}>
              <img className={styles.mask1} src={Mask15} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Market Volatility"
                bold1="Cryptocurrency Price Fluctuations:  "
                content="The value of the cryptocurrency used for ticket purchases and prize distribution can be highly volatile. Participants may experience gains or losses based on market fluctuations.
"
              ></Accordion>
            </div>
          </motion.div>
          <motion.div
            initial={{ x: "15vw", opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{
              duration: 1,
            }}
            viewport={{ once: true }}
            className={styles.cards}
          >
            <div className={styles.imuu}>
              <img className={styles.mask1} src={Mask15} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Education and Adoption"
                bold1="Limited User Adoption: "
                content=" Blockchain technology is still relatively new, and widespread adoption is an ongoing process. The on-chain raffle concept may not be easily accessible or attractive to users who are not familiar with blockchain.
"
              ></Accordion>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
}
