import React from 'react'
import Nav01 from '../Components/Nav01'
import { Footer, IconsDetails } from '../sections_landing'
import { motion, AnimatePresence } from "framer-motion";
import { LocomotiveScrollProvider } from "react-locomotive-scroll";
import 'locomotive-scroll/dist/locomotive-scroll.css'
import { useRef } from "react";
import Products from './Products';
const Gift = () => {
  const ref = useRef(null);

  const options = {
    smooth: true,
  } 
  return (
    <>
        <div>
 <Nav01></Nav01>
 <div className="z-10 absolute bg-sky-950 h-[90px] w-screen"></div>
</div>
    
    <LocomotiveScrollProvider options={options} containerRef={ref}>
    <AnimatePresence>

        <main data-scroll-container ref={ref}>
    <div>
<IconsDetails/>

<Footer></Footer>
    </div>
    </main>
    </AnimatePresence>
    </LocomotiveScrollProvider>
    </>
  )
}

export default Gift