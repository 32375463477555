import React, { useEffect, useState } from 'react';
import Nav01 from '../Components/Nav01';
import { EmailModal, Footer } from '../sections_landing';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from "@clerk/clerk-react";
import { useUser } from "@clerk/clerk-react";
import { SignIn } from "@clerk/clerk-react";
import { useApproval } from '../states/ApprovalContext';
import LoadingSpinner from '../sections_landing/Loader';
import GiftTingBanner from './GiftTingBanner';
import { ButterFly } from '../assets/Videos';
import WaitListScreen from './WaitListScreen';
import { LocomotiveScrollProvider } from "react-locomotive-scroll";
import 'locomotive-scroll/dist/locomotive-scroll.css'
import { useRef } from "react";
import { AnimatePresence } from 'framer-motion';


import Modal from '../Components/Modal';
import { Thumbnail } from '../assets/Images';
import { getDatabase, ref, set } from 'firebase/database';
import toast from 'react-hot-toast';
const ClaimGift = () => {
  
  const[name,setName]=useState()
  const { isLoaded  } = useAuth();
  const {  isSignedIn, user } = useUser();
  const { isApproved, setApproval } = useApproval();
  // //console.log('hello',user)

  // Move the useState calls outside of the component function

  const [uniqueCode, setUniqueCode] = useState('');
  const [approve, setApprove] = useState(false);

  const navigate = useNavigate()
  
  const ref = useRef(null);

  const options = {
    smooth: true,
  } 
  useEffect(() => {
    const fetchUserData = async () => {
      if (isSignedIn) {
        try {

          const response = await fetch(`https://myweb3gift-bd98e-default-rtdb.firebaseio.com/users/${user.id}.json`);
          //console.log(response)
          // const response = await fetch(`https://myweb3gift-bd98e-default-rtdb.firebaseio.com/submitemails/-NkJIn1tg7RhVEh-7-IF.json`);
          
          //console.log(response)
          if (response.ok) {
            const data = await response.json();
            //console.log('Fetch URL:', `https://myweb3gift-bd98e-default-rtdb.firebaseio.com/submitemails/${user.id}.json`);
          //console.log('data',data)
         //console.log(user.id)
    
         if (data && typeof data.approve !== 'undefined') {
          setApproval(data.approve);
        }
            //console.log(isApproved)
          } else {
            console.error('Error fetching data:', response.statusText);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
    };
  
    fetchUserData();
  }, [isSignedIn, user]);
  
  //console.log("Outside useEffect:", isApproved);

  if (!isLoaded) {
    return <div><LoadingSpinner/></div>;
  }


  if (!isSignedIn) {
    return (
      <div className="flex h-screen items-center justify-center">
        <SignIn />
      </div>
    );
  }
 

 

  // ...
  
  // const postData = async (e) => {
  //   e.preventDefault();
  
  //   try {
  //     // Ensure the user is authenticated with Clerk
  //     if (!isSignedIn) {
  //       console.error("User not authenticated with Clerk.");
  //       return;
  //     }
  
  //     // Store additional details in Firebase Realtime Database
  //     const db = getDatabase();
  //     const userRef = ref(db, `users/${user.id}`);
  
  //    // Set 'approve' based on the unique code
  // setApprove(uniqueCode === 'your-unique-code'); // Change 'your-unique-code' to the desired code

  // const userData = {
  //   email: user.primaryEmailAddress.emailAddress,
  //   name: name,
  //   approve: approve,
  //   // Add other form fields as needed
  // };
  
  //     await set(userRef, userData);
  
  //     // Reset form fields after successful registration
  //     //   setName("");
  //     //   setEmail("");
  
  //     //console.log("User details stored in Firebase successfully!");
  
  //     // Show success toast message
  //     toast.success('We have recevied your request!,your are added to waitlist ');
  //   } catch (error) {
  //     console.error("Error storing user details:", error.message);
  
  //     // Show error toast message
  //     toast.error(`Please enter your name`);
   
  //   }
  //   navigate('/')
  // };
  
  // ...
  

  const postData = async (e) => {
    e.preventDefault();
  
    try {
      // Ensure the user is authenticated with Clerk
      if (!isSignedIn) {
        console.error("User not authenticated with Clerk.");
        return;
      }
  
      // Validate name input
      if (!name.trim()) {
        // Show toast for missing name
        toast.error('Please enter your name.');
        return;
      }
  
      // Store additional details in Firebase Realtime Database
      const db = getDatabase();
      const userRef = ref(db, `users/${user.id}`);
  
      // Set 'approve' based on the unique code
      const userApproveStatus = uniqueCode === 'BaseTester35'; // Change 'your-unique-code' to the desired code
  
      const userData = {
        email: user.primaryEmailAddress.emailAddress,
        name: name,
        approve: userApproveStatus,
        // Add other form fields as needed
      };
  
      await set(userRef, userData);
  
      //console.log("User details stored in Firebase successfully!");
  
      // Show approval message if the unique code is correct
      if (userApproveStatus) {
        toast.success('You are approved! You can now send Crypto Gifts to your friends and family.');
      } else if (uniqueCode.trim() !== '') {
        // Show invalid code message if the unique code is incorrect
        toast.error('You have entered the wrong code. Please input the correct unique code.');
      } else {
        // Show message if no unique code is provided
        toast.success('We have received your request! You are added to the waitlist.');
      }
    } catch (error) {
      console.error("Error storing user details:", error.message);
  
      // Show error toast message
      toast.error('An error occurred. Please try again.');
    }
    navigate('/');
  };
  




  
  // if(isApproved===undefined){
  //   // navigate ('/')
  //   openModal()
  // }

  return (
    <>
      <div>
 <Nav01></Nav01>
 {/* <div className="z-10 absolute bg-sky-950 h-[90px] w-full"></div> */}
</div>
    
    <LocomotiveScrollProvider options={options} containerRef={ref}>
    <AnimatePresence>

        <main data-scroll-container ref={ref}>

    <div className='tracking-wide'>

     
  
          <>
          <GiftTingBanner UserName={user.firstName} />
          <div className='relative h-[60vh] bg-gray-100'>
            {/* GIF Overlay */}
            <img
              src={ButterFly}
              alt='GIF Overlay'
              className='max-sm:hidden inset-0 z-30 object-contain w-full h-full '
            />
    
            <div className='absolute inset-0 flex flex-col justify-center items-center'>
              {/* Your content */}
              <div className='flex md:flex-row flex-col gap-6 items-center md:gap-44'>
                {/* Buttons or other elements */}
                <Link to='/MyGifts'>
                <button className='bg-gradient-to-r from-yellow-300 to-yellow-500 hover:from-yellow-400 hover:to-yellow-600 text-white font-bold text-2xl px-8 py-3 rounded-full shadow-2xl transition duration-300'>
  EXPLORE YOUR GIFTS
</button>

                </Link>
    
                <Link to='/gift'>
                <button className='bg-gradient-to-r from-yellow-300 to-yellow-500 hover:from-yellow-400 hover:to-yellow-600 text-white font-bold shadow-2xl text-2xl px-8 py-3 rounded-full transition duration-300'>
  SEND A NEW GIFT
</button>
                </Link>
    
                <Link to='/TransferedGifts'>
                <button className='bg-gradient-to-r from-blue-300 to-blue-500 hover:from-blue-400 hover:to-blue-600 text-white font-bold text-2xl px-8 py-3 rounded-full shadow-2xl transition duration-300'>
  VIEW SENT GIFTS
</button>
                </Link>
              </div>
            </div>
          </div>
        </>
     
        <>
        
    <WaitListScreen  UserName={user.firstName} />
        
        </>
    

      <Footer></Footer>
    </div>
    </main>
    </AnimatePresence>
    </LocomotiveScrollProvider>
    </>
  );
};

export default ClaimGift;
