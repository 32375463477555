import React from "react";
// import { useAuth } from "@clerk/clerk-react";
import { useUser } from "@clerk/clerk-react";
import { UserButton } from "@clerk/clerk-react";

const Login = () => {
  return (
    <div>
      <a
        href="/loginpage"
        class="group text-xl hover:bg-yellow-400 relative flex w-full items-center justify-center gap-x-1.5 rounded-md px-3 py-2 font-semibold text-white transition-all duration-100 ease-in group-hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-900 focus:ring-indigo-500 disabled:opacity-50"
      >
        LOGIN
      </a>
    </div>
  );
};

const UserButtons = () => {
  return (
    <div>
      <UserButton afterSignOutUrl="/" />
    </div>
  );
};

const LoginButton = () => {
  const { isLoaded, isSignedIn, userId } = useUser();
  // const { isLoaded, userId, sessionId, getToken } = useAuth();

  if (!isLoaded || !userId) {
    //   return null;
    // console.log("Not login");
  }

  const renderComponent = () => {
    if (isSignedIn) {
      return <UserButtons />;
    } else {
      return <Login />;
    }
  };

  return renderComponent();
};

export default LoginButton;
