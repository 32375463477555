import React from "react";
import styles from "../style/door4.module.css";
import { motion } from "framer-motion";
import { Web3, Web32 } from "../../DoorsImages/Doorimg4";

export default function FDoor() {
  return (
    <>
      <motion.div
        className={styles.section1}
        initial="hidden"
        animate="visible"
        //  variants={fadeInVariants}
        transition={{ duration: 1 }}
      >
        <div className={styles.walletboxheading}>
          <motion.h1
            layout
            initial={{
              x: "-15vw",opacity:0,
            }}
            whileInView={{ x: 0, opacity:1 }}
            transition={{
             duration:0.6
            }}
            viewport={{once:true}}
          className="text-white text-3xl font-bold pt-5 ">
              On-ramp/off-ramp
            </motion.h1>
            <motion.p
            
            initial={{
              x: "-15vw",opacity:0,
            }}
            whileInView={{ x: 0, opacity:1 }}
            transition={{
             duration:0.6
            }}
             viewport={{once:true}}
            
            className="text-white mt-6 text-lg tracking-widest">
              In order to interact in the web3 space you need to receive
              cryptocurrencies. This can be done by earning cryptocurrencies for
              example by staking or mining. However most people do not want to
              set up mining gear or have enough funds to stake.
              <br />
              <br />
              Therefore you need to be able to convert USD, EUR, CHF or any
              other (fiat) currency into cryptocurrencies. The terms "on-ramp"
              and "off-ramp" refer to the processes of entering (on-ramp) and
              exiting (off-ramp) the cryptocurrency ecosystem, typically
              involving the conversion of traditional fiat currency to
              cryptocurrency and vice versa.
            </motion.p>
        </div>
        <motion.div
        className={styles.walletimgbox}
          initial={{ x: "15vw",opacity:0 }}
          whileInView={{ x: 0,opacity:1}}
          whileHover={{
            scale: 0.9,
            transition: { type: "spring", duration: 1 },
          }}
          exit={{
            x: "-60vw",
            scale: [1, 0],
            transition: { duration: 1 },
          }}
          transition={{duration:0.6}}
          viewport={{once:true}}
        >
          <img className={styles.web3img} src={Web3} alt="img" />
          <div className={styles.imgdd}>
          <img className={styles.webimg2} src={Web32} alt="img" />
          </div>

        </motion.div>
        
      </motion.div>
    </>
  );
}
