import React from "react";
import { useLayoutEffect, useRef } from "react";
import styles from "../style/card.module.css";
import {
  Mobile,
  Hardwarewallet,
  Brainwallet,
  Paperwallet,
  Walletmanage,
} from "../../DoorsImages/Doorimg1";
import Accordion from "./Accordion/Accordition";
import { motion,AnimatePresence } from "framer-motion";

export default function Card2() {
  return (
    <>
      <div className={styles.card2}>
    <AnimatePresence>

        <motion.div
          initial={{ y: "-100%", opacity: 0 }}
          whileInView={{ y: "0", opacity: 1 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
          className={styles.walletboxheading2}
        >
          <h1 className="text-white text-3xl font-bold">
            There are several options to store the private&#47; public keys:
          </h1>
        </motion.div>
        <div className={styles.imgboxcard}>
          <div className={styles.imgboxcard1}>
            <motion.div
              initial={{ x: "-15vw", opacity: 0 }}
              whileInView={{ x: "0", opacity: 1 }}
              transition={{ duration: 1 }}
              viewport={{ once: true }}
              className={styles.cards}
            >
              <div className={styles.imuu}>
                <img className={styles.Mobile} src={Mobile} alt="img1" />
              </div>
              <div className={styles.box}>
                <Accordion
                  title="Software Wallet"
                  content="Desktop or mobile wallets are installed on a computer, laptop, smartphone or tablet
Online Wallets: Web-based wallets that you can access through a browser.
Both types are connected to the internet and can therefore be exploited, so that the keys can be stollen or phished"
                ></Accordion>
              </div>
            </motion.div>
            <motion.div
              initial={{ y: "-15vw", opacity: 0 }}
              viewport={{ once: true }}
              whileInView={{ y: "0", opacity: 1 }}
              transition={{ duration: 1 }}
              className={styles.cards}
            >
              <div className={styles.imuu}>
                <img
                  className={styles.hardware}
                  src={Hardwarewallet}
                  alt="img1"
                />
              </div>
              <div className={styles.box}>
                <Accordion
                  title="Hardware Wallets"
                  content="Physical devices that store cryptocurrency offline. They are considered more secure than software wallets because they are less susceptible to hacking.
Their main security feature is that every transaction needs to be confirmed by clicking on the physical device. Thus, a hacker cannot “just” hack your computer and transfer your funds out of your wallet, he will have to steal your physical device too.  
                  "
                ></Accordion>
              </div>
            </motion.div>
            <motion.div
              initial={{ x: "15vw", opacity: 0 }}
              whileInView={{ x: "0", opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6 }}
              className={styles.cards}
            >
              <div className={styles.imuu}>
                <img className={styles.paper} src={Paperwallet} alt="img1" />
              </div>
              <div className={styles.box}>
                <Accordion
                  title="Paper Wallets"
                  content="
              A physical document containing your cryptocurrency address and private key, often in the form of QR codes."
                ></Accordion>
              </div>
            </motion.div>
          </div>
        </div>
        <div className={styles.imgboxcard}>
          <div className={styles.imgboxcard2}>
            <motion.div
              initial={{ x: "-15vw", opacity: 0 }}
              whileInView={{ x: "0", opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6 }}
              className={styles.cards}
            >
              <div className={styles.imuu}>
                <img className={styles.brain} src={Brainwallet} alt="img1" />
              </div>
              <div className={styles.box}>
                <Accordion
                  title="Brain Wallet"
                  content="A type of wallet where the private key is memorized or derived from a passphrase. While this method is theoretically secure, it can be risky if the passphrase is not complex enough."
                ></Accordion>
              </div>
            </motion.div>
            <motion.div
              initial={{ x: "15vw", opacity: 0 }}
              viewport={{ once: true }}
              whileInView={{ x: "0", opacity: 1 }}
              transition={{ duration: 0.6 }}
              className={styles.cards}
            >
              <div className={styles.imuu}>
                <img className={styles.manage} src={Walletmanage} alt="img1" />
              </div>
              <div className={styles.box}>
                <Accordion
                  title="Wallet managed by someone else"
                  content="Most people are not comfortable being responsible for their own wallets because if the private key is lost, access to most digital assets becomes impossible. Therefore, they appoint others to take over this duty. This can be risky, as seen in the case of FTX, where customers' funds were used by the wallet holder."
                ></Accordion>
              </div>
            </motion.div>
          </div>
        </div>
    </AnimatePresence>

      </div>
    </>
  );
}
