import React from 'react'
import styles from "../style/door12.module.css";
import Card2 from './Card2';

export default function S2Door() {
  return (
    <div className={styles.section22}>
    <div className="">
     <div className={styles.oracleboxheading}>
        <h1 className="text-white text-4xl text-center font-medium">
        Advantage  
        </h1>
        <h1 className="italic text-white text-3xl mt-2 text-center font-light">
          
         of Oracles
        </h1>
      </div>
      <div className={styles.cardboxsss}>
      <div className='flex justify-between items-center '>
<Card2 cheading="Complex Smart Contracts:" text1="Oracles enable smart contracts to be used in a wide range of applications by incorporating real-world data."/>
<Card2 cheading="Cross-Chain Functionality" text1="Oracles enable smart contracts to be used in a wide range of applications by incorporating real-world data." />
</div>
<Card2 cheading="Versatility" text1="Oracles enable smart contracts to be used in a wide range of applications by incorporating real-world data."/>
      </div>
      </div>
      <hr/>
      <div>
      <div className={styles.oracleboxheading}>
        <h1 className="text-white text-4xl text-center font-medium">
        Disadvantage  
        </h1>
        <h1 className="italic text-white text-3xl mt-2 text-center font-light">
          
         of Oracles
        </h1>
      </div>
      <div className={styles.cardboxsss1}>
<Card2 cheading="Dependency on External Systems" text1="Smart contracts relying on oracles are dependent on external systems for accurate and timely data, introducing potential bottlenecks and vulnerabilities."/>
      <div className='flex justify-between items-center '>
<Card2 cheading="Centralization" text1="Depending on the oracle implementation, there may be a risk of centralization if the oracle is controlled by a single entity, leading to a single point of failure or manipulation. "/>
<Card2 cheading="Security Risks " text1="Oracles can be vulnerable to attacks or manipulation. If the data they provide is compromised, it can lead to incorrect execution of smart contracts." />
</div>
      </div>
      </div> 
    </div>
  )
}
