import React from "react";
import { Link } from "react-router-dom";
import Nav01 from "../../Components/Nav01";
import { Footer } from "../../sections_landing";
// import { useUser } from "@clerk/clerk-react";
import DoorComplete from "../DoorComplete";
// import Door2comp from "../DoorComponents/Doorcomponent2/Door2comp";
import { LocomotiveScrollProvider } from "react-locomotive-scroll";
import "locomotive-scroll/dist/locomotive-scroll.css";

import { useRef } from "react";
import { AnimatePresence } from "framer-motion";
import Bonus from "./Bonus";
import { useParams } from "react-router-dom";
import Verify from "./Verify";
// import styles from "../DoorComponents/style/door2.module.css";

const ADoor2 = () => {
  const ref = useRef(null);
  const params = useParams();
  const paramsid = params.id;
  const options = {
    smooth: true,
  };
  return (
    <>
     <div className=''><Verify params={paramsid}/></div>
      <div>
        <Nav01></Nav01>
      </div>

      {/* <LocomotiveScrollProvider options={options} containerRef={ref}>
        <AnimatePresence>
          <main data-scroll-container ref={ref}> */}
      <div
        style={{ backgroundColor: "#0a0a0a", overflow: "" }}
        className="pt-20 select-none"
      >
       
     <div className="h-screen w-full pt-20 scroll-smooth will-change-scroll">
        <iframe
          title="CatAttackNFT"
          src="https://catattacknft-main.vercel.app/"
          frameBorder="0"
          style={{ width: "100%", height: "100vh" }}
          className="select-none"
        ></iframe>
        <div className="w-[100%] bg-[#0a0a0a] justify-center mx-auto py-4 px-6 gap-4 min-h-1/5 flex flex-col items-center ">
          <p className="text-white text-xl tracking-widest">
            If you are interested in learning more. Take a look at the link
            below.
          </p>
          <a href="/ADoor13" target="_blank">
            <Bonus text="NFT-market places" />
          </a>
        </div>
        <div className="flex bg-[#0a0a0a] flex-row justify-center pb-3">
          <DoorComplete Door={2} id={paramsid} />
        </div>

        <Footer></Footer>
        </div>
      </div>
      
      {/* </main>
        </AnimatePresence>
      </LocomotiveScrollProvider> */}
    </>
  );
};

export default ADoor2;
