import React from 'react'
import styles from "../style/door7.module.css";
import { motion}  from 'framer-motion';
export default function Card1({title,para,img}) {
  return (
    <div className={styles.cards}>
       <motion.div initial={{ x: "-15vw",opacity:0}}
            whileInView={{ x: 0, opacity:1 }}
            transition={{duration:0.6}}
             viewport={{once:true}} className={styles.text}>
<h1 className='text-3xl font-bold mb-5'>{title}</h1>
<p className='text-lg font-normal tracking-wider'>{para}</p>

        </motion.div>
        <motion.div initial={{ x: "15vw",opacity:0}}
            whileInView={{ x: 0, opacity:1 }}
            transition={{duration:0.6}}
             viewport={{once:true}} className={styles.cardimgs}>
<img src={img} className={styles.immmggg} alt="img"/>
        </motion.div>
    </div>
  )
}
