import React from "react";
import styles from "../style/door3.module.css";
import { motion } from "framer-motion";
import Accordion from "./Acordition";
import { Borrowing, Lending, LiquidityPro, Mining, Staking, YieldFarming } from "../../DoorsImages/Doorimg17";

export default function SDoor() {
  return (
    <>
      <div className={styles.section2}>
      <motion.div
          initial={{
            x: "-15vw",opacity:0
          }}
          whileInView={{ x: 0,opacity:1}}
          transition={{
           duration:0.6
          }}
          viewport={{once:true}}
         className={styles.nftboxheading2}>
          <h1 className="text-white text-3xl font-bold">
          Here are explanations for key terms:
          </h1>
          <p className="text-white mt-3 text-lg">
            {/* An NFT marketplace is an online platform that facilitates the
            buying, selling, and trading of NFTs. As explainded yesterday, NFTs
            are unique digital tokens that represent ownership or proof of
            authenticity of a specific digital or physical asset, often
            associated with digital art, music, videos, virtual real estate, and
            other digital or tangible items. */}
          </p>
        </motion.div>
        <div className={styles.imgboxcard}>
          <div className={styles.imgboxcard1}>
          <motion.div
          initial={{
            x: "-15vw",opacity:0
          }}
          whileInView={{ x: 0,opacity:1}}
          transition={{
           duration:0.6
          }}
          viewport={{once:true}}
             className={styles.cards}>
              <div className={styles.imuu}>
                <img className={styles.nft2} src={Lending} alt="img1" />
              </div>
              <div className={styles.box}>
                <Accordion
                  title="Lending"
content="Lending in DeFi refers to the practice of individuals or entities providing funds to a decentralized platform or protocol, which in turn lends these funds to other users in exchange for interest.
➡️How it Works: Users can deposit their cryptocurrencies into a lending platform's smart contract, and these funds become available for others to borrow. Borrowers pay interest on the borrowed amount, which is distributed among the lenders. The lending process is facilitated by smart contracts on the blockchain, eliminating the need for traditional intermediaries like banks."
                ></Accordion>
              </div>
            </motion.div>
            <motion.div
          initial={{
            x: "-15vw",opacity:0
          }}
          whileInView={{ x: 0,opacity:1}}
          transition={{
           duration:0.6
          }}
          viewport={{once:true}}
            className={styles.cards}>
              <div className={styles.imuu}>
                <img className={styles.nft2} src={Borrowing} alt="img1" />
              </div>
              <div className={styles.box}>
                <Accordion
                  title="Borrowing"
content="Borrowing in DeFi allows users to obtain loans by leveraging their cryptocurrency assets as collateral. Users can borrow various cryptocurrencies or stablecoins based on the value of the assets they lock in as collateral.
➡️ How it Works:Collateralization: Users lock up a certain amount of cryptocurrency assets (e.g., Ethereum or other tokens) into a smart contract as collateral. The value of the collateral determines the borrowing capacity.
➡️ Smart Contracts: Smart contracts on the blockchain automatically execute the borrowing process. The smart contract ensures that the collateral is sufficient, and it establishes the loan terms, such as interest rates and loan duration.
➡️ Repaying the Loan: Borrowers are required to repay the borrowed amount along with accrued interest within a specified period. If they fail to do so, the smart contract may liquidate a portion of their collateral to cover the outstanding debt.
➡️ Interest Rates: Interest rates in DeFi are often dynamic and may be determined by various factors, including the supply and demand for loans within the protocol. Some platforms use algorithms or decentralized governance to set interest rates.
                  "
                ></Accordion>
              </div>
            </motion.div>
            <motion.div
          initial={{
            x: "-15vw",opacity:0
          }}
          whileInView={{ x: 0,opacity:1}}
          transition={{
           duration:0.6
          }}
          viewport={{once:true}}
             className={styles.cards}>
              <div className={styles.imuu}>
                <img
                  className={styles.smartContract}
                  src={LiquidityPro}
                  alt="img1"
                />
              </div>
              <div className={styles.box}>
                <Accordion
                  title="Liquidity Provisioning"
                  content="Liquidity Provisioning refers to the act of supplying funds to a liquidity pool on a decentralized exchange or lending platform to facilitate trading or lending activities.
➡️ How it Works: Users deposit pairs of tokens into a liquidity pool. These token pairs are then used to enable decentralized trading on platforms like decentralized exchanges (DEXs). In return for providing liquidity, users receive rewards, often a share of the trading fees paid by users who trade against the pool. This process helps maintain sufficient liquidity in decentralized markets and allows for efficient trading without relying on traditional order books."
                ></Accordion>
              </div>
            </motion.div>
            <motion.div
          initial={{
            x: "-15vw",opacity:0
          }}
          whileInView={{ x: 0,opacity:1}}
          transition={{
           duration:0.6
          }}
          viewport={{once:true}}
             className={styles.cards}>
              <div className={styles.imuu}>
                <img
                  className={styles.blockchain2}
                  src={YieldFarming}
                  alt="img1"
                />
              </div>
              <div className={styles.box}>
                <Accordion
                  title="Yield Farming"
                  content="Liquidity Provisioning refers to the act of supplying funds to a liquidity pool on a decentralized exchange or lending platform to facilitate trading or lending activities.
➡️ How it Works: Users deposit pairs of tokens into a liquidity pool. These token pairs are then used to enable decentralized trading on platforms like decentralized exchanges (DEXs). In return for providing liquidity, users receive rewards, often a share of the trading fees paid by users who trade against the pool. This process helps maintain sufficient liquidity in decentralized markets and allows for efficient trading without relying on traditional order books."
                ></Accordion>
              </div>
            </motion.div>
            <motion.div
          initial={{
            x: "-15vw",opacity:0
          }}
          whileInView={{ x: 0,opacity:1}}
          transition={{
           duration:0.6
          }}
          viewport={{once:true}}
             className={styles.cards}>
              <div className={styles.imuu}>
                <img className={styles.wallet2} src={Staking} alt="img1" />
              </div>
              <div className={styles.box}>
                <Accordion
                  title="Staking"
                  content=" Staking involves locking up a certain amount of cryptocurrency in a wallet to support the operations of a blockchain network. In return, participants (stakers) may receive rewards, typically in the form of additional cryptocurrency.
➡️ How it Works: Users stake their tokens by holding them in a designated wallet or smart contract. This helps secure the network and validate transactions. The more tokens a user stakes, the higher the chances of being chosen to create a new block or validate transactions. Stakers are often rewarded with additional tokens for their participation in the network's consensus mechanism."
                ></Accordion>
              </div>
            </motion.div>
            <motion.div
          initial={{
            x: "-15vw",opacity:0
          }}
          whileInView={{ x: 0,opacity:1}}
          transition={{
           duration:0.6
          }}
          viewport={{once:true}}
             className={styles.cards}>
              <div className={styles.imuu}>
                <img
                  className={styles.certificate}
                  src={Mining}
                  alt="img1"
                />
              </div>
              <div className={styles.box}>
                <Accordion
                  title="Mining"
                  content=" Mining is the process by which new cryptocurrency tokens are created and transactions are added to a blockchain. In proof-of-work (PoW) blockchain networks, miners use computational power to solve complex mathematical problems and validate transactions, earning rewards for their efforts.
➡️ How it Works: Miners compete to solve mathematical puzzles, and the first one to solve it gets the right to add a new block to the blockchain. This process requires significant computational power and energy. Miners are rewarded with newly created cryptocurrency (block rewards) and transaction fees. It's important to note that some blockchains, like Bitcoin, use mining, while others, like Ethereum, are transitioning to alternative consensus mechanisms like proof-of-stake (PoS)."
                ></Accordion>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </>
  );
}
