import React from 'react'
import LoginPage from "../../../../Components/LoginPage"
export default function SignIn() {
  return (
    <div>
      <>
        <LoginPage/>
      </>
    </div>
  )
}
