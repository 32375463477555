import React from "react";
import styles from "../style/door13.module.css";
import Cardd1 from "./Cardd1";
import { RightArrow } from "../Icons/RightArrow";
import { motion } from "framer-motion";

export default function SDoor() {
  return (
    <div className={styles.section2}>
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 3 }}
        viewport={{ once: true }}
        className={styles.oracleboxheading}
      >
        <h1 className="italic text-white tracking-widest text-2xl mt-2 text-center font-light">
          Here are key aspects of crypto gaming
        </h1>
        <h1 className="text-white mt-4 text-3xl tracking-wider text-center font-medium">
          Ownership of <br /> In-Game Assets
        </h1>
      </motion.div>
      <div className={styles.caardbox}>
        <div className={styles.iconbox}>
          <RightArrow />
        </div>

        <Cardd1 />

        <div className={styles.iconbox2}>
          <RightArrow />
        </div>
      </div>
    </div>
  );
}
