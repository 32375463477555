import React from "react";
import { motion } from "framer-motion";
import styles from "../style/door14.module.css";

export default function T2Door() {
  return (
    <>
      <motion.div
        className={styles.section1aa}
        initial="hidden"
        animate="visible"
        transition={{ duration: 1 }}
      >
        <motion.div
          initial={{ x: "-15vw", opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{
            duration: 1,
          }}
          viewport={{ once: true }}
          className={styles.walletboxheading19}
        >
          <h1 className="text-white text-4xl font-bold pt-5 ">Multi-Chain</h1>
          <p className="text-white mt-6 text-xl tracking-widest">
            Multi-chain refers to the use of multiple blockchain networks within
            a decentralized ecosystem. Instead of relying on a single
            blockchain, projects may leverage several blockchains for various
            purposes.
            <br />
            <br />
            <b>Importance:</b> Multi-chain approaches can enhance scalability,
            reduce transaction costs, and provide flexibility for developers and
            users. Each blockchain may have its strengths and weaknesses, and
            using multiple chains allows projects to capitalize on the benefits
            of each."
          </p>
        </motion.div>
        {/* <motion.div 
        initial={{x: "-15vw",opacity:0}}
        whileInView={{ x: 0,opacity:1}}
        transition={{duration:1}}
        viewport={{once:true}}
        className={styles.walletimgbox}
          
        >
          <img src={Introperability} alt="img" />   
        </motion.div> */}
      </motion.div>
    </>
  );
}
