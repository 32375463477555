import React from 'react';
import { useEffect, useState } from 'react';
import { GiftGif } from '../assets/Videos';

const LoadingSpinner = () => {


  const [showPreloader, setShowPreloader] = useState(true);

  useEffect(() => {
    const initializationTimeout = setTimeout(() => {
      // Update state to hide the preloader after one second
      setShowPreloader(false);
    }, 1000); // Set the duration to one second (1000 milliseconds)
  
    // Clean up the timeout to avoid memory leaks
    return () => clearTimeout(initializationTimeout);
  }, []);


  return (
    <div className="flex justify-center items-center h-full">
      {/* <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500"></div>*/}
      {showPreloader && (
      <div id="preloader">
        <img src={GiftGif} alt="Loading..." />
      </div>
    )}
    </div>
  );
};

export default LoadingSpinner;
