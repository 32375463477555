import React from 'react'
import styles from "../style/door8.module.css";
import Accordion from './Acordition';
import { Sec2img1, Sec2img2, Sec2img3 } from '../../DoorsImages/Doorimg8';
import { motion } from 'framer-motion';

export default function SDoor() {
  const fadeInVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };
  return (
    <>
    <motion.div
        className={styles.section2}
         variants={fadeInVariants}
        transition={{ duration: 3 }}
      >
       <motion.div
       initial={{y:"-100%",opacity:0}}
       whileInView={{y:0,opacity:1}}
       transition={{duration:1}}
       viewport={{once:true}}

        className={styles.nftboxheading2}>
        <h1 className="text-white text-center font-medium text-3xl tracking-wide">
        Here's a brief overview of the key characteristics and differences between Web1, Web2, and Web3:
        </h1>
      </motion.div>
      <div className={styles.accordionboxcard}>
      <div className={styles.imgboxcard1}>
          <motion.div
           initial={{x:"-15vw",opacity:0}}
       whileInView={{x:0,opacity:1}}
       transition={{duration:1.5}}
       viewport={{once:true}}
           className={styles.cards}>
           
            <div className={styles.box}>
              <Accordion
                title="Web1 (The Static Web): read only" content="
Timeframe: The early days of the internet, from its inception in the late 1980s to the early 2000s.
Characteristics: This was a primarily static web, where content was largely read-only. Websites were basic, with limited interactivity. 
Usage: Users consumed information but had limited means to interact or contribute content."
              ></Accordion>
            </div>
            <div className={styles.imuu}>
              <img className={styles.padlock} src={Sec2img1} alt="img1" />
            </div>
          </motion.div>
          <motion.div
           initial={{x:"-15vw",opacity:0}}
       whileInView={{x:0,opacity:1}}
       transition={{duration:1.5}}
       viewport={{once:true}}

           className={styles.cards}>
           
           <div className={styles.box}>
             <Accordion
               title="Web2 (The Social Web): read write"
content="
Timeframe: Emerged in the mid-2000s and is still the dominant web paradigm today. 
Characteristics: This is the era of dynamic and interactive content. Social media platforms, collaborative content creation, and user-generated content are hallmarks of Web2. 
Usage: Users actively participate in creating and sharing content on platforms like Facebook, Twitter, Instagram, and more."
             ></Accordion>
           </div>
           <div className={styles.imuu}>
             <img className={styles.padlock} src={Sec2img2} alt="img1" />
           </div>
         </motion.div>
         <motion.div
           initial={{x:"-15vw",opacity:0}}
       whileInView={{x:0,opacity:1}}
       transition={{duration:1.5}}
       viewport={{once:true}}

           className={styles.cards}>
           <div className={styles.box}>
             <Accordion
               title="Web3 (The Decentralized Web):
read write own"
content="
Timeframe: Still in the early stages of development as of my last knowledge update in January 2022, gaining more attention in recent years. 

Characteristics- 
Decentralization: One of the key principles of Web3 is decentralization, aiming to reduce reliance on centralized authorities, such as tech companies or governments. 
Blockchain Technology: Often associated with blockchain and distributed ledger technologies. Blockchain provides a transparent and secure way of recording and verifying transactions without the need for a central authority. 
Smart Contracts: Self-executing contracts with the terms of the agreement between buyer and seller directly written into code. These are a fundamental aspect of many Web3 applications. 
Tokenization: The use of digital tokens on blockchain networks to represent ownership of assets or access to services. 

Usage: Web3 envisions a more user-centric internet, where individuals have more control over their data, identity, and digital assets. It aims to enable trustless and censorship-resistant applications."
   ></Accordion>
           </div>
           <div className={styles.imuu}>
             <img className={styles.padlock} src={Sec2img3} alt="img1" />
           </div>
         </motion.div>
      </div>
    </div>
    <motion.div
           initial={{x:"-15vw",opacity:0}}
       whileInView={{x:0,opacity:1}}
       transition={{duration:1.5}}
       viewport={{once:true}}
     className={styles.nftboxheading21}>
       
       <p className="text-white text-lg tracking-widest whitespace-pre-line">
       In summary, while Web1 was static and informational, and Web2 is characterized by social interaction and user-generated content,
Web3 is defined by decentralization, blockchain technology, smart contracts, and a vision of a more user-centric and trustless internet.
It's important to note that the transition to Web3 is still
<br/>ongoing,and the technology and concepts associated with it continue to evolve.
       </p>
      </motion.div>
    </motion.div>
    </>
  )
}
