import React from 'react'
import styles from "../style/door11.module.css";
import { motion } from 'framer-motion';
export default function TDoor() {
  return (
    <div className={styles.section3}>
    <motion.h1 initial={{ x: "-15vw",opacity:0}}
            whileInView={{ x: 0, opacity:1 }}
            transition={{duration:0.6}}
             viewport={{once:true}} className='text-3xl font-bold text-left'>Links to Explore more </motion.h1>
    <div className={styles.doo11sec3box}>
    <div>
    <motion.p initial={{ x: "-15vw",opacity:0}}
            whileInView={{ x: 0, opacity:1 }}
            transition={{duration:0.6}}
             viewport={{once:true}} className='text-xl font-medium '>Use below links to know about Stablecoins</motion.p>
       <motion.button initial={{ x: "-100%",opacity:0}}
            whileInView={{ x: 0, opacity:1 }}
            transition={{duration:0.6}}
             viewport={{once:true}} className={styles.btn}>
             <a  href='https://coincodex.com/cryptocurrencies/sector/stablecoins/'target="_blank" rel="noopener noreferrer">
             Click now</a>
             </motion.button>
      <motion.p initial={{ x: "-15vw",opacity:0}}
            whileInView={{ x: 0, opacity:1 }}
            transition={{duration:0.6}}
             viewport={{once:true}} className='text-xl font-medium '>Click below for more resources </motion.p>
       <motion.button initial={{ x: "-100%",opacity:0}}
            whileInView={{ x: 0, opacity:1 }}
            transition={{duration:0.6}}
             viewport={{once:true}} className={styles.btn}>
             <a  href='https://blockchainsimplified.com/blog/what-are-stablecoins-in-the-world-of-cryptocurrency/'target="_blank" rel="noopener noreferrer">
             Learn more</a>
             </motion.button>
      </div>
    <div>
    <motion.p initial={{ x: "15vw",opacity:0}}
            whileInView={{ x: 0, opacity:1 }}
            transition={{duration:0.6}}
             viewport={{once:true}} className='text-xl font-medium '>Know more about types</motion.p>
      <button className={styles.btn}>
      <a  href='https://101blockchains.com/types-of-stablecoins/'target="_blank" rel="noopener noreferrer">
      Find more</a></button>
      <motion.p initial={{ x: "15vw",opacity:0}}
            whileInView={{ x: 0, opacity:1 }}
            transition={{duration:0.6}}
             viewport={{once:true}} className='text-xl font-medium '>Some resources you will found anywhere</motion.p>
       <motion.button initial={{ x: "15vw",opacity:0}}
            whileInView={{ x: 0, opacity:1 }}
            transition={{duration:0.6}}
             viewport={{once:true}} className={styles.btn}>
             <a  href='https://chain.link/education-hub/stablecoins'target="_blank" rel="noopener noreferrer">
             Click now </a>
             </motion.button>
      </div>
    </div>
    </div>
  )
}
