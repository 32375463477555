import React from "react";
import Nav01 from "../Components/Nav01";
import { Footer } from "../sections_landing";
import { Bert_staf, Valerio_Jacobi, Arpit_Singh } from "../assets/Images/Team";
import { LocomotiveScrollProvider } from "react-locomotive-scroll";
import 'locomotive-scroll/dist/locomotive-scroll.css';
import { useRef } from "react";
import { AnimatePresence,motion } from 'framer-motion';

const Team = () => {
  const ref = useRef(null);

  const options = {
    smooth: true,
  } 
  return (


<>
<div>
<Nav01/>

<div className="z-10 absolute bg-sky-950 h-[90px] w-screen"></div>
</div>
<LocomotiveScrollProvider options={options} containerRef={ref}>
      <AnimatePresence>
          <main data-scroll-container ref={ref}>
          <motion.section initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}  
              transition={{duration:2}}    
                       viewport={{once:true}} className="pt-20 flex max-sm:px-5 sm:px-40 justify-center pb-10 lg:pt-[120px] lg:pb-20">


      <div className="container max-lg:pt-10">
        <div className="flex flex-wrap -mx-4">
          <div className="w-full px-4">
            <div className="mx-auto mb-[60px] max-w-lg text-center">
              <span className="block mb-2 text-lg font-semibold text-primary">
                {/* Our Team */}
              </span>
              <h2 className="mb-4 text-3xl font-bold text-dark sm:text-4xl md:text-[40px]">
              Meet the Minds Behind the Magic at <span className=""> MyWeb3Gift </span> 🎁

              </h2>
              <p className="text-xl text-body-color">
              {/* At MyWeb3Gift, we are more than a team; we are a collective of visionaries united by the mission to redefine gifting. */}
              
               Our passion for blockchain and crypto education drives us to create unique experiences that blend learning with joy. Each member of our leadership team brings diverse expertise, ensuring MyWeb3Gift is not just a platform but a journey into the future of thoughtful, innovative gifting.
              </p>
            </div>


          </div>

          
        </div>
        <div className="flex flex-wrap gap-4 justify-center -mx-4">
          <TeamCard
            name="🌟 Valerio Jacobi"
            profession="The Visionary Maestro"
            profession2="Founder"
            imageSrc={Valerio_Jacobi}
          />
          <TeamCard
            name="👨🏻‍💻 Arpit Singh"
            profession="Tech Whisperer-in-Chief"
              profession2="CTO"
            imageSrc={Arpit_Singh}
          />
          <TeamCard
            name="🌐 Bert Staufenbiel"
            profession="Mastermind of Operations"
              profession2="COO"
            imageSrc={Bert_staf}
          />
          {/* <TeamCard
            name="Coriss Ambady"
            profession="Web Developer"
            imageSrc="https://i.ibb.co/yVVT0Dp/image-02-2.jpg"
          /> */}
        </div>
      </div>
    </motion.section>
    <Footer/>
    </main>
    </AnimatePresence>
    </LocomotiveScrollProvider>

   
</>







  );
};

export default Team;

const TeamCard = ({ imageSrc, name, profession, profession2  }) => {
  return (
    <>
      <div className="w-full px-4 mx-4   md:w-1/2 xl:w-1/4">
        <div className="mx-auto  mb-10 w-full max-w-[370px]">
          <div className="relative overflow-hidden min-w-[320px]  min-h-[370px] rounded-lg">
            <img src={imageSrc} alt=" Arpit Singh Blockchain Developer" className="" loading="lazy"/>
            <div className="absolute left-0 w-full text-center bottom-1">
              <div className="relative px-3 py-5 mx-5 overflow-hidden bg-white rounded-lg">
                <h3 className="text-base font-semibold text-dark">{name}</h3>
                <p className="text-sm  text-body-color">{profession}</p>
                <p className="text-sm hidden text-body-color">{profession2}</p>
                <a href="#"><img src="" alt="" /></a>
                <div>
                  <span className="absolute bottom-0 left-0">
                    <svg
                      width={61}
                      height={30}
                      viewBox="0 0 61 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx={16}
                        cy={45}
                        r={45}
                        fill="#13C296"
                        fillOpacity="0.11"
                      />
                    </svg>
                  </span>
                  <span className="absolute top-0 right-0">
                    <svg
                      width={20}
                      height={25}
                      viewBox="0 0 20 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="0.706257"
                        cy="24.3533"
                        r="0.646687"
                        transform="rotate(-90 0.706257 24.3533)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="6.39669"
                        cy="24.3533"
                        r="0.646687"
                        transform="rotate(-90 6.39669 24.3533)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="12.0881"
                        cy="24.3533"
                        r="0.646687"
                        transform="rotate(-90 12.0881 24.3533)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="17.7785"
                        cy="24.3533"
                        r="0.646687"
                        transform="rotate(-90 17.7785 24.3533)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="0.706257"
                        cy="18.6624"
                        r="0.646687"
                        transform="rotate(-90 0.706257 18.6624)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="6.39669"
                        cy="18.6624"
                        r="0.646687"
                        transform="rotate(-90 6.39669 18.6624)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="12.0881"
                        cy="18.6624"
                        r="0.646687"
                        transform="rotate(-90 12.0881 18.6624)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="17.7785"
                        cy="18.6624"
                        r="0.646687"
                        transform="rotate(-90 17.7785 18.6624)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="0.706257"
                        cy="12.9717"
                        r="0.646687"
                        transform="rotate(-90 0.706257 12.9717)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="6.39669"
                        cy="12.9717"
                        r="0.646687"
                        transform="rotate(-90 6.39669 12.9717)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="12.0881"
                        cy="12.9717"
                        r="0.646687"
                        transform="rotate(-90 12.0881 12.9717)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="17.7785"
                        cy="12.9717"
                        r="0.646687"
                        transform="rotate(-90 17.7785 12.9717)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="0.706257"
                        cy="7.28077"
                        r="0.646687"
                        transform="rotate(-90 0.706257 7.28077)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="6.39669"
                        cy="7.28077"
                        r="0.646687"
                        transform="rotate(-90 6.39669 7.28077)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="12.0881"
                        cy="7.28077"
                        r="0.646687"
                        transform="rotate(-90 12.0881 7.28077)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="17.7785"
                        cy="7.28077"
                        r="0.646687"
                        transform="rotate(-90 17.7785 7.28077)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="0.706257"
                        cy="1.58989"
                        r="0.646687"
                        transform="rotate(-90 0.706257 1.58989)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="6.39669"
                        cy="1.58989"
                        r="0.646687"
                        transform="rotate(-90 6.39669 1.58989)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="12.0881"
                        cy="1.58989"
                        r="0.646687"
                        transform="rotate(-90 12.0881 1.58989)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="17.7785"
                        cy="1.58989"
                        r="0.646687"
                        transform="rotate(-90 17.7785 1.58989)"
                        fill="#3056D3"
                      />
                    </svg>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
