import React from 'react'
import styles from "../style/door8.module.css";
import { Sec3img } from '../../DoorsImages/Doorimg8';
import { motion } from 'framer-motion';
export default function TDoor() {
  return (
   <motion.div
   initial={{opacity:0}}
   animate={{opacity:1}}
   transition={{duration:1}}
    className={styles.section3}>
       <motion.p
           initial={{x:"-15vw",opacity:0}}
       whileInView={{x:0,opacity:1}}
       transition={{duration:1}} className='text-lg tracking-wider text-white'>
      To know more interesting facts and Difference use the below link.
            </motion.p>
            <motion.button
           initial={{x:"-15vw",opacity:0}}
       whileInView={{x:0,opacity:1}}
       transition={{duration:1}}
             className={styles.btn}>
            <a target="_blank" className='font-bold underline' href='https://www.creative-tim.com/blog/educational-tech/web-1-0-vs-web-2-0-vs-web-3-0-what-are-the-differences/
'>Click to explore</a>
            </motion.button>
            <motion.img
           initial={{x:"-15vw",opacity:0}}
       whileInView={{x:0,opacity:1}}
       transition={{duration:1}}
             src={Sec3img} className={styles.door8sec3img} alt="img"/>
    </motion.div>
  )
}
