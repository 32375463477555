import React from 'react';
import styles from "../style/door13.module.css";
import { Cardano, doorbg1, doorbg2 } from '../../DoorsImages/Doorimg13';
import { motion } from 'framer-motion';
export default function FDoor(){
  return (
    <div className={styles.section1}>
<img src={Cardano} className={styles.img1} alt="bg"/>
<div className={styles.box1}>
<img src={doorbg1}/>
</div>
<div className={styles.overlay}></div>
<motion.div initial={{ opacity:0}}
            whileInView={{ opacity:1 }}
            transition={{duration:3}}
             viewport={{once:true}} className={styles.boxheading}>
<h1 className='text-5xl font-bold text-center text-white'>Crypto Gaming</h1>
<p className="text-2xl text-center text-white text-medium">So far we have look at NFTs, web3, blockchain and oracles. Now we have a look at how NFTs can be used in the web3 space. One example is crypto gaming.</p>
{/* <br/> */}
<p className="text-2xl text-center text-white whitespace-pre-line text-medium">Crypto gaming refers to the intersection of cryptocurrency and 
online gaming, where blockchain technology is integrated into
gaming ecosystems. In the context of crypto gaming, blockchain is utilized to introduce unique features and benefits to the
gaming experience.</p>
</motion.div>
    </div>
  )
}
