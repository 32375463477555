import React from 'react'
import styles from "../style/door8.module.css";
import { Sec1img1, Sec1img2 } from '../../DoorsImages/Doorimg8';
import { motion } from 'framer-motion';
export default function FDoor() {
  const fadeInVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };
  
  return (
    <>
      <motion.div
        className={styles.section1}
        initial="hidden"
        animate="visible"
         variants={fadeInVariants}
        transition={{ duration: 3 }}
      >
    <div className={styles.door8sec1box}>
    <img className={styles.door8img1} src={Sec1img1} alt="img1" />
    <p className='text-lg text-center'>
    Yesterday, we learned about the key factors of blockchain, but what <br/> enables the technology to accomplish previously impossible
<br/> things? The utilization of blockchain technology makes it
<br/>possible to create business models that were previously <br/> unattainable. Let's delve into the reasons behind this.
    </p>
    <img className={styles.door8img2} src={Sec1img2} alt="img1" />

    </div>
    </motion.div>
    </>
  )
}
