import { useState } from "react";
import { motion } from "framer-motion";
// import { IoIosArrowDown } from "react-icons/io";
import { FaAngleRight } from "react-icons/fa";

import { NavLink, useLocation } from "react-router-dom";

const SubMenu = ({ data }) => {
  const { pathname } = useLocation();
  const [subMenuOpen, setSubMenuOpen] = useState(false);

  return (
    <>
               

      <li style={{listStyle:"none",display:"flex" }}
        className={`link ${pathname.includes(data.name) && "text-blue-600"}`}
        onClick={() => setSubMenuOpen(!subMenuOpen)}
      >
        {/* <data.icon size={23} className="min-w-max" /> */}
        <p className="flex-1 capitalize">{data.name}</p>
        {/* <FaAngleRight
          className={` ${subMenuOpen && "rotate-180"} duration-200 `}
        /> */}
        <svg
                        //   className="fill-stroke text-gray-100 "
          className={` ${subMenuOpen && "rotate-180"} duration-200 fill-stroke text-gray-100 `}
                          width={12}
                          height={12}
                          viewBox="0 0 12 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4.5 3L7.5 6L4.5 9"
                            stroke="currentColor"
                            strokeWidth="0.75"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
      </li>
      <motion.ul
        animate={
          subMenuOpen
            ? {
                height: "fit-content",
              }
            : {
                height: 0,
              }
        }
        className="flex h-0 flex-col text-base text-sm text-gray-100  overflow-hidden"
      >
        {data.menus?.map((menu) => (
          <li key={menu} className="mt-2">
            {/* className="hover:text-blue-600 hover:font-medium" */}
            <NavLink
              to={`/${data.name}/${menu}`}
            // to="/mygifts"
              className="link !bg-transparent capitalize"
            >
              {menu}
            </NavLink>
          </li>
        ))}
      </motion.ul>
    </>
  );
};

export default SubMenu;