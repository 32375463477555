// ReadMore.js
import React, { useState } from "react";
import styles from "../style/door7.module.css";
import {RightArrow} from "../Icons/RightArrow";
import {DownArrow} from "../Icons/DownArrow";

const Readmore = ({ text, maxLength,heading }) => {
  const [isTruncated, setIsTruncated] = useState(true);

  const toggleTruncate = () => {
    setIsTruncated(!isTruncated);
  };

  const truncatedText = isTruncated ? text.slice(0, maxLength) : text;

  return (
    <div className={styles.ccc} >
      <div className="flex justify-between items-start" onClick={() => setIsTruncated(!isTruncated)}>
<h1 className='text-3xl font-bold mb-5 '>{heading}</h1>
      <div>
      {isTruncated ? (
            <span className={styles.indicator}>
              <RightArrow />
            </span>
          ) : (
            <span className={styles.indicator}>
              <DownArrow />
            </span>
          )}
      </div>
      </div>
      <p className="whitespace-pre-line antialiased mt-3 text-lg tracking-widest">
        {truncatedText}{'\n'}
     
        {text.length > maxLength && (
          <button
            className="text-white hover:underline focus:outline-none"
            onClick={toggleTruncate}
          >
            {isTruncated ? "More" : "Less"}
          </button>
        )}
      </p>
    </div>
  );
};

export default Readmore;
