import React from "react";
import styles from "../style/door14.module.css";
import { motion } from "framer-motion";
import FDoor from "./FDoor";
import SDoor from "./SDoor";
import TDoor from "./TDoor";
import S2Door from "./S2Door";
import S4Door from "./S4Door";
import T2Door from "./T2Door";
import TDoor1 from "./TDoor1";

export default function Door22comp() {
  const fadeInVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };
  const container1 = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.5,
      },
    },
  };

  return (
    <>
      <motion.div
        initial="hidden"
        animate="visible"
        variants={{ fadeInVariants, container1 }}
        transition={{ duration: 1 }}
        className={styles.container14}
      >
        <FDoor />
        <SDoor />
        <T2Door/>
        <TDoor1/>
        <S2Door/>
        <S4Door/>
        {/* <TDoor/> */}
      </motion.div>
    </>
  );
}
