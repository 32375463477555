import Chart from "react-apexcharts";

const PieChart = (props) => {
  const { series, options } = props;

  // Customize colors for pie chart slices
  const pieColors = ["#5f3aff", "#ca8a04", "#666", "#A9A9A9"];

  // Customize colors for progress bar
  // const progressBarColor = "#082f49";

  // Update options to include customized colors
  const updatedOptions = {
    ...options,
    colors: pieColors, // Assign customized colors to pie chart slices
    plotOptions: {
      pie: {
        colors: pieColors,
        offsetY: 0,
        startAngle: 0,
        endAngle: 360,
        donut: {
          size: "70%", // You can customize the size of the donut if needed
        },
      },
    },
    fill: {
      colors: pieColors, // Assign customized color to progress bar
    },
  };

  return (
    <Chart
      options={updatedOptions}
      type="pie"
      width="100%"
      height="100%"
      series={series}
    />
  );
};

export default PieChart;
