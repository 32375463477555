import React from "react";
import styles from "../style/door14.module.css";
import { motion } from "framer-motion";
import Card from "./Card";

export default function Spart1() {
  return (
    <div>
      <motion.div className={styles.sec2part}>
      <motion.div
          initial={{ opacity: 0 }}
          whileInView={{  opacity: 1 }}
          transition={{
            duration: 3,
          }}
          viewport={{ once: true }}
         className={styles.nfth11}>
          <h1 className="text-white font-medium text-center text-3xl tracking-wide">
            Disadvantages of Cross-Chain Bridges
          </h1>
        </motion.div>
        <div className={styles.doorcardss}>
          <div className={styles.door11cards}>
            <Card
              title="Security Risks"
              text=" The security of cross-chain bridges is critical, as vulnerabilities in the bridge's design or implementation could lead to asset losses."
            />
            <Card
              title="Centralization Concerns"
              text=" Some cross-chain solutions may rely on centralized elements, introducing potential points of failure or central control.
"
            />
            <Card
              title="Compatibility Issues "
              text=" Ensuring compatibility between different blockchains and maintaining bridge protocols may present challenges."
            />
          </div>
          {/* <div className={styles.door11cards2}>
     <Card title="Risk Mitigation " text=" Cross-chain bridges can provide risk mitigation by allowing users to move assets to more secure or suitable blockchains in response to changing conditions."  
     />
    </div> */}
        </div>
      </motion.div>
    </div>
  );
}
