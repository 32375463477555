import React from 'react';
import { BeeFly } from '../assets/Videos';

const WaitListScreen = (props) => {
  return (
    <div className='flex flex-wrap justify-center h-screen bg-gray-100'>
   

      <div className='flex-grow flex  max-w-lg lg:pl-2 flex-col justify-center items-center p-8'>
        <div className='font-bold lg:text-4xl text-xl mb-4'>
        {props.UserName}, you are still on the waitlist. Please stay tuned, soon you will be able to send crypto gifts. 🎉
        </div>
        <div className='lg:py-44'>
          Thank you for your patience {props.UserName}.
        </div>
      </div>


   <div className='flex-none'>
        <img
          src={BeeFly}
          alt='GIF Overlay'
          className='object-contain max-sm:w-[350px] max-sm:h-[350px]  w-[700px] h-[700px]'
        />
      </div>





    </div>





  );
};

export default WaitListScreen;
