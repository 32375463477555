import React from "react";
import styles from "../style/door12.module.css";
import { motion } from "framer-motion";
export default function TDoor() {
  return (
    <div className={styles.section3}>
      <motion.div
        initial={{ x: "-15vw", opacity: 0 }}
        whileInView={{ x: 0, opacity: 1 }}
        transition={{ duration: 1 }}
        viewport={{ once: true }}
        className={styles.doo11sec3box}
      >
        <p className="text-white text-lg font-medium ">
          Chainlink (LINK): Chainlink is one of the most widely recognized
          oracle networks. It facilitates smart contracts to securely interact
          with real-world data, APIs, and payment systems.{" "}
        </p>
        <button className={styles.btn}>
          <a
           target='_blank'
            href="https://chain.link/education/blockchain-oracles"
          >
            Click to explore{" "}
          </a>
        </button>
        <p className="text-white text-lg font-medium ">
          Band Protocol (BAND): Band Protocol is a cross-chain data oracle
          platform that aggregates and connects real-world data to smart
          contracts.{" "}
        </p>
        <button className={styles.btn}>
          <a target='_blank' href="https://www.bandprotocol.com/">
            Click to explore
          </a>
        </button>

        <p className="text-white text-lg font-medium ">
          DIA (Decentralized Information Asset): DIA focuses on providing
          open-source financial information using decentralized oracles. It aims
          to bridge traditional finance with the DeFi space.
        </p>
        <button className={styles.btn}>
          <a target='_blank' href="https://www.diadata.org/">
            Click to explore
          </a>
        </button>
        <p className="text-white text-lg font-medium ">
          Tellor (TRB): Tellor is a decentralized oracle network that focuses on
          providing high-value data to smart contracts on the Ethereum
          blockchain.
        </p>
        <button className={styles.btn}>
          <a target='_blank' href="https://tellor.io/">
            Click to explore
          </a>
        </button>
        <p className="text-white text-lg font-medium ">
          Augur (REP): Augur is a decentralized prediction market platform that
          serves as a kind of oracle for events and outcomes. Users can create
          and participate in prediction markets to generate real-world data.
        </p>
        <button className={styles.btn}>
          <a target='_blank' href="https://augur.net/blog/v2-resolution/">
            Click to explore{" "}
          </a>
        </button>
        <p className="text-white text-lg font-medium ">
          Provable (formerly Oraclize): Provable is a blockchain oracle service
          that provides a way for smart contracts to interact with off-chain
          data securely.
        </p>
        <button className={styles.btn}>
          <a target='_blank' href="https://app.provable.xyz/home">
            Click to explore{" "}
          </a>
        </button>
        <p className="text-white text-lg font-medium ">
          Witnet (WIT): Witnet is a decentralized oracle network that aims to
          connect smart contracts with reliable, accurate, and verifiable
          real-world data.
        </p>
        <button className={styles.btn}>
          <a target='_blank' href="https://witnet.io/">
            Click to explore{" "}
          </a>
        </button>
        <p className="text-white text-lg font-medium ">
        Click below for DAO
        </p>
        <button className={styles.btn}>
          <a href="/ADoor18" target="_blank" rel="noopener noreferrer">
            Learn more{" "}
          </a>
        </button>
        <p className="text-white text-lg font-medium ">
        Click below for Oracles
        </p>
        <button className={styles.btn}>
          <a target='_blank' href="/ADoor12">
          Learn more{" "}
          </a>
        </button>
      </motion.div>
    </div>
  );
}
