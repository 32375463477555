import React from 'react'
import styles from "../style/door14.module.css";
import Spart2 from "./Spart2";
import Spart1 from "./Spart1";
import S3Door from "./S3Door";

export default function S4Door() {
  return (
    <>
    <div className={styles.section212}>
        <Spart2/>
        <Spart1/>
        <S3Door/>

    </div>
    </>
  )
}
