import React from "react";
import { motion } from "framer-motion";
import styles from "../style/door6.module.css";
import Accordion from "./Acordition";
import { Anonymous, Baht2, BitcoinLaptop, Cryptowallet, CurrencyExchange, MiningHardware, Padlock2, Rich, Token, Visualization } from "../../DoorsImages/Doorimg6";
export default function SDoor() {
  return (
    <div className={styles.section2}>
      <motion.div initial={{ x: "-15vw",opacity:0}}
            whileInView={{ x: 0, opacity:1 }}
            transition={{duration:0.6}}
             viewport={{once:true}}
       className={styles.nftboxheading2}>
        <h1 className="text-white font-bold text-3xl tracking-wide">
          Here's how a blockchain explorer works and what you can see and do
          with it:
        </h1>
      </motion.div>
      <div className={styles.imgboxcard}>
        <div className={styles.imgboxcard1}>
        <motion.div initial={{ x: "-15vw",opacity:0}}
            whileInView={{ x: 0, opacity:1 }}
            transition={{duration:0.6}}
             viewport={{once:true}} className={styles.cards}>
            <div className={styles.imuu}>
              <img className={styles.padlock} src={Padlock2} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Search and Explore
              Transactions"
                content="You can search for specific transactions by entering transaction IDs or addresses. View details of each transaction, including the sender, receiver, timestamp, and the amount of cryptocurrency transferred."
              ></Accordion>
            </div>
          </motion.div>
          <motion.div initial={{ x: "15vw",opacity:0}}
            whileInView={{ x: 0, opacity:1 }}
            transition={{duration:0.6}}
             viewport={{once:true}} className={styles.cards}>
            <div className={styles.imuu}>
              <img className={styles.padlock} alt="img1" src={Baht2} />
            </div>
            <div className={styles.box}>
              <Accordion
                title="View Blocks"
                content="Explore individual blocks in the blockchain, including details such as the block hash, timestamp, number of transactions, and the list of transactions included in the block."
              ></Accordion>
            </div>
          </motion.div>
          </div>
          </div>
          <div className={styles.imgboxcard}>
        <div className={styles.imgboxcard1}>
          <motion.div initial={{ x: "-15vw",opacity:0}}
            whileInView={{ x: 0, opacity:1 }}
            transition={{duration:0.6}}
             viewport={{once:true}}
           className={styles.cards}>
            <div className={styles.imuu}>
              <img className={styles.padlock} src={BitcoinLaptop} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Address Information"
                content="Enter a cryptocurrency address to view its transaction history and balance. Explore details of incoming and outgoing transactions associated with a specific address."
              ></Accordion>
            </div>
          </motion.div>
          <motion.div initial={{ x: "15vw",opacity:0}}
            whileInView={{ x: 0, opacity:1 }}
            transition={{duration:0.6}}
             viewport={{once:true}} className={styles.cards}>
            <div className={styles.imuu}>
              <img className={styles.cryptowallet} src={Cryptowallet} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Transaction Details"
                content="Get detailed information about each transaction, including the input and output addresses, transaction fees, and confirmation status."
              ></Accordion>
            </div>
          </motion.div>
          </div>
          </div>
          <div className={styles.imgboxcard}>
        <div className={styles.imgboxcard1}>
          <motion.div initial={{ x: "-15vw",opacity:0}}
            whileInView={{ x: 0, opacity:1 }}
            transition={{duration:0.6}}
             viewport={{once:true}} className={styles.cards}>
            <div className={styles.imuu}>
              <img className={styles.padlock} src={CurrencyExchange} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Confirmations"
                content="Track the number of confirmations for a specific transaction to determine its level of security. More confirmations generally indicate a more secure transaction."
              ></Accordion>
            </div>
          </motion.div>
          <motion.div initial={{ x: "15vw",opacity:0}}
            whileInView={{ x: 0, opacity:1 }}
            transition={{duration:0.6}}
             viewport={{once:true}} className={styles.cards}>
            <div className={styles.imuu}>
              <img className={styles.padlock} src={Anonymous} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Blockchain Statistics"
                content="Some blockchain explorers provide statistical information about the overall state of the blockchain, including the total number of transactions, current block height, and network hash rate."
              ></Accordion>
            </div>
          </motion.div>
          </div>
          </div>
          <div className={styles.imgboxcard}>
        <div className={styles.imgboxcard1}>
          <motion.div initial={{ x: "-15vw",opacity:0}}
            whileInView={{ x: 0, opacity:1 }}
            transition={{duration:0.6}}
             viewport={{once:true}} className={styles.cards}>
            <div className={styles.imuu}>
              <img className={styles.padlock} src={Token} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Token and Smart 
              Contract Information"
                content='For blockchains that support tokens or smart contracts, you can explore information related to specific tokens and smart contracts.
              Some explorers display a list of the top cryptocurrency holders, often referred to as the "rich list."'
              ></Accordion>
            </div>
          </motion.div>
          <motion.div initial={{ x: "15vw",opacity:0}}
            whileInView={{ x: 0, opacity:1 }}
            transition={{duration:0.6}}
             viewport={{once:true}} className={styles.cards}>
            <div className={styles.imuu}>
              <img className={styles.padlock} src={MiningHardware} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Network Status"
                content="Check the current status of the blockchain network, including the number of nodes, network difficulty, and other relevant metrics."
              ></Accordion>
            </div>
          </motion.div>
          </div>
          </div>
          <div className={styles.imgboxcard}>
        <div className={styles.imgboxcard1}>
          <motion.div initial={{ x: "-15vw",opacity:0}}
            whileInView={{ x: 0, opacity:1 }}
            transition={{duration:0.6}}
             viewport={{once:true}} className={styles.cards}>
            <div className={styles.imuu}>
              <img className={styles.padlock} src={Visualization} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Visualization Tools"
                content="Some explorers include visualization tools that help users understand the flow of transactions and relationships between addresses in a more graphical way."
              ></Accordion>
            </div>
          </motion.div>
          <motion.div initial={{ x: "15vw",opacity:0}}
            whileInView={{ x: 0, opacity:1 }}
            transition={{duration:0.6}}
             viewport={{once:true}} className={styles.cards}>
            <div className={styles.imuu}>
              <img className={styles.padlock} src={Rich} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Rich List"
                content='Some explorers display a list of the top cryptocurrency holders, often referred to as the "rich list."'
              ></Accordion>
            </div>
          </motion.div>
        </div>
      </div>
      <motion.div initial={{ x: "-15vw",opacity:0}}
            whileInView={{ x: 0, opacity:1 }}
            transition={{duration:0.6}}
             viewport={{once:true}} className={styles.nftboxheading21}>
       <p className="text-white text-lg tracking-widest whitespace-pre-line">
       Blockchain explorers are valuable tools for users, developers, and researchers who want to gain insights into the activities on a blockchain. They enhance transparency, accountability, and understanding of blockchain data, which is essential for the decentralized and trustless nature of blockchain technology.
 <br/>  You can also set up alarm on certain addresses and smart contracts
       </p>
      </motion.div>
    </div>
  );
}
