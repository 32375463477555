import React from "react";
import styles from "../style/door14.module.css";
import { motion } from "framer-motion";
// import gsap from "gsap";
import Accordion from "../Doorcomponent19/Acordition2";

export default function S2Door() {
  return (
    <div className={styles.section21}>
      <motion.div className={styles.nftboxheading3}>
        <motion.div
          initial={{ y: "-100%", opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.2 }}
          viewport={{ once: true }}
          className={styles.nfth11}
        >
          <h1 className="text-white font-medium text-center text-3xl tracking-wide">
            Activities and Strategies
          </h1>
        </motion.div>
        <div className={styles.accordionsection2}>
          <motion.div
            initial={{ x: "-105%", opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.2 }}
            viewport={{ once: true }}
            className={styles.accordion2}
          >
            <Accordion
              title="Smart Contracts"
              content="The raffle is facilitated through the use of smart contracts, self-executing contracts with the terms of the agreement directly written into code. Smart contracts on the blockchain handle the entire raffle process in a transparent and automated manner."
            ></Accordion>
          </motion.div>
          <motion.div
            initial={{ x: "-105%", opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.2 }}
            viewport={{ once: true }}
            className={styles.accordion2}
          >
            <Accordion
              title="Ticket Sales"
              content="Participants purchase raffle tickets using cryptocurrency. Each ticket purchase is recorded on the blockchain, ensuring transparency and immutability."
            ></Accordion>
          </motion.div>
          <motion.div
            initial={{ x: "-105%", opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.2 }}
            className={styles.accordion2}
          >
            <Accordion
              title="Random Selection"
              content="When it's time to select the winner, a random selection mechanism is implemented within the smart contract. This could involve using cryptographic functions to generate a random number that corresponds to a winning ticket."
            ></Accordion>
          </motion.div>
          <motion.div
            initial={{ x: "-105%", opacity: 0 }}
            viewport={{ once: true }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.2 }}
            className={styles.accordion2}
          >
            <Accordion
              title="Prize Distribution"
              content="The smart contract automatically disburses the prize to the winner's wallet. Since everything is on-chain, participants can independently verify the fairness of the process by checking the smart contract and blockchain records."
            ></Accordion>
          </motion.div>
        </div>
      </motion.div>
    </div>
  );
}
