// App.js
import React, { useState } from "react";
// import { BrowserRouter as Router,Routes, Route } from 'react-router-dom';
import LoginForm from "./LoginForm";
import VerificationCodeForm from "./VerificationCodeForm";
import SuccessSentCode from "./SuccessSentCode";
import ImageSection from "./ImageSection";
import Nav01 from "./Nav01";
import Authform from "../sections_landing/Authform";

function LoginPage() {
  const [loginMethod, setLoginMethod] = useState("email"); // Default to email login

  return (
    <>
      <Nav01 />
      <div className="z-10  bg-sky-950 h-[90px]  w-full"></div>
      <div className="flex bg-[#d4dde4] select-none py-7 items-center justify-center min-h-screen px-10 max-lg:px-2">
        {/* max-sm:flex-col-reverse */}
        <div className="flex rounded-xl bg-white min-h-screen max-sm:h-full overflow-hidden max-[765px]:flex-col w-full">
          <div className="lg:w-1/2 w-full p-8 flex flex-col min-h-full bg-white max-[765px]:w-full ">
            <h2 className="text-xl">Welcome!</h2>
            <h1 className="mt-3 text-3xl font-medium">SignIn into</h1>
            <h3 className="mt-1 text-lg">MyWeb3Gift</h3>
            {/* <LoginForm
              loginMethod={loginMethod}
              setLoginMethod={setLoginMethod}
            /> */}
            <Authform />
          </div>
          <div className="lg:w-1/2 min-h-screen max-lg:hidden overflow-hidden">
          <ImageSection />
          </div>
        </div>
      </div>
    </>
  );
}

export default LoginPage;

// LoginPage.js
// import React, { useState } from "react";

// const LoginPage = () => {
//   const [loginMethod, setLoginMethod] = useState("email");
//   const [phoneNumber, setPhoneNumber] = useState("");
//   const [verificationCode, setVerificationCode] = useState("");
//   const [isVerificationCodeSent, setIsVerificationCodeSent] = useState(false);

//   const handleLoginMethodChange = (method) => {
//     setLoginMethod(method);
//   };

//   const handleLogin = () => {
//     if (loginMethod === "phone" && !isVerificationCodeSent) {
//       // Send verification code logic here (e.g., API call)
//       setIsVerificationCodeSent(true);
//     } else {
//       // Perform login logic (e.g., API call)
//       console.log("Perform login with username/password or phone number");
//     }
//   };

//   const handleVerificationCode = () => {
//     // Validate verification code logic here (e.g., API call)
//     console.log("Verify verification code");
//   };

//   return (
//     <div className="flex h-screen">
//       {/* First Section */}
//       <div className="w-1/2 p-8 bg-red-50">
//       <h2 style={{fontFamily:"Gilroy"}} className="text-xl">Welcome!</h2>
//       <h1 style={{fontFamily:"Gilroy"}} className="mt-3 text-3xl font-medium">SignIn into</h1>
//       <h3 style={{fontFamily:"Gilroy"}} className="mt-1 text-lg">MyWeb3Gift</h3>
//         <div className="flex mb-4 mt-10">
//           <button
//             className={`flex-1 py-2 ${
//               loginMethod === "email" ? "bg-blue-500" : "bg-gray-300"
//             }`}
//             onClick={() => handleLoginMethodChange("email")}
//           >
//             Email
//           </button>
//           <button
//             className={`flex-1 py-2 ${
//               loginMethod === "phone" ? "bg-blue-500" : "bg-gray-300"
//             }`}
//             onClick={() => handleLoginMethodChange("phone")}
//           >
//             Phone
//           </button>
//         </div>

//         {loginMethod === "email" ? (
//           <div>
//             <input
//               type="text"
//               placeholder="Username"
//               className="w-full mb-4 p-2 border"
//             />
//             <input
//               type="password"
//               placeholder="Password"
//               className="w-full mb-4 p-2 border"
//             />
//           </div>
//         ) : (
//           <div>
//             <input
//               type="tel"
//               placeholder="Phone Number"
//               value={phoneNumber}
//               onChange={(e) => setPhoneNumber(e.target.value)}
//               className="w-full mb-4 p-2 border"
//             />
//             <button
//               onClick={isVerificationCodeSent ? handleVerificationCode : handleLogin}
//               className={`w-full p-2 bg-blue-500 text-white ${
//                 isVerificationCodeSent ? "hidden" : ""
//               }`}
//             >
//               {isVerificationCodeSent ? "Verify Verification Code" : "Login"}
//             </button>
//             {isVerificationCodeSent && (
//               <input
//                 type="text"
//                 placeholder="Verification Code"
//                 value={verificationCode}
//                 onChange={(e) => setVerificationCode(e.target.value)}
//                 className="w-full mb-4 p-2 border"
//               />
//             )}
//           </div>
//         )}

//         <div className="flex justify-between items-center">
//           <button className="text-blue-500">Login with Google</button>
//           <button className="text-blue-500">Login with Apple</button>
//         </div>

//         <p className="mt-4">
//           Don't have an account? <a href="#">Sign Up</a>
//         </p>
//       </div>

//       {/* Second Section */}
//       <div className="w-1/2 bg-cover bg-center hidden md:block bg-red-100" style={{
//         backgroundImage:"url(https://images.unsplash.com/photo-1604648717742-f93ce63fc25c?q=80&w=2069&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)"
//       }}></div>
//     </div>
//   );
// };

// export default LoginPage;
