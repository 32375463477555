import React from 'react'
import styles from "../style/door7.module.css";
import Bonus from '../../AdventDoors/Bonus';
import { Link } from 'react-router-dom';
import {motion} from "framer-motion";

export default function TDoor() {
  const fadeInVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };
  const container1 = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.5,
      },
    },
  };
  return (
    <motion.div
    className={styles.section3}
    initial="hidden"
    animate="visible"
    variants={{ fadeInVariants, container1 }}
    transition={{ duration: 1 }}
   >
 <motion.h1
          initial={{ x: "-15vw", opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.6 }}
          viewport={{ once: true }} className='text-xl font-medium text-gray-800'>If you are interested in learning more..<br/>
Take a look at the links below.
</motion.h1>
 <motion.div
          initial={{ x: "-15vw", opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.6 }}
          viewport={{ once: true }} className='flex gap-4 max-sm:flex-col py-7'>
  <a target="_blank" href="/ADoor22">
  <Bonus text="Interoperability"/>
  </a>
                <a target="_blank" href="/ADoor16">
                <Bonus text="Token Types
"/>
                </a>
                </motion.div>
    </motion.div>
  )
}
