import React, { useEffect, useState } from "react";
import { Down } from "../Icons/Down";
import { Upper } from "../Icons/Upper";
import styles from "../style/door14.module.css";
import { motion } from "framer-motion";

const Acordion2 = ({
  title,
  content,
  egtext,
  eg,
  bold,
  bold2,
  content1,
  content2,
}) => {
  const [collapse, setCollapse] = useState(false);

  return (
    <>
      <div className={styles.accordion21}>
        <div className={styles.title2} onClick={() => setCollapse(!collapse)}>
          <div className={styles.name2}>
            <h2 className="text-xl font-medium ">{title}</h2>
          </div>
          {collapse ? (
            <span className={styles.indicator}>
              <Upper />
            </span>
          ) : (
            <span className={styles.indicator}>
              <Down />
            </span>
          )}
        </div>
        {collapse && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="pt-0"
          >
            <div className={styles.reveal1}>
              <hr></hr>
              <p className="text-lg text-white">
                {content} <br /> {egtext}
                <br />
                <i>{eg}</i>
                <br />
                <b>{bold} </b>
                {content1}
                <br />
                <br />
                <b>{bold2}</b> {content2}
              </p>
            </div>
          </motion.div>
        )}
      </div>
    </>
  );
};

export default Acordion2;
