import React from "react";
import styles from "../style/door3.module.css";
import { motion } from "framer-motion";
import Accordion from "./Acordition2";
import { Salary } from "../../DoorsImages/Doorimg3";

export default function TDoor() {
  const fadeInVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };
  const container1 = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.5,
      },
    },
  };

  return (
    <motion.div
      className={styles.section3}
      initial="hidden"
      animate="visible"
      variants={{ fadeInVariants, container1 }}
      transition={{ duration: 1 }}
    >
      <motion.div className={styles.nftboxheading3}>
        <div className={styles.nfth1}>
          <motion.h1
        initial={{y: "-100%",opacity:0}}
        whileInView={{ y: 0,opacity:1}}
        transition={{
          duration:0.6
        }}
        viewport={{once:true}}
           className="text-white font-bold text-center text-3xl tracking-widest">

            How NFT Marketplaces Earn Money
          </motion.h1>
        </div>
        <div className={styles.thirdsec}>
          <div className={styles.accordionsection1}>
            <div
              className={styles.accordion2}
              >
              <Accordion
                title="Transaction Fees"
                content="NFT marketplaces typically charge fees on transactions that occur on their platform. These fees can be a percentage of the sale price or a flat fee per transaction. The fees contribute to the platform's revenue."
              ></Accordion>
            </div>
            <div
              className={styles.accordion2}
            >
              <Accordion
                title="Listing Fees"
                content="Some marketplaces charge creators a fee to list their NFTs on the platform. This can be a one-time fee or a recurring cost."
              ></Accordion>
            </div>
            <div className={styles.accordion2}
            >
              <Accordion
                title="Premium Features"
                content=" Marketplaces may offer premium features, such as enhanced visibility for listings, analytics tools, or other services for a subscription fee."
              ></Accordion>
            </div>
            <div className={styles.accordion2}
            >
              <Accordion
                title=" Collaborations and Partnerships"
                content="NFT marketplaces might collaborate with artists, brands, or other entities for exclusive drops or collections, earning money through partnerships and collaborations."
              ></Accordion>
            </div>
            <div className={styles.accordion2}
            >
              <Accordion
                title="Secondary Market Activity"
                content="
  As NFTs are often resold on the secondary market, the platform may earn additional transaction fees from these resale activities. 
  It's essential to note that the NFT space is dynamic, and business models may vary among different marketplaces. Additionally, emerging blockchains and technologies may bring further innovations to the NFT ecosystem."
              ></Accordion>
            </div>
          </div>
          <motion.div
            initial={{ x: "15vw",opacity:0}}
            whileInView={{ x: 0, opacity:1}}
            whileHover={{
              scale: 0.9,
              transition: { type: "spring", duration: 1 },
            }}
            exit={{
              x: "-60vw",
              scale: [1, 0],
              transition: { duration: 1 },
              //  backgroundColor: "rgba(255,0,0,1)",
            }}
            transition={{duration:0.6}}
            viewport={{once:true}}
            className={styles.accordionsection2}
          >
            <img src={Salary} alt="img3" />
          </motion.div>
        </div>
      </motion.div>
    </motion.div>
  );
}
