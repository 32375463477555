import React from 'react'
import styles from "../style/door11.module.css";
import Spart1 from './Spart1';
import Spart2 from './Spart2';
import Spart3 from './Spart3';

export default function SDoor() {
  return (
    <>
    <div className={styles.section2}>
    <Spart1/>
    </div>
    <div className={styles.section21}>
    <Spart2/>
    </div>
    <div className={styles.section21}>
    <Spart3/>
    </div>
    
    </>
  )
}
