import React from 'react';
import backgroundImage from "../assets/Images/close-up-smiley-woman.png"

const ImageSection = () => {
  return (
    <div>
    <div className="w-full min-h-[90vh] max-lg:hidden">
   <img src={backgroundImage} className='w-full min-h-[105vh] max-[1024px]:min-h-[126vh] '/>
   </div>

      <div className="md:hidden">
        <img
          src={backgroundImage}
          alt="img1"
          className="h-full w-full object-cover"
        />
      </div>
      {/* <div className="hidden md:flex flex-1">
        <img
          src={backgroundImage}
          alt="img1"
          className="h-full w-full bg-no-repeat object-contain"
        />
      </div> */}
      
    </div>
  );
};

export default ImageSection;
