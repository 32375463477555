import React from 'react';
import { GiftBoy, GiftHands } from '../assets/Images/MyWeb3Gift-red2';
import { Link } from 'react-router-dom';

const GiftTingBanner = (props) => {
  return (
    <div className="relative overflow-hidden h-[55vh] max-sm:h-[30vh] bg-cover bg-center" id="banner" style={{ backgroundImage: "url('https://img.freepik.com/free-photo/flat-lay-composition-wrapped-presents-with-copy-space_23-2148767640.jpg?w=2000&t=st=1701810446~exp=1701811046~hmac=b5334291f206d31163917722c51359470cc68d6373cee86f8c1f9d49dcd89e67')"}}>
    <div className="absolute inset-0 bg-black opacity-40"></div>
    <div className="container mx-auto px-4 sm:px-6 md:px-8 lg:px-10 flex flex-col md:flex-row items-center justify-between h-full relative z-10">
      <div className="text-center z-10 md:text-left text-white mb-6 md:mb-0">
        <h1 className="text-2xl md:text-4xl lg:text-4xl text-gray-100 font-bold mb-2 md:mb-6">Hello {props.UserName}! Surprise Your Loved Ones <br className='max-sm:hidden' /> With a New Kind of Gift</h1>
        <p className="text-base md:text-lg py-2 text-gray-200 md:py-4 font-semibold mb-2 md:mb-4">Choose your digital gift box now</p>
        <Link to="/gift">
          <a href="#" className="bg-gradient-to-r from-yellow-300 to-yellow-500 hover:from-yellow-400 hover:to-yellow-600 text-white text-base lg:text-lg font-semibold px-6 py-3 rounded-full transition duration-300 focus:outline-none focus:ring focus:border-yellow-600">
            EXPLORE MORE
          </a>
        </Link>
      </div>
      <div className="absolute right-0  bottom-0 hidden md:block mb-4 md:mb-0">
        <img src={GiftHands} alt="Your Image" className="w-full md:max-w-[260px] md:h-[260px] lg:max-w-[510px] lg:h-[350px] object-cover rounded-md " />
      </div>
    </div>
  </div>
  
  );
};

export default GiftTingBanner;
