import React from 'react'
import styles from "../style/door13.module.css";
import Caard2 from './Caard2';
import { CryptoVault, Digitalwallet, Exchange } from '../../DoorsImages/Doorimg13';
import { motion } from 'framer-motion';
export default function TDoor() {
  return (
    <div className={styles.section3}>
     <motion.div initial={{ y: "-100%",opacity:0}}
            whileInView={{ y: 0, opacity:1 }}
            transition={{duration:0.6}}
             viewport={{once:true}} className={styles.oracleboxheading}>
       <h1 className="italic text-white tracking-wider text-2xl mt-2 text-center font-light">
          To play crypto games, you typically need  
      </h1>
        </motion.div>
        <div className={styles.imgcardboxs}> 
<Caard2 imgss={Digitalwallet} cardheading="A Digital Wallet" textcard2="Players use digital wallets to store their cryptocurrency and NFTs. These wallets are often linked to the blockchain network on which the game operates." />

<Caard2 imgss={CryptoVault} cardheading="Cryptocurrency" textcard2="Many crypto games require players to use a specific cryptocurrency or token for in-game transactions. This might involve purchasing the cryptocurrency on a cryptocurrency exchange before using it in the game."/>
<Caard2 imgss={Exchange} cardheading="Understanding Blockchain Basics" textcard2="While not mandatory, having a basic understanding of blockchain technology can enhance the overall gaming experience. This includes understanding how transactions work, the concept of smart contracts, and the security features of blockchain."/>
        </div>
    </div>
  )
}
