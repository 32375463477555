import React from 'react'
import styles from "../style/door13.module.css";
import { CryptoVault } from '../../DoorsImages/Doorimg13';
import { motion } from 'framer-motion';
export default function Caard2({imgss,textcard2,cardheading}) {
  return (
    <>
     <motion.div initial={{ x: "-15vw",opacity:0}}
            whileInView={{ x: 0, opacity:1 }}
            transition={{duration:0.6}}
             viewport={{once:true}} className={styles.cardd2}>
    <h1 className='text-left text-2xl text-white'>{cardheading} </h1>
    <hr></hr>
    <p className='text-xl tracking-widest leading-8 text-white text-left'>
    {textcard2}
    </p>
    <div className={styles.imgbox2}>
        <img src={imgss}/>
    </div>
    
    </motion.div>
        </>

  )
}
