import React, { useEffect } from "react";
import { useFormData } from "../states/FormDataContext";

import toast from "react-hot-toast";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import ReactDOM from "react-dom";
import { IoMdClose } from "react-icons/io";
import { AnimatePresence,motion } from "framer-motion";

const ShowModel = ({ closeModal }) => {
  const { formData, setFormData } = useFormData();

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,

      [name]: type === "checkbox" ? checked : value,
    }));
  };

  useEffect(() => {
    document.body.style.overflowY = "hidden";
    return () => {
      document.body.style.overflowY = "scroll";
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      // Display an error message or take appropriate action
      toast.error("Invalid email address. Please enter a valid email.");
      return;
    }

    // console.log("Form Data before Submit:", formData);
    try {
      // Add form data to Firestore
      const db = getFirestore();
      const docRef = await addDoc(collection(db, "your_collection_name"), {
        name: formData.name,
        email: formData.email,
      });
      // console.log("Document written with ID:", docRef.id);
      // console.log("Form Data:", formData);

      toast.success("Form submitted successfully!");
      closeModal();
    } catch (error) {
      // console.error("Error adding document: ", error);
      toast.error("Error submitting the form. Please try again.");
    }
  };

  // if (isApproved === false) {
  //   return <LoadingSpinner/>;
  // }

  return ReactDOM.createPortal(
    <>
    <AnimatePresence>
      <motion.div
       initial={{ opacity: 0, y: "-100%" }}
      whileInView={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: "-100%" }}
      transition={{duration:"0.3"}}
        className="fixed top-0 left-0 right-0 bottom-0 backdrop-blur-xl bg-sky-800/30"
        onClick={closeModal}
      ></motion.div>
      <motion.div
       initial={{ opacity: 0,}}
      whileInView={{ opacity: 1 }}
      exit={{ opacity: 0, }}
      transition={{duration:"0.3"}}
       className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-100 p-8 bg-sky-200 w-full sm:w-11/12 md:w-3/4 lg:w-1/2 xl:w-1/3 2xl:w-1/4 rounded-xl shadow-md drop-shadow-[0_35px_35px_rgba(0,0,0,0.25)]">
        <button
          className="absolute top-0 right-0 text-2xl text-sky-800  px-4 py-4 "
          onClick={closeModal}
        >
        <motion.div
         initial={{ rotate: 0 }}
    animate={{ rotate:  90 }}
    transition={{ duration: 2 }}
        >
 <IoMdClose size={30} />
        </motion.div>
         
        </button>
        <div class="pt-4 px-6 pb-7 mt-4  bg-gray-100 rounded-md shadow-md">
          <h2 class="text-2xl text-black font-semibold mb-4">Fill this form</h2>
          <div class="w-full flex flex-col justify-start items-start">
            <form
              onSubmit={handleSubmit}
              className="w-full flex flex-col justify-start items-start"
            >
              <label class=" my-2 text-lg font-bold text-gray-500">
                Full Name
              </label>
              <input
                onChange={handleInputChange}
                class="w-full outline-none text-lg rounded-lg border border-solid border-gray-400 bg-slate-50 px-2 py-1"
                placeholder="Enter your name here"
                type="text"
                name="name"
              />
              <label class=" my-2 text-lg font-bold text-gray-500">
                Email Address{" "}
              </label>
              <input
                class="w-full outline-none text-lg rounded-lg border border-solid border-gray-400 bg-slate-50 px-2 py-1"
                placeholder="example123@myweb3gift.com"
                type="email"
                name="email"
                onChange={handleInputChange}
              />
              <button
                type="submit"
                className="rounded-3xl mt-3 w-full sm:w-[200px] px-9 py-2 items-start justify-start py-2 text-xl font-bold text-white  bg-sky-700 hover:bg-sky-800 focus:ring-2"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </motion.div>
      </AnimatePresence>
    </>,
    document.querySelector(".myportaldiv")
  );
};
export default ShowModel;
