import React, { useState, useEffect } from 'react';
import LoadingSpinner from '../sections_landing/Loader';
import { Link } from 'react-router-dom';
import { collection, getDocs, getFirestore } from 'firebase/firestore';
import { EmailModal } from '../sections_landing';

const Products = () => {
  const [products, setProducts] = useState([]);

  const fetchProducts = async () => {
    try {
      const db = getFirestore();

      // Reference to the Products collection
      const productsRef = collection(db, 'Products');

      // Fetch documents from the Products collection
      const querySnapshot = await getDocs(productsRef);

      const productList = [];
      querySnapshot.forEach((doc) => {
        productList.push({ id: doc.id, ...doc.data() });
      });

      setProducts(productList);
    } catch (error) {
      console.error('Error fetching Products:', error);
    }
  };

  useEffect(() => {
    // Call the function to fetch products
    fetchProducts();
  }, []);

  return (
    <>
      {/* Adjust margin for larger screens */}
      <div className="grid grid-cols-3 max-md:grid-cols-1 gap-28 justify-items-center ">
        {products.length > 0 ? (
          products.map((product,index) => (
           
              <ProductCard
                key={product.id}
                imgSrc={product.imgsrc || ''}
                name={product.Name}
                price={product.Price}
                id={product.id}
                unfinished={product.unfinished}
              // ratings={product.ratings}
              // stock={product.Stock}
              // numOfReviews={product.numOfReviews}
              // handler={addToCartHandler}  
             
              />
            

          ))
        ) : (
          <h1 className="text-xl">
            <LoadingSpinner />
          </h1>
        )}
      </div>
    </>
  );
};

const ProductCard = ({
  imgSrc,
  name,
  price,
  id,
  unfinished,
  //   ratings,
  //   stock,
  //   numOfReviews,
  //   handler,
}) => {
  return (
    <div className=" rounded-lg shadow-md p-2 md:p-4 mt-5 transform transition-transform duration-600 ease-in-out hover:-translate-y-1 hover:shadow-lg
    ">
    {unfinished ? (

   
    <> 
      <div className="relative " >
      <div>
        <img
          src={imgSrc}
          alt={name}
          className="w-full grayscale-[80%] h-48 md:h-60 object-cover rounded-t-md"
        />
        {/* <div></div> */}
        </div>
        {/* <span className="bg-green-500 text-white px-2 py-1 rounded-full absolute top-2 right-2">
          {stock > 0 ? 'In Stock' : 'Out of Stock'}
        </span> */}
      </div>
      <h2 className="mt-2 text-xl font-bold">{name}</h2>

      <div className='flex justify-between py-4 px-2 '>

        <p><span className="text-xl font-bold text-slate-600">Free</span>
          <span className="text-sm text-slate-900 line-through">${price}</span>
        </p>



        <button disabled={true}
          className="mt-2 bg-black hover:bg-black-600 text-white py-1 px-2 md:px-3 rounded-md transition-transform hover:scale-105"
        >   Open
        </button>

      </div>
</>
 ) : (
  <>
  <Link to={`/product/${id}`} key={id}>

  <div className="relative">
        <img
          src={imgSrc}
          alt={name}
          className="w-full h-48 md:h-60 object-cover rounded-t-md"
        />
        {/* <span className="bg-green-500 text-white px-2 py-1 rounded-full absolute top-2 right-2">
          {stock > 0 ? 'In Stock' : 'Out of Stock'}
        </span> */}
      </div>
      <h2 className="mt-2 text-xl font-bold">{name}</h2>

      <div className='flex justify-between py-4 px-2 '>

        <p><span className="text-xl font-bold text-slate-600">Free</span>
          <span className="text-sm text-slate-900 line-through">${price}</span>
        </p>



        <button
          className="mt-2 bg-black hover:bg-black-600 text-white py-1 px-2 md:px-3 rounded-md transition-transform hover:scale-105"
        >   Open
        </button>

      </div>
      </Link>
  </>
 )}
      {/* <p className="text-gray-400">Ratings: {ratings}</p>
      <p className="text-gray-400">Reviews: {numOfReviews}</p> */}
      {/* <button
        className="mt-2 bg-blue-500 hover:bg-blue-600 text-white py-1 px-2 md:px-3 rounded-md transition-transform hover:scale-105"
        onClick={() => handler({ name, price, id, quantity: 1, imgSrc })}
      >
        Add to Cart
      </button> */}
      {/* <button>
      <EmailModal
                  label="Send"
                  className="flex items-center rounded-md bg-slate-900 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-blue-300"
                  props={name}
                />
      </button> */}

    </div>
  );
};

export default Products;
