


import { useState, useEffect } from 'react';
import { getDocs, collectionGroup, query, where, getFirestore, collection } from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import TransferedGift from './TransferedGift';
import { useUser } from '@clerk/clerk-react';
import LoadingSpinner from '../sections_landing/Loader';

const ReceiverGiftById = () => {
  const params = useParams();
  const { user } = useUser();
  const [ReceiverGiftById, setReceiverGiftById] = useState(null);
  const db = getFirestore();
  const userid  = user ? user.id : null;
  const fetchGift = async (userid) => {


   
    try {
   

      // Reference to the user's gifts collection
      const userGiftsRef = collection(db, `users/${userid}/gifts`);

      // Fetch documents from the gifts subcollection
      const querySnapshot = await getDocs(userGiftsRef);

      const giftList = [];
      querySnapshot.forEach((doc) => {
        giftList.push({ id: doc.id, ...doc.data() });
      });

  //  console.log("heltaalg",giftList)
  
   const selectedGift = giftList.find((gift) => gift.id === params.id);
      setReceiverGiftById(selectedGift);
    } catch (error) {
      console.error('Error fetching gifts by receiver email:', error);
    }
  };
 

  useEffect(() => {
    
    fetchGift(userid);
  }, [userid, params.id]);

  return (
    <div>
      {ReceiverGiftById ? <TransferedGift gift={ReceiverGiftById} /> : <p><LoadingSpinner/></p>}
    </div>
  );
};

export default ReceiverGiftById;
