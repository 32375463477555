import React from 'react'
import styles from "../style/door13.module.css";
import { motion } from 'framer-motion';
export default function F5Door() {
  return (
    <div  className={styles.section5}>
     <motion.h2 initial={{ x: "-15vw",opacity:0}}
            whileInView={{ x: 0, opacity:1 }}
            transition={{duration:0.6}}
             viewport={{once:true}} className='text-white mb-4 text-2xl italic'>Note</motion.h2>
       <motion.p initial={{ x: "-15vw",opacity:0}}
            whileInView={{ x: 0, opacity:1 }}
            transition={{duration:0.6}}
             viewport={{once:true}} className='text-center text-white text-lg tracking-widest'>
      However, it's important to note that the crypto gaming space is relatively new, and there are both opportunities and challenges associated with it. As with any emerging technology, it's crucial for players to conduct thorough research and exercise caution, especially when dealing with cryptocurrency transactions and investments.
   </motion.p>
    </div>
  )
}
