import React, { useEffect, useRef } from "react";
// import {Link} from "react-router-dom";
import styles from "../style/card.module.css";
import { motion, AnimatePresence } from "framer-motion";
import { nearlogo } from "../../DoorsImages/Doorimg1";
import { useNavigate, useParams } from "react-router-dom";
// import gsap from "gsap";
// import { ScrollTrigger } from "gsap/ScrollTrigger";

// gsap.registerPlugin(ScrollTrigger);
export default function Card3() {
  const navigate = useNavigate(); 
  const params = useParams();
  const paramsid = params.id;

  const fadeInVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };
  const container1 = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.5,
      },
    },
  };

  return (
    <>
    <AnimatePresence>

      <motion.div
        className={styles.card3}
        initial="hidden"
        animate="visible"
        variants={{ container1 }}
        transition={{ duration: 0.6 }}
      >
        <motion.div
      initial={{x:"-15vw",opacity:0}}
      whileInView={{x:"0",opacity:1}}
      transition={{duration:1}}
      viewport={{once:true}} 
          className={styles.walletboxheading3}
        >
          <p className="text-white mt-3 text-lg">
            {/* To start and gain experience we recommend to use software wallet
            option and another. How to get your wallet use below links for
            Step-by-step guideline. <br />
            Be aware: this wallet is not hosted by yourself, therefore it is
            perfecet to start, but if you want to go big, you have to get a new
            wallet
            <br />
            <br />
            <br />
            <span className="mt-6 text-white font-bold">
              Get your wallet today, Use below links for step by step
              understanding.
            </span> */}
            <ul>
            We recommend starting with a software wallet. To trigger a transaction all software require the same basic steps: 
            <li className="ml-7 list-decimal"> Create a wallet (there are many providers, below we highlight two)
</li>
<li className="ml-7 list-decimal"> Fund the wallet (since every transaction requires a transaction fee (“gas fee”) you need to fund your wallet to send crypto funds, mint, stake, etc. However, you do not need any funds to receive crypto funds from someone else as the sender pays the gas fee. Don't worry you will learn about gas fees in this program. Did you know there are testnets on which you can learn and exercise without risking real money?). </li>
            </ul>
          </p>
 

          <div className="w-full min-h-[300px] py-5 flex items-center justify-center">
 <div className="w-[500px] min-h-[300px] gap-5 px-5 py-5  flex-col flex justify-center items-center bg-white">
<img src={nearlogo} className=""/>
<p className="text-black text-lg  text-center">
NEAR is a user-friendly and carbon-neutral blockchain.<br/>
It has 1,9 million follower on X. 

</p>
<p className="text-black text-lg text-center">
We encourage you to try NEAR.
<br/>

<b>Get your wallet and do your first free and guided transactions on the testnet.</b>
</p>
<button className="px-7 rounded-full no-underline py-2 bg-[#00ec97]">
{/* https://metamask.io/ */}
  <a className="font-bold" target="_blank" href={`/ADoor1/${paramsid}/NearT`}  rel="noopener noreferrer">
    Try NEAR
  </a>
</button>
 </div>
 </div>
  {/* <button className={styles.btn}>
  <a className="font-bold underline" target="_blank" href="https://metamask.io/"  rel="noopener noreferrer">
    Explore Crypto Wallet
  </a>
</button>

<button className={styles.btn}>
  <a className="font-bold underline" target="_blank" href="https://andersbrownworth.com/blockchain/public-private-keys/"  rel="noopener noreferrer">
    Click to watch
  </a>
</button> */}

        </motion.div>
      </motion.div>
    </AnimatePresence>

    </>
  );
}
