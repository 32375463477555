import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "firebase/firestore";
import {
  doc,
  getDoc,
  getFirestore,
  setDoc,
  updateDoc,
  arrayUnion,
  collection,
} from "firebase/firestore";
import { useUser } from "@clerk/clerk-react";
import { useParams } from 'react-router-dom';
import Verify from "./AdventDoors/Verify";
import "../sections_landing/Styles/main.css";

const CalendarDoors = () => {
  const [userProgress, setUserProgress] = useState({
    currentDay: 1,
    completedDoors: [],
  });
  const { user } = useUser();
  const userId = user ? user.id : null;
  const Navigate = useNavigate()
  const params = useParams();
  const paramsid = params.id
  // console.log(paramsid)

  useEffect(() => {
    const fetchUserProgress = async () => {
        if (!userId) {
            // Handle the case when there is no user
           Navigate("/")
        }

        const db = getFirestore();
        const userProgressRef = doc(db, `users/${userId}/progress/${paramsid}`);

        try {
            const docSnap = await getDoc(userProgressRef);

            if (docSnap.exists()) {
                setUserProgress(docSnap.data());
            } else {
                // User document doesn't exist in the database, create one
                const completedDoorsArray = Array(25).fill(false); // Create an array of size 24 with all values set to false
                await setDoc(userProgressRef, { currentDay: 1, completedDoors: completedDoorsArray });
                setUserProgress({ currentDay: 1, completedDoors: completedDoorsArray });
            }
        } catch (error) {
            console.error('Error fetching/creating user document:', error);
        }
    };
  //   const createfeedback = async () => {
  //     if (!userId) {
  //         // Handle the case when there is no user
  //        Navigate("/")
  //     }

  //     const db = getFirestore();
  //     const userfeedbackRef = doc(db, `users/${userId}/feedback/${paramsid}`);

  //     try {
  //         const docSnap = await getDoc(userfeedbackRef);

  //         if (docSnap.exists()) {
  //           return;
  //         } else {
  //             // User document doesn't exist in the database, create one
  //             const ratedoorsArray = Array(25).fill(0); // Create an array of size 24 with all values set to false
  //             await setDoc(userfeedbackRef, { currentDay: 1, ratedoors: ratedoorsArray });
  //             // setUserProgress({ currentDay: 1, completedDoors: completedDoorsArray });
  //         }
  //     } catch (error) {
  //         console.error('Error fetching/creating user document:', error);
  //     }
  // };


    fetchUserProgress();
    // createfeedback()
}, [userId]);

  const isDoorAccessible = (doorNumber) => {
    return userProgress.currentDay >= doorNumber;
  };

  const updateCurrentDayAtSpecificTime = () => {
    const targetTime = new Date(); // Set your desired time here
    targetTime.setHours(12, 0, 0, 0); // Example: 12:00:00

    const currentTime = new Date();

    // Calculate the time difference in milliseconds
    const timeDifference = targetTime.getTime() - currentTime.getTime();

    // Check if the target time is already passed for today
    if (timeDifference < 0) {
      // If yes, set the target time for tomorrow
      targetTime.setDate(targetTime.getDate() + 1);
    }

    // Calculate the time difference again after adjusting for tomorrow
    const updatedTimeDifference = targetTime.getTime() - currentTime.getTime();

    // Set an interval to update currentDay every 24 hours
    setInterval(() => {
      // Increase currentDay by 1
      setUserProgress((prevUserProgress) => ({
        ...prevUserProgress,
        currentDay: prevUserProgress.currentDay + 1,
      }));
    }, updatedTimeDifference);
  };

  // Call the function to update currentDay at the specified time
  useEffect(() => {
    updateCurrentDayAtSpecificTime();
  }, []);

  return (
    <>
    <div>
    <Verify params={paramsid}/>
    </div>
    <div className="items-center unselectable py-20 flex flex-row justify-center min-h-screen  ">
      <div className="grid h-fit flex items-center grid-cols-5 max-lg:grid-cols-3 max-[280px]:grid-cols-2 gap-4 max-lg:gap-2 max-sm:gap-2 text-center">
        <div className="col-span-2 row-span-1 h-40 max-lg:h-32 w-80 px-6  py-2 max-lg:w-[240px] max-sm:w-[240px] max-sm:rounded-xl rounded-3xl flex items-center  bg-slate-800 hover:bg-slate-900 font-semibold text-white  ">
          <div className="max-lg:text-sm max-sm:font-bold max-md:text-sm  text-center max-ms:font-bold max-md:font-normal text-xl">
            🚪🔓Opening doors, day by day. You'll discover the
            magic of blockchain soon enough.🌐💫
          </div>
        </div>

        {[1, 2].map((doorNumber) => (
          <Link
            key={doorNumber}
            to={
              isDoorAccessible(doorNumber)
                ? `/ADoor${doorNumber}/${paramsid}`
                : "/LockedDoor"
            }
          >
            <div
              className={`h-40 w-40 max-lg:h-[120px] rounded-3xl overflow-hidden max-lg:w-[120px]  ${
                isDoorAccessible(doorNumber)
                  ? "bg-sky-500 hover:bg-sky-600"
                  : "bg-sky-900 hover:bg-sky-950"
              } flex justify-center items-center text-4xl text-white font-bold`}
            >
              {doorNumber}
            </div>
          </Link>
        ))}

        {[3, 4].map((doorNumber) => (
          <Link
            key={doorNumber}
            to={
              isDoorAccessible(doorNumber)
                ? `/ADoor${doorNumber}/${paramsid}`
                : "/LockedDoor"
            }
          >
            <div
              className={`h-40 w-40 max-lg:h-[120px] rounded-3xl overflow-hidden max-lg:w-[120px]  ${
                isDoorAccessible(doorNumber)
                  ? "bg-gray-700 hover:bg-gray-800"
                  : "bg-gray-900 hover:bg-gray-950"
              } flex justify-center items-center text-4xl text-white font-bold`}
            >
              {doorNumber}
            </div>
          </Link>
        ))}
        <div className="col-span-2 h-full flex items-center max-sm:rounded-xl min-h-50 w-80 rounded-3xl max-lg:w-[240px] max-sm:w-[240px]  max-md:py-1 max-md:px-0 bg-slate-50  hover:bg-slate-200 hover:text-rose text-center text-xl font-semibold text-gray-600">
          <a >
            <div className="px-6 max-sm:text-xs max-md:text-xs max-md:py-1 max-lg:text-base">
              🚀 JUST 5 MINUTES A DAY – UNLOCK THE SECRETS OF BLOCKCHAIN! 🌐💡
            </div>
          </a>
        </div>

        {[5].map((doorNumber) => (
          <Link
            key={doorNumber}
            to={
              isDoorAccessible(doorNumber)
                ? `/ADoor${doorNumber}/${paramsid}`
                : "/LockedDoor"
            }
          >
            <div
              className={`h-40 w-40 max-lg:h-[120px]  rounded-3xl overflow-hidden max-lg:w-[120px]  ${
                isDoorAccessible(doorNumber)
                  ? "bg-gray-700 hover:bg-gray-800"
                  : "bg-gray-900 hover:bg-gray-950"
              } flex justify-center items-center text-4xl text-white font-bold`}
            >
              {doorNumber}
            </div>
          </Link>
        ))}
        <a href="https://www.linkedin.com/company/web3gift" target="_blank" rel="noopener noreferrer">
          <div className="h-40  w-40 max-sm:rounded-xl  max-sm:w-[120px] max-sm:h-[120px] max-lg:w-[120px] max-lg:h-[120px] rounded-3xl flex justify-center items-center bg-slate-800 hover:bg-slate-900 text-center text-xl  max-sm:text-xs max-lg:text-sm text-white font-bold">
            FOLLOW US ON LINKEDIN🔗{" "}
          </div>
        </a>
        {[6, 7, /*... other door numbers ...*/].map((doorNumber) => (
          <Link
            key={doorNumber}
            to={
              isDoorAccessible(doorNumber)
                ? `/ADoor${doorNumber}/${paramsid}`
                : "/LockedDoor"
            }
          >
            <div
              className={`h-40 w-40 max-lg:h-[120px]  rounded-3xl overflow-hidden max-lg:w-[120px]  ${
                isDoorAccessible(doorNumber)
                  ? "bg-sky-700 hover:bg-sky-800"
                  : "bg-sky-900 hover:bg-sky-950"
              } flex justify-center items-center text-4xl text-white font-bold`}
            >
              {doorNumber}
            </div>
          </Link>
        ))}
        <div className="col-span-2 h-40 max-lg:h-32 w-80 flex items-center  rounded-3xl max-sm:rounded-xl max-lg:w-[240px] max-sm:w-[240px]   max-md:py-1 max-md:px-5 max-md:w-[168px] bg-slate-50 hover:bg-slate-200 hover:text-rose  py-5 text-center text-xl font-semibold text-gray-600">
          <h1 className="text-xl max-sm:text-sm max-lg:p-1 px-4 max-lg:text-base ">
           🎁 MYWEB3GIFT CRAFTED THIS CALENDAR WITH LOVE AND AFFECTION 🌟.
          </h1>
        </div>

        {[8,9 /*... other door numbers ...*/].map((doorNumber) => (
          <Link
            key={doorNumber}
            to={
              isDoorAccessible(doorNumber)
                ? `/ADoor${doorNumber}/${paramsid}`
                : "/LockedDoor"
            }
          >
            <div
              className={`h-40 w-40 max-lg:h-[120px]  max-sm:rounded-xl rounded-3xl overflow-hidden max-lg:w-[120px]  ${
                isDoorAccessible(doorNumber)
                  ? "bg-gray-700 hover:bg-gray-800"
                  : "bg-gray-900 hover:bg-gray-950"
              } flex justify-center items-center text-4xl text-white font-bold`}
            >
              {doorNumber}
            </div>
          </Link>
        ))}
        <div className="col-span-2 h-40 w-80 max-lg:w-[16rem] max-lg:h-32 max-sm:hidden flex items-center rounded-3xl  max-md:py-1 max-md:px-5 bg-gray-800 hover:bg-gray-900 hover:text-white  py-5 text-center text-xl font-semibold text-white">
          <a >
            <h1 className="text-xl max-sm:text-sm max-lg:p-1  max-lg:text-base ">
              🚀JUST 5 MINUTES A DAY – UNLOCK THE SECRETS OF BLOCKCHAIN! 🌐💡
            </h1>
          </a>
        </div>
        {[10 /*... other door numbers ...*/].map((doorNumber) => (
          <Link
            key={doorNumber}
            to={
              isDoorAccessible(doorNumber)
                ? `/ADoor${doorNumber}/${paramsid}`
                : "/LockedDoor"
            }
          >
            <div
              className={`h-40 w-40 max-lg:h-[120px] max-sm:rounded-xl rounded-3xl overflow-hidden max-lg:w-[120px]  ${
                isDoorAccessible(doorNumber)
                  ? "bg-gray-700 hover:bg-gray-800"
                  : "bg-gray-900 hover:bg-gray-950"
              } flex justify-center items-center text-4xl text-white font-bold`}
            >
              {doorNumber}
            </div>
          </Link>
        ))}
        <div
          className="h-40 w-40 max-lg:h-[120px] rounded-3xl overflow-hidden max-lg:w-[120px]  ${
                 bg-sky-900 hover:bg-sky-950
               flex justify-center items-center text-4xl text-white font-bold"
        >
           <a href="https://twitter.com/myweb3gift" target="_blank" rel="noopener noreferrer">
          <div className="h-40  w-40 max-sm:rounded-xl  max-sm:w-[120px] max-sm:h-[120px] max-lg:w-[120px] max-lg:h-[120px] rounded-3xl flex justify-center items-center bg-slate-800 hover:bg-slate-900 text-center text-xl  max-sm:text-xs max-lg:text-sm text-white font-bold">
            FOLLOW US ON TWITTER🔗{" "}
          </div>
        </a>
        </div>
      </div>
    </div>
    </>
  );
};

export default CalendarDoors;
