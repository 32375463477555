import React, {useEffect, useState} from 'react';
import { useAuth, useSignIn } from '@clerk/clerk-react';
import { useNavigate } from 'react-router-dom';
import ReactDOM from "react-dom";
import { IoMdClose } from 'react-icons/io';
import { AnimatePresence,motion } from 'framer-motion';

const ForgotPassword = ({CloseforgetModal}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [code, setCode] = useState('');
  const [successfulCreation, setSuccessfulCreation] = useState(false);
  const [secondFactor, setSecondFactor] = useState(false);
  const [error, setError] = useState('');

  // const [data, setData] = useState(null);
  const history = useNavigate();
  const { isSignedIn } = useAuth();
  const { isLoaded, signIn, setActive } = useSignIn();
  useEffect(() => {
    document.body.style.overflowY = "hidden";
    return () => {
      document.body.style.overflowY = "scroll";
    };
  }, []);
  if (!isLoaded) {
    return null;
  }
  

  if (isSignedIn) {
    history('/');
  }

  const create = async (e) => {
    e.preventDefault();
    try {
      await signIn?.create({
        strategy: 'reset_password_email_code',
        identifier: email,
      });
      setSuccessfulCreation(true);
      setError('');
    } catch (err) {
      if(err.errors[0].longMessage === "`reset_password_email_code` isn't allowed for `strategy` when user's password is not set."){
setError("Please log in using the same method you used to create the account.(Google,Apple,Email)")
      }
      else{
        setError(err.errors[0].longMessage);
      }
      console.error('error', err.errors[0].longMessage);

     
    }
  };

  const reset = async (e) => {
    e.preventDefault();
    try {
      const result = await signIn?.attemptFirstFactor({
        strategy: 'reset_password_email_code',
        code,
        password,
      });
      if (result.status === 'needs_second_factor') {
        setSecondFactor(true);
        setError('');
      } else if (result.status === 'complete') {
        setActive({ session: result.createdSessionId });
        setError('');
      } else {
        //console.log(result);
      }
    } catch (err) {
      console.error('error', err.errors[0].longMessage);
      setError(err.errors[0].longMessage);
    }
    
  };
  
  


  return ReactDOM.createPortal(
  <>
  <AnimatePresence>
  <motion.div
       initial={{ opacity: 0, y: "-100%" }}
      whileInView={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: "-100%" }}
      transition={{duration:"0.3"}}
        className="fixed z-[110]  top-0 left-0 right-0 bottom-0 backdrop-blur-xl bg-sky-800/30"
        onClick={CloseforgetModal}
      ></motion.div>
       <motion.div
       initial={{ opacity: 0,}}
      whileInView={{ opacity: 1 }}
      exit={{ opacity: 0, }}
      transition={{duration:"0.3"}} className="fixed z-[120] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-100 py-2 px-8 bg-sky-200 w-full sm:w-11/12 md:w-2/4 lg:w-1/2 xl:w-1/3 2xl:w-1/4 rounded-xl shadow-md drop-shadow-[0_35px_35px_rgba(0,0,0,0.25)]">
        <button
          className="absolute top-0 right-0 text-2xl text-sky-700  px-4 py-4"
          onClick={CloseforgetModal}
        >
          <motion.div
         initial={{ rotate: 0 }}
    animate={{ rotate:  90 }}
    transition={{ duration: 2 }}
        >
 <IoMdClose className='font-extrabold' size={30} />
        </motion.div>
        </button>
<div className='flex flex-row select-none justify-center py-10 items-center'>

<div className="mx-auto max-w-md p-8 bg-white shadow-md rounded-md">
      <h1 className="text-2xl font-bold mb-4">Forgot Password?</h1>
      <form
        className="flex flex-col gap-4"
        onSubmit={!successfulCreation ? create : reset}
      >
        {!successfulCreation && (
          <>
            <label htmlFor="email">Please provide your email address</label>
            <input
              className="border p-2 rounded-md"
              type="email"
              placeholder="e.g john@doe.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />

            <button className="bg-sky-700 hover:bg-sky-800 text-white py-2 rounded-md">
              Send password reset code
            </button>
            {error && <p className="text-red-500">{error}</p>}
          </>
        )}

        {successfulCreation && (
          <>
            <label htmlFor="password">Enter your new password</label>
            <input
              className="border p-2 rounded-md"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />

            <label htmlFor="password">
              Enter the password reset code that was sent to your email
            </label>
            <input
              className="border p-2 rounded-md"
              type="text"
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />

            <button className="bg-sky-700 hover:bg-sky-800 text-white py-2 rounded-md">
              Reset
            </button>
            {error && <p className="text-red-500">{error}</p>}
          </>
        )}

        {secondFactor && (
          <p className="text-red-500">
            2FA is required, but this UI does not handle that
          </p>
        )}
      </form>
    </div>
</div>
</motion.div>
</AnimatePresence>
</>,
document.querySelector(".myportaldiv")

  );
};

export default ForgotPassword;
