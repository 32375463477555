import React from "react";
import styles from "../style/door14.module.css";
import { motion } from "framer-motion";
import { SmartContract1 } from "../../DoorsImages/Doorimg21";

export default function FDoor() {
  return (
    <>
      <motion.div
        className={styles.section11}
        initial="hidden"
        animate="visible"
        transition={{ duration: 1 }}
      >
        <motion.div
          initial={{ x: "-15vw", opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{
            duration: 1,
          }}
          viewport={{ once: true }}
          className={styles.walletboxheading}
        >
          <h1 className="text-white text-4xl font-bold pt-5 ">
            Smart Contract Audit
          </h1>
          <p className="text-white mt-6 text-lg tracking-wider">
            A smart contract audit is a comprehensive inspection and analysis of
            the underlying code of a smart contract. The purpose of such an
            audit is to identify any flaws or vulnerabilities in the code, fix
            them, and make improvements. Smart contract audits are critical in
            the blockchain space as security is paramount. They help ensure that
            the smart contract is secure, transparent, and tamper-proof.
            <br />
            <br />
            Smart contract audits are typically conducted by specialized firms
            that have expertise in blockchain technology and smart contract
            development. These firms use a variety of tools and techniques to
            analyze the code and identify any potential issues. The audit
            process involves a thorough review of the code, testing, and
            verification. The goal is to ensure that the smart contract is
            secure, reliable, and performs as intended.
          </p>
        </motion.div>
        <motion.div
          initial={{ x: "15vw", opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
          className={styles.walletimgbox}
        >
          <img src={SmartContract1} alt="img" />
        </motion.div>
      </motion.div>
    </>
  );
}
