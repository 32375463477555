import React from "react";
import styles from "../style/door3.module.css";
import FDoor from "./FDoor";
import SDoor from "./SDoor";
// import TDoor from "./TDoor";
import { motion } from "framer-motion";
// import LDoor from "./LDoor";

export default function Door3comp() {
  const fadeInVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };
  const container1 = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.5,
      },
    },
  };

  return (
    <>
      <motion.div
        initial="hidden"
        animate="visible"
        variants={{ fadeInVariants, container1 }}
        transition={{ duration: 1 }}
        className={styles.container3}
      >
        <FDoor />
        <SDoor />
        {/* <TDoor /> */}
        {/* <LDoor /> */}
      </motion.div>
    </>
  );
}
