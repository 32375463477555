import React from 'react'
import { UserProfile } from '@clerk/clerk-react'
import Nav01 from './Nav01'



const UserProfiles = () => {
  return (
    <div>

<Nav01></Nav01>

<div className='h-20'>

</div>

<div className='flex justify-center items-center'>
<UserProfile/>

</div>


    </div>
  )
}

export default UserProfiles