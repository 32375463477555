import {
  SignOutButton,
  SignInButton,
  SignIn,
  SignedIn,
  SignedOut,
  RedirectToSignIn,
} from "@clerk/clerk-react";
import React, { useEffect, useRef, useState } from "react";
import Nav01 from "../Components/Nav01";
import { Footer } from "../sections_landing";
import { Link } from "react-router-dom";
import {
  getDocs,
  query,
  where,
  getFirestore,
  collectionGroup,
  doc, // Add this import
  updateDoc,
  getDoc,
  setDoc,
  collection,
} from "firebase/firestore";
import { useUser } from "@clerk/clerk-react";
import { JustBackground2 } from "../assets/Images/AfterLoginPic";
import { Flower } from "../assets/Images";
// import ReceiverGiftCard from './ReceiverGiftCard';
// ... (other imports)
import styles from "./Styles/mygift.module.css";
import { motion, AnimatePresence } from "framer-motion";
import confetti from "canvas-confetti";
import {
  Imogi1,
  Imogi2,
  Imogi3,
  Imogi4,
  Imogi5,
} from "../assets/Images/RandomImogies/index.js";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";
import SignUpForm from "./SignUp.js";
import LoginPage from "../Components/LoginPage.js";
import LoadingSpinner from "../sections_landing/Loader";
import "../sections_landing/Styles/main.css";

const randomImages = [Imogi1, Imogi2, Imogi3, Imogi4, Imogi5];

const MyGifts = () => {
  const { user } = useUser();
  const [gifts, setGifts] = useState([]);
  const [selectedGift, setSelectedGift] = useState(null);
  const [isOpened, setIsOpened] = useState(false);
  const [isThirdBox, setIsThirdBox] = useState(false);
  const [isUserGift, setIsUserGift] = useState(true);
  const [showWrapBox, setShowWrapBox] = useState(false);
  // const [selectedReceiverCard, setSelectedReceiverCard] = useState(null);
  const [activeGiftId, setActiveGiftId] = useState(undefined);
  const [sortedGifts, setSortedGifts] = useState([]);
  const [loading, setLoading] = useState(true);
  const userId = user ? user.id : null;
  const [userProgress, setUserProgress] = useState({
    currentDay: 1,
    completedDoors: [],
  });

  const [click, setClick] = useState(false);

  const receiverEmail = user?.primaryEmailAddress.emailAddress;
  const db = getFirestore();

  const scrollTo = (id) => {
    let element = document.getElementById(id);

    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
    setClick(!click);
  };
  const resetUserProgress = () => {
    setUserProgress({
      currentDay: 1,
      completedDoors: []
    });
  };

  useEffect(() => {
    const sorted = gifts
      .slice()
      .sort((a, b) => new Date(b.date) - new Date(a.date));
    setSortedGifts(sorted);
  }, [gifts]);

  const handleGiftClick2 = async () => {
    setIsOpened((prevIsOpened) => !prevIsOpened);
    setIsThirdBox((prev) => !prev);
    startConfetti();
  };

  const renderStatusText = (gift) => {
    if (gift.opened === true) {
      // if((clickedGift.opened === false) ){
      return (
        <h2 className="text-green-600 text-lg max-lg:text-sm md:text-xl font-medium">
          Opened
        </h2>
      );
    } else {
      return (
        <h2 className="text-red-600 max-lg:text-sm text-lg lg:text-end md:text-xl font-medium">
          Not Opened
        </h2>
      );
    }
  };

  const handleGiftClick = async (clickedGift, receiverEmail) => {
    const giftId = clickedGift.giftId;

    if (gifts.opened === false) {
      setIsOpened(false);
      setIsThirdBox(false);
    }

    if (activeGiftId === clickedGift.id) {
      setActiveGiftId(undefined);
      setIsOpened(true);
      setIsThirdBox(true);
    } else {
      setActiveGiftId(clickedGift.id);
      setIsOpened(false);
      setIsThirdBox(false);
    }

    setSelectedGift(clickedGift);
    if (clickedGift.opened === false) {
      setTimeout(() => {
        handleGiftClick2();
        setIsThirdBox(false);
      }, 2000);
    } else {
      setIsOpened(true);
      setIsThirdBox(true);
    }

    try {
      const giftsCollectionGroupRef = collectionGroup(db, "gifts");
      //console.log("hahahsgag", receiverEmail);
      // Filter gifts based on the received parameters
      const query1 = query(
        giftsCollectionGroupRef,
        where("giftId", "==", giftId)
      );
      // Fetch the matched document
      const querySnapshot = await getDocs(query1);

      if (!querySnapshot.empty) {
        const giftDocRef = querySnapshot.docs[0].ref;
        // Update the 'opened' field to true
        await updateDoc(giftDocRef, {
          opened: true,
        });
      } else {
        //console.log("Selected gift not found.");
      }
    } catch (error) {
      console.error("Error fetching or updating gifts:", error);
    }
    // Update the opened status of the clicked gift in the local state
    setGifts((prevGifts) =>
      prevGifts.map((gift) =>
        gift.id === clickedGift.id ? { ...gift, opened: true } : gift
      )
    );
    // Set the selected gift with the updated opened status
    setSelectedGift((prevSelectedGift) => ({
      ...prevSelectedGift,
      opened: true,
    }));

    // //console.log("IsOpened:", showWrapBox);
    // Check if the selected gift is the user's gift
    setIsUserGift(
      selectedGift.receiverEmail === user.primaryEmailAddress.emailAddress
    );
    setShowWrapBox(
      selectedGift.receiverEmail === user.primaryEmailAddress.emailAddress
    );
  };
  useEffect(() => {
    setIsThirdBox(selectedGift && selectedGift.opened);
  }, [selectedGift]);

  useEffect(() => {
    const fetchGiftsByReceiver = async (receiverEmail) => {
      try {
        const giftsCollectionGroupRef = collectionGroup(db, "gifts");
        const query1 = query(
          giftsCollectionGroupRef,
          where("receiverEmail", "==", receiverEmail)
        );
        const querySnapshot = await getDocs(query1);

        const fetchedGifts = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const sortedGifts = fetchedGifts.sort((a, b) =>
          a.senderName.localeCompare(b.senderName)
        );

        //console.log("Receiver's gifts: ", sortedGifts);
        setGifts(sortedGifts);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching gifts by receiver email:", error);
        setLoading(false);
      }
    };

    if (user && user.primaryEmailAddress) {
      const receiverEmail = user.primaryEmailAddress.emailAddress;
      //console.log("Receiver Email:", receiverEmail);

      fetchGiftsByReceiver(receiverEmail);
    }
  }, [user, db]);

  const fetchUser = async (gift) => {
    if (!userId) {
      // Handle the case when there is no user
      return;
    }
    //console.log("halgewsg",id)
    const db = getFirestore();
    const userProgressRef = doc(db, `users/${userId}/progress/${gift.id}`);

    try {
      const docSnap = await getDoc(userProgressRef);

      if (docSnap.exists()) {
        setUserProgress(docSnap.data());
      } 
      return
    } catch (error) {
      console.error("Error fetching/creating user document:", error);
    }
  };

  useEffect(() => {
    // Set the first gift as selected when the gifts array is updated
    if (gifts.length > 0 && !selectedGift) {
      setSelectedGift(gifts[0]);
    }
  }, [gifts, selectedGift]);

  let displayText;

  if (selectedGift && selectedGift.giftDetails) {
    if (selectedGift.giftDetails === "Crypto Advent Calendar") {
      displayText = "Crypto Advent Calendar";
    } else if (selectedGift.giftDetails === "Crypto Suprise Gift Box") {
      displayText = "Crypto Surprise Gift Box";
    } else if (selectedGift.giftDetails === "10-Day Crypto Challenge") {
      displayText = "10-Day Crypto Challenge";
    } else {
      displayText = " Gift ";
    }
  }

  const startConfetti = () => {
    const duration = 15 * 1000;
    const animationEnd = Date.now() + duration;
    const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

    function randomInRange(min, max) {
      return Math.random() * (max - min) + min;
    }

    const interval = setInterval(() => {
      const timeLeft = animationEnd - Date.now();

      if (timeLeft <= 0) {
        clearInterval(interval);
        return;
      }

      const particleCount = 50 * (timeLeft / duration);

      // since particles fall down, start a bit higher than random
      confetti(
        Object.assign({}, defaults, {
          particleCount,
          origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
        })
      );
      confetti(
        Object.assign({}, defaults, {
          particleCount,
          origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
        })
      );
    }, 250);

    return () => {
      clearInterval(interval);
    };
  };

  useEffect(() => {
    if (isOpened) {
      setTimeout(() => {
        setIsThirdBox(true);
      }, 1000);
    }
  }, [isOpened]);

  const words = selectedGift?.greeting?.split(" ") || [];

  // Take the first 5 words and join them back into a string
  const truncatedGreeting = words.slice(0, 4).join(" ");

  return (
    <>
      <SignedOut>
        {/* <RedirectToSignIn /> */}
        <Nav01 />
        <LoginPage></LoginPage>
      </SignedOut>
      <SignedIn>
        <Nav01 />
        <div className="z-10 absolute bg-sky-950 h-[90px] w-full"></div>
      </SignedIn>
      {loading ? (
        <div className="text-center">
          <LoadingSpinner />
        </div>
      ) : (
        <div>
          <main>
            <div
              className="sectionsss unselectable mx-auto"
              style={{ overflowX: "hidden" }}
            >
              <SignedIn>
                <div
                  className="relative  mx-auto py-10 flex min-h-screen bg-fixed flex-col items-center gap-9 bg-cover bg-no-repeat bg-center"
                  style={{
                    backgroundImage: `url(${JustBackground2})`,
                    // backgroundImage: `url(https://images.unsplash.com/photo-1511184150666-9bb7d41a88f4?q=80&w=2069&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)`,
                  }}
                >
                  {gifts.length === 0 ? (
                    <div className="w-screen max-lg:min-h-full md:min-h-screen flex flex-col justify-center items-center">
                      <div className="bg-gradient-to-r from-orange-100  to-orange-200 p-8 rounded-md shadow-md md:w-3/4 lg:w-1/2 mx-auto min-h-[60vh] flex flex-col justify-center items-center text-center">
                        <p className="text-4xl font-bold text-gray-600 mb-4">
                          🎉 Welcome to the Self-Gift Oasis! 🌈✨
                        </p>
                        <p className="text-lg text-gray-500 mb-6">
                          Embark on a cosmic journey with our unique Crypto
                          gifts. Treat yourself to the joy of self-gifting and
                          explore the world of crypto with surprises that
                          reflect your fantastic taste.
                        </p>
                        <p className="italic text-sm text-gray-600">
                          "You are the captain of your own spaceship, navigating
                          through the galaxies of self-love and crypto wonders."
                        </p>
                        <div className="flex flex-row gap-4 mt-8">
                          {/* <button className="bg-gradient-to-r from-yellow-400 to-yellow-600 text-white font-bold py-2 px-4 rounded-full cursor-pointer hover:from-yellow-500 hover:to-yellow-700">
         🎁 Explore Gifts
               </button> */}

                          <Link to="/gift">
                            {" "}
                            <button className="bg-gradient-to-r from-orange-400 to-orange-600 text-white font-bold py-2 px-4 rounded-full cursor-pointer hover:from-yellow-500 hover:to-yellow-700">
                              🚀 Send to Yourself
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className=" w-screen max-lg:min-h-full md:min-h-screen max-lg:pt-10 md:p-10 flex items-center flex-row max-lg:flex-col">
                        <div className="flex flex-col max-lg:w-full w-6/12 pt-10 min-h-screen overflow-hidden rounded-xl">
                          <div
                            onClick={() => scrollTo("giftcard")}
                            className="w-full min-h-screen overflow-hidden"
                          >
                            <div className="h-[60px] rounded-tl-xl rounded-tr-xl w-full px-16 bg-[#f1d7c8] py-3 flex justify-between items-center border-solid border-b border-[#3f2414a7]">
                              <h2 className=" font-bold  text-gray-500  text-2xl">
                                Name
                              </h2>
                              <h2 className="font-bold  text-gray-500 text-2xl">
                                Gift
                              </h2>
                            </div>
                            <div className="w-full h-screen overflow-x-hidden">
                              {sortedGifts.map((gift) => (
                                <div
                                  key={gift.id}
                                  gift={gift}
                                  onClick={() => {
                                    resetUserProgress();
                                    handleGiftClick(gift);

                                    fetchUser(gift);
                                  }}
                                  className={`w-full px-2 md:px-7 h-[100px] bg-[#f1d7c8] ${
                                    activeGiftId === gift.id ? "bg-sky-100" : ""
                                  } flex justify-between border-solid hover:bg-sky-100 border-black`}
                                >
                                  <div className="flex items-center gap-6 md:gap-7">
                                    {/* <div className="w-[70px] h-[70px] max-lg:w-[50px] max-lg:h-[50px] rounded-full overflow-hidden">
                                   {gift.senderImage ? (
                                    <img
                                      src={gift.senderImage}
                                      className="w-full h-full object-cover"
                                      alt="receiver image"
                                    />
                                  ) : (
                                    <img
                                      className="w-full h-full object-cover"
                                      src={
                                        randomImages[
                                          Math.floor(
                                            Math.random() * randomImages.length
                                          )
                                        ]
                                      }
                                      alt="default emoji"
                                    />
                                  )} 
                                 
                                </div> */}

                                    <div>
                                      <h1 className="font-bold  text-gray-600  max-lg:text-lg text-xl uppercase">
                                        {gift.senderName}
                                      </h1>
                                      <h2 className=" max-lg:text-sm font-light text-[#333] text-lg capitalize">
                                        {gift.giftDetails}
                                      </h2>
                                      <h5 className="max-lg:text-xs font-light text-[#333] text-sm capitalize">
                                        {gift.date}
                                      </h5>
              
                                    </div>
                                  </div>
                                  <div className="flex items-center">
                                    {/* {gift.opened ? (
                                      <>
                                        <h2 className="text-green-600 text-lg max-lg:text-sm md:text-xl font-medium">
                                          Opened
                                        </h2>
                                      </>
                                    ) : (
                                      <>
                                        <h2 className="text-red-600 max-lg:text-sm text-lg lg:text-end md:text-xl font-medium">
                                          Not Opened
                                        </h2>
                                      </>
                                    )} */}
                                    {/* <h2 className={`text-${selectedGift && selectedGift.opened ? 'green' : 'red'}-600 text-lg max-lg:text-sm md:text-xl font-medium`}>
  {selectedGift.opened ? 'Opened' : 'Not Opened'}
</h2> */}
                                    {renderStatusText(gift)}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                        <div className=" w-full max-lg:w-full w-6/12 pt-5 min-h-screen">
                          <div
                            // id="giftcard"
                            className="w-full max-lg:hidden min-h-screen flex items-start justify-start max-lg:justify-center"
                          >
                            {selectedGift && (
                              <>
                                <div className="w-full md:px-5 max-lg:px-0 max-lg:justify-center flex flex-col items-center gap-6">
                                  {showWrapBox && (
                                    <>
                                      <div className="w-full flex flex-col items-center gap-6">
                                        {/* <div className="w-[100px] h-[100px]  rounded-full overflow-hidden">
                                <img
                                  src={
                                    selectedGift.senderImage || randomImages[0]
                                  } // Use the selected gift's image or a default image
                                  className="w-full h-full object-cover"
                                  alt="selected gift"
                                />
                              </div>  */}
<div className="flex flex-col gap-2">
                                        <h1 className="font-bold text-2xl uppercase">
                                          FROM: {selectedGift.senderName}
                                        </h1>
                                        <>
  {userProgress.currentDay === 1 ? (
    null // Don't render anything if current day is 1
  ) : (
    <>
      {userProgress.currentDay <= 10 ? (                           
        <Link
          to={`/ADoor${userProgress.currentDay}/${selectedGift.id}`}
        >
          Continue with next Door: Door {userProgress.currentDay}
        </Link>
      ) : (
        <p>All doors completed</p>
      )}
    </>
  )}
</>

                                      </div>
                                      <div className="w-[90%] h-full">
                                        {/* {showWrapBox && ( */}
                                        <div className={styles.giftwrapbox}>
                                          <motion.div
                                            initial={{ opacity: 1 }}
                                            animate={{
                                              opacity: isOpened ? 0 : 1,
                                              transition: { duration: 3 },
                                            }}
                                            className={styles.rectange11}
                                          >
                                            <motion.div
                                              whileTap={{ opacity: 0 }}
                                              className={styles.rectange1}
                                            >
                                              <AnimatePresence>
                                                <motion.div
                                                  // animate={{ opacity: isOpened ? 0 : 1 }}
                                                  className={`transition-all  ${
                                                    isOpened
                                                      ? "h-1/5 opacity-0.5"
                                                      : "h-2/5"
                                                  }`}
                                                >
                                                  <motion.img
                                                    onClick={handleGiftClick2}
                                                    animate={{
                                                      height: isOpened
                                                        ? "15%"
                                                        : "50%",
                                                    }}
                                                    className={styles.flowerimg}
                                                    src={Flower}
                                                    alt="flower"
                                                  />
                                                </motion.div>
                                              </AnimatePresence>
                                              <AnimatePresence>
                                                <motion.div
                                                  initial={{ x: "-50%" }}
                                                  animate={{
                                                    x: isOpened
                                                      ? "-150%"
                                                      : "-50%",
                                                    transition: { duration: 1 },
                                                  }}
                                                  className={styles.rectange2}
                                                ></motion.div>
                                              </AnimatePresence>
                                              <AnimatePresence>
                                                <motion.div
                                                  initial={{ x: "-50%" }}
                                                  animate={{
                                                    x: isOpened
                                                      ? "150%"
                                                      : "-50%",
                                                    transition: { duration: 1 },
                                                  }}
                                                  className={styles.rectange3}
                                                ></motion.div>
                                              </AnimatePresence>
                                              <AnimatePresence>
                                                <motion.div
                                                  initial={{ opacity: 0 }}
                                                  animate={{
                                                    opacity: isOpened ? 1 : 0,
                                                  }}
                                                  // animate={{height: isOpened ? "100%" : "0",transition:{duration:1}}}
                                                  transition={{ duration: 1 }}
                                                  className={styles.giftoverlay}
                                                ></motion.div>
                                              </AnimatePresence>
                                            </motion.div>
                                          </motion.div>
                                          <>
                                            <motion.div
                                              initial={{ opacity: 0, scale: 0 }}
                                              animate={{ opacity: 1, scale: 1 }}
                                              whileTap={{
                                                scale: 0.9,
                                                transition: { duration: 0.2 },
                                              }}
                                              exit={{ opacity: 0, scale: 0 }}
                                              // transition={{ duration: 0.5 }}
                                              className="flex justify-center items-center"
                                            >
                                              <AnimatePresence>
                                                {isThirdBox && isOpened && (
                                                  <motion.div
                                                    class={
                                                      styles.giftwrapingbox1
                                                    }
                                                  >
                                                    <div
                                                      className={
                                                        styles.giftbox2
                                                      }
                                                    >
                                                      <h2 className="mb-2 text-xl font-semibold text-white">
                                                        {displayText}
                                                      </h2>
                                                      <p class="mb-4 py-9 pb-20 text-white">
                                                        {truncatedGreeting}....
                                                      </p>
                                                      <Link
                                                        to={`/giftid/${selectedGift.id}`}
                                                      >
                                                        <button class="absolute bottom-0 left-1 rounded-3xl bg-yellow-500 px-6 py-2 max-xl:px-1  max-xl:py-0 max-xl:font-semibold font-bold text-white">
                                                          Open Gift
                                                        </button>
                                                      </Link>
                                                    </div>
                                                    {/* <button class="absolute z-10 left-80 top-24 rounded-2xl  bg-white px-5 py-1 font-bold text-yellow-500">
                        Wrap
                      </button> */}
                                                    <div
                                                      className={
                                                        styles.giftbox3
                                                      }
                                                    >
                                                      {/* <img src={image} alt="Gift" class="h-60 w-96 max-2xl:h-[300px]  max-2xl:max-w-[300px]   max-xl:max-w-[220px] object-cover" /> */}
                                                      <img
                                                        // src={isUserGift ? user.image : selectedGift.image}
                                                        src={selectedGift.image}
                                                        alt="Gift"
                                                        className="w-[90%]"
                                                      />
                                                    </div>
                                                  </motion.div>
                                                )}
                                              </AnimatePresence>
                                            </motion.div>
                                          </>
                                        </div>
                                      </div>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </>
                            )}
                          </div>
                          <div
                            id="giftcard"
                            className="w-full lg:hidden min-h-screen flex items-center justify-center"
                          >
                            {selectedGift && (
                              <>
                                <div className="w-full px-2 max-w-md flex flex-col items-center gap-6">
                                  {showWrapBox && (
                                    <>
                                      <div className="flex flex-col items-center gap-6">
                                        <h1 className="font-bold text-2xl uppercase">
                                          FROM: {selectedGift.senderName}
                                        </h1>

                                        <>
                                        {userProgress.currentDay <= 10 ? (                           
  <Link
    to={`/ADoor${userProgress.currentDay}/${selectedGift.id}`}
  >
    Continue with next Door: Door {userProgress.currentDay}
  </Link>
) : (
  <p>All doors completed</p>
)}
</>
                                      </div>
                                      <div className="w-full">
                                        <div className="bg-white rounded-lg overflow-hidden shadow-lg">
                                          <div className="p-4">
                                            <img
                                              src={selectedGift.image}
                                              alt="Gift"
                                              className="w-full"
                                            />
                                          </div>
                                          <div className="p-4">
                                            <h2 className="text-xl font-semibold text-gray-800">
                                              {displayText}
                                            </h2>
                                            <p className="text-gray-600">
                                              {truncatedGreeting}....
                                            </p>
                                            <Link
                                              to={`/giftid/${selectedGift.id}`}
                                            >
                                              <button className="mt-2 px-4 py-2 bg-yellow-500 text-white font-semibold rounded-lg">
                                                Open Gift
                                              </button>
                                            </Link>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div>
                  <Footer></Footer>
                </div>
              </SignedIn>
            </div>
          </main>
        </div>
      )}

      <ScrollLink to="sectionsss" smooth={true} duration={1000}></ScrollLink>
    </>
  );
};

export default MyGifts;
