
// import { GiftBitcoin} from "../assets/Images/MyWeb3Gift-red2/index";
import { AnimatePresence,motion } from "framer-motion"
import EmailForm from "./EmailForm.js"
import { Link } from "react-router-dom"

const Footer = () => {
    return (
        <div>
        <AnimatePresence>
            <motion.footer 
             initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
     className="bg-sky-950 dark:bg-sky-950 tracking-wider">
                <motion.div className="mx-auto w-full max-w-screen-xl">

                    <div className=" py-4 px-4 sm:hidden"> <EmailForm />  </div>

                    <div className="grid grid-cols-3 max-sm:grid-cols-2  gap-4 justify-items-center px-4 py-6 lg:py-8 ">
                        {/* <div className=" px-5">
                            <img src={GiftBitcoin} width={250} height={250} className="  " alt="" />
                        </div> */}

                        <div className=" max-sm:hidden"> <EmailForm />  </div>

                        <div className="">
                            <h2 className="mb-6 text-lg font-semibold text-gray-200 uppercase dark:text-white">Company</h2>
                            <ul className="text-gray-400 dark:text-gray-100 font-medium">
                                
                                <li className="mb-4">
                                    <Link to="/about" className="uppercase hover:underline">About</Link>
                                </li>
                                <li className="mb-4">
                                    <Link to="/blog" className="uppercase hover:underline">Further Readings</Link>
                                </li>

                                <li className="mb-4">
                                    <a href="https://forms.gle/YyJfsw41TPqF94AF9" target="_blank" rel="noopener noreferrer"className="uppercase hover:underline">Careers</a>
                                </li>
                                <li className="mb-4">
                                    <Link to="/FAQS" className="uppercase hover:underline">Faq's</Link>
                                </li>
                                
                            </ul>

                        </div>
                        {/* <div className="">
                            <h2 className="mb-6 text-lg font-semibold text-gray-200 uppercase dark:text-white">Our Blog</h2>
                            <ul className="text-gray-400 dark:text-gray-100 font-medium">
                                <li className="mb-4">
                                    <Link to="/Blog1" className="uppercase hover:underline" >Article1</Link>
                                </li>
                                <li className="mb-4">
                                <Link to="/Blog2" className="uppercase hover:underline" >Article2</Link>
                                    {/* <a href="https://forms.gle/YyJfsw41TPqF94AF9" className="hover:underline">Article2</a> */}
                                {/* </li>

                                <li className="mb-4">
                                    <Link to="/Blog3" className="uppercase hover:underline">Article3</Link>
                                </li>

                            </ul>

                        </div> */} 
                       
                        <div className="">
                            <h2 className="mb-6 text-lg font-semibold text-gray-200 uppercase dark:text-white">Help center</h2>
                            <ul className="text-gray-400 dark:text-gray-100 font-medium">
                                <li className="mb-4 flex gap-5 items-center">
                                    <a href="https://discord.gg/NmSz8yzN" target="_blank" rel="noopener noreferrer"className="uppercase hover:underline">Discord Server</a>

                                </li>
                                <li className="mb-4 flex gap-5 items-center">
                                    <a href="https://discord.gg/NmSz8yzN" target="_blank" rel="noopener noreferrer"className="uppercase hover:underline">Terms of Use</a>

                                </li>
                                {/* <li className="mb-4">
                                    <a href="https://twitter.com/myweb3gift" className="hover:underline">Twitter</a>
                                </li>
                                <li className="mb-4">
                                    <a href="https://www.linkedin.com/company/web3gift" className="hover:underline">LinkedIn</a>
                                </li> */}
                                <li className="mb-4">
                                    <Link to="/contact" className="uppercase hover:underline" > Contact Us</Link>
                                </li>
                                
                            </ul>
                        </div>


                        {/* <div>
                            <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">Legal</h2>
                            <ul className="text-gray-500 dark:text-gray-400 font-medium">
                                <li className="mb-4">
                                    <a href="./" className="hover:underline">Privacy Policy</a>
                                </li>
                                <li className="mb-4">
                                    <a href="./" className="hover:underline">Licensing</a>
                                </li>
                                <li className="mb-4">
                                    <a href="./" className="hover:underline">Terms &amp; Conditions</a>
                                </li>
                            </ul>
                        </div> */}

                        {/* <div>
                            <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">Download</h2>
                            <ul className="text-gray-500 dark:text-gray-400 font-medium">
                                <li className="mb-4">
                                    <a href="./" className="hover:underline">iOS</a>
                                </li>
                                <li className="mb-4">
                                    <a href="./" className="hover:underline">Android</a>
                                </li>
                                <li className="mb-4">
                                    <a href="./" className="hover:underline">Windows</a>
                                </li>
                                <li className="mb-4">
                                    <a href="./" className="hover:underline">MacOS</a>
                                </li>
                            </ul>
                        </div> */}


                    </div>



                    <div className="pl-4 pr-20 py-6 bg-sky-950 dark:bg-sky-950 md:flex md:items-center md:justify-between">
                        <span className="text-sm text-gray-100 dark:text-gray-300 sm:text-center">© 2024 <a href="/">MyWeb3Gift™</a>. All Rights Reserved.
                        </span>


                        <span className="text-sm text-gray-400 dark:text-gray-300 sm:text-center">  <Link to="/Imprint" className="hover:underline">Imprint</Link> | <Link to="/PrivacyPolicy" className="hover:underline">Privacy Policy</Link>
                        </span>

                        <div className="flex mt-4 space-x-5 sm:justify-center md:mt-0">
                            <a href="https://www.linkedin.com/company/web3gift" target="_blank" rel="noopener noreferrer"className="text-gray-100 hover:text-gray-900 dark:hover:text-white">
                                <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 22">
                                    <path d="M20.593 0H1.406A1.408 1.408 0 0 0 0 1.407v19.187c0 .779.629 1.406 1.406 1.406h19.187c.778 0 1.407-.627 1.407-1.406V1.407A1.408 1.408 0 0 0 20.593 0zM6.938 19.187H3.374V9.265h3.564v9.922H6.938zm-1.781-11.63a1.783 1.783 0 1 1-.001-3.566 1.783 1.783 0 0 1 .001 3.566zm14.375 11.63h-3.563v-5.235c0-1.253-.447-2.107-1.568-2.107-1.08 0-1.724.726-2.005 1.43-.104.254-.129.606-.129.961v5.951H8.28s.046-9.515 0-10.496h3.563v1.483c.473-.726 1.317-1.768 3.226-1.768 2.357 0 4.126 1.545 4.126 4.86v5.921h-.001z" fillRule="evenodd" />
                                </svg>

                                <span className="sr-only">LinkedIn page</span>
                            </a>
                            <a href="https://discord.gg/NmSz8yzN" ctarget="_blank" rel="noopener noreferrer"lassName="text-gray-100 hover:text-gray-900 dark:hover:text-white">
                                <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 21 16">
                                    <path d="M16.942 1.556a16.3 16.3 0 0 0-4.126-1.3 12.04 12.04 0 0 0-.529 1.1 15.175 15.175 0 0 0-4.573 0 11.585 11.585 0 0 0-.535-1.1 16.274 16.274 0 0 0-4.129 1.3A17.392 17.392 0 0 0 .182 13.218a15.785 15.785 0 0 0 4.963 2.521c.41-.564.773-1.16 1.084-1.785a10.63 10.63 0 0 1-1.706-.83c.143-.106.283-.217.418-.33a11.664 11.664 0 0 0 10.118 0c.137.113.277.224.418.33-.544.328-1.116.606-1.71.832a12.52 12.52 0 0 0 1.084 1.785 16.46 16.46 0 0 0 5.064-2.595 17.286 17.286 0 0 0-2.973-11.59ZM6.678 10.813a1.941 1.941 0 0 1-1.8-2.045 1.93 1.93 0 0 1 1.8-2.047 1.919 1.919 0 0 1 1.8 2.047 1.93 1.93 0 0 1-1.8 2.045Zm6.644 0a1.94 1.94 0 0 1-1.8-2.045 1.93 1.93 0 0 1 1.8-2.047 1.918 1.918 0 0 1 1.8 2.047 1.93 1.93 0 0 1-1.8 2.045Z" />
                                </svg>
                                <span className="sr-only">Discord community</span>
                            </a>
                            <a href="https://twitter.com/myweb3gift" target="_blank" rel="noopener noreferrer"className="text-gray-100 hover:text-gray-900 dark:hover:text-white">
                                <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 17">
                                    <path fillRule="evenodd" d="M20 1.892a8.178 8.178 0 0 1-2.355.635 4.074 4.074 0 0 0 1.8-2.235 8.344 8.344 0 0 1-2.605.98A4.13 4.13 0 0 0 13.85 0a4.068 4.068 0 0 0-4.1 4.038 4 4 0 0 0 .105.919A11.705 11.705 0 0 1 1.4.734a4.006 4.006 0 0 0 1.268 5.392 4.165 4.165 0 0 1-1.859-.5v.05A4.057 4.057 0 0 0 4.1 9.635a4.19 4.19 0 0 1-1.856.07 4.108 4.108 0 0 0 3.831 2.807A8.36 8.36 0 0 1 0 14.184 11.732 11.732 0 0 0 6.291 16 11.502 11.502 0 0 0 17.964 4.5c0-.177 0-.35-.012-.523A8.143 8.143 0 0 0 20 1.892Z" clipRule="evenodd" />
                                </svg>
                                <span className="sr-only">Twitter page</span>
                            </a>
                            <a href="https://github.com/myweb3gift" target="_blank" rel="noopener noreferrer"className="text-gray-100 hover:text-gray-900 dark:hover:text-white">
                                <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path fillRule="evenodd" d="M10 .333A9.911 9.911 0 0 0 6.866 19.65c.5.092.678-.215.678-.477 0-.237-.01-1.017-.014-1.845-2.757.6-3.338-1.169-3.338-1.169a2.627 2.627 0 0 0-1.1-1.451c-.9-.615.07-.6.07-.6a2.084 2.084 0 0 1 1.518 1.021 2.11 2.11 0 0 0 2.884.823c.044-.503.268-.973.63-1.325-2.2-.25-4.516-1.1-4.516-4.9A3.832 3.832 0 0 1 4.7 7.068a3.56 3.56 0 0 1 .095-2.623s.832-.266 2.726 1.016a9.409 9.409 0 0 1 4.962 0c1.89-1.282 2.717-1.016 2.717-1.016.366.83.402 1.768.1 2.623a3.827 3.827 0 0 1 1.02 2.659c0 3.807-2.319 4.644-4.525 4.889a2.366 2.366 0 0 1 .673 1.834c0 1.326-.012 2.394-.012 2.72 0 .263.18.572.681.475A9.911 9.911 0 0 0 10 .333Z" clipRule="evenodd" />
                                </svg>
                                <span className="sr-only">GitHub account</span>
                            </a>
                            {/* <a href="./" className="text-gray-400 hover:text-gray-900 dark:hover:text-white">
                                <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path fillRule="evenodd" d="M10 0a10 10 0 1 0 10 10A10.009 10.009 0 0 0 10 0Zm6.613 4.614a8.523 8.523 0 0 1 1.93 5.32 20.094 20.094 0 0 0-5.949-.274c-.059-.149-.122-.292-.184-.441a23.879 23.879 0 0 0-.566-1.239 11.41 11.41 0 0 0 4.769-3.366ZM8 1.707a8.821 8.821 0 0 1 2-.238 8.5 8.5 0 0 1 5.664 2.152 9.608 9.608 0 0 1-4.476 3.087A45.758 45.758 0 0 0 8 1.707ZM1.642 8.262a8.57 8.57 0 0 1 4.73-5.981A53.998 53.998 0 0 1 9.54 7.222a32.078 32.078 0 0 1-7.9 1.04h.002Zm2.01 7.46a8.51 8.51 0 0 1-2.2-5.707v-.262a31.64 31.64 0 0 0 8.777-1.219c.243.477.477.964.692 1.449-.114.032-.227.067-.336.1a13.569 13.569 0 0 0-6.942 5.636l.009.003ZM10 18.556a8.508 8.508 0 0 1-5.243-1.8 11.717 11.717 0 0 1 6.7-5.332.509.509 0 0 1 .055-.02 35.65 35.65 0 0 1 1.819 6.476 8.476 8.476 0 0 1-3.331.676Zm4.772-1.462A37.232 37.232 0 0 0 13.113 11a12.513 12.513 0 0 1 5.321.364 8.56 8.56 0 0 1-3.66 5.73h-.002Z" clipRule="evenodd" />
                                </svg>
                                <span className="sr-only">Dribbble account</span>
                            </a> */}
                        </div>
                    </div>
                </motion.div>
            </motion.footer>

</AnimatePresence>
        </div>
    )
}

export default Footer