import { useState } from "react";
import { useSignIn } from "@clerk/clerk-react";
 
export default function SignInForm() {
  const { isLoaded, signIn, setActive } = useSignIn();
  const [emailAddress, setEmailAddress] = useState("");
  const [password, setPassword] = useState("");
  
  // Handle the submission of the sign-in form
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isLoaded) {
      return;
    }
 
    // Start the sign-in process using the email and password provided
    try {
      const completeSignIn = await signIn.create({
        identifier: emailAddress,
        password,
      });
 
      if (completeSignIn.status !== 'complete') {
        // The status can also be `needs_factor_on', 'needs_factor_two', or 'needs_identifier'
        // Please see https://clerk.com/docs/references/react/use-sign-in#result-status for  more information
        // console.log(JSON.stringify(completeSignIn, null, 2));
      }
 
      if (completeSignIn.status === "complete") {
        // If complete, user exists and provided password match -- set session active
        await setActive({ session: completeSignIn.createdSessionId });
        // redirect the user how you see fit.
      }
    } catch (err) { 
      // This can return an array of errors.
      // See https://clerk.com/docs/custom-flows/error-handling to learn about error handling
      console.error(JSON.stringify(err, null, 2));
    }
  };
  const signInWith = (strategy) => {
    return signIn.authenticateWithRedirect({
      strategy,
      redirectUrl: "/sso-callback",
      redirectUrlComplete: "/",
    });
  };
 
  return (
<div class="container mx-auto mt-16 max-w-md px-4">
  <h1 class="mb-8 text-2xl font-bold">Sign In</h1>
  <form>
    <div class="mb-4">
      <label htmlFor="email" class="mb-1 block text-gray-600">Email</label>
      <input onChange={(e) => setEmailAddress(e.target.value)} id="email" name="email" type="email" class="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 py-2 px-4 leading-tight text-gray-600 focus:border-indigo-500 focus:bg-white focus:outline-none" />
    </div>
    <div class="mb-4">
      <label htmlFor="password" class="mb-1 block text-gray-600">Password</label>
      <input onChange={(e) => setPassword(e.target.value)} id="password" name="password" type="password" class="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 py-2 px-4 leading-tight text-gray-600 focus:border-indigo-500 focus:bg-white focus:outline-none" />
    </div>
    <button onClick={handleSubmit} class="focus:shadow-outline-purple inline-flex items-center justify-center rounded-md border border-transparent bg-purple-600 px-4 py-2 font-semibold text-white shadow-sm transition-colors duration-150 focus:outline-none active:bg-purple-700">
      Sign In
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="-ml-1 mr-2 h-4 w-4">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
      </svg>
    </button>
  </form>
  <div class="mt-6">
    <button onClick={() => signInWith("oauth_google")} class="focus:shadow-outline-red inline-flex items-center justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 font-semibold text-white shadow-sm transition-colors duration-150 focus:outline-none active:bg-red-700">
      <img src="/images/google-icon.png" alt="Google Icon" class="mr-2 -ml-1 h-5 w-5" />
      Sign in with Google
    </button>
  </div>
  <div class="mt-3">
    <button onClick={() => signInWith("oauth_apple")} class="focus:shadow-outline-gray inline-flex items-center justify-center rounded-md border border-transparent bg-gray-600 px-4 py-2 font-semibold text-white shadow-sm transition-colors duration-150 focus:outline-none active:bg-gray-700">
      <img src="/images/apple-icon.png" alt="Apple Icon" class="mr-2 -ml-1 h-5 w-5" />
      Sign in with Apple
    </button>
  </div>
</div>
  );
}