import React from "react";
import styles from "../style/door14.module.css";
import Accordion from "./Acordition";
import {motion} from "framer-motion";

import { Mask10, Mask11, Mask12, Mask13, Mask14, Mask15, Mask16, Mask17, Mask9 } from "../../DoorsImages/Doorimg14";

export default function SDoor() {
  return (
    <div className={styles.section2}>
       <motion.div 
        initial={{opacity:0}}
        whileInView={{ opacity:1}}
        transition={{
          duration:3
        }}
        viewport={{once:true}}
       className={styles.nftboxheading2}>
      <h1 className="text-white text-center font-bold text-3xl tracking-wide">
      Here are some ways charity works on blockchain and DeFi:
        </h1>
      </motion.div>
      <div className={styles.imgboxcard}>
        <div className={styles.imgboxcard1}>
        <motion.div 
        initial={{x: "-15vw",opacity:0}}
        whileInView={{ x: 0,opacity:1}}
        transition={{
          duration:1
        }}
        viewport={{once:true}}
           className={styles.cards}>
            <div className={styles.imuu}>
              <img className={styles.padlock} src={Mask9} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Transparency and Traceability" 
                bold1="Smart Contracts:" bold2="Immutable Ledger:"
                content1=" Blockchain allows the creation of smart contracts, self-executing contracts with the terms of the agreement directly written into code. Charities can use smart contracts to automate and ensure transparency in the donation process."
                content2=" Transactions on the blockchain are recorded on an immutable ledger, providing an unchangeable history of all transactions. Donors can trace their donations, ensuring that funds are used as intended"
              ></Accordion>
            </div>
          </motion.div>
          <motion.div 
        initial={{x: "15vw",opacity:0}}
        whileInView={{ x: 0,opacity:1}}
        transition={{
          duration:1
        }}
        viewport={{once:true}}
           className={styles.cards}>
            <div className={styles.imuu}>
              <img className={styles.baht} alt="img1" src={Mask10} />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Direct Peer-to-Peer Transactions"
                bold1="Elimination of Intermediaries:"
                content1="Blockchain eliminates the need for traditional intermediaries, such as banks or payment processors. Donors can send funds directly to the charity without the need for a third party.
"
 ></Accordion>
            </div>
          </motion.div>
          </div>
          </div>
          <div className={styles.imgboxcard}>
        <div className={styles.imgboxcard1}>
          <motion.div 
        initial={{x: "-15vw",opacity:0}}
        whileInView={{ x: 0,opacity:1}}
        transition={{
          duration:1
        }}
        viewport={{once:true}}
           className={styles.cards}>
            <div className={styles.imuu}>
              <img className={styles.mask1} src={Mask11} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Tokenization of Assets" bold1="Tokenized Donations:"
content1=" Charities can tokenize assets, turning them into digital tokens on the blockchain. This allows for fractional ownership, making it easier for donors to contribute even small amounts. Tokens can represent various assets, including money, real estate, or even time.
">

</Accordion>
            </div>
          </motion.div>
          <motion.div 
        initial={{x: "15vw",opacity:0}}
        whileInView={{ x: 0,opacity:1}}
        transition={{
          duration:1
        }}
        viewport={{once:true}}
           className={styles.cards}>
            <div className={styles.imuu}>
              <img className={styles.mask2} src={Mask12} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Decentralized Finance (DeFi)"
                bold1="Decentralized Fundraising:"
                content1="DeFi platforms enable decentralized fundraising, allowing projects and charities to raise funds without relying on centralized institutions. This can include initial token offerings (ITOs) or decentralized autonomous organization (DAO) governance models."
                ></Accordion>
            </div>
          </motion.div>
          </div>
          </div>
          <div className={styles.imgboxcard}>
        <div className={styles.imgboxcard1}>
          <motion.div 
        initial={{x: "-15vw",opacity:0}}
        whileInView={{ x: 0,opacity:1}}
        transition={{
          duration:1
        }}
        viewport={{once:true}}
           className={styles.cards}>
            <div className={styles.imuu}>
              <img className={styles.curency} src={Mask13} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Cross-Border Transactions" bold1="Global Accessibility:" 
                content1=" Blockchain facilitates cross-border transactions without the need for traditional banking systems. This makes it easier for charities to receive donations from a global audience and provides financial inclusion for regions with limited access to traditional banking.
"
></Accordion>
            </div>
          </motion.div>
          <motion.div 
        initial={{x: "15vw",opacity:0}}
        whileInView={{ x: 0,opacity:1}}
        transition={{
          duration:1
        }}
        viewport={{once:true}}
           className={styles.cards}>
            <div className={styles.imuu}>
              <img className={styles.mask1} src={Mask14} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Proof of Impact" bold1="Blockchain Records:" content1=" Charities can use blockchain to record and verify the impact of their initiatives. This can include documenting the use of funds, tracking progress on projects, and providing real-time updates to donors.
" 
         ></Accordion>
            </div>
          </motion.div>
          </div>
          </div>
          <div className={styles.imgboxcard}>
        <div className={styles.imgboxcard1}>
          <motion.div 
        initial={{x: "-15vw",opacity:0}}
        whileInView={{ x: 0,opacity:1}}
        transition={{
          duration:1
        }}
        viewport={{once:true}}
           className={styles.cards}>
            <div className={styles.imuu}>
              <img className={styles.mask1} src={Mask15} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Incentives for Donors"
                bold1="Token Incentives:"
                content1=" Some blockchain-based charity platforms offer token incentives to donors. These tokens may represent a share of the charity project or provide other benefits, creating a more engaging and rewarding experience for contributors.
"
 ></Accordion>
            </div>
          </motion.div>
          <motion.div 
        initial={{x: "15vw",opacity:0}}
        whileInView={{ x: 0,opacity:1}}
        transition={{
          duration:1
        }}
        viewport={{once:true}}
           className={styles.cards}>
            <div className={styles.imuu}>
              <img className={styles.mask1} src={Mask15} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Decentralized Governance:"
                bold1="DAOs:"
               content1=" Charities can implement decentralized autonomous organizations (DAOs) for governance. Token holders can participate in decision-making processes, such as voting on which projects to fund or how to allocate resources.
"
 ></Accordion>
            </div>
          </motion.div>
        </div>
      </div>
      <motion.div 
        initial={{x: "-15vw",opacity:0}}
        whileInView={{ x: 0,opacity:1}}
        transition={{
          duration:1
        }}
        viewport={{once:true}}
       className={styles.nftboxheading2}>
      <p className="text-white pt-5 pl-3 whitespace-pre-line text-lg tracking-widest">
      It's important to note that while blockchain and DeFi bring several advantages to the charity sector, they also pose challenges, including regulatory concerns, security issues, and the need for broader adoption. As the technology evolves, we can expect more innovative solutions to emerge in the intersection of charity, blockchain, and DeFi.
      </p>
      </motion.div>
    </div>
  );
}
