import React from "react";
import styles from "../style/door13.module.css";
import {
  BitSymbol,
  ImageProcessing,
  Padlock4,
} from "../../DoorsImages/Doorimg13";
import Accordion from "./Acordition";
import { motion } from "framer-motion";

export default function F4Door() {
  return (
    <div className={styles.section4}>
      <motion.div
        initial={{ y: "-100%", opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.6 }}
        viewport={{ once: true }}
        className={styles.oracleboxheading}
      >
        <h1 className="italic text-white tracking-wider text-2xl mt-2 text-center font-light">
          People play crypto games for various reasons
        </h1>
      </motion.div>
      <div className={styles.imgboxcard}>
        <div className={styles.imgboxcard1}>
          <motion.div
            initial={{ x: "-15vw", opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
            className={styles.cards}
          >
            <div className={styles.imuu}>
              <img className={styles.padlock44} src={Padlock4} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Ownership and Value "
                content="True ownership of in-game assets and the ability to trade them for real value is a significant draw."
              ></Accordion>
            </div>
          </motion.div>
          <motion.div
            initial={{ x: "15vw", opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
            className={styles.cards}
          >
            <div className={styles.imuu}>
              <img
                className={styles.padlock4}
                src={ImageProcessing}
                alt="img1"
              />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Play-to-Earn Opportunities"
                content="The potential to earn cryptocurrency by playing the game appeals to those looking for a financial incentive."
              ></Accordion>
            </div>
          </motion.div>
        </div>
      </div>
      <div className={styles.imgboxcard}>
        <div className={styles.imgboxcard2}>
          <motion.div
            initial={{ x: "-15vw", opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
            className={styles.cards}
          >
            <div className={styles.imuu}>
              <img className={styles.bitcoin} src={BitSymbol} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Community and Decentralization"
                content="Crypto games often foster strong communities, and the decentralized nature of these games can be attractive to those who appreciate a more democratic and community-driven gaming experience."
              ></Accordion>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
}
