import React from "react";
import styles from "../style/door14.module.css";
import { motion } from "framer-motion";

import { Dao } from "../../DoorsImages/Doorimg18";

export default function FDoor() {
  return (
    <>
      <motion.div
        className={styles.section11}
        initial="hidden"
        animate="visible"
        //  variants={fadeInVariants}
        transition={{ duration: 1 }}
      >
        <div className={styles.walletboxheading}>
          <motion.h1
            initial={{
              x: "-15vw",opacity:0
            }}
            whileInView={{ x: 0,opacity:1}}
            transition={{
              duration:0.6
            }}
            viewport={{once:true}}
           className="text-white text-3xl font-bold pt-5 "
           >
            Decentralized Autonomous Organization
            </motion.h1>
            <motion.p 
            initial={{
              x: "-15vw",opacity:0
            }}
            whileInView={{ x: 0,opacity:1}}
            transition={{
              duration:0.6
            }}
            viewport={{once:true}}
            className="text-white mt-6 text-lg tracking-widest">
            With Web3 comes new forms of organizing projects. Instead of adhering to a classical organizational structure, these projects are organized differently. One example is the Decentralized Autonomous Organization, abbreviated as DAO.
              <br />
              {/* <br /> */}
              It is a type of organization represented by rules encoded as a computer program that is transparent, controlled by organization members, and not influenced by a central government. DAOs are typically implemented on blockchain platforms, such as Ethereum, which allows for a trustless and decentralized execution of smart contracts.
            
          </motion.p>
        </div>
        <motion.div
        className={styles.walletimgbox}
          initial={{ x: "15vw",opacity:0}}
          whileInView={{ x: 0, opacity:1}}
          whileHover={{
            scale: 0.9,
            transition: { type: "spring", duration: 1 },
          }}

          exit={{
            x: "-60vw",
            scale: [1, 0],
            transition: { duration: 1 },
          }}
          transition={{duration:0.6}}
          viewport={{once:true}}
        >
          <img className={styles.web3img} src={Dao} alt="img" />

        </motion.div>
        
      </motion.div>
    </>
  );
}
