import React, { useState } from "react";
// import {Link} from "react-router-dom";
import styles from "../style/card.module.css";
import ReadMore from "./Readmore";
import { Bitcoin } from "../../DoorsImages/Doorimg1";
import { AnimatePresence, motion } from "framer-motion";
// import gsap from "gsap";

export default function Card({}) {
   //These keys are necessary to firstly derive an address for receiving funds (the wallet address) and secondly to send funds to another wallet address.
// This is accomplished by signing a transaction with the private key and making the public key available to others (e.g., miners, stakers, or network participants) to verify that the sender has the proper private key. It's important never to disclose the keys to anyone who should not have access to the digital assets.

  const longText = `  
If you are interested in the technology behind a crypto wallet then note that all assets and transactions are stored on the blockchain (that’s the key purpose of a blockchain) and, strictly speaking, not in your wallet. However, each asset and transaction is matched to a wallet. Modern software wallets offer a nice visual and convenient representation of all current assets and past transactions that are matched to it. Technically speaking a wallet is combination of a public key and a private key (essentially numbers).    
 `;
 const longText2 = `  
 “A public key allows you to receive cryptocurrency transactions. It’s a cryptographic code that’s paired to a private key. While anyone can send transactions to the public key, you need the private key to “unlock” them and prove that you are the owner of the cryptocurrency received in the transaction. The public key that can receive transactions is usually an address, which is simply a shortened form of your public key.” (Source Gemini Cryptopedia). 
  `;
  return (
    <>
    <AnimatePresence>
      <motion.div
        className={styles.card1}
        initial="hidden"
        animate="visible"
        transition={{ duration: 0.5 }}
      >
        <div className={styles.walletboxheading}>
          <motion.div
            layout
            initial={{
              x: "-15vw",
              transition: { type: "spring", duration: 2.5 },
            }}
            whileInView={{ x: 0, transition: { type: "spring", duration: 2.5 } }}
            transition={{
              opacity: { ease: "linear" },
              type: "spring",
              layout: { duration: 1 },
            }}
        viewport={{once:true}}
          >
            <h1 className="text-white text-3xl font-bold">Wallet</h1>
            <p className="text-white mt-3 text-lg">
            Hello and welcome to the start of the Web3 ecosystem. We are delighted that someone (or yourself) has given you this gift. We believe that actions speak louder than words; therefore, we first get our hands dirty, and afterwards, we will show you how it works.
<br/>
              A wallet is the most essential thing to have to own digital
              assets. A crypto wallet helps you to manage your crypto funds and tokens e.g. similar to a Paypal account or Apple Pay account. As such with a crypto wallet you can receive and send crypto tokens but you can also trigger other transactions that are only available on a blockchain such as staking or minting NFTs just to name a few. (Dont worry if you are not familiar with these terms, you will learn them in this program). 
               {/* However, the wallet does not hold the digital asset, like
              a physical wallet holds a $10 bill; instead, it contains a set of
              private and public keys (essentially numbers). */}
            </p>

            <ReadMore text={longText} text2={longText2} maxLength2={0} maxLength={138} />
          </motion.div>
        </div>
        <motion.div
          className={styles.walletimgbox}
          initial={{ x: "15vw", transition: { type: "spring", duration: 2.5 } }}
          whileInView={{ x: 0, transition: { type: "spring", duration: 2.5 } }}
        viewport={{once:true}}
        >
          <img src={Bitcoin} alt="img" />
        </motion.div>
      </motion.div>
      </AnimatePresence>
    </>
  );
}
