import React, { useEffect, useState } from "react";
import Nav01 from "../Components/Nav01";
import { Footer } from "../sections_landing";
import { Link } from "react-router-dom";
import { useUser } from "@clerk/clerk-react";
import { collection, getDocs, getFirestore } from "firebase/firestore";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";

import {
  Imogi1,
  Imogi2,
  Imogi3,
  Imogi4,
  Imogi5,
} from "../assets/Images/RandomImogies/index.js";
import { JustBackground2 } from "../assets/Images/AfterLoginPic/index.js";
import LoadingSpinner from "../sections_landing/Loader";
import "../sections_landing/Styles/main.css";

const randomImages = [Imogi1, Imogi2, Imogi3, Imogi4, Imogi5];

const TransferedGifts = () => {
  const { user } = useUser();
  const senderUserId = user ? user.id : null;
  const [gifts, setGifts] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchUserGifts = async (senderUserId) => {
    try {
      const db = getFirestore();

      // Reference to the user's gifts collection
      const userGiftsRef = collection(db, `users/${senderUserId}/gifts`);

      // Fetch documents from the gifts subcollection
      const querySnapshot = await getDocs(userGiftsRef);

      const giftList = [];
      querySnapshot.forEach((doc) => {
        giftList.push({ id: doc.id, ...doc.data() });
      });

      setGifts(giftList);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching user gifts:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    // Call the function to fetch user gifts
    fetchUserGifts(senderUserId);
  }, [senderUserId]);

  return (
    <>
      <ScrollLink to="sectionsss" smooth={true} duration={1000}></ScrollLink>
      <div className="sectionsss unselectable" style={{ overflowX: "hidden" }}>
        <Nav01 />
        <div className="bg-sky-950 h-[90px] w-screen"></div>
        {loading ? (
          <div className="text-center">
            <LoadingSpinner />
          </div>
        ) : (
          <div>
            <div className="min-h-screen">
              <div
                className="relative mx-auto py-10 flex min-h-screen bg-fixed flex-col items-center gap-9 rounded-3xl bg-cover bg-center"
                style={{
                  backgroundImage: `url(${JustBackground2})`, // Use the imported image
                }}
              >
                <div className="min-h-screen">
                  {gifts.length === 0 ? (
                    <div className="flex flex-1 justify-center align-middle items-center">
                      <div>
                        <div className="text-purple-600 font-semibold text-4xl my-8 text-center">
                          🚀 Welcome to the Crypto Gift Universe, Explorer! 🌌✨
                        </div>
                        <div className="text-gray-500 font-light text-lg my-4 text-center">
                          🌟 Buckle up! Your gift spaceship is ready for
                          liftoff. Time to embark on a journey to share
                          interstellar joy using the power of crypto gifting!
                          🎁🚀
                        </div>
                        <div className="text-teal-500 font-bold text-lg my-6 text-center">
                          🌈 Dive into the Wonders of the Crypto Gift Universe:
                        </div>
                        <div className="flex flex-row gap-4 justify-center">
                          {/* <div className="bg-pink-500 text-white font-bold py-2 px-4 rounded-full cursor-pointer hover:bg-pink-600">
🎉 Explore Exciting Crypto Gifts
</div> */}

                          <Link to="/gift">
                            <button className="bg-gradient-to-r from-blue-500 to-blue-600 text-white font-bold py-2 px-4 rounded-full cursor-pointer hover:from-blue-600 hover:to-blue-700">
                              🚀 Send Your First Crypto Gift
                            </button>
                          </Link>
                        </div>
                        <div className="text-gray-500 font-light text-lg my-6 text-center">
                          🌟 Need inspiration? Uncover unique crypto gifts and
                          begin your quest to spread joy across the Crypto Gift
                          Universe. 🌈✨
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className="absolute top-0 left-2 font-extrabold text-3xl text-gray-800 ">
                        Sent Gifts : {gifts.length}
                      </div>
                      <div className="flex flex-row gap-28 my-20 flex-wrap justify-center align-middle items-center max-md:flex-col">
                        {gifts.map((gift) => (
                          <TransferedGiftsCard key={gift.id} gift={gift} />
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="h-full">
</div>

        <Footer />
      </div>
    </>
  );
};

const TransferedGiftsCard = ({ gift }) => {
  // Assuming that the 'gift' object has properties like 'date', 'greeting', 'receiverEmail', 'receiverName', 'senderEmail', and 'senderName'
  // const { date, greeting, receiverEmail, receiverName, senderEmail, senderName , image ,senderImage } = gift;
  const {
    greeting,
    receiverName,
    image,
    senderImage,
    cardColor,
    cardFont,
    date,
    giftDetails,
    receiverEmail,
    senderEmail,
    senderName,
    id,
  } = gift;

  //console.log("Here We are getting gift details", gift);

  //console.log(senderImage);
  // Split the greeting into an array of words
  const words = greeting.split(" ");
  // Take the first 5 words and join them back into a string
  const truncatedGreeting = words.slice(0, 4).join(" ");

  const [SenderImogi, setSenderImogi] = useState(null);

  useEffect(() => {
    // Function to pick a random image
    const getRandomImogi = () => {
      // Create a copy of the array to avoid modifying the original array
      const remainingImages = [...randomImages];

      // Get a random index from the remainingImages array
      const randomIndex = Math.floor(Math.random() * remainingImages.length);

      // Remove the selected image from the remainingImages array
      const selectedImogi = remainingImages.splice(randomIndex, 1)[0];

      // Update the randomImages array (optional, depending on your use case)
      // randomImages = remainingImages;

      return selectedImogi;
    };

    // Set the random image to SenderImogi state
    setSenderImogi(getRandomImogi());
  }, []);

  return (
    <div className="border-1 h-[500px] bg-[#f5d8c8] max-w-[400px] max-sm:max-w-[90vw] overflow-hidden rounded-3xl border border-gray-100 shadow-2xl hover:duration-300 hover:cursor-pointer hover:ease-in hover:scale-105 transition-transform">
      <div className="flex h-full  py-2 flex-col items-center justify-start overflow-hidden">
        <div className="h-[25%] w-full flex justify-center gap-10 items-center px-4">
          {/* <div className='h-full flex items-center'>
        {SenderImogi && (
          <img src={SenderImogi} className="h-20 w-20 rounded-full " alt="" />
        )}
        </div> */}
          <div className="h-full text-center flex items-center text-2xl capitalize font-bold text-gray-600">
            <span>{receiverName}</span>
          </div>
        </div>
        <div className="h-[45%] w-full  py-2 bg-[#f5d8c8] flex justify-center items-center">
          {image && (
            <img
              src={image}
              className=" h-full w-[400px] rounded-3xl  object-contain"
              alt="gift image"
            />
          )}
        </div>
        <div className="h-[30%] w-full flex gap-4 flex-col justify-center items-center">
          <span className="font-thin text-xl text-gray-700 text-center leading-tight">
            {truncatedGreeting}....
          </span>
          <Link to={`/Receiver_giftid/${gift.id}`}>
            <button className="rounded-full bg-gradient-to-r from-yellow-300 to-yellow-500 px-10 py-3 text-xl font-bold text-white hover:from-yellow-400 hover:to-yellow-600 transition duration-300 shadow-lg">
              OPEN
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default TransferedGifts;
