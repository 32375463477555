import React from "react";
import styles from "../style/door14.module.css";
import { motion } from "framer-motion";
import { Charity } from "../../DoorsImages/Doorimg23";

export default function FDoor() {
  return (
    <>
      <motion.div
        className={styles.section1}
        initial="hidden"
        animate="visible"
        transition={{ duration: 1 }}
      >
        <motion.div 
        initial={{x: "-15vw",opacity:0}}
        whileInView={{ x: 0,opacity:1}}
        transition={{
          duration:1
        }}
        viewport={{once:true}}
         className={styles.walletboxheading}>
            <h1 className="text-white text-4xl font-bold pt-5 ">
            Charity 
            </h1>
            <p className="text-white mt-6 text-xl tracking-widest">
            Charity on the blockchain and within the decentralized finance (DeFi) space leverages the transparency, security, and efficiency of blockchain technology to enhance the donation and distribution processes. 
                        </p>
          </motion.div>
          <motion.div 
        initial={{x: "15vw",opacity:0}}
        whileInView={{ x: 0,opacity:1}}
        transition={{duration:1}}
        viewport={{once:true}}
        className={styles.walletimgbox}
          
        >
          <img src={Charity} alt="img" />   
        </motion.div>
        
      </motion.div>
    </>
  );
}
