import React from "react";
import styles from "../style/door14.module.css";
import Accordion from "./Acordition";
import {motion} from "framer-motion";

import { Mask10, Mask11, Mask12, Mask13, Mask14, Mask15, Mask16, Mask17, Mask9 } from "../../DoorsImages/Doorimg14";
import { Blockchainn, Centrale, Centralize1, DeCentrale, Intermediaries, Interoper, Interoperability, Lending, Openness, Peer, Regulation, Regulation1, Risks, Stablecoins } from "../../DoorsImages/Doorimg15";

export default function SDoor() {
  return (
    <div className={styles.section2}>
       <motion.div 
        initial={{y: "-15vh",opacity:0}}
        whileInView={{ y: 0,opacity:1}}
        transition={{
          duration:1
        }}
        viewport={{once:true}}
       className={styles.nftboxheading2}>
      <h1 className="text-white text-center font-bold text-3xl tracking-wide">
      Key features of DeFi include:
        </h1>
      </motion.div>
      <div className={styles.imgboxcard}>
        <div className={styles.imgboxcard1}>
        <motion.div 
        initial={{x: "-15vw",opacity:0}}
        whileInView={{ x: 0,opacity:1}}
        transition={{
          duration:1
        }}
        viewport={{once:true}}
           className={styles.cards}>
            <div className={styles.imuu}>
              <img className={styles.padlock} src={Blockchainn} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Blockchain Technology" content="DeFi applications are typically built on blockchain platforms like Ethereum. Smart contracts, self-executing contracts with the terms of the agreement directly written into code, play a crucial role in automating and facilitating various financial transactions."
              ></Accordion>
            </div>
          </motion.div>
          <motion.div 
        initial={{x: "15vw",opacity:0}}
        whileInView={{ x: 0,opacity:1}}
        transition={{
          duration:1
        }}
        viewport={{once:true}}
           className={styles.cards}>
            <div className={styles.imuu}>
              <img className={styles.baht} alt="img1" src={Openness} />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Open and Permissionless" content="DeFi is designed to be open and accessible to anyone with an internet connection. There are no gatekeepers, and users can participate without needing permission from traditional financial institutions.
" ></Accordion>
            </div>
          </motion.div>
          </div>
          </div>
          <div className={styles.imgboxcard}>
        <div className={styles.imgboxcard1}>
          <motion.div 
        initial={{x: "-15vw",opacity:0}}
        whileInView={{ x: 0,opacity:1}}
        transition={{
          duration:1
        }}
        viewport={{once:true}}
           className={styles.cards}>
            <div className={styles.imuu}>
              <img className={styles.mask1} src={DeCentrale} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Interoperability" content="Many DeFi protocols are designed to be interoperable, meaning they can interact with each other seamlessly. This allows users to leverage various services and products within the DeFi ecosystem." ></Accordion>
            </div>
          </motion.div>
          <motion.div 
        initial={{x: "15vw",opacity:0}}
        whileInView={{ x: 0,opacity:1}}
        transition={{
          duration:1
        }}
        viewport={{once:true}}
           className={styles.cards}>
            <div className={styles.imuu}>
              <img className={styles.mask2} src={Interoperability} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Tokenization" content="DeFi often involves the use of tokens, which represent ownership or participation in a particular network or protocol. Tokens can represent assets like cryptocurrencies, stablecoins, or even real-world assets like real estate."    ></Accordion>
            </div>
          </motion.div>
          </div>
          </div>
          <div className={styles.imgboxcard}>
        <div className={styles.imgboxcard1}>
          <motion.div 
        initial={{x: "-15vw",opacity:0}}
        whileInView={{ x: 0,opacity:1}}
        transition={{
          duration:1
        }}
        viewport={{once:true}}
           className={styles.cards}>
            <div className={styles.imuu}>
              <img className={styles.curency} src={Interoper} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Liquidity Pools and Decentralized Exchanges (DEXs)" content="DeFi platforms enable users to trade assets directly with one another through decentralized exchanges, removing the need for a centralized intermediary. Liquidity pools, where users can contribute their assets to facilitate trading, are a common feature.
"></Accordion>
            </div>
          </motion.div>
          <motion.div 
        initial={{x: "15vw",opacity:0}}
        whileInView={{ x: 0,opacity:1}}
        transition={{
          duration:1
        }}
        viewport={{once:true}}
           className={styles.cards}>
            <div className={styles.imuu}>
              <img className={styles.mask1} src={Lending} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Lending and Borrowing " content="DeFi platforms allow users to lend their assets to others in exchange for interest or borrow assets by providing collateral. This is often done through decentralized lending protocols."
         ></Accordion>
            </div>
          </motion.div>
          </div>
          </div>
          <div className={styles.imgboxcard}>
        <div className={styles.imgboxcard2}>
          <motion.div 
        initial={{x: "-15vw",opacity:0}}
        whileInView={{ x: 0,opacity:1}}
        transition={{
          duration:1
        }}
        viewport={{once:true}}
           className={styles.cards}>
            <div className={styles.imuu}>
              <img className={styles.mask1} src={Stablecoins} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Stablecoins" content="Stablecoins, which are cryptocurrencies pegged to the value of traditional fiat currencies, play a crucial role in many DeFi applications. They provide stability to users who want to avoid the volatility associated with other cryptocurrencies.
" ></Accordion>
            </div>
          </motion.div>  
        </div>
      </div>
      <motion.div 
        initial={{x: "-15vw",opacity:0}}
        whileInView={{ x: 0,opacity:1}}
        transition={{
          duration:1
        }}
        viewport={{once:true}}
       className={styles.nftboxheading2}>
      <p className="text-white pt-5 pl-3 whitespace-pre-line text-lg tracking-widest">
      While DeFi has brought about innovation and new opportunities, it's important to note that it also comes with risks and challenges, such as smart contract vulnerabilities, regulatory uncertainties, and market volatility. Users should exercise caution and conduct thorough research before participating in the DeFi space.
      </p>
      </motion.div>
    </div>
  );
}
