import React from 'react'
import styles from "../style/door11.module.css";
import { motion} from "framer-motion";
import Card from './Card';

export default function Spart2(){
  return (
    <div>
    <motion.div className={styles.sec2part}>
    <motion.div initial={{ opacity:0}}
            whileInView={{ opacity:1 }}
            transition={{duration:2}}
             viewport={{once:true}} className={styles.nfth1}>
       <h1 className="font-medium text-center text-3xl tracking-wide">
       Disadvantages of Stablecoins
             </h1>
     </motion.div>
     <div className={styles.doorcardss}>
    <div className={styles.door11cards}>
     <Card title="Centralization Risk" 
     text="Fiat-collateralized stablecoins may involve a central authority holding the reserve, introducing counterparty risk. Crypto-collateralized stablecoins can be susceptible to price fluctuations in the collateral assets."
     />
     <Card title="Regulatory Scrutiny "  text="Stablecoins, especially those pegged to fiat currencies, may face regulatory challenges and scrutiny due to concerns about their backing and compliance with financial regulations."/>
    </div>
    <div className={styles.door11cards2}>
     <Card title="Smart Contract Risks " text="Algorithmic stablecoins rely heavily on smart contracts, and vulnerabilities or bugs in these contracts can lead to issues such as price instability or loss of funds."/>
     <Card title="Market Adoption" text="While stablecoins are gaining popularity, widespread adoption is necessary for them to fulfill their potential. If they are not widely accepted, their utility may be limited." />
    </div>
   </div>
   </motion.div>
 
 </div>
  )
}
