import Nav01 from '../Components/Nav01'
import { FaqSection, Footer} from '../sections_landing'


import React from 'react'

const FAQS = () => {
  return (
    <div>
<Nav01/>
{/* <div className="bg-sky-900 h-[90px] w-full"></div> */}
<FaqSection/>
<Footer/>

    </div>
  )
}

export default FAQS