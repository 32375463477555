import React from 'react'
import styles from "../style/door7.module.css";
import Card1 from './Card1';
import { Anonymous2, Bitcoin3, Blockchain3, Business, Currency, Database, Decentralize, Padlock3, Participants, Transparency } from '../../DoorsImages/Doorimg7';
import Card2 from './Card2';
import Card3 from './Card3';

export default function SDoor() {

  return (
    <div className={styles.section2}>
      <Card1 title="Decentralization" img={Decentralize} para="Blockchains operate on a network of computers (nodes) without a central authority, making them decentralized. This enhances security and reduces the risk of a single point of failure."/>
      <Card3 title="Participants in a blockchain
network" img={Participants} para=
{`Nodes: Nodes are individual computers or devices that are part of the blockchain network. They maintain a copy of the entire blockchain ledger and participate in the validation and verification of transactions.
More Miners: In proof-of-work blockchain networks like Bitcoin, miners are nodes that compete to solve complex mathematical problems to add new blocks to the blockchain. In return, they are rewarded with cryptocurrency.
Validators: In proof-of-stake or other consensus mechanisms, validators are nodes responsible for confirming the validity of transactions and proposing new blocks. Validators are often chosen based on factors like the amount of cryptocurrency they hold or other criteria.
Users: These are individuals or entities that initiate transactions on the blockchain. Users interact with the network through their wallets, where they can send or receive cryptocurrencies, tokens, or engage in other.
Developers: Developers contribute to the blockchain ecosystem by creating smart contracts, decentralized applications (DApps), and other software solutions that run on the blockchain network.blockchain-related activities.`}/>
      <Card1 title="Distributed Ledger" img={Bitcoin3} para="All participants in the blockchain network have a copy of the entire ledger. This distributed nature ensures transparency and prevents a single entity from controlling the data."/>
      <Card2 title="Immutability" img={Database} para="Once a block is added to the blockchain, it is nearly impossible to alter. This immutability ensures the integrity of the transaction history, making it resistant to fraud and tampering."/>
      <Card1 title="Consensus Mechanism" img={Blockchain3} para="Blockchain networks rely on a consensus algorithm to agree on the state of the ledger. Common mechanisms include Proof of Work (used in Bitcoin) and Proof of Stake. Consensus ensures that all nodes in the network have a consistent view of the data."/>
      <Card2 title="Immutability" img={Padlock3} 
      para='Cryptography is employed to secure transactions and control access to the blockchain.
(1) Hashing in the context of blockchain involves using a cryptographic hash function to convert input data, regardless of its size, into a fixed-size alphanumeric string. This process generates a unique "hash" that acts as a digital fingerprint for the original data, providing security, integrity, and efficiently facilitating data retrieval.
(2) Public and private keys enable secure ownership and transfer of assets.'
      />
      <Card1 title="Smart Contracts" img={Business} para="These are self-executing contracts with the terms directly written into code. Smart contracts automatically execute predefined actions when certain conditions are met, streamlining and automating processes on the blockchain."/>
      <Card2 title="Transparency" img={Transparency} para="The transparent nature of the blockchain allows participants to view the entire transaction history. While individual identities may be pseudonymous, the transaction history is public."/>
      <Card1 title="Anonymity vs. Pseudonymity" img={Anonymous2} para="Users on the blockchain have a certain level of privacy through cryptographic addresses, allowing transactions without revealing personal information. However, with a certain amount of research, a wallet address can be connected to a real person."/>
      <Card2 title="Interoperability" img={Currency} para="Some blockchain platforms are designed to work together, enabling interoperability between different blockchains and facilitating the exchange of assets or information."/>

      </div>
  )
}
