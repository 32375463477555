import React from "react";
import styles from "../style/door14.module.css";
import Accordion from "./Acordition";
import { motion } from "framer-motion";

import {
  Mask10,
  Mask11,
  Mask12,
  Mask13,
  Mask14,
  Mask15,
  Mask16,
  Mask17,
  Mask9,
} from "../../DoorsImages/Doorimg14";

export default function SDoor() {
  return (
    <div className={styles.section2}>
      <motion.div
        initial={{ y: "-15vh", opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        transition={{
          duration: 1,
        }}
        viewport={{ once: true }}
        className={styles.nftboxheading2}
      >
        <h1 className="text-white text-center font-bold text-3xl tracking-wide">
          Advantages of Airdrops
        </h1>
      </motion.div>
      <div className={styles.imgboxcard}>
        <div className={styles.imgboxcard1}>
          <motion.div
            initial={{ x: "-15vw", opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{
              duration: 1,
            }}
            viewport={{ once: true }}
            className={styles.cards}
          >
            <div className={styles.imuu}>
              <img className={styles.padlock} src={Mask9} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Free tokens"
                content="Airdrops offer free tokens to users, which can be a great way to accumulate cryptocurrency assets without spending any money."
              ></Accordion>
            </div>
          </motion.div>
          <motion.div
            initial={{ x: "15vw", opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{
              duration: 1,
            }}
            viewport={{ once: true }}
            className={styles.cards}
          >
            <div className={styles.imuu}>
              <img className={styles.baht} alt="img1" src={Mask10} />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Marketing strategy
"
                content="Airdrops are an effective marketing strategy for cryptocurrency startups to raise awareness for their project and increase token ownership.
"
              ></Accordion>
            </div>
          </motion.div>
          </div>
          </div>
          <div className={styles.imgboxcard}>
        <div className={styles.imgboxcard1}>
          <motion.div
            initial={{ x: "-15vw", opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{
              duration: 1,
            }}
            viewport={{ once: true }}
            className={styles.cards}
          >
            <div className={styles.imuu}>
              <img className={styles.mask1} src={Mask11} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Community building"
                content="Airdrops can help build a strong community around a project by rewarding early adopters and loyal supporters. "
              ></Accordion>
            </div>
          </motion.div>
          <motion.div
            initial={{ x: "15vw", opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{
              duration: 1,
            }}
            viewport={{ once: true }}
            className={styles.cards}
          >
            <div className={styles.imuu}>
              <img className={styles.mask2} src={Mask12} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Increased liquidity"
                content="Airdrops can increase the liquidity of a project’s token by distributing it to a wider audience.
"
              ></Accordion>
            </div>
          </motion.div>
        </div>
      </div>
     
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{
              duration: 1,
            }}
            viewport={{ once: true }}
            className={styles.nftboxheading2}
          >
            <h1 className="text-white text-center font-bold text-3xl tracking-wide">
              Disadvantages of Airdrops
            </h1>
          </motion.div>
          <div className={styles.imgboxcard}>
        <div className={styles.imgboxcard1}>
          <motion.div
            initial={{ x: "-15vw", opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{
              duration: 1,
            }}
            viewport={{ once: true }}
            className={styles.cards}
          >
            <div className={styles.imuu}>
              <img className={styles.curency} src={Mask13} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Low value tokens"
                content="Airdrops can distribute tokens that have little to no value, which can be a waste of time and effort for users.
"
              ></Accordion>
            </div>
          </motion.div>
          <motion.div
            initial={{ x: "15vw", opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{
              duration: 1,
            }}
            viewport={{ once: true }}
            className={styles.cards}
          >
            <div className={styles.imuu}>
              <img className={styles.mask1} src={Mask14} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Eligibility criteria"
                content="Airdrops usually have eligibility criteria that must be met in order to receive the tokens. This can be a barrier for some users who may not meet the criteria.
"
              ></Accordion>
            </div>
          </motion.div>
          </div>
          </div>
          <div className={styles.imgboxcard}>
        <div className={styles.imgboxcard1}>
          <motion.div
            initial={{ x: "-15vw", opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{
              duration: 1,
            }}
            viewport={{ once: true }}
            className={styles.cards}
          >
            <div className={styles.imuu}>
              <img className={styles.mask1} src={Mask15} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Scams"
                content="Airdrops can be used as a tool for scammers to steal users’ private keys or personal information.
"
              ></Accordion>
            </div>
          </motion.div>         
          <motion.div
            initial={{ x: "15vw", opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{
              duration: 1,
            }}
            viewport={{ once: true }}
            className={styles.cards}
          >
            <div className={styles.imuu}>
              <img className={styles.mask1} src={Mask15} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Market saturation
"
                content="Airdrops have become increasingly popular, which has led to market saturation and a decrease in the value of tokens distributed through airdrops.
"
              ></Accordion>
            </div>
          </motion.div>
        </div>
      </div>
      <motion.div
        initial={{ x: "-15vw", opacity: 0 }}
        whileInView={{ x: 0, opacity: 1 }}
        transition={{
          duration: 1,
        }}
        viewport={{ once: true }}
        className={styles.nftboxheading2}
      >
        <p className="text-white pt-5 pl-3 whitespace-pre-line text-lg tracking-widest">
          While DeFi has brought about innovation and new opportunities, it's
          important to note that it also comes with risks and challenges, such
          as smart contract vulnerabilities, regulatory uncertainties, and
          market volatility. Users should exercise caution and conduct thorough
          research before participating in the DeFi space.
        </p>
      </motion.div>
    </div>
  );
}
