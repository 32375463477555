// import { useUser } from "@clerk/clerk-react";
// import { doc, getDoc, getFirestore, setDoc } from "firebase/firestore";
// import React, { useState, useEffect } from "react";
// import ReactDOM from "react-dom";
// import { IoMdClose, IoMdStar } from "react-icons/io";
// import { FaStar } from "react-icons/fa";
// import { AnimatePresence, motion } from "framer-motion";
// import { useNavigate } from "react-router-dom";
// import "./Styles/ClaimGift.css";
// const Feedback = ({  toggleModal, Door, id }) => {
//   const [feedback, setFeedback] = useState({ text: "" });
//   // rating: 0
//   const [showThankYouMessage, setShowThankYouMessage] = useState(false);

//   const [rating, setRating] = useState(0);
//   const [hover, setHover] = useState(null);

//   const { user } = useUser();
//   const userId = user ? user.id : null;
//   const navigate = useNavigate();
//   const [completed, setCompleted] = useState(false);
//   const [userProgress, setUserProgress] = useState({
//     currentDay: 1,
//     completedDoors: new Array(25).fill(false),
//   });

//   useEffect(() => {
//     if (userProgress.completedDoors[Door]) {
//       setCompleted(true);
//     } else {
//       setCompleted(false);
//     }
//   }, [userProgress, Door]);

//   const handleSaveFeedback = async (Door, id) => {
//     const db = getFirestore();
//     const userFeedbackRef = doc(db, `users/${userId}/feedback/${id}`);

//     try {
//       const userFeedbackDoc = await getDoc(userFeedbackRef);
//       const userFeedbackData = userFeedbackDoc.data() || {};

//       if (Door >= 0 && Door < 25) {
//         userFeedbackData[Door] = {
//           feedback: feedback.text,
//           rating: rating,
//         };
//         console.log("Rating value before to the backend:", rating);
//         setShowThankYouMessage(false);
//         await setDoc(userFeedbackRef, userFeedbackData, { merge: true });
//         // toggleModal();
//         console.log("Modal closed after providing feedback.");
//         // navigate(-1);
//         setShowThankYouMessage(true);
//       } else {
//         console.error("Invalid Door index");
//       }
//     } catch (error) {
//       console.error("Error saving feedback:", error);
//     }

//     setFeedback({ text: "", rating: 0 });
//     console.log("Rating value sent to the backend:", rating);
//   };

//   useEffect(() => {
//     document.body.style.overflowY = "hidden";
//     return () => {
//       document.body.style.overflowY = "scroll";
//     };
//   }, []);

//   return ReactDOM.createPortal(
//     <>
//       <AnimatePresence>
//         <motion.div
//           initial={{ opacity: 0, y: "-100%" }}
//           whileInView={{ opacity: 1, y: "0" }}
//           exit={{ opacity: 0 }}
//           transition={{ duration: "0.3" }}
//           className="fixed top-0 left-0 right-0 bottom-0 backdrop-blur-xl bg-sky-800/30"
//         >
//           <motion.div
//             initial={{ opacity: 0 }}
//             whileInView={{ opacity: 1 }}
//             exit={{ opacity: 0 }}
//             transition={{ duration: "0.3" }}
//             className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-100  bg-sky-200 w-full sm:w-11/12 md:w-6/12 lg:w-1/2 xl:w-1/3 2xl:w-1/4 rounded-xl shadow-md drop-shadow-[0_35px_35px_rgba(0,0,0,0.25)]"
//           >
//             {showThankYouMessage && (
//             <button
//               className="absolute top-0 right-0 text-2xl text-sky-800  px-4 py-4 "
//               onClick={toggleModal}
//             >
//               <motion.div
//                 initial={{ rotate: 0 }}
//                 animate={{ rotate: 90 }}
//                 transition={{ duration: 2 }}
//               >
//                 <IoMdClose size={30} />
//               </motion.div>
//             </button>
//             )}
//             <div className="pt-4 px-6 w-full pb-7 bg-gray-100 rounded-md shadow-md">
//               <h2 className="text-2xl text-black flex items-center justify-center font-semibold ">
//                 Rate Your Experience
//               </h2>
//               <div className="w-full flex flex-col justify-start items-start">
//                 <form className="w-full flex flex-col justify-start items-start">
//                   <label className=" text-lg font-bold self-center text-gray-500">
//                     <div className="flex gap-2 items-center w-full h-20">
//                       {[...Array(5)].map((star, index) => {
//                         const currentRating = index + 1;
//                         return (
//                           <label className="flex text-lg self-center font-bold text-gray-500">
//                             <input
//                               type="radio"
//                               value={currentRating}
//                               onClick={() => {
//                                 setRating(currentRating);
//                                 handleSaveFeedback(Door, id, currentRating);
//                               }}
//                               name="rating"
//                             />
//                             <FaStar
//                               size={40}
//                               color={
//                                 currentRating <= (hover || rating)
//                                   ? "#ffc107"
//                                   : "#c1c8cd"
//                               }
//                               onMouseEnter={() => setHover(currentRating)}
//                               onMouseLeave={() => setHover(null)}
//                               className="star "
//                             />
//                           </label>
//                         );
//                       })}
//                     </div>

//                     <p className="flex text-lg flex justify-center font-bold text-gray-500">
//                       Your rating score: {rating}{" "}
//                     </p>
//                   </label>
//                   <label className=" w-full my-2 text-lg font-bold text-gray-500">
//                     <textarea
//                       rows="4"
//                       // cols="10"
//                       className="w-full resize-none outline-none text-lg rounded-lg border border-solid border-gray-400 bg-slate-50 px-2 py-1"
//                       value={feedback.text}
//                       placeholder=" Tell us what can be improved?"
//                       onChange={(e) =>
//                         setFeedback({ ...feedback, text: e.target.value })
//                       }
//                     />
//                   </label>

//                   <div className="flex py-2 h-[10%] justify-center items-center gap-5  w-full ">
//                     <button
//                       className=" font-medium max-w-full rounded-full bg-gradient-to-r from-yellow-400 to-yellow-600 px-6 py-2 text-lg  text-white shadow hover:bg-yellow-600 hover:from-yellow-500 hover:to-yellow-600 focus:outline-none  active:bg-red-500"
//                       onClick={() => handleSaveFeedback(Door, id)}
//                     >
//                       Submit
//                     </button>
//                   </div>

//                 </form>
//               </div>

//             </div>
//             {showThankYouMessage && (
//               <>
//             <div className="pt-4 px-6 w-full pb-7 bg-gray-100 rounded-md shadow-md">

//                     <p className="text-lg text-center font-bold self-center text-gray-500">
//                       Thank you <br/>
//                        Your feedback will help us more.
//                     </p>

//                   <div className="flex py-2 h-[10%] justify-center items-center gap-5  w-full ">
//                     <button
//                       className=" font-medium max-w-full rounded-full bg-gradient-to-r from-yellow-400 to-yellow-600 px-6 py-2 text-lg  text-white shadow hover:bg-yellow-600 hover:from-yellow-500 hover:to-yellow-600 focus:outline-none  active:bg-red-500"
//                       onClick={toggleModal}
//                     >
//                       Continue
//                     </button>
//                   </div>

//               </div>
//               </>
//                   )}
//           </motion.div>
//         </motion.div>
//       </AnimatePresence>
//     </>,
//     document.querySelector(".myportaldiv")
//   );
// };

// export default Feedback;

import { useUser } from "@clerk/clerk-react";
import { doc, getDoc, getFirestore, setDoc } from "firebase/firestore";
import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { IoMdClose, IoMdStar } from "react-icons/io";
import { FaStar } from "react-icons/fa";
import { AnimatePresence, motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import "./Styles/ClaimGift.css";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  TelegramShareButton,
  LinkedinShareButton,
  TelegramIcon,
} from "react-share";
import {
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  LinkedinIcon,
} from "react-share";
import { FaArrowRightArrowLeft } from "react-icons/fa6";

const Feedback = ({ toggleModal, Door, id }) => {
  const hashtags = [
    "web3gift",
    "cryptogift",
    "myweb3gift",
    "crypto",
    "cryptocurrency",
    "NFT",
  ];
  const via = "@myweb3gift";

  const [feedback, setFeedback] = useState({ text: "", rating: 0 });
  const [showThankYouMessage, setShowThankYouMessage] = useState(false);
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { user } = useUser();
  const userId = user ? user.id : null;
  const navigate = useNavigate();

  useEffect(() => {
    document.body.style.overflowY = "hidden";
    return () => {
      document.body.style.overflowY = "scroll";
    };
  }, []);

  const handleShowTq = () => {
    setShowThankYouMessage((prev) => !prev);
  };

  // const handleSaveFeedback = async (Door, id, feedback) => {
  //   const db = getFirestore();
  //   const userFeedbackRef = doc(db, `users/${userId}/feedback/${id}`);

  //   try {
  //     const userFeedbackDoc = await getDoc(userFeedbackRef);
  //     const userFeedbackData = userFeedbackDoc.data() || {};

  //     if (Door >= 0 && Door < 25) {
  //       userFeedbackData[Door] = {
  //         feedback: feedback.text,
  //         rating: rating,
  //       };

  //       await setDoc(userFeedbackRef, userFeedbackData, { merge: true });
  //       console.log("Feedback saved successfully!");
  //     } else {
  //       console.error("Invalid Door index");
  //     }
  //   } catch (error) {
  //     console.error("Error saving feedback:", error);
  //   }

  //   setFeedback({ text: "", rating: 0 });
  //   toggleModal();
  //   navigate(`/10-Day Crypto Challenge/${id}`);
  // };

  const handleSaveFeedback = async (Door, id, feedback) => {
    const db = getFirestore();
    const userFeedbackRef = doc(db, `users/${userId}/feedback/${id}`);

    try {
      const userFeedbackDoc = await getDoc(userFeedbackRef);
      const userFeedbackData = userFeedbackDoc.data() || {};

      if (Door >= 0 && Door < 25) {
        userFeedbackData[Door] = {
          feedback: feedback.text,
          rating: rating,
        };

        await setDoc(userFeedbackRef, userFeedbackData, { merge: true });
        console.log("Feedback saved successfully!");

        if (rating >= 1 && rating <= 3) {
          // Navigate if rating is between 1 to 3
          navigate(`/10-Day Crypto Challenge/${id}`);
        } else {
          // Show thank you message if rating is between 4 to 5
          setShowThankYouMessage(true);
        }
      } else {
        console.error("Invalid Door index");
      }
    } catch (error) {
      console.error("Error saving feedback:", error);
    }

    setFeedback({ text: "", rating: 0 });
  };

  return ReactDOM.createPortal(
    <>
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0, y: "-100%" }}
          whileInView={{ opacity: 1, y: "0" }}
          exit={{ opacity: 0 }}
          transition={{ duration: "0.3" }}
          className="fixed top-0 left-0 right-0 bottom-0 backdrop-blur-xl bg-sky-800/30"
        >
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: "0.3" }}
            className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-100  bg-sky-200 w-full sm:w-11/12 md:w-6/12 lg:w-6/12 xl:w-1/3 2xl:w-1/4 rounded-xl shadow-md drop-shadow-[0_35px_35px_rgba(0,0,0,0.25)]"
          >
            {!showThankYouMessage && (
              <button
                className="absolute top-0 right-0 text-2xl text-sky-800  px-4 py-4 "
                onClick={toggleModal}
              >
                <IoMdClose size={30} />
              </button>
            )}
            {showThankYouMessage ? (
              <div className="pt-4 px-6 w-full pb-7 bg-gray-100 rounded-md shadow-md">
                <p className="text-xl text-center font-bold  text-gray-500">
                  <span className="text-xl text-gray-500 uppercase">
                    {" "}
                    Thank You{" "}
                  </span>{" "}
                  <br />
                  Your feedback will help us more.
                </p>
                <div className="flex border-b border-dotted border-gray-400  py-2 pb-3 h-[10%] justify-center items-center gap-5  w-full ">
                  <button
                    className=" font-medium max-w-full rounded-full bg-gradient-to-r from-yellow-400 to-yellow-600 px-6 py-2 text-lg  text-white shadow hover:bg-yellow-600 hover:from-yellow-500 hover:to-yellow-600 focus:outline-none  active:bg-red-500"
                    onClick={() => {
                      // setShowThankYouMessage(false);
                      // toggleModal(); // Close the modal
                      navigate(`/10-Day Crypto Challenge/${id}`); // Navigate back to the calendar door page
                    }}
                  >
                    Continue
                  </button>
                </div>
                <div className="h-full flex py-3 gap-4 w-full max-lg:w-full flex-col items-center justify-center">
                  <span className="text-gray-500 text-center text-xl font-bold ">
                    Share your achievement with your friends
                  </span>

                  <div className="flex py-0 max-lg:flex  flex-row gap-4">
                    <FacebookShareButton
                      url="https://www.myweb3gift.com"
                      quote={`I just opened another door on ${via} .`}
                      hashtag="#myweb3gift"
                      className="max-h-[50px] hover:scale-105 hover:duration-150 hover:ease-in"
                    >
                      <FacebookIcon
                        size={40}
                        round={false}
                        className="rounded-xl"
                      ></FacebookIcon>
                    </FacebookShareButton>
                    <LinkedinShareButton
                      url="https://www.myweb3gift.com"
                      title="MYWEB3GIFT"
                      description={`I just opened another door on ${via} . \n if you want to check them out:\n`}
                      className="max-h-[50px] hover:scale-105 hover:duration-150 hover:ease-in"
                    >
                      <LinkedinIcon
                        size={40}
                        round={false}
                        className="rounded-xl"
                      />
                    </LinkedinShareButton>
                    <WhatsappShareButton
                      url="https://www.myweb3gift.com"
                      title={`I just opened another door on ${via} .\n if you want to check them out:\n`}
                      separator=""
                      className="max-h-[50px] hover:scale-105 hover:duration-150 hover:ease-in"
                    >
                      <WhatsappIcon
                        size={40}
                        round={false}
                        className="rounded-xl "
                      />
                    </WhatsappShareButton>
                    <TwitterShareButton
                      url="https://www.myweb3gift.com"
                      className="max-h-[50px] hover:scale-105 hover:duration-150 hover:ease-in"
                      title={`I just opened another door on ${via} .\n if you want to check them out:\n`}
                      // via="Myweb3gift"
                      hashtags={hashtags}
                    >
                      <TwitterIcon
                        size={40}
                        round={false}
                        className="rounded-xl"
                      />
                    </TwitterShareButton>
                  </div>
                </div>
              </div>
            ) : (
              <div className="pt-4 px-6 w-full pb-7 bg-gray-100 rounded-md shadow-md">
                <h2 className="text-2xl text-black flex items-center justify-center font-semibold ">
                  Rate Your Experience
                </h2>
                <div className="w-full flex flex-col justify-start items-start">
                  <form className="w-full flex flex-col justify-start items-start">
                    <label className=" text-lg font-bold self-center text-gray-500">
                      <div className="flex gap-2 items-center w-full h-20">
                        {[...Array(5)].map((star, index) => {
                          const currentRating = index + 1;
                          return (
                            <label
                              className="flex text-lg self-center font-bold text-gray-500"
                              key={index}
                            >
                              <input
                                type="radio"
                                value={currentRating}
                                onClick={() => {
                                  setRating(currentRating);
                                }}
                                name="rating"
                              />
                              <FaStar
                                size={40}
                                color={
                                  currentRating <= (hover || rating)
                                    ? "#ffc107"
                                    : "#c1c8cd"
                                }
                                onMouseEnter={() => setHover(currentRating)}
                                onMouseLeave={() => setHover(null)}
                                className="star "
                              />
                            </label>
                          );
                        })}
                      </div>

                      <p className="flex text-lg flex justify-center font-bold text-gray-500">
                        Your rating score: {rating}
                      </p>
                    </label>
                    <label className=" w-full my-2 text-lg font-bold text-gray-500">
                      <textarea
                        rows="4"
                        className="w-full resize-none outline-none text-lg rounded-lg border border-solid border-gray-400 bg-slate-50 px-2 py-1"
                        value={feedback.text}
                        placeholder=" Tell us what can be improved?"
                        onChange={(e) =>
                          setFeedback({ ...feedback, text: e.target.value })
                        }
                      />
                    </label>

                    <div className="flex py-2 h-[10%] justify-center items-center gap-5  w-full ">
                      <button
                        type="button"
                        className=" font-medium max-w-full rounded-full bg-gradient-to-r from-yellow-400 to-yellow-600 px-6 py-2 text-lg  text-white shadow hover:bg-yellow-600 hover:from-yellow-500 hover:to-yellow-600 focus:outline-none  active:bg-red-500"
                        onClick={() => {
                          handleSaveFeedback(Door, id, feedback);
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            )}
          </motion.div>
        </motion.div>
      </AnimatePresence>
    </>,
    document.querySelector(".myportaldiv")
  );
};

export default Feedback;
