import React from 'react'

export function Upper(props) {
  return (
    <svg 
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
     viewBox="0 0 24 24"
     {...props}
     ><path d="M11.9997 10.8284L7.04996 15.7782L5.63574 14.364L11.9997 8L18.3637 14.364L16.9495 15.7782L11.9997 10.8284Z"></path>
    </svg>
  )
}
