import React from "react";
import styles from "../style/door11.module.css";
import { door11img1, door11img2, door11img3, door11img4 } from "../../DoorsImages/Doorimg11";
import { motion } from "framer-motion";
export default function FDoor() {
  return (
    <div className={styles.section1}>
    <div className={styles.sec1box}>
    <motion.div initial={{ x: "-15vw",opacity:0}}
            whileInView={{ x: 0, opacity:1 }}
            transition={{duration:0.6}}
             viewport={{once:true}} className={styles.sec1stablecoinleft}>
        <h1 className="text-5xl font-bold">Stablecoins</h1>
        <p className="text-center mt-6 tracking-wide text-lg whitespace-pre-line">
          As already indicated yesterday stablecoins are next to protocol
          tokens, the most important token class. The volumes have increased in
          the last years. As of December 2023 the share was roughly 10% ($130
          bln).
        </p>
        <p className="text-center mt-3 tracking-wide text-lg whitespace-pre-line">
          Stablecoins are a type of cryptocurrency that are designed to maintain
          a stable value by pegging their worth to a reserve of assets or a
          stable benchmark, such as a fiat currency like the US Dollar or a
          commodity like gold.
          <br />
          The primary goal of stablecoins is to reduce the volatility often
          associated with traditional cryptocurrencies like Bitcoin or Ethereum.
        </p>
      </motion.div>
      <motion.div initial={{ x: "15vw",opacity:0}}
            whileInView={{ x: 0, opacity:1 }}
            transition={{duration:0.6}}
             viewport={{once:true}}
       className={styles.sec1stablecoinright}>
        <div className={styles.webimgbox}>

<div className={styles.card1}>
    <img src={door11img1} alt="img1"/>
</div>
<div className={styles.card1}>
<img src={door11img2} alt="img1"/>

</div>
        </div>
        <div className={styles.webimgbox}>
        <div className={styles.card1}>
    <img src={door11img3} alt="img1"/>

        </div>
        <div className={styles.card1}>
    <img src={door11img4} alt="img1"/>

        </div>


</div>
      </motion.div>
      </div>
    </div>
  );
}
