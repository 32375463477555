import React from "react";
import styles from "../style/door14.module.css";
import { motion } from "framer-motion";
// import gsap from "gsap";
import Accordion from "./Acordition2";

export default function S3Door() {
  return (
    <div className={styles.section21}>
      <motion.div className={styles.nftboxheading3}>
        <motion.div
          initial={{ y: "-100%", opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
          className={styles.nfth11}
        >
          <h1 className="text-white font-medium text-center text-3xl tracking-wide">
            Activities and Strategies
          </h1>
        </motion.div>
        <div className={styles.accordionsection2}>
          <motion.div
            initial={{ x: "-105%", opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.2 }}
            viewport={{ once: true }}
            className={styles.accordion2}
          >
            <Accordion
              title="Stay active on chain"
              content="Long-term activity is proof that you are an engaged user. During bear markets, many people stop being active on chains, but staying active sets you apart from the crowd. Most wallets are dormant during times of low airdrop hype and only become active when an airdrop is announced. To be in the top 1 %, don’t be like that."
            ></Accordion>
          </motion.div>
          <motion.div
            initial={{ x: "-105%", opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.2 }}
            viewport={{ once: true }}
            className={styles.accordion2}
          >
            <Accordion
              title="Stake your cryptocurrency on different chains"
              content="Major projects like Arbitrum, Cosmos, Optimism or ZkSync may distribute assets to users who contribute to their TVL. They could also allocate a percentage of crypto assets to projects so that they can give back to their communities. When it comes to staking, volume is important. It’s better to stake 300 USD than 1 USD. However, it can be risky if a protocol gets exploited, so choose where you stake carefully."
            ></Accordion>
          </motion.div>
          <motion.div
            initial={{ x: "-105%", opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.2 }}
            viewport={{ once: true }}
            className={styles.accordion2}
          >
            <Accordion
              title="Focus your airdrop farming on a few projects"
              content=" Airdrop farming can be time-consuming and expensive due to fees. It’s better to focus on farming a small number of projects properly, than a large number of projects poorly. Some airdrops will be much larger than others, so it’s important to identify the best ones and focus your farming efforts on them. If you want to farm airdrops on Cosmos and Arbitrum, which can be large and have a decent probability of success, you can start by farming and staking on these blockchains and keeping up-to-date with the latest news.
"
            ></Accordion>
          </motion.div>
          <motion.div
            initial={{ x: "-105%", opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.2 }}
            viewport={{ once: true }}
            className={styles.accordion2}
          >
            <Accordion
              title="Interact with new protocols"
              content="Many protocols are currently being launched on Arbitrum, Optimism, ZkSync, Cosmos, and Aptos. They are often highlighted via AMAs or quests, as is the case with Arbitrum or Optimism. These protocols may receive allocations for future airdrops, and you could be eligible for them if you have interacted with these projects. Interacting with new projects during the bear market is proof that you are an engaged user."
            ></Accordion>
          </motion.div>
          <motion.div
            initial={{ x: "-105%", opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.2 }}
            viewport={{ once: true }}
            className={styles.accordion2}
          >
            <Accordion
              title="Stay up to date"
              content="It can be challenging to analyze all the information on your own. To stay informed about the latest airdrop news and developments, consider following Twitter accounts that gather and share the best information."
            ></Accordion>
          </motion.div>
        </div>
      </motion.div>
    </div>
  );
}
