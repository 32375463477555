import React from "react";
import Nav01 from "../Components/Nav01";
import { Footer } from "../sections_landing";
import { LocomotiveScrollProvider } from "react-locomotive-scroll";
import "locomotive-scroll/dist/locomotive-scroll.css";
import { useRef } from "react";
import { AnimatePresence } from "framer-motion";
import CalendarDoors from "./CalendarDoors";
import { BlackGifts } from "../assets/Images/AfterLoginPic";

const CryptoAdventCalendar = () => {
  return (

    <main>
      <div>
        <Nav01></Nav01>
        <div className="absolute z-10 bg-sky-950 h-[90px] w-full"></div>
      </div>
      <div
        className="relative w-full h-full flex bg-fixed flex-col items-center gap-9 bg-cover bg-center"
        style={{
          backgroundImage: `url(${BlackGifts})`, // Use the imported image
        }}
      >
        <div className=" w-full h-full">
          <CalendarDoors />
        </div>
      </div>
      <Footer></Footer>
    </main>
    
  );
};

export default CryptoAdventCalendar;
