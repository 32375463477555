import React, { useRef, useState, useEffect } from 'react';
import { FaPause, FaPlay } from 'react-icons/fa';
import { motion } from "framer-motion"

const VideoPlayer = () => {
  const videoRef = useRef(null);
  const progressBarRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);


  const [videoSource, setVideoSource] = useState('');

  useEffect(() => {
    function handleResize() {
      const isMobile = window.innerWidth <= 768; // Adjust the breakpoint as needed
      if (isMobile) {
        setVideoSource("https://firebasestorage.googleapis.com/v0/b/myweb3gift-bd98e.appspot.com/o/Intro%20(1)%20(1).mp4?alt=media&token=e0cb94f8-93d6-45c8-b083-49a6be67c73e"); // Replace with your mobile video URL
      } else {
        setVideoSource("https://firebasestorage.googleapis.com/v0/b/myweb3gift-bd98e.appspot.com/o/Intro%20(1).mp4?alt=media&token=79f295ce-e0c1-4c66-9d72-676e17cfedb3"); // Replace with your desktop video URL
      }
    }

    handleResize(); // Call the function initially
    window.addEventListener('resize', handleResize); // Listen for window resize events

    return () => {
      window.removeEventListener('resize', handleResize); // Clean up the event listener on unmount
    };
  }, []);

  useEffect(() => {
    // When the video source changes, load the new video
    if (videoRef.current) {
      videoRef.current.load();
    }
  }, [videoSource]);




  useEffect(() => {
    // const video = videoRef.current;
    // const progressBar = progressBarRef.current;

    // const handleTimeUpdate = () => {
    //   const progress = (video.currentTime / video.duration) * 100;
    //   progressBar.value = progress;
    // };

    // video.addEventListener('timeupdate', handleTimeUpdate);

    const handleVideoEnd = () => {
      setIsPlaying(false);
      // Reset background color or perform any other cleanup
      document.body.style.backgroundColor = '';
    };

  }, []);

  const [isHovered, setIsHovered] = useState(false);

  const handleHover = () => {
    setIsHovered(true);
  };

  const handleLeave = () => {
    setIsHovered(false);
  };

  const boxStyle = {

    boxShadow: isHovered ? '0px 0 50px #fec303' : '0px 0 20px #f7d917',
    transition: 'box-shadow 1s ease-in-out', // Optional: Add a smooth transition effect
  };

  const handlePlayPause = () => {
    const video = videoRef.current;
    video.play();
    video.muted = false; // Unmute the video
    setIsPlaying(true);
  };

  return (
    <>
      <div className='flex flex-col gap-10 h-full py-10 '>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 3, ease: "easeIn", delay: "0" }}
          className="text-center flex justify-center items-center hiden leading-9 text-black font-semibold max-sm:text-2xl sm:text-5xl sm:mx-0">
          Watch our video to learn more
        </motion.div>

        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 3, ease: "easeIn", delay: "0" }}
          className='flex relative flex-col w-full overflow-hidden px-6  max-sm:px-1  max-lg:px-16 justify-center items-center'>
          <div className='overflow-x-hidden relative rounded-xl p-10 max-sm:p-1 relative '>

            <video
              ref={videoRef}
              className='rounded-xl relative bg-cover'
              src={videoSource}
              width="950"
              style={boxStyle}
              onMouseEnter={handleHover}
              onMouseLeave={handleLeave}
              controls
              playsInline
              autoPlay
              muted
              controlsList="nodownload" // This line removes the "more" option button
            ></video>

            {!isPlaying &&
              <button onClick={handlePlayPause} className=' flex text-black w-[60px] h-[60px] bg-white/50 rounded-full justify-center items-center absolute top-2/4 left-2/4 z-10' style={{ transform: "translate(-50%,-50%)" }}>
                <FaPlay/>
              </button>
            }
          </div>
        </motion.div>
      </div>
    </>
  );
};

export default VideoPlayer;
