import React from "react";
import { motion } from "framer-motion";
import styles from "../style/door9.module.css";
import Accordion from "./Acordition";
import { Ethereum,Speed, Flame, Receipt2, Statics,Native, Mining3, Money } from "../../DoorsImages/Doorimg9";
export default function SDoor() {
  return (
    <div className={styles.section2}>
      <motion.div initial={{ x: "-15vw",opacity:0}}
            whileInView={{ x: 0, opacity:1 }}
            transition={{duration:0.6}}
             viewport={{once:true}} className={styles.nftboxheading2}>
        <h1 className="text-white font-bold text-3xl tracking-wide">
        To understand gas fees, it's essential to grasp the underlying concepts.
        </h1>
      </motion.div>
      <div className={styles.imgboxcard}>
        <div className={styles.imgboxcard1}>
        <motion.div initial={{ x: "-15vw",opacity:0}}
            whileInView={{ x: 0, opacity:1 }}
            transition={{duration:0.6}}
             viewport={{once:true}} className={styles.cards}>
            <div className={styles.imuu}>
              <img className={styles.padlock} src={Receipt2} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Smart Contracts" content="Smart contracts are self-executing contracts with the terms of the agreement directly written into code. They run on blockchain networks and automatically execute when predefined conditions are met."
              ></Accordion>
            </div>
          </motion.div>
          <motion.div initial={{ x: "15vw",opacity:0}}
            whileInView={{ x: 0, opacity:1 }}
            transition={{duration:0.6}}
             viewport={{once:true}} className={styles.cards}>
            <div className={styles.imuu}>
              <img className={styles.padlock} alt="img1" src={Native} />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Decentralized Applications(DApps)" content="DApps are applications that run on a decentralized network of computers, typically a blockchain."
              ></Accordion>
            </div>
          </motion.div>
          </div>
          </div>
          <div className={styles.imgboxcard}>
        <div className={styles.imgboxcard1}>
          <motion.div initial={{ x: "-15vw",opacity:0}}
            whileInView={{ x: 0, opacity:1 }}
            transition={{duration:0.6}}
             viewport={{once:true}} className={styles.cards}>
            <div className={styles.imuu}>
              <img className={styles.padlock} src={Money} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Gas" content='In the context of blockchain, "gas" refers to the computational work required to perform operations or execute smart contracts. Every operation on the blockchain network consumes a certain amount of computational resources, and this is measured in gas.'
              ></Accordion>
            </div>
          </motion.div>
          <motion.div initial={{ x: "15vw",opacity:0}}
            whileInView={{ x: 0, opacity:1 }}
            transition={{duration:0.6}}
             viewport={{once:true}} className={styles.cards}>
            <div className={styles.imuu}>
              <img className={styles.padlock} src={Ethereum} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Gas Price" content='Gas has a price, denoted in a unit called "gwei," which is a subunit of the cryptocurrency associated with the blockchain (e.g., Ether for Ethereum). Gas price represents the amount of cryptocurrency (gwei) a user is willing to pay for each unit of gas.'
              ></Accordion>
            </div>
          </motion.div>
          </div>
          </div>
          <div className={styles.imgboxcard}>
        <div className={styles.imgboxcard1}>
          <motion.div initial={{ x: "-15vw",opacity:0}}
            whileInView={{ x: 0, opacity:1 }}
            transition={{duration:0.6}}
             viewport={{once:true}} className={styles.cards}>
            <div className={styles.imuu}>
              <img className={styles.padlock} src={Speed} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Gas Limit" content='Gas limit is the maximum amount of gas units a user is willing to spend on a transaction or a smart contract execution. It serves as a safeguard against infinite loops or unintentional resource consumption.'
              ></Accordion>
            </div>
          </motion.div>
          <motion.div initial={{ x: "15vw",opacity:0}}
            whileInView={{ x: 0, opacity:1 }}
            transition={{duration:0.6}}
             viewport={{once:true}} className={styles.cards}>
            <div className={styles.imuu}>
              <img className={styles.padlock} src={Flame} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Gas Fee" content='The total cost of a transaction or smart contract execution is calculated as the product of the gas used and the gas price.
Gas Fee = Gas Used * Gas Price'
              ></Accordion>
            </div>
          </motion.div>
          </div>
          </div>
          <div className={styles.imgboxcard}>
        <div className={styles.imgboxcard1}>
          <motion.div initial={{ x: "-15vw",opacity:0}}
            whileInView={{ x: 0, opacity:1 }}
            transition={{duration:0.6}}
             viewport={{once:true}} className={styles.cards}>
            <div className={styles.imuu}>
              <img className={styles.padlock} src={Mining3} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title=" Miners/Validators" content='Miners (or validators) on the blockchain network validate and process transactions. 
They prioritize transactions based on the gas price attached to them. Higher gas prices attract more attention from miners.'
              ></Accordion>
            </div>
          </motion.div>
          <motion.div initial={{ x: "15vw",opacity:0}}
            whileInView={{ x: 0, opacity:1 }}
            transition={{duration:0.6}}
             viewport={{once:true}} className={styles.cards}>
            <div className={styles.imuu}>
              <img className={styles.padlock} src={Statics} alt="img1" />
            </div>
            <div className={styles.box}>
              <Accordion
                title="Market Dynamics" content='Gas fees fluctuate based on network demand and congestion. Higher demand for transactions leads to higher gas prices. Users can adjust the gas price to have their transactions processed faster or at a lower cost, depending on their priorities.'
              ></Accordion>
            </div>
          </motion.div>
        </div>
      </div>
      <motion.div initial={{ x: "-15vw",opacity:0}}
            whileInView={{ x: 0, opacity:1 }}
            transition={{duration:0.6}}
             viewport={{once:true}} className={styles.nftboxheading21}>
       <p className="text-white text-lg tracking-widest whitespace-pre-line">
       In summary, gas fees are the costs associated with utilizing the computational resources of a blockchain network. Users pay these fees to miners or validators to have their transactions processed and smart contracts executed. Gas fees vary dynamically based on network demand, and users can optimize their fees by adjusting the gas price and gas limit according to their preferences and urgency.
       </p>
      </motion.div>
    </div>
  );
}
