import React, { useRef } from "react";
import html2canvas from "html2canvas";
import Nav01 from "../Components/Nav01";
// import { Footer } from '../sections_landing';
// import { QR_CODE } from '../assets/Images';
// import { Link} from 'react-router-dom';
import { LogoCP } from "../assets/Images/MyWeb3Gift-red2";
import { JustBackground } from "../assets/Images/AfterLoginPic";
// import { Manisha } from '../assets/Images';
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";
import QRCodeWithLogo from "./QRCode";
import "./Styles/ClaimGift.css";
import "../sections_landing/Styles/main.css";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  TelegramShareButton,
  instagramShareLink,
  LinkedinShareButton,
  PinterestIcon,
  TelegramIcon,
} from "react-share";
import {
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  LinkedinIcon,
} from "react-share";

const TransferedGift = ({ gift }) => {
  const websiteURL = "https://myweb3gift.com/";
  const hashtags = ["web3gift", "cryptogift", "myweb3gift"];
  const via = "@myweb3gift";

  const {
    greeting,
    receiverName,
    image,
    senderImage,
    cardColor,
    cardFont,
    date,
    giftDetails,
    receiverEmail,
    senderEmail,
    senderName,
    id,
  } = gift;

  //console.log("Here We are getting giftID details", gift);

  let displayText;

  if (gift.giftDetails === "Crypto Advent Calendar") {
    displayText = "Crypto Advent Calendar";
  } else if (gift.giftDetails === "Crypto Suprise Gift Box") {
    displayText = "Crypto Suprise Gift Box";
  } else if (gift.giftDetails === "10-Day Crypto Challenge") {
    displayText = "10-Day Crypto Challenge";
  } else {
    // Default case if the received giftDetails doesn't match any expected values
    displayText = " Gift ";
  }

  const cardRef = useRef(null);

  const handleDownload = () => {
    if (!cardRef.current) return;

    html2canvas(cardRef.current, {
      onclone: (doc) => {
        const cardElement = doc.querySelector(".rounded-card"); // Add a class to the card container
        if (cardElement) {
          // Apply rounded border to the cloned card container
          cardElement.style.borderRadius = "20px"; // Adjust the radius as needed
        }
      },
    }).then((canvas) => {
      const image = canvas.toDataURL("image/png");

      // Optionally, you can trigger a download
      const downloadLink = document.createElement("a");
      downloadLink.href = image;
      downloadLink.download = "MyWeb3Gift_Card.png";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    });
  };
  const handleFacebookShare = () => {
    if (!cardRef.current) return;

    html2canvas(cardRef.current).then((canvas) => {
      const image = canvas.toDataURL("image/png");

      // Create a share link for Facebook
      const facebookShareLink = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        window.location.href
      )}&quote=Check%20out%20this%20birthday%20card&picture=${encodeURIComponent(
        image
      )}`;

      // Open a new window to redirect to Facebook for sharing
      window.open(facebookShareLink, "_blank");
    });
  };
  const handleLinkedInShare = () => {
    if (!cardRef.current) return;

    html2canvas(cardRef.current).then((canvas) => {
      const image = canvas.toDataURL("image/png");

      // Create a share link for LinkedIn
      const linkedInShareLink = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
        window.location.href
      )}&title=Birthday%20Card&summary=Check%20out%20this%20birthday%20card&source=My%20Website&source=My%20Website&image=${encodeURIComponent(
        image
      )}`;

      // Open a new window to redirect to LinkedIn for sharing
      window.open(linkedInShareLink, "_blank");
    });
  };

  const handleWhatsAppShare = () => {
    if (!cardRef.current) return;

    html2canvas(cardRef.current).then((canvas) => {
      //   const image = canvas.toDataURL('image/png');

      // Create a share link for WhatsApp
      const whatsAppShareLink = `https://api.whatsapp.com/send?text=Check%20out%20this%20birthday%20card%20${encodeURIComponent(
        window.location.href
      )}`;

      // Open a new window to redirect to WhatsApp for sharing
      window.open(whatsAppShareLink, "_blank");
    });
  };

  const handleTwitterShare = () => {
    if (!cardRef.current) return;

    html2canvas(cardRef.current).then((canvas) => {
      //   const image = canvas.toDataURL('image/png');

      // Create a share link for Twitter
      const twitterShareLink = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
        window.location.href
      )}&text=Check%20out%20this%20birthday%20card&hashtags=BirthdayCard`;

      // Open a new window to redirect to Twitter for sharing
      window.open(twitterShareLink, "_blank");
    });
  };

  const handleInstagramShare = () => {
    if (!cardRef.current) return;

    html2canvas(cardRef.current).then((canvas) => {
      //   const image = canvas.toDataURL('image/png');

      // Create a share link for Instagram
      const instagramShareLink = `https://www.instagram.com/?url=${encodeURIComponent(
        window.location.href
      )}`;

      // Open a new window to redirect to Instagram for sharing
      window.open(instagramShareLink, "_blank");
    });
  };
  //console.log(gift.giftDetails);
  return (
    <>
      <ScrollLink to="sectionsss " smooth={true} duration={1000}></ScrollLink>

      <div className="overflow-x-hidden unselectable">
        <Nav01></Nav01>
        <div className="bg-sky-950 h-[90px] w-screen"></div>
        <div className="flex min-h-screen flex-row content-center items-center justify-items-center">
          <div
            className="relative mx-auto max-lg:min-h-screen py-10 flex min-h-max min-w-[100vw] flex-col items-center gap-9 rounded-3xl bg-cover bg-center"
            style={{
              backgroundImage: `url(${JustBackground})`,
            }}
          >
            <div className="flex w-full min-h-screen max-lg:flex-col-reverse items-center justify-center max-lg:gap-5">
              <div className="w-6/12 max-lg:w-full h-screen flex flex-col items-center gap-5 ">
                <div className="max-lg:w-full w-[40vw] overflow-hidden rounded-3xl max-sm:rounded-lg border-2 border-sky-100 bg-white">
                  <div className=" px-5 max-sm:flex max-sm:px-2  max-sm:flex-col  flex flex-row max-w-[100%] justify-center items-center gap-5">
                    {/* <Link to="/CryptoAdventCalendar"><button className="mx-auto my-11 items-center rounded-3xl bg-yellow-300 px-9 py-2 text-xl font-bold text-white hover:bg-yellow-400 focus:ring-2">Open Crypto Calendar</button></Link> */}

                    {/* <Link to={`/${gift.giftDetails}`}>
  <button className="mx-auto my-11 items-center rounded-3xl bg-yellow-300 px-9 py-2 text-xl font-bold text-white hover:bg-yellow-400 focus:ring-2">
  {displayText}
  </button>
</Link> */}

                    {/* greeting, receiverName, image, senderImage, cardColor,cardFont
        ,date, giftDetails, receiverEmail, senderEmail, senderName, id */}

                    {/* Gift Name:   {gift.giftDetails}   
<br />
Receiver Email:   {gift.receiverEmail}  
<br />
Receiver Name:   {gift.receiverName}  
<br />
Your Name:      {gift.senderName}
<br />
Sent Date:     {gift.date ? (<>{gift.date }  </>) : (<> Not Available  </>)     }
<br />
     
     <br /> */}

                    <div className="my-4 p-4 bg-gray-100 max-sm:w-full rounded-md">
                      <p className="font-bold text-blue-700">Gift Name:</p>
                      <p className="text-gray-800">{gift.giftDetails}</p>
                      <br />

                      <p className="font-bold text-blue-700">Receiver Email:</p>
                      <p className="text-gray-800">{gift.receiverEmail}</p>
                      <br />

                      <p className="font-bold text-blue-700">Receiver Name:</p>
                      <p className="text-gray-800">{gift.receiverName}</p>
                      <br />

                      <p className="font-bold text-blue-700">Your Name:</p>
                      <p className="text-gray-800">{gift.senderName}</p>
                      <br />

                      <p className="font-bold text-blue-700">Sent Date:</p>
                      <p className="text-gray-800">
                        {gift.date ? <>{gift.date}</> : <>Not Available</>}
                      </p>
                      <br />
                    </div>

                    <button
                      onClick={handleDownload}
                      className="mx-auto my-11 items-center rounded-3xl bg-yellow-300 px-9 py-2 text-xl font-bold text-white hover:bg-yellow-400 focus:ring-2"
                    >
                      Print Card
                    </button>
                  </div>
                </div>
                <div className="max-md:px-3 max-md:text-center">
                  <span className="text-gray-500  text-3xl font-semibold my-5 ">
                    Share this card with your friend
                  </span>
                  <div className="flex my-8 py-0 flex-row gap-8">
                    {/* <div>
                      <img
                        className="max-h-[50px] hover:scale-105 hover:duration-150 hover:ease-in"
                        src="https://cdn2.iconfinder.com/data/icons/social-media-2285/512/1_Facebook_colored_svg_copy-512.png"
                        alt=""
                        onClick={handleFacebookShare}
                      />
                    </div>
                    <div>
                      <img
                        className="max-h-[50px] hover:scale-105 hover:duration-150 hover:ease-in"
                        src="https://cdn2.iconfinder.com/data/icons/social-media-2285/512/1_Linkedin_unofficial_colored_svg-512.png"
                        alt=""
                        onClick={handleLinkedInShare}
                      />
                    </div>
                    <div>
                      <img
                        className="max-h-[50px] hover:scale-105 hover:duration-150 hover:ease-in"
                        src="https://cdn2.iconfinder.com/data/icons/social-media-2285/512/1_Whatsapp2_colored_svg-512.png"
                        alt=""
                        onClick={handleWhatsAppShare}
                      />
                    </div>
                    <div>
                      <img
                        className="max-h-[50px] hover:scale-105 hover:duration-150 hover:ease-in"
                        src="https://cdn2.iconfinder.com/data/icons/social-media-2285/512/1_Instagram_colored_svg_1-512.png"
                        alt=""
                        onClick={handleInstagramShare}
                      />
                    </div>
                    <div>
                      <img
                        className="max-h-[50px] hover:scale-105 hover:duration-150 hover:ease-in"
                        src="https://cdn2.iconfinder.com/data/icons/social-media-2285/512/1_Twitter3_colored_svg-512.png"
                        alt=""
                        onClick={handleTwitterShare}
                      />
                    </div> */}
                    <FacebookShareButton
                      url="https://www.myweb3gift.com"
                      quote="I am happy to share this web3gift"
                      hashtag="#myweb3gift"
                      className="max-h-[50px] hover:scale-105 hover:duration-150 hover:ease-in"
                    >
                      <FacebookIcon
                        size={50}
                        round={false}
                        className="rounded-xl"
                      ></FacebookIcon>
                    </FacebookShareButton>
                    <LinkedinShareButton
                      url="https://www.myweb3gift.com"
                      title="MYWEB3GIFT"
                      description="I am happy to share that I received a crypto gift via ${via} \n if you want to check them out:\n"
                      className="max-h-[50px] hover:scale-105 hover:duration-150 hover:ease-in"
                    >
                      <LinkedinIcon
                        size={50}
                        round={false}
                        className="rounded-xl"
                      />
                    </LinkedinShareButton>
                    <WhatsappShareButton
                      url="https://www.myweb3gift.com"
                      title={`I am happy to share that I received a crypto gift if you want to check them out:\n`}
                      separator=""
                      className="max-h-[50px] hover:scale-105 hover:duration-150 hover:ease-in"
                    >
                      <WhatsappIcon
                        size={50}
                        round={false}
                        className="rounded-xl "
                      />
                    </WhatsappShareButton>
                    <TwitterShareButton
                      url="https://www.myweb3gift.com"
                      className="max-h-[50px] hover:scale-105 hover:duration-150 hover:ease-in"
                      title={`I am happy to share that I received a crypto gift via ${via} \n if you want to check them out:\n`}
                      // via="Myweb3gift"
                      hashtags={hashtags}
                    >
                      <TwitterIcon
                        size={50}
                        round={false}
                        className="rounded-xl"
                      />
                    </TwitterShareButton>
                    <TelegramShareButton
                      url="https://www.myweb3gift.com"
                      className="max-h-[50px] hover:scale-105 hover:duration-150 hover:ease-in"
                      title={`I am happy to share that I received a crypto gift via ${via} `}
                    >
                      <TelegramIcon
                        size={50}
                        round={false}
                        className="rounded-xl"
                      />
                    </TelegramShareButton>
                  </div>
                </div>
              </div>
              <div className="relative max-lg:h-full h-screen px-6 w-6/12 max-lg:pt-0 max-lg:w-full flex items-start justify-center">
                <div
                  className="w-full max-h-[100vh] max-lg:min-h-full rounded-card  gap-4 md:w-[380px] z-10 p-4 flex flex-col items-center relative overflow-hidden rounded-xl"
                  style={{
                    backgroundColor: gift.cardColor
                      ? gift.cardColor
                      : "#082f49",
                    boxShadow: `0 0 10px ${gift.shadowColor}, 0 0 20px ${gift.shadowColor}`,
                  }}
                  ref={cardRef}
                >
                

                  <div
                    className=" w-[150px] h-[150px] bg-contain bg-no-repeat bg-top flex items-center justify-center z-30"
                    style={{ backgroundImage: `url(${gift.image})` }}
                  ></div>
                  <div className="relative lg:pb-6  py-2 w-full h-fit rounded-[10px] bg-white flex flex-col justify-between">
                    <div className="greetingcards whitespace-pre-line px-2 md:leading-8 text-lg w-full overflow-x-visible overflow-y-scroll font-medium text-gray-400">
                      <div className="w-full h-full text-lg px-3 border-none outline-none py-0">
                        <p
                          className="max-sm:text-sm  md:text-2xl lg:text-2xl xl:text-xl capitalize mb-1 font-semibold  "
                          style={{ fontFamily: gift.cardFont }}
                        >
                          Dear {gift.receiverName},
                        </p>
                        <p>
                          <br className="sm:hidden md:block lg:hidden xl:block 2xl:hidden" />
                          {gift.greeting}
                          {/* <br className="sm:hidden md:block lg:hidden xl:block 2xl:hidden" /> */}
                          <br />
                          <br />
                        </p>
                      </div>
                    </div>

                    <div className="w-full h-fit  flex flex-col items-end ">
                      <div className="w-full flex items-start relative justify-between">
                        <p className="pb-4 px-5 pt-3 capitalize text-lg font-semibold text-gray-400">
                          {` 🙂 ${gift.senderName}`}
                        </p>
                      </div>

                      <div className="w-full flex items-start relative justify-between">
                        <div></div>
                        <div className="px-2   pt-1">
                          <QRCodeWithLogo value={websiteURL} logo={LogoCP} />
                        </div>
                        {/* <img className="absolute -bottom-5 z-10  right-2 max-w-[100px]" src={QR_CODE} alt="frame-1" border="0" /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TransferedGift;
