import React, { useEffect } from 'react'
import { useState } from 'react'
import { ChristmasCalendar } from '../assets/icons';
import { useUser } from "@clerk/clerk-react";
import { getDatabase, ref, set } from "firebase/database";
import { Navigate, useNavigate } from 'react-router-dom';
import { useApproval } from '../states/ApprovalContext';
import { useFormData } from '../states/FormDataContext';

const EmailModal = ({label, className,props = ''}) => {
  const { isSignedIn, user } = useUser();
  const { formData, setFormData } = useFormData();
  const [isModalOpen, setIsModalOpen] = useState(false)
  // const { isApproved} = useApproval();
  const navigate = useNavigate();
  const[name,setName]=useState()
  const OpenModal = () => {
      setIsModalOpen(true);
      // //console.log("Modal open");
  }

   const { isApproved, setApproval } = useApproval();
  const [uniqueCode, setUniqueCode] = useState('');
  //const [approve, setApprove] = useState(false);

useEffect(() => {
    const fetchUserData = async () => {
      if (isSignedIn) 
{
        try {

          const response = await fetch(`https://myweb3gift-bd98e-default-rtdb.firebaseio.com/users/${user.id}.json`);
          //console.log(response)
          // const response = await fetch(`https://myweb3gift-bd98e-default-rtdb.firebaseio.com/submitemails/-NkJIn1tg7RhVEh-7-IF.json`);

          //console.log(response)
          if (response.ok) {
            const data = await response.json();
            //console.log('Fetch URL:', `https://myweb3gift-bd98e-default-rtdb.firebaseio.com/submitemails/${user.id}.json`);
            //console.log('data', data)
            //console.log(user.id)

            if (data && typeof data.approve !== 'undefined') {
              setApproval(data.approve);
            }
            //console.log(isApproved)
          } else {
            console.error('Error fetching data:', response.statusText);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
    };

    fetchUserData();
  }, [isSignedIn, user]);














       useEffect(() => {
      const fetchUserData = async () => {
        if (isSignedIn) {
          try {
  
            const response = await fetch(`https://myweb3gift-bd98e-default-rtdb.firebaseio.com/users/${user.id}.json`);
            //console.log(response)
            // const response = await fetch(`https://myweb3gift-bd98e-default-rtdb.firebaseio.com/submitemails/-NkJIn1tg7RhVEh-7-IF.json`);
  
            //console.log(response)
            if (response.ok) {
              const data = await response.json();
              //console.log('Fetch URL:', `https://myweb3gift-bd98e-default-rtdb.firebaseio.com/submitemails/${user.id}.json`);
              //console.log('data', data)
              //console.log(user.id)
  
              if (data && typeof data.approve !== 'undefined') {
                setApproval(data.approve);
              }
              //console.log(isApproved)
            } else {
              console.error('Error fetching data:', response.statusText);
            }
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        }
      };
  
      fetchUserData();
    }, [isSignedIn, user]);
  
    // //console.log(label)
//    const giftDetails = props

//     //console.log('hello',giftDetails)
//     setFormData((prevData) => ({
//         ...prevData,
//         giftDetails: giftDetails,
//       }));


// useEffect(() => {
//     //console.log('props:', props);
//     const giftDetails = props;

//     //console.log('hello', giftDetails);

//     setFormData((prevData) => ({
//       ...prevData,
//       giftDetails: giftDetails,
//     }));
//   }, [props]);


    const handleBuyButtonClick = (props) => {
      
         
          
            // Redirect to /gifts
                setFormData((prevData) => ({
        ...prevData,
        giftDetails: props,
      }));
            navigate('/CreateGiftCard');
    }
          
        
        
        
      //   else {
      //     // Redirect to sign-up if not signed in
      //     navigate('/sign-up/gift');
      //   }
      // };
      
    

    const [isThanksModalOpen, setIsThanksModalOpen] = useState(false)

    // const OpenThanksModal = () => {
    //     setIsThanksModalOpen(true);
    //     //console.log("Thanks Modal open");
    // }


    const closeThanksModal = () => {
        setIsThanksModalOpen(false);
        navigate('/');
        window.location.reload();
        // //console.log("Thanks Modal not open");
    }



    // Get USER dATA 
    const [userDetails, setuserDetails] = useState(

    // const [ setuserDetails] = useState(

        {
            name: "",
            email: ""

        }
    )

    // let name, value;
    // const getUserData = (event) => {

    //     name = event.target.name;
    //     value = event.target.value;
    //     setuserDetails({ ...user, [name]: value });
    // };

    // const postData = async (e) => {

    //     e.preventDefault();

    //     const { name, email } = user;

    //     if (name && email) {

    //         const res = await fetch("https://myweb3gift-bd98e-default-rtdb.firebaseio.com/submitemails.json",

    //             {
    //                 method: "POST",
    //                 headers: {
    //                     "Content-Type": "application/json"
    //                 },

    //                 body: JSON.stringify({
    //                     name,
    //                     email,
    //                     approve: false  // Adding the 'approve' field with the default value of false

    //                 })
    //             }
    //         );
    //         if (res) {
    //             setUser(
    //                 {
    //                     name: "",
    //                     email: "",

    //                 });
    //             setIsModalOpen(false);
    //             setIsThanksModalOpen(true);
    //             // alert("We will contact you, Thankyou 🙂");
    //         }
    //     } else {

    //         alert("Please Write your proper email");
    //     }
    // };
    
    const postData = async (e) => {
        e.preventDefault();
    
        try {
          // Ensure the user is authenticated with Clerk
          if (!isSignedIn) {
            console.error("User not authenticated with Clerk.");
            return;
          }
    
          // Store additional details in Firebase Realtime Database
          const db = getDatabase();
          const userRef = ref(db, `users/${user.id}`);
    

          const userApproveStatus = uniqueCode === 'BaseTester35'; // Change 'your-unique-code' to the desired code

          // Customize this structure based on your form fields
          const userData = {
            email: user.primaryEmailAddress.emailAddress,
            name: name,
            approve: userApproveStatus,
            // Add other form fields as needed
          };
      
    
          await set(userRef, userData);
    
          



          // Reset form fields after successful registration
        //   setName("");
        //   setEmail("");
    
          //console.log("User details stored in Firebase successfully!");
    
          setIsModalOpen(false);
            setIsThanksModalOpen(true);
          // Close the modal
          setIsModalOpen(false);
          setIsThanksModalOpen(true);
          closeModal();
          
      
        } catch (error) {
          console.error("Error storing user details:", error.message);
        }




//           // Show approval message if the unique code is correct
//   if (userApproveStatus) {
//     toast.success('You are approved! You can now send Crypto Gifts to your friends and family.');
//   } else if (uniqueCode.trim() !== '') {
//     // Show invalid code message if the unique code is incorrect
//     toast.error('You have entered the wrong code. Please input the correct unique code.');
//   } else {
//     // Show message if no unique code is provided
//     toast.success('We have received your request! You are added to the waitlist.');
//   }
// } catch (error) {
//   console.error("Error storing user details:", error.message);

//   // Show error toast message
//   toast.error('An error occurred. Please try again.');
// }
// navigate('/');


      };
      const closeModal = () => {
        setIsModalOpen(false);
        
        // //console.log("Modal not open")


    };


    const defaultClasses = '';



    return (
        
        <div className=' flex flex-row '>

            <button className={`${defaultClasses} ${className}`} onClick={() => handleBuyButtonClick(props)} >  {label}   </button>

            {isModalOpen && (
                <div className='fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden backdrop-blur-xl bg-white/30 overflow-y-auto md:inset-0 h-[calc(100%-1rem)] min-h-screen flex justify-center items-start'>

                    <div id="authentication-modal" tabIndex="-1" aria-hidden="true" >
                        <div className="relative w-full max-w-md max-h-full">

                            <div className="relative mt-40 bg-white max-h-2xl min-w-screen rounded-lg shadow dark:bg-gray-700">
                                <button type="button" onClick={closeModal} className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="authentication-modal">
                                    <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                    </svg>
                                    <span className="sr-only">Close modal</span>
                                </button>

                                <img src={ChristmasCalendar} alt="Crypto Calendar" className='h-40' />


                                <div className="px-6 py-6   lg:px-8">
                                    {/* <h3 className="mb-4 max-sm:text-5xl sm:text-4xl font-medium text-gray-900 dark:text-white">Join Our Waitlist and Be the First to Get Exclusive Gifts!🎁 </h3> */}
                                    <h3 className="mb-4 text-4xl md:text-5xl font-extrabold text-primary-600 dark:text-white">
  Join Our Waitlist and Be the First to Get Exclusive Gifts! 🎁
</h3>

                                    <form className="space-y-6" method="POST">
                                        <div className='h-8'></div>
                                        <div >
                                           

<div className='flex flex-1'>
<label htmlFor="name" className="block mb-2 text-xl font-medium text-gray-900 dark:text-white">Name</label>
</div>

                                            <input type="name" name="name" id="name" placeholder="Jacob milton" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-yellow-500 focus:border-yellow-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" value={user.name}
                                                onChange={(e) => setName(e.target.value)} required true />
                                        </div>
                                        <div className=''></div>
                                        <div>


<div className='flex flex-1'>
<label htmlFor="email" className="block mb-2 text-xl font-medium text-gray-900 dark:text-white">Your email</label>
</div>

                                            <input type="email" name="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-yellow-500 focus:border-yellow-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" placeholder="name@xyz.com" value={user.primaryEmailAddress.emailAddress}
                                                 required disabled/>
                                        </div>


                                        <div>
  <label htmlFor="uniqueCode" className="block mb-2 text-xl font-medium text-left text-gray-900 dark:text-white">
    Unique code if have
  </label>
  <input
    type="text"
    name="uniqueCode"
    value={uniqueCode}
    id="uniqueCode"
    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
    placeholder="Enter your unique code"
    onChange={(e) => setUniqueCode(e.target.value)}
  />
</div>


                                        <div className=''></div>

                                        <button type="submit"
                                            onClick={postData} className="w-full  text-white bg-yellow-400 hover:bg-yellow-600 focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-2xl px-5 py-2.5 text-center dark:bg-yellow-600 dark:hover:bg-yellow-400 dark:focus:ring-yellow-600">Submit</button>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}








            {isThanksModalOpen && (
                <div className='fixed top-0 left-0  right-0 z-50 w-full p-4 overflow-x-hidden backdrop-blur-xl bg-white/30 overflow-y-auto md:inset-0 h-[calc(100%-1rem)] min-h-full flex justify-center items-center'>

                    <div id="authentication-modal" tabIndex="-1" aria-hidden="true" >
                        <div className="relative w-full max-w-md max-h-full">

                            <div className="relative bg-white max-h-2xl min-w-screen rounded-3xl shadow dark:bg-gray-700">
                                <button type="button" onClick={closeThanksModal} className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="authentication-modal">
                                    <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                    </svg>
                                    <span className="sr-only">Close modal</span>
                                </button>
                                <div className=' flex flex-col justify-center align-items-center '>
                                    <img src={ChristmasCalendar} alt="Crypto Calendar" className='h-1/2' />
                                    <div className="px-6 py-6 lg:px-8">
                                        <h3 className="mb-4 max-sm:text-5xl sm:text-4xl text-center font-medium text-gray-900 dark:text-white">  Thankyou 🙂   </h3>
                                    </div>
                                    <button type="submit"
                                            onClick={closeThanksModal} className="w-full  text-white bg-yellow-400 hover:bg-yellow-600 focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-2xl px-5 py-2.5 text-center dark:bg-yellow-600 dark:hover:bg-yellow-400 dark:focus:ring-yellow-600">CLOSE</button>
                                </div>
                            </div>
                        </div>
                    </div>




                </div>
            )}








        </div>
    )
}

export default EmailModal




// import React from 'react'

// const OpenModalEmailForm = () => {
//   return (
// <>



// </>
//   )
// }

