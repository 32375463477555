import React from 'react'
import FDoor from './FDoor'
import { motion } from "framer-motion";
import SDoor from './SDoor';
import TDoor from './TDoor';
import styles from "../style/door8.module.css";


export default function Door8comp() {
  const fadeInVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };
  const container1 = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.5,
      },
    },
  };

  return (
    <>
      <motion.div
        initial="hidden"
        animate="visible"
        variants={{ fadeInVariants, container1 }}
        transition={{ duration: 1 }}
        className={styles.container8}
      >
        <FDoor />
        <SDoor />
        <TDoor />
      </motion.div>
    </>
  );
}
