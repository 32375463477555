import React from "react";
import { LogoCP } from "../assets/Images/MyWeb3Gift-red2";
import Nav01 from "../Components/Nav01";
import { Link } from "react-router-dom";
import { SignInButton, SignedOut } from "@clerk/clerk-react";
import { useUser } from "@clerk/clerk-react";
import { AnimatePresence,motion } from "framer-motion";
import { giftsentbg } from "../assets/Images";
const GiftSentCard2 = () => {
  const { user } = useUser();
  const { isSignedIn } = useUser();

  return (
    <div>
      <Nav01 />
     
      <motion.div initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}  
              transition={{duration:1,delay:"0.6"}}    
                       viewport={{once:true}} className="z-10 absolute bg-sky-950 h-[90px] w-full"></motion.div>
      <AnimatePresence>
      <motion.div initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}  
              transition={{duration:1,delay:"0.6"}}    
                       viewport={{once:true}}
        className="relative mx-auto flex min-h-[100vh] min-w-[100%] flex-col justify-center items-center bg-cover bg-no-repeat overflow-x-hidden bg-center"
        style={{
          backgroundImage:`url(${giftsentbg})`
        }}
      >
        <div className="max-w-[500px]  min-h-[200px] flex-col rounded-3xl flex items-center justify-start py-10 mt-20 px-6 bg-white gap-3">
          <img
            src={LogoCP}
            className=" w-20 rounded-full transition hover:ease-in-out hover:duration-300 hover:delay-300  hover:scale-110     "
            alt="Logo"
          />
          <h1 className="text-2xl font-bold text-center">
            🎁 Gift Sent Successfully !🚀
          </h1>
          <SignedOut>
            <>
              <p className=" font-medium text-left">
              <ul className="w-full">
              
              <h2 className="font-semibold text-xl mb-2"> Log in to secure the following benefits:</h2>
             <li className="list-inside list-disc"> login and get 5 more extra credits </li> 

             <li className="list-inside list-disc mb-1"> track your gift history</li> 
             <li className="list-inside list-disc mb-1"> get personalized features</li> 
             <li className="list-inside list-disc"> and have a more convenient gifting experience. </li> 
                </ul>
              </p>
            </>
            <Link to = "/loginpage">
            <button className="items-center rounded-3xl bg-yellow-300 px-9 py-2 text-xl font-bold text-white hover:bg-yellow-400 focus:ring-2">
            {/* <SignInButton /> */}
            sign in
           
          </button>
          </Link>
          </SignedOut>
          {isSignedIn && (

          <Link to="/TransferedGifts">

          <button className="items-center rounded-3xl bg-yellow-300 px-9 py-2 text-xl font-bold text-white hover:bg-yellow-400 focus:ring-2">
           View Sent Gifts
          </button>
          </Link>
            )} 
          
        </div>
      </motion.div>
      </AnimatePresence>
    </div>
  );
};
// export default GiftSentSuccess;
export default GiftSentCard2;
