export function Down(props) {
  return (
    <svg
    id="Layer_1"
        data-name="Layer 1"
     xmlns="http://www.w3.org/2000/svg"
     viewBox="0 0 24 24"
     {...props}
     >
        <path d="M11.9997 13.1714L16.9495 8.22168L18.3637 9.63589L11.9997 15.9999L5.63574 9.63589L7.04996 8.22168L11.9997 13.1714Z">
    </path>
    </svg>
  )
}
