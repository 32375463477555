import React from 'react'
import { motion } from "framer-motion";
import styles from "../style/door6.module.css";
import { Fimg } from '../../DoorsImages/Doorimg6';

export default function FDoor() {
  return (
    <>
    <motion.div
        className={styles.section1}
        initial="hidden"
        animate="visible"
        //  variants={fadeInVariants}
        transition={{ duration: 1 }}
      >
        <div className={styles.walletboxheading}>
          <motion.div
            layout
            initial={{
              x: "-15vw",
             opacity:0
            }}
            animate={{ x: 0, opacity:1}}
            // animate={{ opacity: 1, y: 0 }}
            transition={{
              duration:1
            }}
          >
            <h1 className="text-white text-3xl font-bold pt-5 ">
            Explorer (polygon scan)
            </h1>
            <p className="text-white mt-6 text-lg tracking-widest">
            Now that we have a wallet and have minted our first NFT, let's take a look under the hood of the blockchain. We'll begin by exploring the various tools and platforms available.
<br></br>
<br></br>
A blockchain explorer is a web-based tool or application that allows users to view and interact with the data on a blockchain. It provides a user-friendly interface to explore and analyze transactions, addresses, and other relevant information on the blockchain.
            </p>
          </motion.div>
        </div>
        <motion.div
            
            initial={{
              x: "15vw",opacity:0,
            }}
            whileInView={{ x: 0, opacity:1 }}
            transition={{
             duration:0.6
            }}
            whileHover={{scale:0.9,transition:{type:"spring",duration:1}}}
            exit={{x:"-60vw", scale:[1,0],transition:{duration:1}}}
             viewport={{once:true}}
         className={styles.walletimg}>
          <img src={Fimg} alt="img1" />

        </motion.div>
        </motion.div>
        
    
    </>
  )
}
