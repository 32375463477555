import React from "react";
import styles from "../style/door14.module.css";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
export default function TDoor() {
  const fadeInVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };
  const container1 = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.5,
      },
    },
  };
  return (
    <motion.div
      className={styles.section3}
      initial="hidden"
      animate="visible"
      variants={{ fadeInVariants, container1 }}
      transition={{ duration: 1 }}
    >
      <div className={styles.nftboxheading3}>
        <motion.div
          initial={{ x: "-15vw", opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{
            duration: 1,
          }}
          viewport={{ once: true }}
          className={styles.nfth1}
        >
          <h1 className="text-white font-bold text-center text-3xl tracking-wide">
            Links to Explore more
          </h1>
        </motion.div>
        <div className={styles.nfttextbox}>
          <div className={styles.div1}>
            <motion.p
              initial={{ x: "-15vw", opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{
                duration: 1,
              }}
              viewport={{ once: true }}
              className="text-lg text-white tracking-wider"
            >
              Use below links to know about Deepdive DeFi
            </motion.p>
            
            <motion.button
              initial={{ x: "-100%", opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{
                duration: 1,
              }}
              viewport={{ once: true }}
              className={styles.btn}
            >
              <a target="_blank" href="#">Click now</a>
            </motion.button>
            
          </div>
        
        </div>
      </div>
    </motion.div>
  );
}
