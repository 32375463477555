import React, {useEffect} from 'react'
import Nav01 from '../Components/Nav01'
import { Footer } from '../sections_landing'
import { LocomotiveScrollProvider } from "react-locomotive-scroll";
import 'locomotive-scroll/dist/locomotive-scroll.css';
import { useRef } from "react";
import { AnimatePresence, motion } from 'framer-motion';
// import { Bert_staf, Valerio_Jacobi, Arpit_Singh } from "../assets/Images/Team";

const About = () => {
  const ref = useRef(null);

  const options = {
    smooth: true,
  }

  useEffect(() => {
    document.addEventListener('contextmenu', (event) => {
      event.preventDefault();
      // Add your custom action here, e.g., display a message or redirect
      // alert('Right-clicking is disabled on this website.');
    });
  }, []);

  return (

    <div className='overflow-hidden'>
      <>
        <div>
          <AnimatePresence>
            <Nav01></Nav01>
            <motion.div
              initial={{ opacity: 0, y: "-100%" }}
              whileInView={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: "-100%" }}
              transition={{ duration: "0.7" }} className="z-10 absolute bg-sky-950 h-[90px] w-full"></motion.div>
          </AnimatePresence>
        </div>
        {/* <LocomotiveScrollProvider options={options} containerRef={ref}>
      
          <main data-scroll-container ref={ref}> */}
        <AnimatePresence>
          <motion.section initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 2 }}
            viewport={{ once: true }} className="select-none sm:mx-20 max-sm:mx-5 pt-20 lg:pt-[120px] pb-12 lg:pb-[90px] overflow-hidden">

            <div className='flex items-center justify-center'>

              <div className="container">

                <div className=' '>

                  <div className="flex flex-wrap max-lg:justify-center max-lg:flex-col items-center justify-between -mx-4">

                    <div className="flex py-10 flex-wrap max-lg:justify-center max-lg:flex-col items-center justify-between -mx-4">
                      <div className="w-full px-4 lg:w-1/3">
                        <TeamCard
                          name="Valerio Jacobi"
                          profession="Co-Founder & CEO"
                          imageSrc="https://firebasestorage.googleapis.com/v0/b/myweb3gift-bd98e.appspot.com/o/Team_Pictures%2Fview-smiling-3d-man-using-tablet1.jpg?alt=media&token=ce622eec-71cc-4697-a77d-37f1220bdd81"
                        />
                      </div>

                      <div className="w-full px-4 lg:w-1/3">
                        <TeamCard
                          name="Bert Staufenbiel"
                          profession="Co-Founder & CKA"
                          imageSrc="https://firebasestorage.googleapis.com/v0/b/myweb3gift-bd98e.appspot.com/o/Team_Pictures%2F3d-dia-de-muertos-cartoon-style-illustration.jpg?alt=media&token=3c5ecd8a-2770-46c9-bb05-b01bdedd48ad"
                        />

                      </div>

                      <div className="w-full px-4 lg:w-1/3">
                        <TeamCard
                          name="Arpit Singh"
                          profession="Tech Lead & Web3 Developer"
                          imageSrc="https://firebasestorage.googleapis.com/v0/b/myweb3gift-bd98e.appspot.com/o/Team_Pictures%2Farpitsiog.jpg?alt=media&token=86b864dc-e8f8-48d8-8149-dccaed4951ce"
                        />
                      </div>
                    </div>

                    <div className="flex flex-wrap max-lg:justify-center max-lg:flex-col items-center justify-between -mx-4">
                      <div className="w-full px-4 lg:w-1/3">
                        <TeamCard
                          name="Amaka Chukwu"
                          profession="Business Development Manager"
                        imageSrc="https://firebasestorage.googleapis.com/v0/b/myweb3gift-bd98e.appspot.com/o/Team_Pictures%2Fcartoon-woman-wearing-glasses%20(1).jpg?alt=media&token=ae8dc0b0-3be1-4bd8-8eee-198255538225"
                        />
                      </div>
                      <div className="w-full px-4 lg:w-1/3">
                        <TeamCard
                          name="Ashish Pandey"
                          profession="Fullstack Developer"
                        imageSrc="https://firebasestorage.googleapis.com/v0/b/myweb3gift-bd98e.appspot.com/o/Team_Pictures%2Fcartoon-man-wearing-glasses%20(1).jpg?alt=media&token=a795426a-e4aa-4de0-b02a-cc9287d5f029" 
                        />
                      </div>
                      <div className="w-full px-4 lg:w-1/3">
                        <TeamCard
                          name="Obinna Chukwu"
                          profession="Web3 Developer"
                        imageSrc="https://firebasestorage.googleapis.com/v0/b/myweb3gift-bd98e.appspot.com/o/Team_Pictures%2Fcartoon-man-wearing-glasses.jpg?alt=media&token=e76d6f49-b0a2-4020-9443-e9e0deeb4e3c"
                        />
                      </div>
                    </div>

                    <div className="flex py-10 flex-wrap max-lg:justify-center max-lg:flex-col items-center justify-between -mx-4">
                      <div className="w-full px-4 lg:w-1/3">
                        <TeamCard
                          name="Muskan"
                          profession="Fullstack React Developer"
                        imageSrc="https://firebasestorage.googleapis.com/v0/b/myweb3gift-bd98e.appspot.com/o/Team_Pictures%2Fcartoon-woman-wearing-glasses.jpg?alt=media&token=e42ffb20-6dda-498e-a1c8-0ff023ef0cd7" 
                        />
                      </div>
                      <div className="w-full px-4 lg:w-1/3">
                        <TeamCard
                          name="Jonas Schneider"
                          profession="Web3 Strategist"
                        imageSrc="https://firebasestorage.googleapis.com/v0/b/myweb3gift-bd98e.appspot.com/o/Team_Pictures%2Fmedium-shot-man-digital-art.jpg?alt=media&token=277f5ad5-cfc0-41bb-8343-84209a618479"
                        />
                      </div>
                      <div className="w-full px-4 lg:w-1/3">
                        <TeamCard
                          name="Himanshu"
                          profession="UI/UX Designer"
                        imageSrc="https://firebasestorage.googleapis.com/v0/b/myweb3gift-bd98e.appspot.com/o/Team_Pictures%2Fportrait-man-cartoon-style1.jpg?alt=media&token=762f3e4f-f180-44bf-99e7-382e7db01a38" 
                        />
                      </div>
                    </div>

                    <div className="flex py-10 flex-wrap max-lg:justify-center max-lg:flex-col items-center justify-between -mx-4">
                      <div className="w-full px-4 lg:w-1/3">
                        <TeamCard
                          name="Clara Wagner"
                          profession="Solidity Developer"
                        imageSrc="https://firebasestorage.googleapis.com/v0/b/myweb3gift-bd98e.appspot.com/o/Team_Pictures%2Fdoor-leading-magical-world.jpg?alt=media&token=a82c1ebc-30f4-4da3-b264-e63f001935b1" 
                        />
                      </div>
                      <div className="w-full px-4 lg:w-1/3">
                        <TeamCard
                          name="Paul"
                          profession="Social Media Manager"
                        imageSrc="https://firebasestorage.googleapis.com/v0/b/myweb3gift-bd98e.appspot.com/o/Team_Pictures%2Fone-young-adult-posing-with-futuristic-eyeglasses-generated-by-ai.jpg?alt=media&token=5c4eb080-1b7e-48bc-921b-fba8a6ebe414"
                        />
                      </div>
                      <div className="w-full px-4 lg:w-1/3">
                        <TeamCard
                          name="Felix Becker"
                          profession="Legal Advisor"
                        imageSrc="https://firebasestorage.googleapis.com/v0/b/myweb3gift-bd98e.appspot.com/o/Team_Pictures%2Fmedium-shot-man-futuristic-portrait.jpg?alt=media&token=0906ddcb-5e6e-45b4-b440-9d93b8d802cd"
                        />
                      </div>
                    </div>

                    <div className="w-full px-4 max-sm:w-full xl:w-5/12 max-lg:w-full">
                      <div className="mt-10 lg:mt-0 ">
                        <span className="block mb-2 text-xl font-semibold text-primary">
                          Introduction
                        </span>
                        <h2 className="mb-8 text-4xl  font-bold text-dark sm:text-5xl">
                          About MyWeb3Gift
                        </h2>
                        <p className="mb-8 text-lg text-body-color">
                          MyWeb3Gift is on a mission to revolutionize gifting by combining the world of cryptocurrencies and blockchain with the joy of sharing. Our unique gifting experiences offer recipients an engaging journey into the world of crypto.
                        </p>
                        {/* <a
    href="/#"
    className="inline-flex items-center justify-center px-10 py-4 text-base font-normal text-center text-white rounded-lg bg-primary hover:bg-opacity-90 lg:px-8 xl:px-10"
  >
    Get Started
  </a> */}
                      </div>
                    </div>

                    <div className="w-full px-4 sm:py-10 lg:w-1/2 xl:w-5/12">
                      <div className="mt-10 lg:mt-0">
                        <span className="block mb-2 text-xl font-semibold text-primary">
                          Our Story
                        </span>
                        <h2 className="mb-8 text-4xl font-bold text-dark sm:text-5xl">
                          Our Journey
                        </h2>
                        <p className="mb-8 text-lg text-body-color">
                          Born out of a passion for blockchain and a commitment to simplifying its complexities, MyWeb3Gift was founded by a team of crypto enthusiasts. We believe that crypto education should be exciting and accessible, and that's exactly what we deliver through our gifting platform.
                        </p>
                        {/* <a
    href="/#"
    className="inline-flex items-center justify-center px-10 py-4 text-base font-normal text-center text-white rounded-lg bg-primary hover:bg-opacity-90 lg:px-8 xl:px-10"
  >
    Get Started
  </a> */}
                      </div>
                    </div>

                    {/* <div className="w-full px-4 lg:w-1/2 xl:w-5/12">
    <div className="mt-10 lg:mt-0">
      <span className="block mb-2 text-xl font-semibold text-primary">
        What Sets Us Apart
      </span>
      <h2 className="mb-8 text-4xl font-bold text-dark sm:text-5xl">
        Uniqueness of MyWeb3Gift
      </h2>
      <p className="mb-8 text-lg text-body-color">
        What makes MyWeb3Gift stand out is our innovative approach to learning about blockchain. Our 10-day educational calendars and gift boxes provide users with hands-on experience, NFTs, and exclusive perks. By the end of our journey, users have more blockchain knowledge than 95% of the population.
      </p>
 
    </div>
  </div> */}


                  </div>

                </div>

              </div>

            </div>

          </motion.section>
        </AnimatePresence>
        {/* </main>

       </LocomotiveScrollProvider> */}
        <Footer />
      </>


    </div>
  )
}

export default About


const TeamCard = ({ imageSrc, name, profession, profession2 }) => {
  return (
    <>
      <div className="w-full px-4 max-[1280px]:mx-0 mx-4 max-lg:mx-0 flex items-center justify-center w-full">
        <div className="mx-auto  max-w-[370px]">
          <div className="relative overflow-hidden min-w-[320px] min-h-[370px] rounded-lg max-[1280px]:min-w-[250px]">
            <img src={imageSrc} alt=" Team member" className="rounded-lg" loading="lazy" />
            <div className="absolute left-0 w-full bottom-1 text-center max-lg:bottom-1 max-[1280px]:bottom-10">
              <div className="relative px-3 py-5 mx-5 overflow-hidden max-[1280px]:mx-2 max-[1280px]:px-2 max-[1280px]:py-2  bg-white rounded-lg">
                <h3 className="text-xl max-sm:text-lg font-semibold text-dark">{name}</h3>
                <p className="text-lg max-sm:text-sm text-body-color">{profession}</p>
                <p className="text-lg max-sm:text-lg hidden text-body-color">{profession2}</p>
                <a href="#"><img src="" alt="" /></a>
                <div>
                  <span className="absolute bottom-0 left-0">
                    <svg
                      width={61}
                      height={30}
                      viewBox="0 0 61 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx={16}
                        cy={45}
                        r={45}
                        fill="#13C296"
                        fillOpacity="0.11"
                      />
                    </svg>
                  </span>
                  <span className="absolute top-0 right-0">
                    <svg
                      width={20}
                      height={25}
                      viewBox="0 0 20 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="0.706257"
                        cy="24.3533"
                        r="0.646687"
                        transform="rotate(-90 0.706257 24.3533)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="6.39669"
                        cy="24.3533"
                        r="0.646687"
                        transform="rotate(-90 6.39669 24.3533)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="12.0881"
                        cy="24.3533"
                        r="0.646687"
                        transform="rotate(-90 12.0881 24.3533)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="17.7785"
                        cy="24.3533"
                        r="0.646687"
                        transform="rotate(-90 17.7785 24.3533)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="0.706257"
                        cy="18.6624"
                        r="0.646687"
                        transform="rotate(-90 0.706257 18.6624)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="6.39669"
                        cy="18.6624"
                        r="0.646687"
                        transform="rotate(-90 6.39669 18.6624)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="12.0881"
                        cy="18.6624"
                        r="0.646687"
                        transform="rotate(-90 12.0881 18.6624)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="17.7785"
                        cy="18.6624"
                        r="0.646687"
                        transform="rotate(-90 17.7785 18.6624)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="0.706257"
                        cy="12.9717"
                        r="0.646687"
                        transform="rotate(-90 0.706257 12.9717)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="6.39669"
                        cy="12.9717"
                        r="0.646687"
                        transform="rotate(-90 6.39669 12.9717)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="12.0881"
                        cy="12.9717"
                        r="0.646687"
                        transform="rotate(-90 12.0881 12.9717)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="17.7785"
                        cy="12.9717"
                        r="0.646687"
                        transform="rotate(-90 17.7785 12.9717)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="0.706257"
                        cy="7.28077"
                        r="0.646687"
                        transform="rotate(-90 0.706257 7.28077)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="6.39669"
                        cy="7.28077"
                        r="0.646687"
                        transform="rotate(-90 6.39669 7.28077)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="12.0881"
                        cy="7.28077"
                        r="0.646687"
                        transform="rotate(-90 12.0881 7.28077)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="17.7785"
                        cy="7.28077"
                        r="0.646687"
                        transform="rotate(-90 17.7785 7.28077)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="0.706257"
                        cy="1.58989"
                        r="0.646687"
                        transform="rotate(-90 0.706257 1.58989)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="6.39669"
                        cy="1.58989"
                        r="0.646687"
                        transform="rotate(-90 6.39669 1.58989)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="12.0881"
                        cy="1.58989"
                        r="0.646687"
                        transform="rotate(-90 12.0881 1.58989)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="17.7785"
                        cy="1.58989"
                        r="0.646687"
                        transform="rotate(-90 17.7785 1.58989)"
                        fill="#3056D3"
                      />
                    </svg>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};