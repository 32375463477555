import React from 'react'
import styles from "../style/door12.module.css";
import {motion} from "framer-motion";
import Card1 from './Card1';
import { Elipse1, Elipse2, Elipse3, cs1, cs2, cs3 } from '../../DoorsImages/Doorimg12';

export default function FDoor() {
  return (
    <motion.div
    className={styles.section1}
    initial="hidden"
    animate="visible"
    //  variants={fadeInVariants}
    transition={{ duration: 1 }}
  >
      <motion.div
        layout
        initial={{
          x: "-15vw",
      opacity:0
        }}
        animate={{ x: 0, opacity:1 }}
        transition={{
        duration:1
        }}
        viewport={{once:true}}
        className={styles.walletboxheading}
      >
        <h1 className="text-white text-center text-4xl font-medium tracking-wider">
        Here's an overview of oracles in blockchain
        </h1>
        <h3 className=" mt-2 text-white text-center italic text-3xl font-light ">Why are oracles needed?</h3>
       
      </motion.div>
      <div className={styles.doo12cardds}>

    <motion.div
    
        initial={{
          x: "-15vw",
         opacity:0
        }}
        whileInView={{ x: 0,opacity:1}}
        transition={{
          duration:1
        }}
        viewport={{once:true}}
    className={styles.door12cards}
    >
<Card1 navhead="Access to External Data" cardimg={cs1}/>
<Card1 navhead="Enhancing Use Cases" cardimg={cs3}/>
<Card1 navhead="Interoperability" cardimg={cs2}/>
    </motion.div>
    </div>
    </motion.div>
    
  )
}
