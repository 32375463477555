import React from 'react'
import Nav01 from "../../Components/Nav01";
import { Footer } from "../../sections_landing";
import DoorComplete from "../DoorComplete";
import Door12comp from '../DoorComponents/Doorcomponent12/Door12comp';
import styles from "../DoorComponents/style/door12.module.css";
import { LocomotiveScrollProvider } from "react-locomotive-scroll";
import 'locomotive-scroll/dist/locomotive-scroll.css'

import { useRef } from "react";
import { AnimatePresence } from 'framer-motion'
import { useParams } from 'react-router-dom';
import Verify from './Verify';

const ADoor12 = () => {
  
  const ref = useRef(null);
  const params = useParams();
  const paramsid = params.id
  const options = {
    smooth: true,
  } 
  return (
    <>
     <div className=''><Verify params={paramsid}/></div>

       <div>
<Nav01></Nav01>
{/* <div className="z-10 absolute bg-sky-950 h-[90px] w-screen"></div> */}
</div>
  
    <LocomotiveScrollProvider options={options} containerRef={ref}>
    <AnimatePresence>

        <main data-scroll-container ref={ref} className='select-none'>
    <div style={{backgroundColor:"#00352c",overflow:"hidden"}} className='pt-20'>
   
    <div>
    <div className={styles.container11}>
        <Door12comp/>
        </div>
        <div className="flex flex-row justify-center mb-3">
          <DoorComplete Door={12} id={paramsid}/>
        </div>
        <Footer></Footer>
      </div>
    </div>
    </main>
    </AnimatePresence>
    </LocomotiveScrollProvider>
    </>
  )
}

export default ADoor12