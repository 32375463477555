import React from "react";
import styles from "../style/door14.module.css";
import { motion } from "framer-motion";
import {  Testnets } from "../../DoorsImages/Doorimg23";

export default function FDoor() {
  return (
    <>
      <motion.div
        className={styles.section1}
        initial="hidden"
        animate="visible"
        transition={{ duration: 1 }}
      >
        <motion.div 
        initial={{x: "-15vw",opacity:0}}
        whileInView={{ x: 0,opacity:1}}
        transition={{
          duration:1
        }}
        viewport={{once:true}}
         className={styles.walletboxheading}>
            <h1 className="text-white text-4xl font-bold pt-5 ">
            Testnets
            </h1>
            <p className="text-white mt-6 text-xl tracking-widest">
            A testnet is a blockchain network used for testing and development purposes before deploying a new blockchain or updating an existing one on the mainnet (production network). It allows developers and users to experiment with blockchain applications, test smart contracts, and identify potential issues without risking real assets or transactions.
                        </p>
          </motion.div>
          <motion.div 
        initial={{x: "15vw",opacity:0}}
        whileInView={{ x: 0,opacity:1}}
        transition={{duration:1}}
        viewport={{once:true}}
        className={styles.walletimgbox}
          
        >
          <img src={Testnets} alt="img" />   
        </motion.div>
        
      </motion.div>
    </>
  );
}
