import React from 'react'
import styles from "../style/door10.module.css";
import {motion } from 'framer-motion';
export default function TDoor() {
  return (
    <div className={styles.section3}>
      <motion.p initial={{ x: "-15vw",opacity:0}}
            whileInView={{ x: 0, opacity:1 }}
            transition={{duration:0.6}}
             viewport={{once:true}} className='text-lg tracking-wider'>
      Understanding the different token types is crucial for participants in the blockchain ecosystem, as each type serves a specific function and comes with its own set of rules and use cases. The choice of token type depends on the intended purpose of the project or application built on the blockchain.
      </motion.p>
    </div>
  )
}
