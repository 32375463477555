import React from "react";
import Nav01 from "../../Components/Nav01";
import { Footer } from "../../sections_landing";
// import { Link } from 'react-router-dom';
import DoorComplete from "../DoorComplete";
import Door6comp from '../DoorComponents/Doorcomponent6/Door6comp';
import styles from "../DoorComponents/style/door6.module.css";

import { LocomotiveScrollProvider } from "react-locomotive-scroll";
import 'locomotive-scroll/dist/locomotive-scroll.css'

import { useRef } from "react";
import { AnimatePresence } from 'framer-motion'
import { useParams } from 'react-router-dom';
import Verify from "./Verify";

const ADoor4 = () => {
  const ref = useRef(null);
  const params = useParams();
  const paramsid = params.id
  const options = {
    smooth: true,
  } 
  return (
    <>
     <div className=''><Verify params={paramsid}/></div>
    <div>
    <Nav01></Nav01>
    {/* <div className="z-10 absolute bg-sky-950 h-[90px] w-screen"></div> */}
    </div>
    <LocomotiveScrollProvider options={options} containerRef={ref}>
      <AnimatePresence>

          <main data-scroll-container ref={ref} className="select-none">

    {/* <div style={{ backgroundColor: "#0a0a0a",overflow:"hidden" }} className="pt-20">
     

      <iframe
        title="CatAttackNFT"
        src="https://catattacknft-main.vercel.app/"
        frameBorder="0"
        style={{ width: '100%', height: '100vh' }}
      ></iframe>
     

      <div className="flex flex-row justify-center mb-3">
        <DoorComplete Door={4}/>
      </div>

      <Footer></Footer>
    </div> */}
    <div style={{backgroundColor:"#3f6212",overflow:"hidden"}} className='pt-20'>
    <div>
    <div className={styles.container4}>
          <Door6comp/>
        </div>
        <div className="flex gap-4 flex-row justify-center">
       
          <DoorComplete Door={4} id={paramsid}/>
        </div>

        <Footer></Footer>
      </div>
    </div>
    </main>
    </AnimatePresence>
    </LocomotiveScrollProvider>
    </>
  );
};

export default ADoor4;
