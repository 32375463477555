import React from 'react'
import styles from "../style/door10.module.css";
import Card1 from './Card1';

import Card2 from './Card2';
import { Biscuit, Crypto, Cymbals, Ledger, Money2, Nft4, Tenge } from '../../DoorsImages/Doorimg10';

export default function SDoor() {

  return (
    <div className={styles.section2}>      
      <Card1 title=" Cryptocurrencies/Protocol tokens :" img={Crypto} 
para="Example: Bitcoin (BTC), Ethereum (ETH)
Purpose: Protocol tokens are used as a medium of exchange or store of value within a blockchain network."        
      />
      <Card2 title="Utility Tokens :" img={Tenge} 
para="Example: Ethereum-based ERC-20 tokens (e.g., Binance Coin, Chainlink)
Purpose: Provide access to a specific product or service within a blockchain platform. These tokens are often used for governance, giving holders voting rights on protocol changes."  
/>
      <Card1 title="Security Tokens :" img={Ledger}
  para="Example: tZero, Polymath
Purpose: Represent ownership in real-world assets such as stocks, bonds, or real estate. Security tokens are subject to regulations governing traditional securities."
 />
      <Card2 title="Non-Fungible Tokens (NFTs) :" img={Nft4} 
      para="Example: CryptoKitties, Decentraland 
Purpose: Represent ownership of unique, indivisible digital or physical assets. NFTs are often used for digital art, virtual real estate, or collectibles."
       />
      <Card1 title="Stable coins" img={Money2} 
para="Example: Tether (USDT), USD Coin (USDC)
Purpose: Designed to maintain a stable value by pegging it to a reserve of assets (such as fiat currencies) to reduce volatility, stablecoins serve as a stable medium of exchange within the crypto space. Given the significant role stablecoins play in the Web3 space, we have dedicated tomorrow exclusively to this type."  
/>
      <Card2 title="Governance Tokens" img={Cymbals} 
para="Example: MakerDAO (MKR), Compound (COMP)
Purpose: Enable holders to participate in the governance of a blockchain protocol by voting on proposals or decisions related to the platform's development and management."  
/>
      <Card1 title="Wrapped Tokens" img={Biscuit}
para="Example: Wrapped Bitcoin (WBTC) 
Purpose: Represent assets from one blockchain on another. Wrapped tokens are pegged to the value of an underlying asset but operate on a different blockchain."        
       />

      </div>
  )
}
