import React, { useState, useRef,useEffect } from 'react';
import { Menu } from '@headlessui/react';
import { Link } from 'react-router-dom';

import {
    collectionGroup,
    query,
    getDocs,
    getFirestore,
    where,
  } from "@firebase/firestore";
  import { useUser } from "@clerk/clerk-react";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const MenuItem = ({ to, children }) => (

  <Menu.Item>
    {({ active }) => (
      <Link
        to={to}
        className={classNames(
          active ? 'bg-yellow-100 py-2 text-lg rounded-xl text-gray-900' : 'text-gray-700',
          'block px-4  py-2 text-lg'
        )}
      >
        {children}
      </Link>
    )}
  </Menu.Item>
);

export default function History() {


    const { user } = useUser();
    const [gifts, setGifts] = useState([]);
    const db = getFirestore();
    const {isSignedIn} = useUser()
  
    const fetchGiftsByReceiverEmail = async (receiverEmail) => {
      try {
        // Reference to the "gifts" collection group
        const giftsCollectionGroupRef = collectionGroup(db, "gifts");
  
        // Query to filter gifts based on receiver's email
        const query1 = query(
          giftsCollectionGroupRef,
          where("receiverEmail", "==", receiverEmail)
        );
  
        // Fetch documents that match the query
        const querySnapshot = await getDocs(query1);
  
        const fetchedGifts = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
  
        // console.log("Receiver's gifts: ", fetchedGifts);
        setGifts(fetchedGifts);
      } catch (error) {
        console.error("Error fetching gifts by receiver email:", error);
      }
    };
  

  const [isMenuOpen, setMenuOpen] = useState(false);
  const [delayedClose, setDelayedClose] = useState(null);
  const menuRef = useRef(null);

  const handleMouseEnter = () => {
    setMenuOpen(true);
    clearTimeout(delayedClose);
  };

  const handleMouseLeave = () => {
    setDelayedClose(setTimeout(() => setMenuOpen(false), 30)); // Adjust the delay time as needed
  };

  useEffect(() => {
    if (!user || !user.primaryEmailAddress) {
      // console.log("User or primaryEmailAddress is not defined.");
      return;
    }

    // const receiverEmail = user.primaryEmailAddress.emailAddress;
    const receiverEmail = user.primaryEmailAddress.emailAddress;
    // console.log("Receiver Email:", receiverEmail);

    fetchGiftsByReceiverEmail(receiverEmail);
  
  }, [user]);









  return (
    <Menu
      as="div"
      className="relative inline-block text-left"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div>
        <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md px-3 py-2 text-lg font-semibold text-white ring-inset ring-gray-300 hover:bg-yellow-500">
          HISTORY
        </Menu.Button>
      </div>

      {isMenuOpen && (
        <div
          ref={menuRef}
          className="absolute right-0 z-10 mt-2 w-48 min-h-[10vh] origin-top-right rounded-md bg-opacity-80 bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className="py-5 mx-4  flex flex-col gap-1">
          <MenuItem to="/mygifts">
                          <button
                            aria-label="view favourites"
                            className="relative focus:outline-none"
                          >
                            <span className=" justify-center rounded-md py-2 ring-inset ring-gray-300 ">
                              Received
                            </span>
                            {gifts.length > 0 ? (
                              <span className="absolute top-0 right-0 -mt-2 -mr-8 px-2 py-1 bg-blue-500 text-white  rounded-full text-xs">
                                {gifts.length}
                              </span>
                            ) : null}
                          </button>
                        </MenuItem>
            {/* <MenuItem to="/mygifts">Received </MenuItem> */}
            <MenuItem to="/TransferedGifts">
            <span className=" justify-center rounded-md py-2 ring-inset ring-gray-300 ">
                              Sent
                            </span>
            </MenuItem>
          </div>
        </div>
      )}
    </Menu>
  );
}

