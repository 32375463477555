import React from "react";
import styles from "../style/door6.module.css";
import { motion } from "framer-motion";
export default function TDoor() {
  const fadeInVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };
  const container1 = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.5,
      },
    },
  };
  return (
    <motion.div
      className={styles.section3}
      initial="hidden"
      animate="visible"
      variants={{ fadeInVariants, container1 }}
      transition={{ duration: 1 }}
    >
      <div className={styles.nftboxheading3}>
        <motion.div
          initial={{ x: "-15vw", opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.6 }}
          viewport={{ once: true }}
          className={styles.nfth1}
        >
          <h1 className="text-white font-bold text-center text-3xl tracking-wide">
            Links to Explore more
          </h1>
        </motion.div>
        <div className={styles.nfttextbox}>
          <div className={styles.div1}>
            <motion.p
              initial={{ x: "-15vw", opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.6 }}
              viewport={{ once: true }}
              className="text-lg text-white tracking-wider"
            >
              Use below links to know about blockchain explorer
            </motion.p>
            <motion.button
              initial={{ x: "-100%", opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.6 }}
              viewport={{ once: true }}
              className={styles.btn}
            >
              <a
                className="font-bold underline"
                href="https://www.blockchain.com/explorer
" target="_blank"
              >
                {" "}
                Click now
              </a>
            </motion.button>
            <motion.p
              initial={{ x: "-15vw", opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.6 }}
              viewport={{ once: true }}
              className="text-lg text-white tracking-wider"
            >
              Click below for etherscan
            </motion.p>
            <motion.button
              initial={{ x: "-100%", opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.6 }}
              viewport={{ once: true }}
              className={styles.btn}
            >
              <a className="font-bold underline" target="_blank" href="https://etherscan.io/">
                {" "}
                Learn more
              </a>
            </motion.button>
            <motion.p
              initial={{ x: "-15vw", opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.6 }}
              viewport={{ once: true }}
              className="text-lg text-white tracking-wider"
            >
              Search more
            </motion.p>
            <motion.button
              initial={{ x: "-100%", opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.6 }}
              viewport={{ once: true }}
              className={styles.btn}
            >
              <a
                className="font-bold underline"
                target="_blank"
                href="https://www.coincarp.com/currencies/ethereum/richlist/"
              >
                Click here
              </a>
            </motion.button>
          </div>
          <div className={styles.div2}>
            <motion.p
              initial={{ x: "15vw", opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.6 }}
              viewport={{ once: true }}
              className="text-lg text-white tracking-wider"
            >
              Know more about polygonscan
            </motion.p>
            <motion.button
              initial={{ x: "15vw", opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.6 }}
              viewport={{ once: true }}
              className={styles.btn}
            >
              <a
                className="font-bold underline"
                target="_blank"
                href="https://polygonscan.com/"
              >
                Find more{" "}
              </a>
            </motion.button>
            <motion.p
              initial={{ x: "15vw", opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.6 }}
              viewport={{ once: true }}
              className="text-lg text-white tracking-wider"
            >
              Explore more about charts and richest bitcoin
            </motion.p>
            <motion.button
              initial={{ x: "15vw", opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.6 }}
              viewport={{ once: true }}
              className={styles.btn}
            >
              <a
                className="font-bold underline"
                target="_blank"
                href="https://bitinfocharts.com/top-100-richest-bitcoin-addresses.html
"
              >
                {" "}
                Click here
              </a>
            </motion.button>
            <motion.p
              initial={{ x: "15vw", opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.6 }}
              viewport={{ once: true }}
              className="text-lg text-white tracking-wider"
            >
              Explore more about Gas Fees
            </motion.p>
            <motion.button
              initial={{ x: "15vw", opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.6 }}
              viewport={{ once: true }}
              className={styles.btn}
            >
              <a
              target="_blank"
                className="font-bold underline" 
                href="/ADoor15"
                >
                {" "}
                Click here
              </a>
            </motion.button>
          </div>
        </div>
      </div>
    </motion.div>
  );
}
