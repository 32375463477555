
import { LogoCP } from "../assets/Images/MyWeb3Gift-red2";
import LoginButton from "./LoginButton";
import { Link, useNavigate } from "react-router-dom";


import { SignInButton, SignedOut, SignedIn, UserButton, SignOutButton, SignUpButton } from '@clerk/clerk-react';


import Menu1 from "./Menu1";
import { motion,AnimatePresence } from "framer-motion";
import React, { useEffect, useState } from "react";
import LocomotiveScroll from "locomotive-scroll";
import "locomotive-scroll/dist/locomotive-scroll.css";
import "./Style/Nav01.css";
import "../sections_landing/Styles/main.css";

import {
  collectionGroup,
  query,
  getDocs,
  getFirestore,
  where,
} from "@firebase/firestore";
import { useUser } from "@clerk/clerk-react";
import History from "./History";
import SubMenu from "./Submenu";
import { NavLink, useLocation, useRoutes } from "react-router-dom";
import { useRef } from "react";
import { useMediaQuery } from "react-responsive";
import { TfiControlBackward } from "react-icons/tfi";
import { FaCircleArrowLeft } from "react-icons/fa6";
import Credit from "./Credit";


const Nav01 = () => {
  const { user } = useUser();
  const [gifts, setGifts] = useState([]);
  const db = getFirestore();
  const { isSignedIn } = useUser();
  const [credits, setCredits] = React.useState(0);
      const [freecredits, setFreeCredits] = React.useState(0);
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); // Go back one step
  };
 const freecalculateCreditsLeft = () => {
      const freegiftsSentWithLoginFromStorage = parseInt(localStorage.getItem('giftsSentWithoutLogin')) || 0;
      const freecreditsLeft = Math.max(0, 2-freegiftsSentWithLoginFromStorage );
   
      setFreeCredits(freecreditsLeft);
      return freecreditsLeft;
     
    };
  const fetchGiftsByReceiverEmail = async (receiverEmail) => {
    try {
      // Reference to the "gifts" collection group
      const giftsCollectionGroupRef = collectionGroup(db, "gifts");

      // Query to filter gifts based on receiver's email
      const query1 = query(
        giftsCollectionGroupRef,
        where("receiverEmail", "==", receiverEmail)
      );

      // Fetch documents that match the query
      const querySnapshot = await getDocs(query1);

      const fetchedGifts = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // console.log("Receiver's gifts: ", fetchedGifts);
      setGifts(fetchedGifts);
    } catch (error) {
      console.error("Error fetching gifts by receiver email:", error);
    }
  };

  const [mdOptionsToggle, setMdOptionsToggle] = useState(true);
  const [showMenu, setShowMenu] = useState(false);



  const [Navbar, setNavbar] = useState(false);
  // console.log("this is ScrollToPlugin", window.scrollY);

  const ChangeBackground = () => {
    if (window.scrollY >= 30) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", ChangeBackground);


  useEffect(() => {
    if (!user || !user.primaryEmailAddress) {
      // console.log("User or primaryEmailAddress is not defined.");
      return;
    }

    // const receiverEmail = user.primaryEmailAddress.emailAddress;
    const receiverEmail = user.primaryEmailAddress.emailAddress;
    // console.log("Receiver Email:", receiverEmail);

    fetchGiftsByReceiverEmail(receiverEmail);
    const element = document.querySelector("[data-scroll-container]");
    if (element) {
      const scroll = new LocomotiveScroll({
        el: document.querySelector("[data-scroll-container]"),
        smooth: true,
      });

      // Update background color based on scroll position
      scroll.on("scroll", (args) => {
        setNavbar(args.scroll.y >= 30);
      });
      return () => {
        scroll.destroy();
      };
    } else {
    }
  }, [user]);
  let isTabletMid = useMediaQuery({ query: "(max-width: 768px)" });
  const [open, setOpen] = useState(isTabletMid ? false : true);
  const sidebarRef = useRef();
  const { pathname } = useLocation();

  useEffect(() => {
    if (isTabletMid) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [isTabletMid]);

  useEffect(() => {
    isTabletMid && setOpen(false);
  }, [pathname]);

  const subMenusList = [
    {
      name: "History",
      // icon: TbReportAnalytics,
      menus: ["Received", "Sent"],
    },
  ];
  const calculateCreditsLeft = () => {
    const giftsSentWithLoginFromStorage = parseInt(localStorage.getItem('giftsSentWithLogin')) || 0;
    const creditsLeft = Math.max(0, 5-giftsSentWithLoginFromStorage );
    // console.log("gadsged",creditsLeft)
    setCredits(creditsLeft);
    return creditsLeft;
   
  };
  useEffect(() => {
      freecalculateCreditsLeft()
    calculateCreditsLeft();
  }, []);  // Empty dependency array ensures it runs once after the initial render
  

  return (
    <>
      <motion.div 
        // style={{ fontFamily: '"Raleway",sans-seerif' }}
        className="z-50 unselectable fixed top-0 w-screen font-bold  "
      >
        <motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.2, delay: "0" }}
            viewport={{ once: true }}
            className="unselectable"
          >
           <AnimatePresence>
            <motion.div
              className={`${Navbar ? "navbar active" : "navbar"
                } py-2  sm:px-2 sm:pr-8 `}
                initial={{ opacity: 0, y: "-100%" }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: "-100%" }}
      transition={{duration:"0.5"}}
            >
              {/* {console.log(window.scrollY)} */}

              <div className=" flex items-center justify-between">
              <div className="">
                <h1
                  className=" cursor-pointer text-4xl flex Logo_heading mx-4  text-gray-100 "
                  aria-label="MyWeb3Gift "
                >
                <div className="hidden md:flex items-center mr-5 ">
              <button
                onClick={handleGoBack}
                className="text-gray-100 focus:outline-none focus:ring-2 rounded focus:ring-gray-600"
              >
                <TfiControlBackward className="text-4xl text-yellow-300"/>

              </button>
            </div>
            <div className="flex -ml-3 md:hidden  items-center">
              <button
                onClick={handleGoBack}
                className="text-gray-100 focus:outline-none focus:ring-2 rounded focus:ring-gray-600"
              >
                <TfiControlBackward className="text-xl max-[280px]:text-xs text-yellow-300" />
              </button>
            </div>
               <div className="flex items-center">
                  <Link to="/">
                    <img
                      src={LogoCP}
                      className=" w-16  rounded-full   hover:ease-in-out hover:duration-700 hover:delay-300  hover:scale-110     "
                      alt="Logo"
                    />

                  </Link>
                  
                  <Link to="/">
                    {" "}
                    <p
                      // style={{ fontFamily: '"Raleway",san-serif' }}
                      className="py-4 px-2"
                    >
                      <span className="text-yellow-400  ">My</span>
                      <span className="text-yellow-400">Web3</span>
                      <span className="text-yellow-400">Gift</span>
                    </p>{" "}
                  </Link>
                  </div>
                </h1>
                
</div>
                <div className="md:w-2/12 justify-end flex items-center space-x-4 xl:space-x-8">
                <div className="max-sm:hidden">
                    <Credit creditsLeft={credits}/>
                  </div>
                  <div className="max-sm:hidden lg:flex items-center space-x-2 xl:space-x-2   backdrop-filter  ">
                    {isSignedIn && (
                      <>

                        <div className="max-sm:hidden">
                          <History />
                        </div>

                      </>
                    )}
                  </div>
                 
                  <div className="max-sm:hidden">
                    <Menu1 />
                  </div>
                  <div className="max-sm:hidden rounded-xl  ring-inset ml-4 ">
                    <LoginButton />
                  </div>

                  <div className="flex md:hidden mr-2">
                    <button
                      aria-label="show options"
                      onClick={() => setMdOptionsToggle(!mdOptionsToggle)}
                      className="text-gray-100   hidden md:flex focus:outline-none focus:ring-2 rounded focus:ring-gray-600"
                    >
                      <svg
                        className="fill-stroke"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4 6H20"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M10 12H20"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M6 18H20"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                    <button
                      aria-label="open menu"
                      onClick={() => setShowMenu(true)}
                      className="text-gray-100   md:hidden focus:outline-none focus:ring-2 rounded focus:ring-gray-600"
                    >
                      <motion.svg
                        className="fill-stroke"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        initial={{ rotate: 0 }}
    animate={{ rotate: showMenu ? 45 : 0 }}
    transition={{ duration: 0.3 }}
                      >
                        <path
                          d="M4 6H20"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M10 12H20"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M6 18H20"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </motion.svg>
                    </button>
                  </div>
                </div>
              </div>
              
            </motion.div>
            </AnimatePresence>
            <AnimatePresence>
            {showMenu && (
            <motion.div
              id="mobile-menu"
              className={`${showMenu ? "flex" : "hidden"
                } absolute  z-50 inset-0 md:hidden  bg-sky-950 text-white flex-col h-screen w-full`}
                initial={{ opacity: 0, y: "-100%" }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: "-100%" }}
      transition={{duration:"0.5"}}
            >
            
              <motion.div className="flex items-center justify-between z-50 border-b border-gray-200  pb-4 p-4">
                {/* <div className=" flex items-center justify-between hover:underline text-base  text-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-800">
                  <LoginButton />     
                </div> */}
                <div>
               
                </div>
                <motion.button
                  onClick={() => setShowMenu(false)}
                  aria-label="close menu"
                  className="focus:outline-none focus:ring-2 rounded focus:ring-gray-600"
                >
                  <motion.svg
                    className="fill-stroke text-gray-100 "
                    width={16}
                    height={16}
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
    transition={{ duration: 0.3 }}
                  >
                    <path
                      d="M12 4L4 12"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M4 4L12 12"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </motion.svg>
                </motion.button>
              </motion.div>

              <motion.div className="mt-6 p-4">
                <motion.ul className="flex flex-col space-y-6"
                 initial={{ opacity: 0, x: "-100%" }}
      whileInView={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: "-100%" }}
      transition={{duration:"0.3"}}
                >
                  <motion.li
                  initial={{ opacity: 0, x: "-100%" }}
      whileInView={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: "-100%" }}
      transition={{duration:"0.3"}}
                  >
                    <Link
                      href="/"
                      className=" flex items-center justify-between hover:underline text-base text-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-800"
                    >
                      Home
                      <div>
                        <svg
                          className="fill-stroke text-gray-100 "
                          width={12}
                          height={12}
                          viewBox="0 0 12 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4.5 3L7.5 6L4.5 9"
                            stroke="currentColor"
                            strokeWidth="0.75"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </Link>
                  </motion.li>
 <motion.li
 initial={{ opacity: 0, x: "-100%" }}
      whileInView={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: "-100%" }}
      transition={{duration:"0.4"}}
 >
                    <Link
                      href="/"
                      className=" flex items-center justify-between hover:underline text-base text-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-800"
                    >
                           <SignedIn>
        
         <p className='text-lg'> Credits: 
         {gifts.length >= 0 ? (
         <span>  {credits}</span>
         ): null}
         </p>
        
        </SignedIn>

        <SignedOut>
         
         <p className='text-xl'> Credits: 
         {gifts.length >= 0 ? (
         <span>  {freecredits}</span>
         ): null}
         </p>
       
        </SignedOut>
                      <div>
                        <svg
                          className="fill-stroke text-gray-100 "
                          width={12}
                          height={12}
                          viewBox="0 0 12 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4.5 3L7.5 6L4.5 9"
                            stroke="currentColor"
                            strokeWidth="0.75"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </Link>
                  </motion.li>



                  <SignedIn>

                    <motion.li initial={{ opacity: 0, x: "-100%" }}
      whileInView={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: "-100%" }}
      transition={{duration:"0.5"}}>
                      <Link
                        to="/userprofile"
                        className=" flex items-center justify-between hover:underline text-base text-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-800"
                      >

                        Profile

                        <motion.div>
                          <svg
                            className="fill-stroke text-gray-100 "
                            width={12}
                            height={12}
                            viewBox="0 0 12 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M4.5 3L7.5 6L4.5 9"
                              stroke="currentColor"
                              strokeWidth="0.75"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </motion.div>
                      </Link>
                    </motion.li>

                  </SignedIn>






                  <motion.li initial={{ opacity: 0, x: "-100%" }}
      whileInView={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: "-100%" }}
      transition={{duration:"0.5"}}>
                    <Link
                      to="/about"
                      className=" flex items-center justify-between hover:underline text-base text-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-800"
                    >
                      About
                      <div>
                        <svg
                          className="fill-stroke text-gray-100 "
                          width={12}
                          height={12}
                          viewBox="0 0 12 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4.5 3L7.5 6L4.5 9"
                            stroke="currentColor"
                            strokeWidth="0.75"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </Link>
                  </motion.li>
                  {isSignedIn && (
                    <>
                      {(open || isTabletMid) && (
                        <motion.div className=" border-slate-300 "
                        initial={{ opacity: 0, x: "-100%" }}
      whileInView={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: "-100%" }}
      transition={{duration:"0.6"}}
                        >
                          {subMenusList?.map((menu) => (
                            <motion.div
                              key={menu.name}
                              className="flex flex-col gap-1"
                            >
                              <SubMenu data={menu} />
                            </motion.div>
                          ))}
                        </motion.div>
                      )}
                    </>
                  )}



                  <motion.li initial={{ opacity: 0, x: "-100%" }}
      whileInView={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: "-100%" }}
      transition={{duration:"0.6"}}>
                    <Link
                      to="/contact"
                      className=" flex items-center justify-between hover:underline text-base text-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-800"
                    >
                      Contact
                      <div>
                        <svg
                          className="fill-stroke text-gray-100 "
                          width={12}
                          height={12}
                          viewBox="0 0 12 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4.5 3L7.5 6L4.5 9"
                            stroke="currentColor"
                            strokeWidth="0.75"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </Link>
                  </motion.li>

                  {/* <li>
                    <Link
                      to="/team"
                      className=" flex items-center justify-between hover:underline text-base text-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-800"
                    >
                      Team
                      <div>
                        <svg
                          className="fill-stroke text-gray-100 "
                          width={12}
                          height={12}
                          viewBox="0 0 12 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4.5 3L7.5 6L4.5 9"
                            stroke="currentColor"
                            strokeWidth="0.75"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </Link>
                  </li> */}

                  <motion.li initial={{ opacity: 0, x: "-100%" }}
      whileInView={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: "-100%" }}
      transition={{duration:"0.6"}}>
                    <Link
                      to="/blog"
                      className=" flex items-center justify-between hover:underline text-base text-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-800"
                    >
                      Further Readings
                      <div>
                        <svg
                          className="fill-stroke text-gray-100 "
                          width={12}
                          height={12}
                          viewBox="0 0 12 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4.5 3L7.5 6L4.5 9"
                            stroke="currentColor"
                            strokeWidth="0.75"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </Link>
                  </motion.li>
                  <motion.li initial={{ opacity: 0, x: "-100%" }}
      whileInView={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: "-100%" }}
      transition={{duration:"0.7"}}>
                    <Link
                      to="/faqs"
                      className=" flex items-center justify-between hover:underline text-base text-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-800"
                    >
                      FAQ's
                      <div>
                        <svg
                          className="fill-stroke text-gray-100 "
                          width={12}
                          height={12}
                          viewBox="0 0 12 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4.5 3L7.5 6L4.5 9"
                            stroke="currentColor"
                            strokeWidth="0.75"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </Link>
                  </motion.li>

                  <SignedOut afterSignInUrl="/" >
                    <motion.li initial={{ opacity: 0, x: "-100%" }} 
      whileInView={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: "-100%" }}
      transition={{duration:"0.8"}}>
                      <Link
                        to="/loginpage"
                        className=" flex items-center justify-between hover:underline text-base text-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-800"
                      >

                        Login

                        <div>
                          <svg
                            className="fill-stroke text-gray-100 "
                            width={12}
                            height={12}
                            viewBox="0 0 12 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M4.5 3L7.5 6L4.5 9"
                              stroke="currentColor"
                              strokeWidth="0.75"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                      </Link>
                    </motion.li>
                  </SignedOut>





                  <SignedIn>


                    <motion.li initial={{ opacity: 0, x: "-100%" }}
      whileInView={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: "-100%" }}
      transition={{duration:"0.8"}}>
                      <Link
                        to="/"
                        className=" flex items-center justify-between hover:underline text-base text-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-800"
                      >

                        <SignOutButton aftersignouturl="/" >Logout </SignOutButton>

                        <div>
                          <svg
                            className="fill-stroke text-gray-100 "
                            width={12}
                            height={12}
                            viewBox="0 0 12 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M4.5 3L7.5 6L4.5 9"
                              stroke="currentColor"
                              strokeWidth="0.75"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                      </Link>
                    </motion.li>


                  </SignedIn>


                </motion.ul>
              </motion.div>
              <div className="h-full flex items-end">
              </div>
            </motion.div>
            )}
            </AnimatePresence>
            
          </motion.div>
        </motion.div>
      </motion.div>
    </>
  );
};

export default Nav01;
