import React from 'react'
import styles from "../style/door12.module.css";
import { db3 } from '../../DoorsImages/Doorimg12';
import { motion } from 'framer-motion';
export default function SDoor() {
  return (
    <div className={styles.section2}>
     <motion.div initial={{ opacity:0}}
            whileInView={{ opacity:1 }}
            transition={{duration:3}}
             viewport={{once:true}} className={styles.webimg}>
      <img src={db3} alt="dbimg"/>
    </motion.div>
<div className={styles.paragraph}>
<motion.p initial={{ opacity:0}}
            whileInView={{  opacity:1 }}
            transition={{duration:3}}
             viewport={{once:true}} className='text-2xl text-center whitespace-pre-line text-white'>
In summary, while oracles play a crucial role in expanding the capabilities of
smart contracts, careful consideration and implementation are 
required to address potential security and centralization 
concerns. Developers and users in the blockchain space
need to choose or design oracles that align with 
the specific requirements of their use cases.
  </motion.p>
</div>
    </div>
  )
}
