// // import React, { useEffect, useState } from 'react';
// // import { useUser } from '@clerk/clerk-react';
// // import { arrayUnion, doc, getDoc, getFirestore, setDoc, updateDoc } from 'firebase/firestore';
// // import Modal from 'react-modal';
// // import { useNavigate } from 'react-router-dom';

// // const DoorComplete = ({Door,id}) => {
// //   const { user } = useUser();
// //   const userId = user ? user.id : null;
// //   const [completed, setCompleted] = useState(false);
// //   const navigate = useNavigate();
// //   const [userProgress, setUserProgress] = useState({ currentDay: 1, completedDoors: [] });
// //   const [isModalOpen, setIsModalOpen] = useState(false);
// //   const [feedback, setFeedback] = useState({ text: '', rating: 0 });

// //   useEffect(() => {
// //     const fetchUserProgress = async (id) => {
// //         if (!userId) {
// //             // Handle the case when there is no user
// //             return;
// //         }
// // //console.log("halgewsg",id)
// //         const db = getFirestore();
// //         const userProgressRef = doc(db, `users/${userId}/progress/${id}`);

// //         try {
// //             const docSnap = await getDoc(userProgressRef);

// //             if (docSnap.exists()) {
// //                 setUserProgress(docSnap.data());
// //             } else {
// //                 // User document doesn't exist in the database, create one
// //                 const completedDoorsArray = Array(25).fill(false); // Create an array of size 24 with all values set to false
// //                 await setDoc(userProgressRef, { currentDay: 1, completedDoors: completedDoorsArray });
// //                 setUserProgress({ currentDay: 1, completedDoors: completedDoorsArray });
// //             }
// //         } catch (error) {
// //             console.error('Error fetching/creating user document:', error);
// //         }
// //     };

// //     fetchUserProgress(id);

// //     if(userProgress.completedDoors[Door]===true){
// //       setCompleted(true)
// //     }
// //     else {
// //       setCompleted(false);
// //     }

// // }, [ userProgress, Door]);

// // const toggleModal = () => {
// //   setIsModalOpen((prevState) => !prevState);
// // };

// // const handleDoorCompletion = async (Door,id) => {
// //     if (!userId) {
// //         // Handle the case when there is no user
// //         return;
// //     }

// //     const db = getFirestore();
// //     const userProgressRef = doc(db, `users/${userId}/progress/${id}`);

// //     try {
// //         // Fetch user's current progress
// //         const userProgressDoc = await getDoc(userProgressRef);
// //         const userProgress = userProgressDoc.data();
// // //console.log("hello",userProgress.completedDoors[Door])
// //         // Ensure the Door index is within the array bounds (0 to 23 for an array of size 24)
// //         if (Door >= 0 && Door < 25) {
// //             // Update the specific Door to true
// //             userProgress.completedDoors[Door] = true;
// //             setCompleted(true)
// //         }

// //         if(userProgress.completedDoors[Door]===true){
// //           setCompleted(true)
// //         }

// //         // Increment currentDay by 1
// //         const updatedCurrentDay = userProgress.currentDay + 1;

// //         // Update user's completed doors in Firestore and increase currentDay
// //         await updateDoc(userProgressRef, {
// //             completedDoors: userProgress.completedDoors,
// //             currentDay: updatedCurrentDay,
// //         });
// //         // navigate('/feedback')
// //         // setIsModalOpen(true);
// //         console.log('Modal should be open now');
// //         //  navigate(-1)
// //         //console.log(`Door ${Door} marked as completed for user:`, userId);
// //     } catch (error) {
// //         console.error('Error updating user document:', error);
// //     }
// // };

// // const handleSkip = () => {
// //   navigate(-1);
// // };

// // const handleSaveFeedback = async (Door, id) => {
// //   const db = getFirestore();
// //   const userFeedbackRef = doc(db, `users/${userId}/feedback/${id}`);

// //   try {
// //     const userFeedbackDoc = await getDoc(userFeedbackRef);
// //     const userFeedbackData = userFeedbackDoc.data() || {};

// //     // Ensure the Door index is within the array bounds (0 to 23 for an array of size 24)
// //     if (Door >= 0 && Door < 25) {
// //       // Update the specific Door feedback and rating
// //       userFeedbackData[Door] = {
// //         feedback: feedback.text,
// //         rating: feedback.rating
// //       };

// //       // Update user's feedback in Firestore
// //       await setDoc(userFeedbackRef, userFeedbackData, { merge: true });
// //       // setIsModalOpen(false); // Close the modal after saving feedback
// //       console.log('Modal should be open now');
// //       navigate('-1')
// //     } else {
// //       console.error('Invalid Door index');
// //     }
// //   } catch (error) {
// //     console.error('Error saving feedback:', error);
// //   }

// //   // Reset feedback state
// //   setFeedback({ text: '', rating: 0 });
// // };

// //   const handleClick = () => {
// //     // Navigate to the previous page
// //     navigate(-1);
// //   };

// //   return (

// //     <div>
// //       {completed ? (
// //         <button
// //           className='bg-gradient-to-r from-sky-700 to-sky-500 text-3xl font-bold text-white py-4 px-7 rounded-3xl my-7 hover:bg-gradient-to-r hover:from-sky-500 hover:to-sky-300 transition duration-300'
// //           onClick={handleClick}
// //         >
// //           Let's Open New Doors
// //         </button>
// //       ) :  (
// //         <>
// //         <button
// //          onClick={() => {
// //           handleDoorCompletion(Door, id);
// //           toggleModal();
// //         }}

// //           className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full focus:outline-none focus:shadow-outline-blue active:bg-blue-800"
// //         >
// //           Click here to complete task
// //         </button>
// //          <Modal
// //          isOpen={isModalOpen}
// //          onRequestClose={toggleModal}
// //          contentLabel="Feedback Modal"
// //        >
// //                <h2>Provide Feedback</h2>
// //         <label htmlFor="feedbackTextInput">
// //           Feedback:{" "}
// //           <input
// //             type="text"
// //             id="feedbackTextInput"
// //             value={feedback.text}
// //             onChange={(e) =>
// //               setFeedback({ ...feedback, text: e.target.value })
// //             }
// //           />
// //         </label>
// //         <label htmlFor="feedbackRatingInput">
// //           Rating (1-10):{" "}
// //           <input
// //             type="number"
// //             id="feedbackRatingInput"
// //             min="1"
// //             max="10"
// //             value={feedback.rating}
// //             onChange={(e) =>
// //               setFeedback({ ...feedback, rating: parseInt(e.target.value, 10) })
// //             }
// //           />
// //         </label>
// //             <button onClick={() => handleSaveFeedback(Door, id)}>
// //             Save Feedback
// //             </button>

// //             <button onClick={toggleModal}>Cancel</button>
// //        </Modal>
// //        </>
// //       )}
// //     </div>

// //   );
// // };

// // export default DoorComplete;

import React, { useEffect, useState } from "react";
import { useUser } from "@clerk/clerk-react";
import {
  arrayUnion,
  doc,
  getDoc,
  getFirestore,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import Feedback from "./Feedback";
import confetti from "canvas-confetti";

const DoorComplete = ({ Door, id }) => {
  const { user } = useUser();
  const userId = user ? user.id : null;
  const [completed, setCompleted] = useState(false);
  const navigate = useNavigate();
  const [userProgress, setUserProgress] = useState({
    currentDay: 1,
    completedDoors: new Array(25).fill(false),
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [feedback, setFeedback] = useState({ text: "", rating: 0 });

  const toggleModal = () => {
    setIsModalOpen((prevState) => !prevState);
  };

  useEffect(() => {
    const fetchUserProgress = async (id) => {
      if (!userId) {
        // Handle the case when there is no user
        return;
      }
      //console.log("halgewsg",id)
      const db = getFirestore();
      const userProgressRef = doc(db, `users/${userId}/progress/${id}`);

      try {
        const docSnap = await getDoc(userProgressRef);

        if (docSnap.exists()) {
          setUserProgress(docSnap.data());
        } else {
          // User document doesn't exist in the database, create one
          const completedDoorsArray = Array(25).fill(false); // Create an array of size 24 with all values set to false
          await setDoc(userProgressRef, {
            currentDay: 1,
            completedDoors: completedDoorsArray,
          });
          setUserProgress({
            currentDay: 1,
            completedDoors: completedDoorsArray,
          });
        }
      } catch (error) {
        console.error("Error fetching/creating user document:", error);
      }
    };

    fetchUserProgress(id);

    if (userProgress.completedDoors[Door] === true) {
      setCompleted(true);
    } else {
      setCompleted(false);
    }
  }, [userProgress, Door]);

  const handleDoorCompletion = async (Door, id) => {
    if (!userId) {
      return;
    }

    const db = getFirestore();
    const userProgressRef = doc(db, `users/${userId}/progress/${id}`);

    try {
      const userProgressDoc = await getDoc(userProgressRef);
      const userProgress = userProgressDoc.data();

      if (Door >= 0 && Door < 25) {
        userProgress.completedDoors[Door] = true;
        setCompleted(true);
      }

      const updatedCurrentDay = userProgress.currentDay + 1;

      await updateDoc(userProgressRef, {
        completedDoors: userProgress.completedDoors,
        currentDay: updatedCurrentDay,
      });

      console.log(`Door ${Door} marked as completed for user: ${userId}`);
    } catch (error) {
      console.error("Error updating user document:", error);
    }
  };

  const handleClick = () => {
    // Navigate to the previous page
    navigate(`/10-Day Crypto Challenge/${id}`);
  };
  const confettihandle = () => {
    if (Door === 10) startConfetti();
  };

  const startConfetti = () => {
    const duration = 15 * 1000;
    const animationEnd = Date.now() + duration;
    const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

    function randomInRange(min, max) {
      return Math.random() * (max - min) + min;
    }

    const interval = setInterval(() => {
      const timeLeft = animationEnd - Date.now();

      if (timeLeft <= 0) {
        clearInterval(interval);
        return;
      }

      const particleCount = 50 * (timeLeft / duration);

      // since particles fall down, start a bit higher than random
      confetti(
        Object.assign({}, defaults, {
          particleCount,
          origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
        })
      );
      confetti(
        Object.assign({}, defaults, {
          particleCount,
          origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
        })
      );
    }, 250);

    return () => {
      clearInterval(interval);
    };
  };
  // const handleSkip = () => {
  //   navigate(-1);
  // };

  // const handleSaveFeedback = async (Door, id) => {
  //   const db = getFirestore();
  //   const userFeedbackRef = doc(db, `users/${userId}/feedback/${id}`);

  //   try {
  //     const userFeedbackDoc = await getDoc(userFeedbackRef);
  //     const userFeedbackData = userFeedbackDoc.data() || {};

  //     if (Door >= 0 && Door < 25) {
  //       userFeedbackData[Door] = {
  //         feedback: feedback.text,
  //         rating: feedback.rating,
  //       };

  //       await setDoc(userFeedbackRef, userFeedbackData, { merge: true });
  //       toggleModal();
  //       console.log("Modal closed after providing feedback.");
  //       navigate(-1);
  //     } else {
  //       console.error("Invalid Door index");
  //     }
  //   } catch (error) {
  //     console.error("Error saving feedback:", error);
  //   }

  //   setFeedback({ text: "", rating: 0 });
  // };

  return (
    <div>
      {completed ? (
        <button
          className="bg-gradient-to-r from-sky-700 to-sky-500 text-3xl font-bold text-white py-4 px-7 rounded-3xl my-7 hover:bg-gradient-to-r hover:from-sky-500 hover:to-sky-300 transition duration-300"
          onClick={handleClick}
        >
          Let's Open New Doors
        </button>
      ) : (
        <>
          <button
            onClick={() => {
              handleDoorCompletion(Door, id);
              toggleModal();
              confettihandle();
            }}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full focus:outline-none focus:shadow-outline-blue active:bg-blue-800 mr-5"
          >
            Complete Task
          </button>
        </>
      )}

      {isModalOpen && (
        <Feedback toggleModal={toggleModal} Door={Door} id={id} />
      )}
    </div>
  );
};

export default DoorComplete;

// import React, { useEffect, useState } from 'react';
// import { useUser } from '@clerk/clerk-react';
// import { arrayUnion, doc, getDoc, getFirestore, setDoc, updateDoc } from 'firebase/firestore';
// import { useNavigate } from 'react-router-dom';

// const DoorComplete = ({Door,id}) => {
//   const { user } = useUser();
//   const userId = user ? user.id : null;
//   const [completed, setCompleted] = useState(false);
//   const navigate = useNavigate();
//   const [userProgress, setUserProgress] = useState({ currentDay: 1, completedDoors: [] });

//   useEffect(() => {
//     const fetchUserProgress = async (id) => {
//         if (!userId) {
//             // Handle the case when there is no user
//             return;
//         }
// //console.log("halgewsg",id)
//         const db = getFirestore();
//         const userProgressRef = doc(db, `users/${userId}/progress/${id}`);

//         try {
//             const docSnap = await getDoc(userProgressRef);

//             if (docSnap.exists()) {
//                 setUserProgress(docSnap.data());
//             } else {
//                 // User document doesn't exist in the database, create one
//                 const completedDoorsArray = Array(25).fill(false); // Create an array of size 24 with all values set to false
//                 await setDoc(userProgressRef, { currentDay: 1, completedDoors: completedDoorsArray });
//                 setUserProgress({ currentDay: 1, completedDoors: completedDoorsArray });
//             }
//         } catch (error) {
//             console.error('Error fetching/creating user document:', error);
//         }
//     };

//     fetchUserProgress(id);

//     if(userProgress.completedDoors[Door]===true){
//       setCompleted(true)
//     }
//     else {
//       setCompleted(false);
//     }

// }, [userId, userProgress, Door,id]);

// const handleDoorCompletion = async (Door,id) => {
//     if (!userId) {
//         // Handle the case when there is no user
//         return;
//     }

//     const db = getFirestore();
//     const userProgressRef = doc(db, `users/${userId}/progress/${id}`);

//     try {
//         // Fetch user's current progress
//         const userProgressDoc = await getDoc(userProgressRef);
//         const userProgress = userProgressDoc.data();
// //console.log("hello",userProgress.completedDoors[Door])
//         // Ensure the Door index is within the array bounds (0 to 23 for an array of size 24)
//         if (Door >= 0 && Door < 25) {
//             // Update the specific Door to true
//             userProgress.completedDoors[Door] = true;
//             setCompleted(true)
//         }

//         if(userProgress.completedDoors[Door]===true){
//           setCompleted(true)
//         }

//         // Increment currentDay by 1
//         const updatedCurrentDay = userProgress.currentDay + 1;

//         // Update user's completed doors in Firestore and increase currentDay
//         await updateDoc(userProgressRef, {
//             completedDoors: userProgress.completedDoors,
//             currentDay: updatedCurrentDay,
//         });
//          navigate(-1)
//         //console.log(`Door ${Door} marked as completed for user:`, userId);
//     } catch (error) {
//         console.error('Error updating user document:', error);
//     }
// };

//   const handleClick = () => {
//     // Navigate to the previous page
//     navigate(-1);
//   };

//   return (
//     <div>
//       {completed ? (
//         <button
//           className='bg-gradient-to-r from-sky-700 to-sky-500 text-3xl font-bold text-white py-4 px-7 rounded-3xl my-7 hover:bg-gradient-to-r hover:from-sky-500 hover:to-sky-300 transition duration-300'
//           onClick={handleClick}
//         >
//           Let's Open New Doors
//         </button>
//       ) :  (
//         <button
//         onClick={() => handleDoorCompletion(Door,id)}

//           className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full focus:outline-none focus:shadow-outline-blue active:bg-blue-800"
//         >
//           Complete Task
//         </button>
//       )}
//     </div>
//   );
// };

// export default DoorComplete;
