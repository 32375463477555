import React from 'react';
import { motion } from 'framer-motion';
import styles from "../style/door12.module.css";

export default function Card2({text1,cheading}) {
  return (
    <>
         <motion.div initial={{ opacity:0}}
            whileInView={{  opacity:1 }}
            transition={{duration:2}}
             viewport={{once:true}}
         className={styles.cards}>
  <h1 className='text-2xl font-bold'>{cheading}</h1>
  <p className='text-lg text-center font-medium'>{text1}</p>
</motion.div>
    </>
  )
}
