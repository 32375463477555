import React from "react";
import { motion } from "framer-motion";
import { Bitcoinbg } from "../../DoorsImages/Doorimg7";
import styles from "../style/door7.module.css";

export default function FDoor() {
  return (
    <>
      <motion.div
        className={styles.section1}
        initial="hidden"
        animate="visible"
        //  variants={fadeInVariants}
        transition={{ duration: 1 }}
      >
        <motion.div
          initial={{
            y: "-15vh",
            opacity:0
          }}
          whileInView={{ y: 0, opacity:1}}
          // animate={{ opacity: 1, y: 0 }}
          transition={{
           duration:0.6
          }}
          className={styles.tokenheading}
        >
          <h1 className="text-4xl font-bold ">Blockchain</h1>
          <p className="text-center mt-3 text-lg whitespace-pre-line">
          We've engaged with wallets through NFT minting and gained insights into the information available on the blockchain. But why does it operate the way it does? Today, we'll explore the fundamentals of blockchains and examine how they can be classified.
</p>
          <p className="text-center mt-3 text-lg whitespace-pre-line">
          A blockchain is a decentralized and distributed digital ledger that records transactions across a network of computers. Each transaction is grouped into a "block," and these blocks are linked together in certain order, forming a chain.
</p>
<p className="text-center mt-3 text-lg whitespace-pre-line">
What makes blockchains unique is their immutability. Once a block is added, it cannot be altered, 
ensuring a secure and tamper-resistant record of transactions. This technology is the
 <br/>foundation for various applications, including cryptocurrencies
<br/> like Bitcoin, smart contracts, and decentralized finance.
            <br />
          </p>
        </motion.div>
        <motion.div
          className={styles.bitcoinimgbox}
          // initial={{ opacity: 0, x:20 }}
          initial={{
            y: "15vw",
            transition: { type: "spring", duration: 2.5 },
          }}
          animate={{ y: 0, transition: { type: "spring", duration: 2.5 } }}
          // animate={{ opacity: 1, y: 0 }}
          transition={{
            opacity: { ease: "linear" },
            type: "spring",
            layout: { duration: 1 },
          }}
          // whileHover={{
          //   scale: 0.9,
          //   transition: { type: "spring", duration: 1 },
          // }}
          // exit={{
          //   x: "-60vw",
          //   scale: [1, 0],
          //   transition: { duration: 1 },
          // }}
        >
          <img src={Bitcoinbg} alt="nftimg" />
        </motion.div>
      </motion.div>
    </>
  );
}
