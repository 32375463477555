import React from 'react'
import styles from "../style/door13.module.css";
import { WavyTech } from '../../DoorsImages/Doorimg13';
import { motion } from 'framer-motion';
export default function Cardd1() {
  return (
    <>
<motion.div initial={{ opacity:0}}
            whileInView={{ opacity:1 }}
            transition={{duration:3}}
             viewport={{once:true}} className={styles.cardd1}>
<p className='text-lg tracking-widest leading-8 text-white text-center'>
In traditional games, players don't truly own the in-game items they acquire.
However, in crypto gaming, assets like characters, weapons, or skins are represented as non-fungible tokens (NFTs) on the blockchain. NFTs are unique digital assets that can be bought, sold, and traded, giving players true ownership.
</p>
<div className={styles.imgbox}>
    <img src={WavyTech}/>
</div>

</motion.div>
    </>
  )
}
